/*
 *
 * OperationsContainer reducer
 *
 */

import {
  // FETCH_TRACKER_DETAILS_ACTION,
  FETCH_TRACKER_DETAILS_SUCCESS_ACTION,
  FETCH_TRACKER_DETAILS_ERROR_ACTION,
  FETCH_TRACKER_SECTION_LIST_ACTION,
  FETCH_TRACKER_SECTION_LIST_SUCCESS_ACTION,
  FETCH_TRACKER_SECTION_LIST_ERROR_ACTION,
  FETCH_TRACKER_SECTION_DETAILS_ACTION,
  FETCH_TRACKER_SECTION_DETAILS_SUCCESS_ACTION,
  FETCH_TRACKER_SECTION_DETAILS_ERROR_ACTION,
  ADD_TRACKER_ACTION,
  ADD_TRACKER_SUCCESS_ACTION,
  ADD_TRACKER_ERROR_ACTION,
  // FETCH_TRACKER_SCORE_CARDS_ACTION,
  FETCH_TRACKER_SCORE_CARDS_SUCCESS_ACTION,
  FETCH_TRACKER_SCORE_CARDS_ERROR_ACTION,
  FETCH_TRACKER_MERGE_ACTION,
} from './constants';

export const initialState = {
  trackers: {
    loading: false,
    error: null,
    payload: [],
  },
  sectionList: {
    loading: false,
    error: null,
    payload: [],
  },
  scores: {
    error: null,
    loading: false,
    payload: {
      netImpact: 0,
      ongoingCategoryTracker: 0,
      ongoingItemTracker: 0,
      ongoingSubCategoryTracker: 0,
    },
  },
  sectionDetails: {
    loading: false,
    error: null,
    payload: [
      { label: 'Name', value: '-' },
      { label: 'ID', value: '-' },
      { label: 'Sequence', value: '-' },
      // { label: 'View Type', value: '-' },
    ],
  },
  addTracker: {
    loading: false,
    error: null,
    success: false,
  },
  isReady: false,
};

function trackerReducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    // case FETCH_TRACKER_SCORE_CARDS_ACTION:
    //   return {
    //     ...state,
    //     scores: {
    //       error: null,
    //       loading: true,
    //       payload: state.scores.payload,
    //     },
    //   };

      case FETCH_TRACKER_MERGE_ACTION:
      return {
        ...state,
        scores: {
          error: null,
          loading: true,
          payload: state.scores.payload,
        },
        trackers: {
          error: null,
          loading: true,
          payload: state.trackers.payload,
        },
      };
    case FETCH_TRACKER_SCORE_CARDS_SUCCESS_ACTION:
      return {
        ...state,
        scores: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case FETCH_TRACKER_SCORE_CARDS_ERROR_ACTION:
      return {
        ...state,
        scores: {
          error: error,
          loading: false,
          payload: state.scores.payload,
        },
      };
    // case FETCH_TRACKER_DETAILS_ACTION:
    //   return {
    //     ...state,
    //     trackers: {
    //       error: null,
    //       loading: true,
    //       payload: state.trackers.payload,
    //     },
    //   };
    case FETCH_TRACKER_DETAILS_SUCCESS_ACTION:
      return {
        ...state,
        trackers: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case FETCH_TRACKER_DETAILS_ERROR_ACTION:
      return {
        ...state,
        trackers: {
          error: error,
          loading: false,
          payload: [],
        },
      };
    case FETCH_TRACKER_SECTION_DETAILS_ACTION:
      return {
        ...state,
        sectionDetails: {
          error: null,
          loading: true,
          payload: state.sectionDetails.payload,
        },
      };
    case FETCH_TRACKER_SECTION_DETAILS_SUCCESS_ACTION:
      return {
        ...state,
        sectionDetails: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case FETCH_TRACKER_SECTION_DETAILS_ERROR_ACTION:
      return {
        ...state,
        sectionDetails: {
          error: error,
          loading: false,
          payload: initialState.sectionDetails.payload,
        },
      };
    case FETCH_TRACKER_SECTION_LIST_ACTION:
      return {
        ...state,
        sectionList: {
          error: null,
          loading: true,
          payload: state.sectionList.payload,
        },
      };
    case FETCH_TRACKER_SECTION_LIST_SUCCESS_ACTION:
      return {
        ...state,
        sectionList: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case FETCH_TRACKER_SECTION_LIST_ERROR_ACTION:
      return {
        ...state,
        sectionList: {
          error: error,
          loading: false,
          payload: [],
        },
      };
    case ADD_TRACKER_ACTION:
      return {
        ...state,
        addTracker: {
          error: null,
          loading: true,
          success: false,
        },
      };
    case ADD_TRACKER_SUCCESS_ACTION:
      return {
        ...state,
        addTracker: {
          error: null,
          loading: false,
          success: true,
        },
      };
    case ADD_TRACKER_ERROR_ACTION:
      return {
        ...state,
        addTracker: {
          error: error,
          loading: false,
          success: false,
        },
      };

    default:
      return state;
  }
}

export default trackerReducer;
