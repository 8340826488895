/*
 *
 *
 * POS Tab Actions
 *
 */

import * as cn from './constants';

//* Dropdown Lists

export const getDropdownList = payload => {
  return {
    type: cn.DROPDOWN_LIST_INIT,
    payload,
  };
};
export const getDropdownListSuccess = payload => {
  return {
    type: cn.DROPDOWN_LIST_SUCCESS,
    payload,
  };
};
export const getDropdownListError = payload => {
  return {
    type: cn.DROPDOWN_LIST_ERROR,
    error,
  };
};

//*  Score cards
export const getScores = payload => {
  return {
    type: cn.SCORE_INIT,
    payload,
  };
};

export const getScoresSuccess = payload => {
  return {
    type: cn.SCORE_SUCCESS,
    payload,
  };
};

export const getScoresError = error => {
  return {
    type: cn.SCORE_ERROR,
    error,
  };
};

//* Summary Table
export const getSummaryTable = payload => {
  return {
    type: cn.SUMMARY_TABLE_INIT,
    payload,
  };
};

export const getSummaryTableSuccess = payload => {
  return {
    type: cn.SUMMARY_TABLE_SUCCESS,
    payload,
  };
};

export const getSummaryTableError = error => {
  return {
    type: cn.SUMMARY_TABLE_ERROR,
    error,
  };
};

//* Item table
export const getItemTable = payload => {
  return {
    type: cn.ITEM_TABLE_INIT,
    payload,
  };
};

export const getItemTableSuccess = payload => {
  return {
    type: cn.ITEM_TABLE_SUCCESS,
    payload,
  };
};

export const getItemTableError = error => {
  return {
    type: cn.ITEM_TABLE_ERROR,
    error,
  };
};

//* Category Table
export const getCategoryTable = payload => {
  return {
    type: cn.CATEGORY_TABLE_INIT,
    payload,
  };
};

export const getCategoryTableSuccess = payload => {
  return {
    type: cn.CATEGORY_TABLE_SUCCESS,
    payload,
  };
};

export const getCategoryTableError = error => {
  return {
    type: cn.CATEGORY_TABLE_ERROR,
    error,
  };
};

//* Transactions Items Table
export const getTransactionsItemsTable = payload => {
  return {
    type: cn.TRANSACTIONS_ITEMS_TABLE_INIT,
    payload,
  };
};

export const getTransactionsItemsTableSuccess = payload => {
  return {
    type: cn.TRANSACTIONS_ITEMS_TABLE_SUCCESS,
    payload,
  };
};

export const getTransactionsItemsTableError = error => {
  return {
    type: cn.TRANSACTIONS_ITEMS_TABLE_ERROR,
    error,
  };
};

//* Transactions Orders Table
export const getTransactionsOrdersTable = payload => {
  return {
    type: cn.TRANSACTIONS_ORDERS_TABLE_INIT,
    payload,
  };
};

export const getTransactionsOrdersTableSuccess = payload => {
  return {
    type: cn.TRANSACTIONS_ORDERS_TABLE_SUCCESS,
    payload,
  };
};

export const getTransactionsOrdersTableError = error => {
  return {
    type: cn.TRANSACTIONS_ORDERS_TABLE_ERROR,
    error,
  };
};

export const setDropdowns = payload => {
  return { type: cn.SET_DROPDOWNS, payload };
};
