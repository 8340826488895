//* Import Libs
import { takeLatest, call, put } from 'redux-saga/effects';

//* Import services
import * as api from './services';

//* Import transformers
import * as fm from './formatter';
import * as cn from './constants';
import * as a from './actions';

import scorecardDataTransformer from 'transformers/scorecardDataTransformer';

//* Fetch POS Scores
function* fetchScores({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(api.getScores, payload);
    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getScoresError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getScoresError('locked'));
      } else {
        const formattedData = scorecardDataTransformer(responseData[0], 'POS');
        // Feed the data to reducer
        yield put(a.getScoresSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getScoresError('Something went wrong'));
  }
}

//* Fetch Summary Table
function* fetchSummaryTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getSummaryTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getSummaryTableError(error_message));
      } else {
        const formattedData = fm.summaryTableFormatter(
          responseData,
          payload.view_type,
        );
        // Feed the data to reducer
        yield put(a.getSummaryTableSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getSummaryTableError('Something went wrong'));
  }
}

//* Fetch item table
function* fetchItemTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getItemTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;
      if (error_message) {
        yield put(a.getItemTableError(error_message));
      } else {
        const formattedData = fm.itemTableFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getItemTableSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getItemTableError('Something went wrong'));
  }
}

//* Fetch Category Table
function* fetchCategoryTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getCategoryTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getCategoryTableError(error_message));
      } else {
        const formattedData = fm.categoryTableFormatter(
          responseData,
          payload.view_type,
        );
        // Feed the data to reducer
        yield put(a.getCategoryTableSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getCategoryTableError('Something went wrong'));
  }
}

//* Fetch Transactions Items table
function* fetchTransactionsItemsTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getTransactionsItemsTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getTransactionsItemsTableError(error_message));
      } else {
        const formattedData = fm.transactionsItemsTableFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getTransactionsItemsTableSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getTransactionsItemsTableError('Something went wrong'));
  }
}

//
function* fetchTransactionsOrdersTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getTransactionsOrdersTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getTransactionsOrdersTableError(error_message));
      } else {
        const formattedData = fm.transactionsOrdersTableFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getTransactionsOrdersTableSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getTransactionsOrdersTableError('Something went wrong'));
  }
}

function* fetchDropdownList({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getDropdownLists,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getDropdownListError(error_message));
      } else {
        const formattedData = fm.dropdownListFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getDropdownListSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getDropdownListError('Something went wrong'));
  }
}

// watcher Saga generator function
export default function* watchPosContainer() {
  yield takeLatest(cn.SCORE_INIT, fetchScores);
  yield takeLatest(cn.ITEM_TABLE_INIT, fetchItemTable);
  yield takeLatest(cn.SUMMARY_TABLE_INIT, fetchSummaryTable);
  yield takeLatest(cn.CATEGORY_TABLE_INIT, fetchCategoryTable);
  yield takeLatest(
    cn.TRANSACTIONS_ORDERS_TABLE_INIT,
    fetchTransactionsOrdersTable,
  );
  yield takeLatest(
    cn.TRANSACTIONS_ITEMS_TABLE_INIT,
    fetchTransactionsItemsTable,
  );
  yield takeLatest(cn.DROPDOWN_LIST_INIT, fetchDropdownList);
}
