/*
 *
 * OperationsContainer reducer
 *
 */

import {
  FETCH_TRACKER_METRICS_ACTION,
  FETCH_TRACKER_METRICS_SUCCESS_ACTION,
  FETCH_TRACKER_METRICS_ERROR_ACTION,
  FETCH_TRACKER_CATEGORY_BY_TIME_ACTION,
  FETCH_TRACKER_CATEGORY_BY_TIME_SUCCESS_ACTION,
  FETCH_TRACKER_CATEGORY_BY_TIME_ERROR_ACTION,
  FETCH_CATEGORY_SALES_ORDERS_TRACKER_ACTION,
  FETCH_CATEGORY_SALES_ORDERS_TRACKER_SUCCESS_ACTION,
  FETCH_CATEGORY_SALES_ORDERS_TRACKER_ERROR_ACTION,
} from './constants';

export const initialState = {
  metrics: {
    loading: false,
    error: null,
    payload: [],
    timeRanges: [],
  },
  categoryByTime: {
    loading: false,
    error: null,
    payload: [],
  },
  categorySalesOrders: {
    loading: false,
    error: null,
    payload: [],
  },
  isReady: false,
};

function trackerReducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    case FETCH_TRACKER_METRICS_ACTION:
      return {
        ...state,
        metrics: {
          error: null,
          loading: true,
          payload: initialState.metrics.payload,
          timeRanges: initialState.metrics.timeRanges,
        },
      };
    case FETCH_TRACKER_METRICS_SUCCESS_ACTION:
      return {
        ...state,
        metrics: {
          error: null,
          loading: false,
          payload: payload.allMetrics,
          timeRanges: payload.timeRanges,
        },
      };
    case FETCH_TRACKER_METRICS_ERROR_ACTION:
      return {
        ...state,
        metrics: {
          error: error,
          loading: false,
          payload: initialState.metrics.payload,
          timeRanges: initialState.metrics.timeRanges,
        },
      };
    case FETCH_TRACKER_CATEGORY_BY_TIME_ACTION:
      return {
        ...state,
        categoryByTime: {
          error: null,
          loading: true,
          payload: initialState.categoryByTime.payload,
        },
      };
    case FETCH_TRACKER_CATEGORY_BY_TIME_SUCCESS_ACTION:
      return {
        ...state,
        categoryByTime: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case FETCH_TRACKER_CATEGORY_BY_TIME_ERROR_ACTION:
      return {
        ...state,
        categoryByTime: {
          error: error,
          loading: false,
          payload: [],
        },
      };
    case FETCH_CATEGORY_SALES_ORDERS_TRACKER_ACTION:
      return {
        ...state,
        categorySalesOrders: {
          error: null,
          loading: true,
          payload: state,
          payload,
        },
      };
    case FETCH_CATEGORY_SALES_ORDERS_TRACKER_SUCCESS_ACTION:
      return {
        ...state,
        categorySalesOrders: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case FETCH_CATEGORY_SALES_ORDERS_TRACKER_ERROR_ACTION:
      return {
        ...state,
        categorySalesOrders: {
          error: error,
          loading: false,
          payload: state,
          payload,
        },
      };
    default:
      return state;
  }
}

export default trackerReducer;
