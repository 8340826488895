/*
 *
 * smart connect tab constants
 *
 */

export const PARTNER_ORDERTYPE_LIST_INIT =
  'app/SmartConnect/PARTNER_ORDERTYPE_LIST_INIT';
export const PARTNER_ORDERTYPE_LIST_SUCCESS =
  'app/SmartConnect/PARTNER_ORDERTYPE_LIST_SUCCESS';
export const PARTNER_ORDERTYPE_LIST_ERROR =
  'app/SmartConnect/PARTNER_ORDERTYPE_LIST_ERROR';

export const SMART_CONNECT_SCORE_INIT =
  'app/SmartConnect/SMART_CONNECT_SCORE_INIT';
export const SMART_CONNECT_SCORE_SUCCESS =
  'app/SmartConnect/SMART_CONNECT_SCORE_SUCCESS';
export const SMART_CONNECT_SCORE_ERROR =
  'app/SmartConnect/SMART_CONNECT_SCORE_ERROR';

export const SMART_CONNECT_PERFORMANCE_INIT =
  'app/SmartConnect/SMART_CONNECT_PERFORMANCE_INIT';
export const SMART_CONNECT_PERFORMANCE_SUCCESS =
  'app/SmartConnect/SMART_CONNECT_PERFORMANCE_SUCCESS';
export const SMART_CONNECT_PERFORMANCE_ERROR =
  'app/SmartConnect/SMART_CONNECT_PERFORMANCE_ERROR';

export const SMART_CONNECT_TREND_INIT =
  'app/SmartConnect/SMART_CONNECT_TREND_INIT';
export const SMART_CONNECT_TREND_SUCCESS =
  'app/SmartConnect/SMART_CONNECT_TREND_SUCCESS';
export const SMART_CONNECT_TREND_ERROR =
  'app/SmartConnect/SMART_CONNECT_TREND_ERROR';

export const SMART_CONNECT_ZL_PERF_INIT =
  'app/SmartConnect/SMART_CONNECT_ZL_PERF_INIT';
export const SMART_CONNECT_ZL_PERF_SUCCESS =
  'app/SmartConnect/SMART_CONNECT_ZL_PERF_SUCCESS';
export const SMART_CONNECT_ZL_PERF_ERROR =
  'app/SmartConnect/SMART_CONNECT_ZL_PERF_ERROR';

export const SMART_CONNECT_CHAIN_TREND_INIT =
  'app/SmartConnect/SMART_CONNECT_CHAIN_TREND_INIT';
export const SMART_CONNECT_CHAIN_TREND_SUCCESS =
  'app/SmartConnect/SMART_CONNECT_CHAIN_TREND_SUCCESS';
export const SMART_CONNECT_CHAIN_TREND_ERROR =
  'app/SmartConnect/SMART_CONNECT_CHAIN_TREND_ERROR';

export const PROFIT_PERCENTAGE_INIT = 'app/SmartConnect/PROFIT_PERCENTAGE_INIT';
export const PROFIT_PERCENTAGE_SUCCUESS =
  'app/SmartConnect/PROFIT_PERCENTAGE_SUCCUESS';
export const PROFIT_PERCENTAGE_ERROR =
  'app/SmartConnect/PROFIT_PERCENTAGE_ERROR';

export const PROFIT_TREND_INIT = 'app/SmartConnect/PROFIT_TREND_INIT';
export const PROFIT_TREND_SUCCESS = 'app/SmartConnect/PROFIT_TREND_SUCCESS';
export const PROFIT_TREND_ERROR = 'app/SmartConnect/PROFIT_TREND_ERROR';

export const SET_ORDERTYPES_ACTION = 'app/SmartConnect/SET_ORDERTYPES_ACTION';

export const SET_PARTNERS_ACTION = 'app/SmartConnect/SET_PARTNERS_ACTION';

export const SET_SALES_TYPE = 'app/SmartConnect/SET_SALES_TYPE';
