/*
 *
 * benchmarking order level analysis constants
 *
 */

export const FETCH_ORDER_TYPE_PERFORMANCE_ACTION =
  'app/Benchmarking/FETCH_ORDER_TYPE_PERFORMANCE_ACTION';

export const FETCH_ORDER_TYPE_PERFORMANCE_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_ORDER_TYPE_PERFORMANCE_SUCCESS_ACTION';

export const FETCH_ORDER_TYPE_PERFORMANCE_ERROR_ACTION =
  'app/Benchmarking/FETCH_ORDER_TYPE_PERFORMANCE_ERROR_ACTION';

export const FETCH_ORDER_ITEM_PLOT_ACTION =
  'app/Benchmarking/FETCH_ORDER_ITEM_PLOT_ACTION';

export const FETCH_ORDER_ITEM_PLOT_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_ORDER_ITEM_PLOT_SUCCESS_ACTION';

export const FETCH_ORDER_ITEM_PLOT_ERROR_ACTION =
  'app/Benchmarking/FETCH_ORDER_ITEM_PLOT_ERROR_ACTION';

export const FETCH_BUCKET_LIST_ACTION =
  'app/Benchmarking/FETCH_BUCKET_LIST_ACTION';

export const FETCH_BUCKET_LIST_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_BUCKET_LIST_SUCCESS_ACTION';

export const FETCH_BUCKET_LIST_ERROR_ACTION =
  'app/Benchmarking/FETCH_BUCKET_LIST_ERROR_ACTION';
