/*
 *
 * ItemContainer constants
 *
 */
export const FETCH_ITEMS_SCORE = 'app/ItemContainer/FETCH_ITEMS_SCORE';
export const ITEMS_SCORE_LOADED = 'app/ItemContainer/ITEMS_SCORE_LOADED';
export const ITEMS_SCORE_FAILED = 'app/ItemContainer/ITEMS_SCORE_FAILED';
export const FETCH_ITEM_MAPS = 'app/ItemContainer/FETCH_ITEM_MAPS';
export const ITEM_MAPS_LOADED = 'app/ItemContainer/ITEM_MAPS_LOADED';
export const ITEM_MAPS_FAILED = 'app/ItemContainer/ITEM_MAPS_FAILED';
export const FETCH_ITEM_MAPS_QUADRANT =
  'app/ItemContainer/FETCH_ITEM_MAPS_QUADRANT';
export const ITEM_MAPS_QUADRANT_LOADED =
  'app/ItemContainer/ITEM_MAPS_QUADRANT_LOADED';
export const ITEM_MAPS_QUADRANT_FAILED =
  'app/ItemContainer/ITEM_MAPS_QUADRANT_FAILED';
export const FETCH_SEARCHED_ITEMS = 'app/ItemContainer/FETCH_SEARCHED_ITEMS';
export const SEARCHED_ITEMS_LOADED = 'app/ItemContainer/SEARCHED_ITEMS_LOADED';
export const SEARCHED_ITEMS_FAILED = 'app/ItemContainer/SEARCHED_ITEMS_FAILED';
export const FETCH_ITEM_STATS = 'app/ItemContainer/FETCH_ITEM_STATS';
export const ITEM_STATS_LOADED = 'app/ItemContainer/ITEM_STATS_LOADED';
export const ITEM_STATS_FAILED = 'app/ItemContainer/ITEM_STATS_FAILED';
export const FETCH_ITEM_TRANSACTIONS =
  'app/ItemContainer/FETCH_ITEM_TRANSACTIONS';
export const ITEM_TRANSACTIONS_LOADED =
  'app/ItemContainer/ITEM_TRANSACTIONS_LOADED';
export const ITEM_TRANSACTIONS_FAILED =
  'app/ItemContainer/ITEM_TRANSACTIONS_FAILED';
export const FETCH_ITEMS_BY_TIME = 'app/ItemContainer/FETCH_ITEMS_BY_TIME';
export const ITEMS_BY_TIME_LOADED = 'app/ItemContainer/ITEMS_BY_TIME_LOADED';
export const ITEMS_BY_TIME_FAILED = 'app/ItemContainer/ITEMS_BY_TIME_FAILED';
