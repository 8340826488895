// Import Libs
import { takeLatest, call, put } from 'redux-saga/effects';

// Import services
import {
  fetchScorecardList1Data,
  fetchScorecardList2Data,
  fetchCustomerVisitData,
  fetchAIScorecardTableData,
  fetchPerformanceMatrixData,
  fetchCustomerBarChartData,
  fetchSalesBarChartData,
  fetchOrdersBarChartData,
  fetchABVBarChartData,
} from './services';

// Import transformers
import scorecardDataTransformer from 'transformers/scorecardDataTransformer';

import {
  aiScorecardTableDataFormatter,
  customerVisitDataFormatter,
  performanceMatrixFormatter,
  performanceChartFormatter,
} from './formatters';

export function* fetchScorecardList1Saga(action) {
  const { payload } = action;

  try {
    // Get the response from API call
    const response = yield call(fetchScorecardList1Data, payload);

    // Get the data/payload and status code from response
    const { data, status } = response;

    // Get the formatted data to feed reducer
    const formattedData = scorecardDataTransformer(data, 'aiEngineNvsrTop');

    // Feed the data to reducer
    yield put({
      type: 'FETCH_aiEngine/nvsr/scorecardGroup1_SUCCESS',
      payload: formattedData,
    });
  } catch (error) {
    const { response } = error;

    const { status, data } = response || {};

    console.log('Failed!', {
      status,
      data,
    });
    // yield false
  }
}

export function* fetchScorecardList2Saga(action) {
  const { payload } = action;
  try {
    // Get the response from API call
    const response = yield call(fetchScorecardList2Data, payload);

    // Get the data/payload and status code from response
    const { data, status } = response;

    // Get the formatted data to feed reducer
    const formattedData = scorecardDataTransformer(data, 'aiEngineNvsrBottom');

    // Feed the data to reducer
    yield put({
      type: 'FETCH_aiEngine/nvsr/scorecardGroup2_SUCCESS',
      payload: formattedData,
    });
  } catch (error) {
    const { response } = error;

    const { status, data } = response || {};

    console.log('Failed!', {
      status,
      data,
    });
    // yield false
  }
}

export function* fetchAIScorecardTableSaga(action) {
  const { payload } = action;
  try {
    // Get the response from API call
    const response = yield call(fetchAIScorecardTableData, payload);

    // Get the data/payload and status code from response
    const { data, status } = response;

    // Get the formatted data to feed reducer
    const formattedData = aiScorecardTableDataFormatter(data);

    // Feed the data to reducer
    yield put({
      type: 'FETCH_aiEngine/nvsr/aiScorecard_SUCCESS',
      payload: formattedData,
    });
  } catch (error) {
    const { response } = error;

    const { status, data } = response || {};

    console.log('Failed!', {
      status,
      data,
    });
    // yield false
  }
}

export function* fetchCustomerVisitDataSaga(action) {
  const { payload } = action;
  try {
    // Get the response from API call
    const { data, status } = yield call(fetchCustomerVisitData, payload);

    if (status === 200 || (status === 206 && data)) {
      const { error_message, premium_lock } = data;

      if (error_message) {
        yield put({
          type: 'FETCH_aiEngine/nvsr/customerVisits_FAIL',
          error: error_message,
        });
      } else if (premium_lock === false) {
        yield put({
          type: 'FETCH_aiEngine/nvsr/customerVisits_FAIL',
          error: 'locked',
        });
      } else {
        const formattedData = customerVisitDataFormatter(data);

        // Feed the data to reducer
        yield put({
          type: 'FETCH_aiEngine/nvsr/customerVisits_SUCCESS',
          payload: formattedData,
        });
      }
    } else throw new Error('Something went wrong');
  } catch (error) {
    console.log('Something went wrong', error);
    yield put({
      type: 'FETCH_aiEngine/nvsr/customerVisits_FAIL',
      error: 'Something went wrong',
    });
  }
}

// Performance MatriX Saga
export function* fetchPerformanceMatrixSaga(action) {
  const { payload } = action;
  try {
    // Get the response from API call
    const { data, status } = yield call(fetchPerformanceMatrixData, payload);

    if (status === 200 || (status === 206 && data)) {
      const { error_message, premium_lock } = data;

      if (error_message) {
        yield put({
          type: 'FETCH_aiEngine/nvsr/performanceMatrix_FAIL',
          error: error_message,
        });
      } else if (premium_lock === false) {
        yield put({
          type: 'FETCH_aiEngine/nvsr/performanceMatrix_FAIL',
          error: 'locked',
        });
      } else {
        const formattedData = performanceMatrixFormatter(data);

        // Feed the data to reducer
        yield put({
          type: 'FETCH_aiEngine/nvsr/performanceMatrix_SUCCESS',
          payload: formattedData,
        });
      }
    } else throw new Error('Something went wrong');
  } catch (error) {
    console.log('Something went wrong', error.message);
    yield put({
      type: 'FETCH_aiEngine/nvsr/performanceMatrix_FAIL',
      error: 'Something went wrong',
    });
  }
}

// Performance MatriX Saga
export function* fetchPerformanceChartSaga(action) {
  const { attribute, payload } = action;

  const services = {
    customers: fetchCustomerBarChartData,
    sales: fetchSalesBarChartData,
    orders: fetchOrdersBarChartData,
    abv: fetchABVBarChartData,
  };

  try {
    if (!(attribute in services))
      throw new Error('No appropriate way find to handle such case!');

    const { data, status } = yield call(services[attribute], payload);

    if (status === 200 || (status === 206 && data)) {
      const { error_message, premium_lock } = data;

      if (error_message) {
        yield put({
          type: 'FETCH_aiEngine/nvsr/performanceData_FAIL',
          error: error_message,
        });
      } else if (premium_lock === false) {
        yield put({
          type: 'FETCH_aiEngine/nvsr/performanceData_FAIL',
          error: 'locked',
        });
      } else {
        const formattedData = performanceChartFormatter(data, attribute);

        // Feed the data to reducer
        yield put({
          type: 'FETCH_aiEngine/nvsr/performanceData_SUCCESS',
          payload: formattedData,
          attribute,
        });
      }
    } else throw new Error('Something went wrong');
  } catch (error) {
    console.log('Something went wrong', error);
    yield put({
      type: 'FETCH_aiEngine/nvsr/performanceData_FAIL',
      error: 'Something went wrong',
    });
  }
}

// watcher Saga generator function
export default function* watchAIEngineNewVsRepeatLoginContainer() {
  yield takeLatest(
    'FETCH_aiEngine/nvsr/scorecardGroup1_INIT',
    fetchScorecardList1Saga,
  );
  yield takeLatest(
    'FETCH_aiEngine/nvsr/scorecardGroup2_INIT',
    fetchScorecardList2Saga,
  );
  yield takeLatest(
    'FETCH_aiEngine/nvsr/aiScorecard_INIT',
    fetchAIScorecardTableSaga,
  );

  yield takeLatest(
    'FETCH_aiEngine/nvsr/customerVisits_INIT',
    fetchCustomerVisitDataSaga,
  );

  yield takeLatest(
    'FETCH_aiEngine/nvsr/performanceMatrix_INIT',
    fetchPerformanceMatrixSaga,
  );
  yield takeLatest(
    'FETCH_aiEngine/nvsr/performanceData_INIT',
    fetchPerformanceChartSaga,
  );
}
