import i18n from 'i18n/config';

export const formatCancelledOrdersTable = data => {
  const formattedData = [];

  if(data[0].error_message ==='No Data to display'){
    return formattedData;
  }
  if (data?.length) {
    data.forEach(item => {
      formattedData.push({
        orderNo: item.order_no || '-',
        key: item.orderNo,
        tabsquareOrderId: item.ts_order_id || '-',
        cancellationReason: item.cancel_reason || '',
        cancelledDate: item.cancelled_at || '',
        orderDate: item.date_take || '',
        restaurant: item.restaurant_name || '',
        orderAmount: item.order_amount || '',
        partner: item.partner || '',
        orderType: item.order_type || '',
        orderID: item.partner_order_code || '',
        uniqueReference : item.partner_order_id || ''

      });
    });
  }
  return formattedData;
};

export const formatCancelledReasonsTable = data => {
  const formattedData = [];
  if (data?.length) {
    data.forEach(item => {
      if (item.cancel_reason)
        formattedData.push({
          cancellationReason: item.cancel_reason,
          key: item.cancel_reason,
          totalCancelledOrders: {
            value: item.total_cancelled_orders ?? 0,
            delta: item.Percent_diff_orders ?? 0,
          },
          totalCancelledAmount: {
            value: item.total_cancelled_amount ?? 0,
            delta: item.Percent_diff_amount ?? 0,
          },
        });
    });
  }
  return formattedData;
};

export const formatCancelledOrdersBreakup = data => {
  const formattedData = {
    partners: [],
    orderTypes: [],
  };
  if (data?.partner?.length) {
    data.partner.forEach(item => {
      if (item.total_cancelled_amount || item.total_cancelled_orders) {
        formattedData.partners.push({
          name: i18n.t(item.partner_name),
          color: item.color,
          amount: item.total_cancelled_amount
            ? {
                value: item.total_cancelled_amount ?? 0,
                delta: item.Percent_diff_amount ?? 0,
              }
            : undefined,
          orders: item.total_cancelled_orders
            ? {
                value: item.total_cancelled_orders ?? 0,
                delta: item.Percent_diff_orders ?? 0,
              }
            : undefined,
        });
      }
    });
  }
  if (data?.order_type?.length) {
    data.order_type.forEach(item => {
      if (item.total_cancelled_amount || item.total_cancelled_orders) {
        formattedData.orderTypes.push({
          name: i18n.t(item.order_type),
          color: item.color,
          amount: item.total_cancelled_amount
            ? {
                value: item.total_cancelled_amount ?? 0,
                delta: item.Percent_diff_amount ?? 0,
              }
            : undefined,
          orders: item.total_cancelled_orders
            ? {
                value: item.total_cancelled_orders ?? 0,
                delta: item.Percent_diff_orders ?? 0,
              }
            : undefined,
        });
      }
    });
  }
  return formattedData;
};
