import { all, select } from 'redux-saga/effects';

// Import sagas
import watchCrossSellUpSellContainer from 'containers/CrossSellUpSellPage/saga';
import watchOperationsContainer from 'containers/Operations/saga';
import watchCategoryContainer from 'containers/CategoryPage/saga';
import watchAIEngineLoginVsNonLoginContainer from 'containers/CRM/AIEngineLoginVsNonLogin/saga';
import watchAIEngineNewVsRepeatLoginContainer from 'containers/CRM/AIEngineNewVsRepeat/saga';
import watchAIPromotionContainer from 'containers/CRM/AIPromotions/sagas';
import watchCSGroupAnalysisContainer from 'containers/CRM/CSegmentationGroupAnalysis/sagas';
import watchCSSegmentAnalysisContainer from 'containers/CRM/CSegmentationSegmentAnalysis/sagas';
import watchCSCustomerAnalysisContainer from 'containers/CRM/CSegmentationCustomerAnalysis/sagas';
import watchCashbackContainer from 'containers/CRM/Cashback/sagas';
import watchSmartConnectContainer from 'containers/SmartConnect/sagas';
import watchCancelledOrdersContainer from 'containers/SmartConnect/CancelledOrders/sagas';
import watchDayEndReportContainer from 'containers/DayEndReporting/sagas';
import watchVoidOrdersContainer from 'containers/DayEndReporting/VoidOrders/sagas';
import watchLostRevenueContainer from 'containers/LostRevenue/saga';
import watchPosContainer from 'containers/POS/sagas';
import watchTerminalAnalyticsContainer from 'containers/TerminalAnalytics/sagas';
import watchTrackerSaga from 'containers/Tracker/saga';

import formatDate from '../helpers/formatDate';

const selectCurrentDateRangeReducer = state => state.currentDateRange;
const selectCompareDateRangeReducer = state => state.compareDateRange;
const selectmerchantReducer = state => state.merchant.activeList;

export function* getMerchantAndDate() {
  const merchantIdList = yield select(selectmerchantReducer);
  const currentDateRange = yield select(selectCurrentDateRangeReducer);
  const compareDateRange = yield select(selectCompareDateRangeReducer);
  const startDate = formatDate(currentDateRange.start);
  const endDate = formatDate(currentDateRange.end);
  const cStartDate = formatDate(compareDateRange.start);
  const cEndDate = formatDate(compareDateRange.end);
  return { merchantIdList, startDate, endDate, cStartDate, cEndDate };
}

/**
 * Root saga manages watcher lifecycle
 * Watchers are like event listeners
 * They watch for actions and trigger effects to handle the actions
 */
function* rootSaga() {
  yield all([
    watchCategoryContainer(),
    watchOperationsContainer(),
    watchCrossSellUpSellContainer(),
    watchAIEngineLoginVsNonLoginContainer(),
    watchAIEngineNewVsRepeatLoginContainer(),
    watchCSGroupAnalysisContainer(),
    watchCSSegmentAnalysisContainer(),
    watchAIPromotionContainer(),
    watchCSCustomerAnalysisContainer(),
    watchCashbackContainer(),
    watchSmartConnectContainer(),
    watchCancelledOrdersContainer(),
    watchDayEndReportContainer(),
    watchVoidOrdersContainer(),
    watchLostRevenueContainer(),
    watchPosContainer(),
    watchTerminalAnalyticsContainer(),
    watchTrackerSaga(),
  ]);
}

export default rootSaga;
