/*
 *
 * sc actions
 *
 */

import * as cn from './constants';

export const getOrderTypePartnerList = (payload, isChain = false) => {
  return {
    type: cn.PARTNER_ORDERTYPE_LIST_INIT,
    payload,
    isChain,
  };
};

export const getOrderTypePartnerListSuccess = payload => {
  return {
    type: cn.PARTNER_ORDERTYPE_LIST_SUCCESS,
    payload,
  };
};

export const getOrderTypePartnerListError = error => {
  return {
    type: cn.PARTNER_ORDERTYPE_LIST_ERROR,
    error,
  };
};

export const getSmartConnectScore = (payload, isChain = false) => {
  return {
    type: cn.SMART_CONNECT_SCORE_INIT,
    payload,
    isChain,
  };
};

export const getSmartConnectScoreSuccess = payload => {
  return {
    type: cn.SMART_CONNECT_SCORE_SUCCESS,
    payload,
  };
};

export const getSmartConnectScoreError = error => {
  return {
    type: cn.SMART_CONNECT_SCORE_ERROR,
    error,
  };
};

export const getSmartConnectPerformance = (payload, isChain) => {
  return {
    type: cn.SMART_CONNECT_PERFORMANCE_INIT,
    payload,
    isChain,
  };
};

export const getSmartConnectPerformanceSuccess = payload => {
  return {
    type: cn.SMART_CONNECT_PERFORMANCE_SUCCESS,
    payload,
  };
};

export const getSmartConnectPerformanceError = error => {
  return {
    type: cn.SMART_CONNECT_PERFORMANCE_ERROR,
    error,
  };
};

export const getSmartConnectTrend = payload => {
  return {
    type: cn.SMART_CONNECT_TREND_INIT,
    payload,
  };
};

export const getSmartConnectTrendSuccess = payload => {
  return {
    type: cn.SMART_CONNECT_TREND_SUCCESS,
    payload,
  };
};

export const getSmartConnectTrendError = error => {
  return {
    type: cn.SMART_CONNECT_TREND_ERROR,
    error,
  };
};

export const getSmartConnectZoneLocPerf = payload => {
  return {
    type: cn.SMART_CONNECT_ZL_PERF_INIT,
    payload,
  };
};

export const getSmartConnectZoneLocPerfSuccess = payload => {
  return {
    type: cn.SMART_CONNECT_ZL_PERF_SUCCESS,
    payload,
  };
};

export const getSmartConnectZoneLocPerfError = error => {
  return {
    type: cn.SMART_CONNECT_ZL_PERF_ERROR,
    error,
  };
};

export const getSmartConnectChainTrend = payload => {
  return {
    type: cn.SMART_CONNECT_CHAIN_TREND_INIT,
    payload,
  };
};

export const getSmartConnectChainTrendSuccess = payload => {
  return {
    type: cn.SMART_CONNECT_CHAIN_TREND_SUCCESS,
    payload,
  };
};

export const getSmartConnectChainTrendError = error => {
  return {
    type: cn.SMART_CONNECT_CHAIN_TREND_ERROR,
    error,
  };
};

export const getProfitPercentage = payload => {
  return {
    type: cn.PROFIT_PERCENTAGE_INIT,
    payload,
  };
};

export const getProfitPercentageSuccess = payload => {
  return {
    type: cn.PROFIT_PERCENTAGE_SUCCUESS,
    payload,
  };
};

export const getProfitPercentageError = error => {
  return {
    type: cn.PROFIT_PERCENTAGE_ERROR,
    error,
  };
};

export const getProfitTrend = payload => {
  return {
    type: cn.PROFIT_TREND_INIT,
    payload,
  };
};

export const getProfitTrendSuccess = payload => {
  return {
    type: cn.PROFIT_TREND_SUCCESS,
    payload,
  };
};

export const getProfitTrendError = error => {
  return {
    type: cn.PROFIT_TREND_ERROR,
    error,
  };
};

export const setSelectedOrderTypesAction = payload => ({
  type: cn.SET_ORDERTYPES_ACTION,
  payload,
});

export const setSelectedPartnersAction = payload => ({
  type: cn.SET_PARTNERS_ACTION,
  payload,
});

export const setSalesTypeAction = payload => ({
  type: cn.SET_SALES_TYPE,
  payload,
});

// export function getMasterMerchantScoresFn(payload) {
//   return async dispatch => {
//     dispatch(getMasterMerchantScoresAction());
//     try {
//       const { status, data, request } = await api.getScores(payload);
//       console.log(request);
//       if (status === 200 && data) {
//         dispatch(
//           masterMerchantScoresLoadedAction(
//             scorecardDataTransformer(data, 'masterMerchant'),
//           ),
//         );
//       } else {
//         dispatch(masterMerchantScoresFailedAction('No Data Found'));
//       }
//     } catch (e) {
//       dispatch(masterMerchantScoresFailedAction('Something went wrong'));
//     }
//   };
// }

// export function getOrdertypePartnerListFn(payload) {
//   return async dispatch => {
//     dispatch(getOrderTypePartnerListAction());
//     try {
//       const { status, data } = await api.getDropdowns(payload);
//       if (status === 200) {
//         const formattedData = fm.orderTypePartnerListFormatter(data);
//         dispatch(orderTypePartnerListLoadedAction(formattedData));
//       } else {
//         dispatch(orderTypePartnerListFailedAction('No Data Found'));
//       }
//     } catch (e) {
//       dispatch(orderTypePartnerListFailedAction('Something went wrong'));
//     }
//   };
// }

// export function getMasterMerchantPerformanceFn(payload) {
//   return async dispatch => {
//     dispatch(getMasterMerchantPerformanceAction());
//     try {
//       const { status, data } = await api.getPerformance(payload);
//       if (status === 200 && data) {
//         const formattedData = fm.performanceDataFormatter(
//           data,
//           payload.graph !== 'order_type',
//         );
//         dispatch(masterMerchantPerformanceLoadedAction(formattedData));
//       } else {
//         dispatch(masterMerchantPerformanceFailedAction('No Data Found'));
//       }
//     } catch (e) {
//       dispatch(
//         masterMerchantPerformanceFailedAction('Something went wrong'),
//       );
//     }
//   };
// }

// export function getMasterMerchantTrendFn(payload) {
//   return async dispatch => {
//     dispatch(getMasterMerchantTrendAction());
//     try {
//       const { status, data } = await api.getTrend(payload);
//       if (status === 200 && data.data) {
//         const formattedData = fm.trendDataFormatter(
//           data.data,
//           payload.graph !== 'order_type',
//           payload.metric,
//           payload.granularity,
//         );
//         dispatch(masterMerchantTrendLoadedAction(formattedData));
//       } else {
//         dispatch(masterMerchantTrendFailedAction('No Data Found'));
//       }
//     } catch (e) {
//       dispatch(masterMerchantTrendFailedAction('Something went wrong'));
//     }
//   };
// }

// export function getProfitPercentageFn(payload) {
//   return async dispatch => {
//     dispatch(getProfitPercentageAction());
//     try {
//       const { status, data } = await api.getProfitPercentage(payload);
//       if (status === 200 && data?.data?.length) {
//         const formattedData = fm.profitPercentageDataFormatter(
//           data.data,
//           payload.graph !== 'order_type',
//         );
//         dispatch(profitPercentageLoadedAction(formattedData));
//       } else {
//         dispatch(profitPercentageFailedAction('No Data Found'));
//       }
//     } catch (e) {
//       if (e?.response?.status && e.response.status === 406) {
//         dispatch(
//           profitPercentageFailedAction('Please update commission details'),
//         );
//       } else {
//         dispatch(profitPercentageFailedAction('Something went wrong'));
//       }
//     }
//   };
// }

// export function getProfitTrendFn(payload) {
//   return async dispatch => {
//     dispatch(getProfitTrendAction());
//     try {
//       const { status, data } = await api.getProfitTrend(payload);
//       if (status === 200 && data?.data?.length) {
//         const formattedData = fm.profitTrendDataFormatter(
//           data.data,
//           payload.graph !== 'order_type',
//           payload.granularity,
//         );
//         dispatch(profitTrendLoadedAction(formattedData));
//       } else {
//         dispatch(profitTrendFailedAction('No Data Found'));
//       }
//     } catch (e) {
//       if (e?.response?.status && e.response.status === 406) {
//         dispatch(profitTrendFailedAction('Please update commission details'));
//       } else {
//         dispatch(profitTrendFailedAction('Something went wrong'));
//       }
//     }
//   };
// }
