// Import Libs
import { takeLatest, call, put, select } from "redux-saga/effects"

// Import services
import {
	fetchScorecardListTopData,
	fetchScorecardListBottomData,
	fetchABVComparisonData,
	fetchCashbackInfluenceData,
	fetchCashbackRedemptionData
} from "./services"

// Import transformers
import scorecardDataTransformer from "../../../transformers/scorecardDataTransformer"

import {
	abvComparisonDataFormatter,
	cashbackInfluenceDataFormatter,
	cashbackRedemptionTableFormatter,
	cashbackRedemptionChartBreakpointsFormatter,
	cashbackRedemptionChartFormatter
} from "./formatters"

export function* fetchScorecardListTopSaga(action) {
	const { payload } = action

	try {
		// Get the response from API call
		const response = yield call(fetchScorecardListTopData, payload)

		// Get the data/payload and status code from response
		const { data, status } = response

		// Get the formatted data to feed reducer
		const {
			scorecardGroupData,
			datacardGroupData
		} = scorecardDataTransformer(data, "cashbackTop")

		// Feed the data to reducer
		yield put({
			type: "FETCH_cashback/scorecardGroup1_SUCCESS",
			payload: scorecardGroupData
		})

		// Feed the data to reducer
		yield put({
			type: "FETCH_cashback/datacardGroup1_SUCCESS",
			payload: datacardGroupData
		})
	} catch (error) {
		const { response } = error

		const { status, data } = response || {}

		console.log("Failed!", {
			status,
			data
		})
		// yield false
	}
}

export function* fetchScorecardListBottomSaga(action) {
	const { payload } = action

	try {
		// Get the response from API call
		const response = yield call(fetchScorecardListBottomData, payload)

		// Get the data/payload and status code from response
		const { data, status } = response

		// Get the formatted data to feed reducer
		const formatedPayload = scorecardDataTransformer(data, "cashbackBottom")

		// Feed the data to reducer
		yield put({
			type: "FETCH_cashback/scorecardGroup2_SUCCESS",
			payload: formatedPayload
		})
	} catch (error) {
		const { response } = error

		const { status, data } = response || {}

		console.log("Failed!", {
			status,
			data
		})
		// yield false
	}
}

export function* fetchABVComparisonDataSaga(action) {
	const { payload } = action

	try {
		// Get the response from API call
		const { data, status } = yield call(fetchABVComparisonData, payload)

		if (status === 200 && data) {
			const { error_message, premium_lock } = data

			if (error_message) {
				yield put({
					type: "FETCH_cashback/abvComparison_FAIL",
					error: error_message
				})
			} else if (premium_lock === false) {
				yield put({
					type: "FETCH_cashback/abvComparison_FAIL",
					error: "locked"
				})
			} else {
				const formattedData = abvComparisonDataFormatter(data)

				// Feed the data to reducer
				yield put({
					type: "FETCH_cashback/abvComparison_SUCCESS",
					payload: formattedData
				})
			}
		} else throw new Error("Unexpected behaviour")
	} catch (error) {
		console.log("Something went wrong", error)
		yield put({
			type: "FETCH_cashback/abvComparison_FAIL",
			error: "Something went wrong"
		})
	}
}

export function* fetchCashbackInfluenceDataSaga(action) {
	const { payload, attribute } = action

	try {
		// Get the response from API call
		const { data, status } = yield call(fetchCashbackInfluenceData, payload)

		if (status === 200 && data) {
			const { error_message, premium_lock } = data

			if (error_message) {
				yield put({
					type: "FETCH_cashback/cashbackInfluence_FAIL",
					error: error_message
				})
			} else if (premium_lock === false) {
				yield put({
					type: "FETCH_cashback/cashbackInfluence_FAIL",
					error: "locked"
				})
			} else {
				const formattedData = cashbackInfluenceDataFormatter(
					data,
					attribute
				)

				// Feed the data to reducer
				yield put({
					type: "FETCH_cashback/cashbackInfluence_SUCCESS",
					payload: formattedData
				})
			}
		} else throw new Error("Unexpected behaviour")
	} catch (error) {
		console.log("Something went wrong", error)
		yield put({
			type: "FETCH_cashback/cashbackInfluence_FAIL",
			error: "Something went wrong"
		})
	}
}

export function* fetchCashbackRedemptionDataSaga(action) {
	const { payload, attribute } = action

	try {
		yield put({
			type: "FETCH_cashback/cashbackRedemptionTable_INIT"
		})

		yield put({
			type: "FETCH_cashback/cashbackRedemptionChart_INIT"
		})

		// Get the response from API call
		const { data, status } = yield call(
			fetchCashbackRedemptionData,
			payload
		)

		if (status === 200 && data) {
			const { error_message, premium_lock } = data

			if (error_message) {
				yield put({
					type: "FETCH_cashback/cashbackRedemptionTable_FAIL",
					error: error_message
				})

				yield put({
					type: "FETCH_cashback/cashbackRedemptionChart_FAIL",
					error: error_message
				})
			} else if (premium_lock === false) {
				yield put({
					type: "FETCH_cashback/cashbackRedemptionTable_FAIL",
					error: "locked"
				})

				yield put({
					type: "FETCH_cashback/cashbackRedemptionChart_FAIL",
					error: "locked"
				})
			} else {
				const { chart, table, lifetime_members, redeemtion_time } = data
				const formattedTable = cashbackRedemptionTableFormatter(table)
				const formattedBreakpoints = cashbackRedemptionChartBreakpointsFormatter(
					redeemtion_time
				)
				const { danger = Infinity } = formattedBreakpoints

				let formattedChartData = cashbackRedemptionChartFormatter(
					chart,
					danger
				)

				// Feed the data to reducer
				yield put({
					type: "FETCH_cashback/cashbackRedemptionTable_SUCCESS",
					payload: formattedTable
				})

				yield put({
					type: "FETCH_cashback/cashbackRedemptionChart_SUCCESS",
					payload: {
						breakpoints: formattedBreakpoints,
						chartData: formattedChartData
					}
				})

				yield put({
					type: "UPDATE_cashback/scorecardGroup2_SUCCESS",
					payload: lifetime_members
				})
			}
		} else throw new Error("Unexpected behaviour")
	} catch (error) {
		console.log("Something went wrong", error)
		yield put({
			type: "FETCH_cashback/cashbackRedemptionTable_FAIL",
			error: "Something went wrong"
		})

		yield put({
			type: "FETCH_cashback/cashbackRedemptionChart_FAIL",
			error: "Something went wrong"
		})
	}
}

// watcher Saga generator function
export default function* watchCashbackContainer() {
	yield takeLatest(
		"FETCH_cashback/scorecardGroup1_INIT",
		fetchScorecardListTopSaga
	)

	yield takeLatest(
		"FETCH_cashback/scorecardGroup2_INIT",
		fetchScorecardListBottomSaga
	)

	yield takeLatest(
		"FETCH_cashback/abvComparison_INIT",
		fetchABVComparisonDataSaga
	)

	yield takeLatest(
		"FETCH_cashback/cashbackInfluence_INIT",
		fetchCashbackInfluenceDataSaga
	)

	yield takeLatest(
		"FETCH_cashback/cashbackRedemptionData_INIT",
		fetchCashbackRedemptionDataSaga
	)
}
