export const categoryPenetrationDataFormatter = payload => {
  if (payload) {
    const formattedPayload = Object.values(payload).map(obj => {
      return {
        category: obj.Category,
        percentage: obj['Penetration Percentage'],
        color: obj.Colors,
        change: obj.Change,
      };
    });
    return formattedPayload;
  } else {
    return [];
  }
};

export const mealPenetrationDataFormatter = payload => {
  if (payload) {
    const formattedPayload = Object.values(payload)
      .map(obj => {
        return {
          meal: obj['Meal Bucket'],
          percentage: obj['Penetration Percentage'],
          change: obj.Change,
        };
      })
      .filter(obj => obj.percentage);
    return formattedPayload;
  } else {
    return [];
  }
};

export const categoryPlacementDataFormatter = payload => {
  if (payload) {
    const formattedPayload = Object.values(payload).map(obj => {
      return {
        // category: obj.category_name,
        // clicks: obj.Clicks,
        // sales: obj.Sales,
        // percentageClicks: obj['Percentage of Clicks'],
        // percentageSales: obj['Percentage of Sales'],
        ...obj,
        sequence: obj.sequence || "-",
      };
    });
    return formattedPayload;
  } else {
    return [];
  }
};

export const categoryContributionDataFormatter = payload => {
  if (payload) {
    const formattedPayload = Object.values(payload).map(obj => {
      return {
        category: obj.Category,
        sales: obj.Sales,
        percentage: obj['Percentage Contribution'],
        sequence: obj.sequence || 0,
        color: obj.Colors,
        change: obj.Change,
      };
    });
    return formattedPayload;
  } else {
    return [];
  }
};

export const categoryByTimeDataFormatter = payload => {
  if (payload) {
    const formattedPayload = Object.values(payload).map(obj => {
      return {
        hour: obj.Hour,
        category: obj.Category,
        percentage: obj['Percentage Contribution'],
        color: obj.Colors,
      };
    });

    // remove duplicates
    const result = formattedPayload.filter(function (obj) {
      var key = obj.hour + '|' + obj.category;
      if (!this[key]) {
        this[key] = true;
        return true;
      }
    }, Object.create(null));

    return result;
  } else {
    return [];
  }
};
