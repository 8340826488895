import * as cn from './constants';

export const initialState = {
  cancelledScore: {
    error: null,
    loading: false,
    payload: {
      cancelledOrders: {
        delta: 0,
        value: 0,
      },
      cancelledOrdersPercentage: {
        delta: 0,
        value: 0,
      },
      cancelledSales: {
        delta: 0,
        value: 0,
      },
      cancelledSalesPercentage: {
        delta: 0,
        value: 0,
      },
    },
  },
  cancelledBreakup: {
    loading: false,
    error: null,
    payload: {
      partners: [],
      orderTypes: [],
    },
  },
  cancelledTable: {
    loading: false,
    error: null,
    payload: [],
  },
  cancelledReasons: {
    loading: false,
    error: null,
    payload: [],
  },
};

function cancelledOrdersReducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    // case cn.CANCELLED_ORDER_SCORES_INIT:
    //   return {
    //     ...state,
    //     cancelledScore: {
    //       payload: state.cancelledScore.payload,
    //       loading: true,
    //       error: null,
    //     },
    //   };

    case cn.CANCELLED_ORDER_SCORES_SUCCESS:
      return {
        ...state,
        cancelledScore: {
          payload,
          loading: false,
          error: null,
        },
      };

    // case cn.CANCELLED_ORDER_SCORES_ERROR:
    //   return {
    //     ...state,
    //     cancelledScore: {
    //       payload: initialState.cancelledScore.payload,
    //       loading: false,
    //       error,
    //     },
    //   };

    // case cn.CANCELLED_ORDER_BREAKUP_INIT:
    //   return {
    //     ...state,
    //     cancelledBreakup: {
    //       payload: state.cancelledBreakup.payload,
    //       loading: true,
    //       error: null,
    //     },
    //   };

    case cn.CANCELLED_ORDER_BREAKUP_SUCCESS:
      return {
        ...state,
        cancelledBreakup: {
          payload,
          loading: false,
          error: null,
        },
      };

    // case cn.CANCELLED_ORDER_BREAKUP_ERROR:
    //   return {
    //     ...state,
    //     cancelledBreakup: {
    //       payload: initialState.cancelledBreakup.payload,
    //       loading: false,
    //       error,
    //     },
    //   };

    // case cn.CANCELLED_ORDER_TABLE_INIT:
    //   return {
    //     ...state,
    //     cancelledTable: {
    //       payload: state.cancelledTable.payload,
    //       loading: true,
    //       error: null,
    //     },
    //   };

    case cn.CANCELLED_ORDER_TABLE_SUCCESS:
      return {
        ...state,
        cancelledTable: {
          payload,
          loading: false,
          error: null,
        },
      };

    // case cn.CANCELLED_ORDER_TABLE_ERROR:
    //   return {
    //     ...state,
    //     cancelledTable: {
    //       payload: initialState.cancelledTable.payload,
    //       loading: false,
    //       error,
    //     },
    //   };

    // case cn.CANCELLED_ORDER_REASONS_INIT:
    //   return {
    //     ...state,
    //     cancelledReasons: {
    //       payload: state.cancelledReasons.payload,
    //       loading: true,
    //       error: null,
    //     },
    //   };

    case cn.CANCELLED_ORDER_REASONS_SUCCESS:
      return {
        ...state,
        cancelledReasons: {
          payload,
          loading: false,
          error: null,
        },
      };

    // case cn.CANCELLED_ORDER_REASONS_ERROR:
    //   return {
    //     ...state,
    //     cancelledReasons: {
    //       payload: initialState.cancelledReasons.payload,
    //       loading: false,
    //       error,
    //     },
    //   };


      case cn.CANCELLED_ORDER_MERGE_DATA_INIT:
        return {
          ...state,
          cancelledScore: {
            payload: state.cancelledScore.payload,
            loading: true,
            error: null,
          },
          cancelledBreakup: {
            payload: state.cancelledBreakup.payload,
            loading: true,
            error: null,
          },
          cancelledTable: {
            payload: state.cancelledTable.payload,
            loading: true,
            error: null,
          },
          cancelledReasons: {
            payload: state.cancelledReasons.payload,
            loading: true,
            error: null,
          },
        };
  
      // case cn.CANCELLED_ORDER_MERGE_DATA_SUCCESS:
      //   return {
      //     ...state,
      //     cancelledOrderMerge: {
      //       payload,
      //       loading: false,
      //       error: null,
      //     },
      //   };
  
      case cn.CANCELLED_ORDER_MERGE_DATA_ERROR:
    return {
        ...state,
        cancelledScore: {
          payload: initialState.cancelledScore.payload,
          loading: false,
          error,
        },
         cancelledBreakup: {
          payload: initialState.cancelledBreakup.payload,
          loading: false,
          error,
        },
          cancelledTable: {
          payload: initialState.cancelledTable.payload,
          loading: false,
          error,
        },
         cancelledReasons: {
          payload: initialState.cancelledReasons.payload,
          loading: false,
          error,
        },
      };

        // return {
        //   ...state,
        //   cancelledOrderMerge: {
        //     payload: initialState.cancelledOrderMerge.payload,
        //     loading: false,
        //     error,
        //   },
        // };

    default:
      return state;
  }
}

export default cancelledOrdersReducer;
