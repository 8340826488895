import React from 'react';

/**
 *
 * @param {React.Component} lazyComponent - React component to be loaded dynamically
 * @param {?number} [attemptsLeft=5] - Number of attempts left to load the component
 * @returns {promise} Promise that resolves to the loaded component
 */
export default function componentLoader(lazyComponent, attemptsLeft = 5) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(error => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject,
          );
        }, 1500);
      });
  });
}
