export const SCORES_INIT = 'app/LostRevenue/SCORES_INIT';
export const SCORES_SUCCESS = 'app/LostRevenue/SCORES_SUCCESS';
export const SCORES_ERROR = 'app/LostRevenue/SCORES_ERROR';

export const LIVE_DATA_SCORES_INIT = 'app/LostRevenue/LIVE_DATA_SCORES_INIT';
export const LIVE_DATA_SCORES_SUCCESS =
  'app/LostRevenue/LIVE_DATA_SCORES_SUCCESS';
export const LIVE_DATA_SCORES_ERROR = 'app/LostRevenue/LIVE_DATA_SCORES_ERROR';

export const CONTRIBUTION_INIT = 'app/LostRevenue/CONTRIBUTION_INIT';
export const CONTRIBUTION_SUCCESS = 'app/LostRevenue/CONTRIBUTION_SUCCESS';
export const CONTRIBUTION_ERROR = 'app/LostRevenue/CONTRIBUTION_ERROR';

export const TREND_INIT = 'app/LostRevenue/TREND_INIT';
export const TREND_SUCCESS = 'app/LostRevenue/TREND_SUCCESS';
export const TREND_ERROR = 'app/LostRevenue/TREND_ERROR';

export const CATEGORY_ITEM_INIT = 'app/LostRevenue/CATEGORY_ITEM_INIT';
export const CATEGORY_ITEM_SUCCESS = 'app/LostRevenue/CATEGORY_ITEM_SUCCESS';
export const CATEGORY_ITEM_ERROR = 'app/LostRevenue/CATEGORY_ITEM_ERROR';

export const OUTLETWISE_INIT = 'app/LostRevenue/OUTLETWISE_INIT';
export const OUTLETWISE_SUCCESS = 'app/LostRevenue/OUTLETWISE_SUCCESS';
export const OUTLETWISE_ERROR = 'app/LostRevenue/OUTLETWISE_ERROR';

export const LIVE_DATA_OUTLETWISE_INIT =
  'app/LostRevenue/LIVE_DATA_OUTLETWISE_INIT';
export const LIVE_DATA_OUTLETWISE_SUCCESS =
  'app/LostRevenue/LIVE_DATA_OUTLETWISE_SUCCESS';
export const LIVE_DATA_OUTLETWISE_ERROR =
  'app/LostRevenue/LIVE_DATA_OUTLETWISE_ERROR';

export const TABLE_STOCKOUT_INIT = 'app/LostRevenue/TABLE_STOCKOUT_INIT';
export const TABLE_STOCKOUT_SUCCESS = 'app/LostRevenue/TABLE_STOCKOUT_SUCCESS';
export const TABLE_STOCKOUT_ERROR = 'app/LostRevenue/TABLE_STOCKOUT_ERROR';

export const LIVE_DATA_TABLE_STOCKOUT_INIT =
  'app/LostRevenue/LIVE_DATA_TABLE_STOCKOUT_INIT';
export const LIVE_DATA_TABLE_STOCKOUT_SUCCESS =
  'app/LostRevenue/LIVE_DATA_TABLE_STOCKOUT_SUCCESS';
export const LIVE_DATA_TABLE_STOCKOUT_ERROR =
  'app/LostRevenue/LIVE_DATA_TABLE_STOCKOUT_ERROR';

export const TABLE_ORDERPAUSE_INIT = 'app/LostRevenue/TABLE_ORDERPAUSE_INIT';
export const TABLE_ORDERPAUSE_SUCCESS =
  'app/LostRevenue/TABLE_ORDERPAUSE_SUCCESS';
export const TABLE_ORDERPAUSE_ERROR = 'app/LostRevenue/TABLE_ORDERPAUSE_ERROR';

export const LIVE_DATA_TABLE_ORDERPAUSE_INIT =
  'app/LostRevenue/LIVE_DATA_TABLE_ORDERPAUSE_INIT';
export const LIVE_DATA_TABLE_ORDERPAUSE_SUCCESS =
  'app/LostRevenue/LIVE_DATA_TABLE_ORDERPAUSE_SUCCESS';
export const LIVE_DATA_TABLE_ORDERPAUSE_ERROR =
  'app/LostRevenue/LIVE_DATA_TABLE_ORDERPAUSE_ERROR';
