/*
 *
 * benchmarking enterprise stats constants
 *
 */

export const FETCH_ENTERPRISE_STATS_ACTION =
  'app/Benchmarking/FETCH_ENTERPRISE_STATS_ACTION';

export const FETCH_ENTERPRISE_STATS_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_ENTERPRISE_STATS_SUCCESS_ACTION';

export const FETCH_ENTERPRISE_STATS_ERROR_ACTION =
  'app/Benchmarking/FETCH_ENTERPRISE_STATS_ERROR_ACTION';
