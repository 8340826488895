import moment from 'moment';
import i18n from 'i18n/config';

function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
}

const sessionOrder = {
  [i18n.t('Morning')]: 0,
  [i18n.t('Mid Morning')]: 1,
  [i18n.t('Lunch')]: 2,
  [i18n.t('Mid Afternoon')]: 3,
  [i18n.t('Evening')]: 4,
  [i18n.t('Dinner')]: 5,
  [i18n.t('Late Dinner')]: 6,
};

function compare(a, b) {
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
}

const formatGranularity = (val, granularity) => {
  try {
    if (granularity === 'dw') {
      return moment.weekdays(val + 1);
    }
    if (granularity === 'd') {
      return moment(val).format('MMM D, YY');
    }
    if (granularity === 'm') {
      return moment(val, 'M').format('MMMM');
    }
    if (granularity === 's'){
      return i18n.t(val)
    }
  } catch (error) {
    console.error(error);
  }
  return val;
};

export const orderTypePartnerListFormatter = payload => {
  const formattedData = {
    orderTypes: [],
    partners: [],
  };

  if (payload?.orderTypes?.length) {
    formattedData.orderTypes = payload.orderTypes;
  }
  if (payload?.partners?.length) {
    formattedData.partners = payload.partners;
  }
  return formattedData;
};

export const performanceDataFormatter = (payload, isPartner) => {
  const formattedData = {
    data: [],
    advData: [],
  };
  try {
    const pushData = (record, name, color) => {
      formattedData.data.push({
        name: i18n.t(name),
        color,
        sales: {
          curr: record.sales || 0,
          change: record.sales_change || 0,
        },
        orders: {
          curr: record.orders || 0,
          change: record.orders_change || 0,
        },
        cancelledOrders: {
          curr: record.cancelledOrders || 0,
          change: record.cancelledOrders_change || 0,
        },
        abv: {
          curr: record.abv || 0,
          change: record.abv_change || 0,
        },
      });
      if (name === 'Dine In') {
        formattedData.advData.push({
          name,
          color,
          advancedOrders: {
            curr: (record.orders_adv || 0) + (record.orders_now || 0),
            change:
              (record.orders_adv_change || 0) + (record.orders_now_change || 0),
          },
        });
      } else {
        formattedData.advData.push({
          name: `${name}|Advanced`,
          color: shadeColor(color, 45),
          advancedOrders: {
            curr: record.orders_adv,
            change: record.orders_adv_change,
          },
        });
        formattedData.advData.push({
          name: `${name}|Now`,
          color,
          advancedOrders: {
            curr: record.orders_now,
            change: record.orders_now_change,
          },
        });
      }
    };
    const payloadArray = Object.values(payload);

    if (payloadArray?.length) {
      payloadArray.forEach(obj => {
        if (isPartner) {
          pushData(obj, obj.partner_name, obj.color);
        } else {
          if (obj.order_type) {
            pushData(obj, obj.order_type, obj.color);
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
  return formattedData;
};

export const trendDataFormatter = (payload, isPartner, metric, granularity) => {
  const formattedData = [];

  const pushData = (record, name, color) => {
    formattedData.push({
      name: i18n.t(name),
      color,
      [metric]: record[metric],
      [granularity]: formatGranularity(record[granularity], granularity),
    });
  };
  if (payload?.length) {
    payload.forEach(obj => {
      if (isPartner) {
        pushData(obj, obj.partner_name, obj.color);
      } else {
        if (obj.order_type) {
          pushData(obj, obj.order_type, obj.color);
        }
      }
    });
  }
  if (granularity === 's') {
    const sortedData = formattedData.sort((a, b) => {
      var index_result = compare(sessionOrder[a.s], sessionOrder[b.s]);

      return index_result;
    });
    return sortedData;
  }
  return formattedData;
};

export const chainTrendDataFormatter = (
  payload,
  graph,
  metric,
  granularity,
  sw,
) => {
  const formattedData = {
    zl: [],
    op: [],
  };

  const pushData = (record, name, obj) => {
    formattedData[obj].push({
      name,
      color: record.color || '#ff6b00',
      [metric]: record[metric],
      [granularity]: formatGranularity(record[granularity], granularity),
    });
  };

  if (payload.overall?.length) {
    payload.overall.forEach(obj => {
      pushData(obj, i18n.t('Overall'), 'zl');
      pushData(obj, i18n.t('Overall'), 'op');
    });
  }
  if (payload[sw]?.length) {
    payload[sw].forEach(obj => {
      pushData(obj, obj[sw], 'zl');
    });
  }
  if (payload[graph]?.length) {
    payload[graph].forEach(obj => {
      pushData(obj, i18n.t(obj[graph]), 'op');
    });
  }
  if (granularity === 's') {
    const zl = formattedData.zl.sort((a, b) =>
      compare(sessionOrder[a.s], sessionOrder[b.s]),
    );
    const op = formattedData.op.sort((a, b) =>
      compare(sessionOrder[a.s], sessionOrder[b.s]),
    );
    return {
      zl,
      op,
    };
  }
  return formattedData;
};

export const zlPerfFormatter = (payload, isPartner, metric, sw) => {
  const formattedData = [];
  const pushData = (record, name) => {
    formattedData.push({
      name: i18n.t(record[name]),
      color: record.color,
      [metric]: record[metric],
      [sw]: record[sw],
    });
  };

  if (payload?.length) {
    payload.forEach(obj => {
      if (isPartner) {
        pushData(obj, 'partner_name');
      } else {
        if (obj.order_type) {
          pushData(obj, 'order_type');
        }
      }
    });
  }
  return formattedData;
};

export const profitPercentageDataFormatter = (payload, isPartner) => {
  const formattedData = [];
  try {
    const pushData = (record, name, color) => {
      formattedData.push({
        name,
        color,
        profit: record.profit,
        percentage: record.profit_percentage,
        change: record.profit_percentage_change,
      });
    };
    payload.forEach(obj => {
      if (isPartner) {
        pushData(obj, obj.partner_name, obj.color);
      } else {
        if (obj.order_type) {
          pushData(obj, obj.order_type, obj.color);
        }
      }
    });
  } catch (error) {
    console.log(error);
  }
  return formattedData;
};

export const profitTrendDataFormatter = (payload, isPartner, granularity) => {
  const formattedData = [];
  try {
    const pushData = (record, name, color) => {
      formattedData.push({
        name,
        color,
        profit: record.profit,
        [granularity]: formatGranularity(record[granularity], granularity),
      });
    };
    payload.forEach(obj => {
      if (isPartner) {
        pushData(obj, obj.partner_name, obj.color);
      } else {
        if (obj.order_type) {
          pushData(obj, obj.order_type, obj.color);
        }
      }
    });
  } catch (error) {
    console.log(error);
  }

  if (granularity === 's') {
    const sortedData = formattedData.sort((a, b) => {
      var index_result = compare(sessionOrder[a['s']], sessionOrder[b['s']]);

      return index_result;
    });
    return sortedData;
  }

  return formattedData;
};
