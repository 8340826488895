export const FETCH_CROSS_SELL_UPSELL_SALES_SCORE =
  'app/CrossSellUpSellPage/FETCH_CROSS_SELL_UPSELL_SALES_SCORE';
export const CROSS_SELL_UPSELL_SALES_SCORE_LOADED =
  'app/CrossSellUpSellPage/CROSS_SELL_UPSELL_SALES_SCORE_LOADED';
export const CROSS_SELL_UPSELL_SALES_SCORE_FAILED =
  'app/CrossSellUpSellPage/CROSS_SELL_UPSELL_SALES_SCORE_FAILED';
export const FETCH_CROSS_SELL_UPSELL_CONTRIBUTION =
  'app/CrossSellUpSellPage/FETCH_CROSS_SELL_UPSELL_CONTRIBUTION';
export const CROSS_SELL_UPSELL_CONTRIBUTION_LOADED =
  'app/CrossSellUpSellPage/CROSS_SELL_UPSELL_CONTRIBUTION_LOADED';
export const CROSS_SELL_UPSELL_CONTRIBUTION_FAILED =
  'app/CrossSellUpSellPage/CROSS_SELL_UPSELL_CONTRIBUTION_FAILED';
export const FETCH_SALES_COMPARISON =
  'app/CrossSellUpSellPage/FETCH_SALES_COMPARISON';
export const SALES_COMPARISON_LOADED =
  'app/CrossSellUpSellPage/SALES_COMPARISON_LOADED';
export const SALES_COMPARISON_FAILED =
  'app/CrossSellUpSellPage/SALES_COMPARISON_FAILED';
export const FETCH_SALES_SCORE = 'app/CrossSellUpSellPage/FETCH_SALES_SCORE';
export const SALES_SCORE_LOADED = 'app/CrossSellUpSellPage/SALES_SCORE_LOADED';
export const SALES_SCORE_FAILED = 'app/CrossSellUpSellPage/SALES_SCORE_FAILED';
export const FETCH_CROSS_SELL_UPSELL_PRICING =
  'app/CrossSellUpSellPage/FETCH_CROSS_SELL_UPSELL_PRICING';
export const CROSS_SELL_UPSELL_PRICING_LOADED =
  'app/CrossSellUpSellPage/CROSS_SELL_UPSELL_PRICING_LOADED';
export const CROSS_SELL_UPSELL_PRICING_FAILED =
  'app/CrossSellUpSellPage/CROSS_SELL_UPSELL_PRICING_FAILED';
export const FETCH_ITEM_PERFORMANCE =
  'app/CrossSellUpSellPage/FETCH_ITEM_PERFORMANCE';
export const ITEM_PERFORMANCE_LOADED =
  'app/CrossSellUpSellPage/ITEM_PERFORMANCE_LOADED';
export const ITEM_PERFORMANCE_FAILED =
  'app/CrossSellUpSellPage/ITEM_PERFORMANCE_FAILED';
