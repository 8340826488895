const dropdownFormatter = payload => ({
  terminalType: payload.terminal_types || [],
  paymentTypes: payload.payment_types || [],
  networkTypes: payload.network_types || [],
  cardTypes: payload.card_types || [],
});

const summaryTableFormatter = (payload, type) => {
  const formattedData = {
    overall: [],
    daily: [],
  };

  if (payload?.overall_breakups?.length) {
    payload.overall_breakups.forEach(obj => {
      formattedData.overall.push({
        id: obj[type],
        key: obj[type],
        name: obj[type],
        type: 'main',
        refund: obj.refund,
        terminalType: obj.terminal_type ?? obj.restaurant_name,
        totalAmount: obj.total_amount,
        totalCommissionFees: obj.total_commission_fees,
        totalOrders: obj.total_orders,
        transferAmount: obj.transfer_amount,
        children: obj.breakup?.length
          ? obj.breakup.map(childObj => ({
              type: 'child',
              name: childObj.partner_type,
              refund: childObj.refund,
              totalAmount: childObj.total_amount,
              totalCommissionFees: childObj.total_commission_fees,
              totalOrders: childObj.total_orders,
              transferAmount: childObj.transfer_amount,
            }))
          : undefined,
      });
    });
  }

  if (payload?.day_breakups?.length) {
    payload.day_breakups.forEach(obj => {
      formattedData.daily.push({
        id: obj.date,
        date: obj.date,
        key: `${obj.date}-main`,
        type: 'main',
        name: obj.date,
        refund: obj.refund,
        totalAmount: obj.total_amount,
        totalCommissionFees: obj.total_commission_fees,
        totalOrders: obj.total_orders,
        transferAmount: obj.transfer_amount,
        children: obj.break_up.map(childObj => ({
          id: `${obj.date}-${childObj[type]}`,
          key: `${obj.date}${childObj[type]}`,
          date: childObj[type],
          type: 'child',
          name: childObj[type],
          refund: childObj.refund,
          totalAmount: childObj.total_amount,
          totalCommissionFees: childObj.total_commission_fees,
          totalOrders: childObj.total_orders,
          transferAmount: childObj.transfer_amount,
          children: childObj.breakup_partner_type?.length
            ? childObj.breakup_partner_type.map(child2Obj => ({
                id: `${obj.date}${childObj[type]}${child2Obj.terminal_type}`,
                key: `${obj.date}${childObj[type]}${child2Obj.terminal_type}`,
                date: child2Obj[type],
                name: child2Obj[type],
                refund: child2Obj.refund,
                totalAmount: child2Obj.total_amount,
                totalCommissionFees: child2Obj.total_commission_fees,
                totalOrders: child2Obj.total_orders,
                transferAmount: child2Obj.transfer_amount,
              }))
            : undefined,
        })),
      });
    });
  }
  return formattedData;
};

const transactionsOrdersTableFormatter = payload => {
  if (payload?.length) {
    const formattedData = payload
      .map(obj => ({
        key: obj.bill_info_id,
        id: obj.bill_info_id,
        billNo: obj.bill_info_id,
        date: obj.date || '-',
        paymentType: obj.payment_type || '-',
        paymentCode: obj.payment_code || '-',
        networkType: obj.network,
        refund: obj.refund || '-',
        refundDate: obj.refund_date == " " || null ? '-' : obj.refund_date,
        refundRemarks: obj.refund_reason || '-',
        terminalType: obj.terminal_type || 0,
        totalAmount: obj.total_amount || 0,
        transferAmount: obj.transfer_amount || 0,
        commission: obj.total_commission_fees || 0,
        restaurantName: obj.restaurant_name,
      }))
      .filter(o => o.id && o.key);
    return formattedData;
  }
  return [];
};

export {
  dropdownFormatter,
  summaryTableFormatter,
  transactionsOrdersTableFormatter,
};
