/*
 *
 * benchmarking performance analysis constants
 *
 */

export const FETCH_PERFORMANCE_ANALYSIS_ACTION =
  'app/Benchmarking/FETCH_PERFORMANCE_ANALYSIS_ACTION';

export const FETCH_PERFORMANCE_ANALYSIS_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_PERFORMANCE_ANALYSIS_SUCCESS_ACTION';

export const FETCH_PERFORMANCE_ANALYSIS_ERROR_ACTION =
  'app/Benchmarking/FETCH_PERFORMANCE_ANALYSIS_ERROR_ACTION';

export const FETCH_PERFORMANCE_ANALYSIS_SCORE_CARD_ACTION =
  'app/Benchmarking/FETCH_PERFORMANCE_ANALYSIS_SCORE_CARD_ACTION';

export const FETCH_PERFORMANCE_ANALYSIS_SCORE_CARD_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_PERFORMANCE_ANALYSIS_SCORE_CARD_SUCCESS_ACTION';

export const FETCH_PERFORMANCE_ANALYSIS_SCORE_CARD_ERROR_ACTION =
  'app/Benchmarking/FETCH_PERFORMANCE_ANALYSIS_SCORE_CARD_ERROR_ACTION';

export const FETCH_PRICE_BAND_ANALYSIS_ACTION =
  'app/Benchmarking/FETCH_PRICE_BAND_ANALYSIS_ACTION';

export const FETCH_PRICE_BAND_ANALYSIS_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_PRICE_BAND_ANALYSIS_SUCCESS_ACTION';

export const FETCH_PRICE_BAND_ANALYSIS_ERROR_ACTION =
  'app/Benchmarking/FETCH_PRICE_BAND_ANALYSIS_ERROR_ACTION';

export const FETCH_PRICE_BAND_STATS_ACTION =
  'app/Benchmarking/FETCH_PRICE_BAND_STATS_ACTION';

export const FETCH_PRICE_BAND_STATS_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_PRICE_BAND_STATS_SUCCESS_ACTION';

export const FETCH_PRICE_BAND_STATS_ERROR_ACTION =
  'app/Benchmarking/FETCH_PRICE_BAND_STATS_ERROR_ACTION';

export const FETCH_MEAL_PENETRATION_ACTION =
  'app/Benchmarking/FETCH_MEAL_PENETRATION_ACTION';

export const FETCH_MEAL_PENETRATION_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_MEAL_PENETRATION_SUCCESS_ACTION';

export const FETCH_MEAL_PENETRATION_ERROR_ACTION =
  'app/Benchmarking/FETCH_MEAL_PENETRATION_ERROR_ACTION';
