import { takeLatest, call, put } from 'redux-saga/effects';
import { getMerchantAndDate } from 'redux/rootSaga';
import {
  mealPenetrationDataFormatter,
  categoryPenetrationDataFormatter,
  categoryPlacementDataFormatter,
  categoryContributionDataFormatter,
  categoryByTimeDataFormatter,
} from './formatter';
import {
  fetchCategoryPlacement,
  fetchMealPenetration,
  fetchCategoryPenetrationContribution,
  fetchCategoryByTime,
} from './services';
import {
  FETCH_CATEGORY_PLACEMENT,
  FETCH_CATEGORY_CONTRIBUTION,
  FETCH_CATEGORY_BY_TIME,
  FETCH_CATEGORY_PENETRATION,
  FETCH_MEAL_PENETRATION,
} from './constants';
import {
  categoryPlacementLoadedAction,
  categoryPlacementFailedAction,
  categoryContributionDataLoaded,
  categoryContributionError,
  categoryByTimeDataLoaded,
  categoryByTimeFailed,
  categoryPenetrationLoadedAction,
  categoryPenetrationFailedAction,
  mealPenetrationLoadedAction,
  mealPenetrationFailedAction,
} from './actions';

export function* fetchCategoryPenetrationContributionDataSaga({ graphType }) {
  const { merchantIdList, startDate, endDate, cStartDate, cEndDate } =
    yield getMerchantAndDate();
  const payload = {
    merchant_id: merchantIdList,
    start_date: startDate,
    end_date: endDate,
    c_start_date: cStartDate,
    c_end_date: cEndDate,
    graph_type: graphType,
  };

  try {
    const response = yield call(fetchCategoryPenetrationContribution, payload);
    if (graphType === 'P') {
      if (response.status == 206) {
        yield put(categoryPenetrationLoadedAction([]));
      } else {
        const formattedData = categoryPenetrationDataFormatter(response.data);
        yield put(categoryPenetrationLoadedAction(formattedData));
      }
    } else {
      if (response.status == 206) {
        yield put(categoryContributionDataLoaded([]));
      } else {
        const formattedData = categoryContributionDataFormatter(response.data);
        yield put(categoryContributionDataLoaded(formattedData));
      }
    }
  } catch (e) {
    if (graphType === 'P') yield put(categoryPenetrationFailedAction());
    else yield put(categoryContributionError('Something went wrong'));
  }
}

export function* fetchMealPenetrationDataSaga() {
  const { merchantIdList, startDate, endDate, cStartDate, cEndDate } =
    yield getMerchantAndDate();
  try {
    const response = yield call(fetchMealPenetration, {
      merchant_id: merchantIdList,
      start_date: startDate,
      end_date: endDate,
      c_start_date: cStartDate,
      c_end_date: cEndDate,
    });
    if (response.status == 206) yield put(mealPenetrationLoadedAction([]));
    else {
      const formattedData = mealPenetrationDataFormatter(response.data);
      yield put(mealPenetrationLoadedAction(formattedData));
    }
  } catch (e) {
    yield put(mealPenetrationFailedAction('Something went wrong'));
  }
}

export function* fetchCategoryPlacementDataSaga() {
  const { merchantIdList, startDate, endDate } = yield getMerchantAndDate();
  try {
    const response = yield call(fetchCategoryPlacement, {
      merchant_id: merchantIdList,
      start_date: startDate,
      end_date: endDate,
    });
    if (response.status == 206) yield put(categoryPlacementLoadedAction([]));
    else {
      const formattedData = categoryPlacementDataFormatter(response.data);
      yield put(categoryPlacementLoadedAction(formattedData));
    }
  } catch (e) {
    yield put(categoryPlacementFailedAction('Something went wrong'));
  }
}

export function* fetchCategoryByTimeDataSaga({ payload: filter }) {
  const { merchantIdList, startDate, endDate } = yield getMerchantAndDate();
  const payload = {
    merchant_id: merchantIdList,
    start_date: startDate,
    end_date: endDate,
    graph_type: filter,
  };

  try {
    const { data, status } = yield call(fetchCategoryByTime, payload);
    if (status === 200 || (status === 206 && data)) {
      const { error_message } = data;
      if (error_message) {
        yield put(categoryByTimeFailed('No Data Found'));
      } else {
        const formattedData = categoryByTimeDataFormatter(data);
        yield put(categoryByTimeDataLoaded(formattedData));
      }
    } else throw new Error('Something went wrong');
  } catch (error) {
    console.log('Something went wrong', error);
    yield put(categoryByTimeFailed('Something went wrong'));
  }
}

export default function* categoryContainerSaga() {
  yield takeLatest(
    FETCH_CATEGORY_PENETRATION,
    fetchCategoryPenetrationContributionDataSaga,
    { graphType: 'P' },
  );
  yield takeLatest(
    FETCH_CATEGORY_CONTRIBUTION,
    fetchCategoryPenetrationContributionDataSaga,
    { graphType: 'C' },
  );
  yield takeLatest(FETCH_MEAL_PENETRATION, fetchMealPenetrationDataSaga);
  yield takeLatest(FETCH_CATEGORY_PLACEMENT, fetchCategoryPlacementDataSaga);
  yield takeLatest(FETCH_CATEGORY_BY_TIME, fetchCategoryByTimeDataSaga);
}
