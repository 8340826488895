import { takeLatest, call, put } from 'redux-saga/effects';
import { getMerchantAndDate } from 'redux/rootSaga';
import {
  trackerDetailsFormatter,
  trackerSectionListFormatter,
  trackerSectionDetailsFormatter,
} from './formatter';
import {
  // FETCH_TRACKER_DETAILS_ACTION,
  FETCH_TRACKER_SECTION_LIST_ACTION,
  FETCH_TRACKER_SECTION_DETAILS_ACTION,
  ADD_TRACKER_ACTION,
  // FETCH_TRACKER_SCORE_CARDS_ACTION,
  FETCH_TRACKER_MERGE_ACTION
} from './constants';
import {
  fetchTrackerScoreCardsSuccessAction,
  fetchTrackerScoreCardsErrorAction,
  fetchTrackerDetailsSuccessAction,
  fetchTrackerDetailsErrorAction,
  fetchTrackerSectionListSuccessAction,
  fetchTrackerSectionListErrorAction,
  fetchTrackerSectionDetailsSuccessAction,
  fetchTrackerSectionDetailsErrorAction,
  addTrackerSuccessAction,
  addTrackerErrorAction,
} from './actions';
import {
  // fetchTrackerDetails,
  fetchTrackerSectionDetails,
  fetchTrackerSectionList,
  addTracker,
  // fetchTrackerScoreCards,
  fetchTrackerMergeData,
} from './services';
import scorecardDataTransformer from 'transformers/scorecardDataTransformer';

   
// function* fetchTrackerScoresSaga({ payload }) {
//   let merchantIdList1
//   const { chainId } = payload;
//   const { merchantIdList, startDate, endDate, cStartDate, cEndDate } =
//     yield getMerchantAndDate();
//     if(chainId){
//       const list1 = merchantIdList[0].split('-');
//       merchantIdList1 = list1[0] + "-1-"+ list1[1];
//     }
//   try {
//     //Score Card
//     const response = yield call(fetchTrackerScoreCards, {
//       merchant_id: chainId? [merchantIdList1] : merchantIdList,
//       start_date: startDate,
//       end_date: endDate,
//       // c_start_date: cStartDate,
//       // c_end_date: cEndDate,
//       is_chain: chainId ? 1 : 0,
//     });
//     if (response.status == 206)
//       yield put(fetchTrackerScoreCardsSuccessAction([]));
//     else {
//       const formattedData = scorecardDataTransformer(
//         response.data[0],
//         'tracker',
//       );
//       yield put(fetchTrackerScoreCardsSuccessAction(formattedData));
//     }
//   } catch (e) {
//     yield put(fetchTrackerScoreCardsErrorAction('Something went wrong'));
//   }
// }



// function* trackerDetailsSaga({ payload }) {
//   let merchantIdList1
//   const { chainId } = payload;
//   const { merchantIdList, startDate, endDate, cStartDate, cEndDate } =
//     yield getMerchantAndDate();
//   try {
//     if(chainId){
//       const list1 = merchantIdList[0].split('-');
//       merchantIdList1 = list1[0] + "-1-"+ list1[1];
//     }
//     // Table Data
//     const response = yield call(fetchTrackerDetails, {
//       merchant_id: chainId ? [merchantIdList1] : merchantIdList,
//       //merchant_id: merchantIdList,
//       start_date: startDate,
//       end_date: endDate,
//       // c_start_date: cStartDate,
//       // c_end_date: cEndDate,
//       is_chain: chainId ? 1 : 0,
//     });
//     if (response.status == 206) yield put(fetchTrackerDetailsSuccessAction([]));
//     else {
//       const formattedData = trackerDetailsFormatter(response.data);
//       yield put(fetchTrackerDetailsSuccessAction(formattedData));
//     }
//   } catch (e) {
//     yield put(fetchTrackerDetailsErrorAction('Something went wrong'));
//   }
// }

function* fetchTrackerMergeSaga({ payload }) {
  let merchantIdList1
  const { chainId } = payload;
  const { merchantIdList, startDate, endDate, cStartDate, cEndDate } =
    yield getMerchantAndDate();
    if(chainId){
      const list1 = merchantIdList[0].split('-');
      merchantIdList1 = list1[0] + "-1-"+ list1[1];
    }
  try {
    const response = yield call(fetchTrackerMergeData, {
      merchant_id: chainId? [merchantIdList1] : merchantIdList,
      start_date: startDate,
      end_date: endDate,
      is_chain: chainId ? 1 : 0,
      // c_start_date: cStartDate,
      // c_end_date: cEndDate,
    });
    
     try {
       if (response.data['scorecard'][1] == 206) {
         yield put(fetchTrackerScoreCardsSuccessAction([]));
       }
       else {
         const scoreCardResponce = response.data.scorecard[0]
         const formattedDataScore = scorecardDataTransformer(scoreCardResponce, 'tracker');
         yield put(fetchTrackerScoreCardsSuccessAction(formattedDataScore));
       }
 
     } catch (e) {
       yield put(fetchTrackerScoreCardsErrorAction('Something went wrong'));
     }
 
     try {
         if (response.status == 206) {
         yield put(fetchTrackerDetailsSuccessAction([]));
       }
       else {
         const trackerDetailsResponce = response.data.tracker_details
         const formattedDataTrackerDetails = trackerDetailsFormatter(trackerDetailsResponce);
         yield put(fetchTrackerDetailsSuccessAction(formattedDataTrackerDetails));
       }
     } catch (e) {
       yield put(fetchTrackerDetailsErrorAction('Something went wrong'))
     }
 
   } catch (e) {
     yield put(fetchTrackerScoreCardsErrorAction('Something went wrong'));
     yield put(fetchTrackerDetailsErrorAction('Something went wrong'))
   }
 }
function* trackerSectionListSaga({ payload }) {
  const { merchantIdList } = yield getMerchantAndDate();
  try {
    const response = yield call(fetchTrackerSectionList, {
      merchant_id: merchantIdList,
      ...payload,
    });
    yield put(
      fetchTrackerSectionListSuccessAction(
        trackerSectionListFormatter(response.data),
      ),
    );
  } catch (e) {
    yield put(fetchTrackerSectionListErrorAction('Something went wrong'));
  }
}

function* trackerSectionDetailsSaga({ payload }) {
  const { merchantIdList } = yield getMerchantAndDate();
  try {
    if (payload.section_id) {
      const response = yield call(fetchTrackerSectionDetails, {
        merchant_id: merchantIdList,
        ...payload,
      });

      yield put(
        fetchTrackerSectionDetailsSuccessAction(
          trackerSectionDetailsFormatter(response.data),
        ),
      );
    } else {
      yield put(
        fetchTrackerSectionDetailsSuccessAction([
          { label: 'Name', value: '-' },
          { label: 'ID', value: '-' },
          { label: 'Sequence', value: '-' },
          // { label: 'View Type', value: '-' },
        ]),
      );
    }
  } catch (e) {
    yield put(fetchTrackerSectionDetailsErrorAction('Something went wrong'));
  }
}

function* addTrackerSaga({ payload }) {
  const { merchantIdList } = yield getMerchantAndDate();
  try {
    const response = yield call(addTracker, {
      merchant_id: merchantIdList,
      ...payload,
    });
    yield put(addTrackerSuccessAction(response.data));
  } catch (e) {
    yield put(addTrackerErrorAction('Something went wrong'));
  }
}

export default function* watchTrackerSaga() {
  yield takeLatest(FETCH_TRACKER_MERGE_ACTION, fetchTrackerMergeSaga);
  // yield takeLatest(FETCH_TRACKER_SCORE_CARDS_ACTION, fetchTrackerScoresSaga);
  // yield takeLatest(FETCH_TRACKER_DETAILS_ACTION, trackerDetailsSaga);
  yield takeLatest(FETCH_TRACKER_SECTION_LIST_ACTION, trackerSectionListSaga);
  yield takeLatest(FETCH_TRACKER_SECTION_DETAILS_ACTION,trackerSectionDetailsSaga);
  yield takeLatest(ADD_TRACKER_ACTION, addTrackerSaga);
}
