/*
 *
 * commission details constants
 *
 */

export const FETCH_COMMISSION_DETAILS_ACTION =
  'app/OutletInfo/FETCH_COMMISSION_DETAILS_ACTION';

export const FETCH_COMMISSION_DETAILS_SUCCESS_ACTION =
  'app/OutletInfo/FETCH_COMMISSION_DETAILS_SUCCESS_ACTION';

export const FETCH_COMMISSION_DETAILS_ERROR_ACTION =
  'app/OutletInfo/FETCH_COMMISSION_DETAILS_ERROR_ACTION';

export const SAVE_COMMISSION_DETAILS_ACTION =
  'app/OutletInfo/SAVE_COMMISSION_DETAILS_ACTION';

export const SAVE_COMMISSION_DETAILS_SUCCESS_ACTION =
  'app/OutletInfo/SAVE_COMMISSION_DETAILS_SUCCESS_ACTION';

export const SAVE_COMMISSION_DETAILS_ERROR_ACTION =
  'app/OutletInfo/SAVE_COMMISSION_DETAILS_ERROR_ACTION';

export const DELETE_COMMISSION_DETAILS_ACTION =
  'app/OutletInfo/DELETE_COMMISSION_DETAILS_ACTION';

export const DELETE_COMMISSION_DETAILS_SUCCESS_ACTION =
  'app/OutletInfo/DELETE_COMMISSION_DETAILS_SUCCESS_ACTION';

export const DELETE_COMMISSION_DETAILS_ERROR_ACTION =
  'app/OutletInfo/DELETE_COMMISSION_DETAILS_ERROR_ACTION';
