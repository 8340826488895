export const FETCH_PROMOTION_USAGE_SCORES_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_SCORES_ACTION';

export const FETCH_PROMOTION_USAGE_SCORES_SUCCESS_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_SCORES_SUCCESS_ACTION';

export const FETCH_PROMOTION_USAGE_SCORES_ERROR_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_SCORES_ERROR_ACTION';

export const FETCH_PROMOTION_USAGE_PROMO_NON_PROMO_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_PROMO_NON_PROMO_ACTION';

export const FETCH_PROMOTION_USAGE_PROMO_NON_PROMO_SUCCESS_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_PROMO_NON_PROMO_SUCCESS_ACTION';

export const FETCH_PROMOTION_USAGE_PROMO_NON_PROMO_ERROR_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_PROMO_NON_PROMO_ERROR_ACTION';

export const FETCH_PROMOTION_USAGE_CODE_USAGE_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_CODE_USAGE_ACTION';

export const FETCH_PROMOTION_USAGE_CODE_USAGE_SUCCESS_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_CODE_USAGE_SUCCESS_ACTION';

export const FETCH_PROMOTION_USAGE_CODE_USAGE_ERROR_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_CODE_USAGE_ERROR_ACTION';

export const FETCH_PROMOTION_USAGE_TABLE_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_TABLE_ACTION';

export const FETCH_PROMOTION_USAGE_TABLE_SUCCESS_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_TABLE_SUCCESS_ACTION';

export const FETCH_PROMOTION_USAGE_TABLE_ERROR_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_TABLE_ERROR_ACTION';

export const FETCH_PROMOTION_USAGE_BY_DAY_TIME_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_BY_DAY_TIME_ACTION';

export const FETCH_PROMOTION_USAGE_BY_DAY_TIME_SUCCESS_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_BY_DAY_TIME_SUCCESS_ACTION';

export const FETCH_PROMOTION_USAGE_BY_DAY_TIME_ERROR_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_BY_DAY_TIME_ERROR_ACTION';

export const FETCH_PROMOTION_USAGE_DROPDOWNS_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_DROPDOWNS_ACTION';

export const FETCH_PROMOTION_USAGE_DROPDOWNS_SUCCESS_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_DROPDOWNS_SUCCESS_ACTION';

export const FETCH_PROMOTION_USAGE_DROPDOWNS_ERROR_ACTION =
  'app/PromotionUsage/FETCH_PROMOTION_USAGE_DROPDOWNS_ERROR_ACTION';

export const SET_PROMOTION_USAGE_APP_SELECTED =
  'app/PromotionUsage/SET_PROMOTION_USAGE_APP_SELECTED';

export const SET_PROMOTION_USAGE_ORDERTYPE_SELECTED =
  'app/PromotionUsage/SET_PROMOTION_USAGE_ORDERTYPE_SELECTED';
