// Check if the UI is ready to take a screenshot
const checkStateLoading = state => {
  try {
    // If the state is object or not
    if (state && typeof state === 'object') {
      return Object.keys(state).reduce((accu, chunkKey) => {
        // If the nested chunk is an object or not

        if (typeof state[chunkKey] === 'object') {
          const { loading } = state[chunkKey];
          return typeof loading === 'boolean' ? accu || loading : accu || false;
        }
        return accu || false;
      }, false);
    }
    return false;
  } catch (error) {
    console.error({
      error,
    });
    return true;
  }
};

export default checkStateLoading;
