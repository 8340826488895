import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import refreshAuthLogic from './refreshAuthLogic';
import cache from './cache';
import { getAuthToken } from '../authHelper';
import { getBaseUrl } from '../../config';

//* disabling the process.env base url.
// const baseURL = process.env.REACT_APP_BASE_URL;
const baseURL = getBaseUrl();

/**
 *
 * @param {string} url api path
 * @param {object} [data={}] request paylod
 * @param {object} [config={}] axios config
 * @param {boolean} [ignoreCache=false] flag to enable or disable cache
 *
 */

const fetch = ({ url, data = {}, config = {}, ignoreCache = false }) => {
  try {
    const accessToken = getAuthToken();
    if (baseURL && url && accessToken) {
      const api = axios.create({
        ...(ignoreCache ? {} : { adapter: cache.adapter }),
        baseURL: baseURL,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // set access token
      api.interceptors.request.use(
        config => {
          if (!config?.headers?.Authorization) {
            config.headers['Authorization'] = 'Bearer ' + accessToken;
          }
          return config;
        },
        error => {
          Promise.reject(error);
        },
      );

      // if 401 (Token expired)
      createAuthRefreshInterceptor(api, refreshAuthLogic);

      return api.post(url, data, config);
    }
  } catch (error) {
    console.log(error);
  }
};

export default fetch;
