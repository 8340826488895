import moment from 'moment';

const scoresFormatter = payload => {
  const {
    total_failed_orders: orders = 0,
    failed_order_amount: amount = 0,
    percentage_failed_orders: ordersPerc = 0,
    percentage_sales_failed_orders: amoutPerc = 0,
  } = payload;

  return {
    amount,
    orders,
    amoutPerc,
    ordersPerc,
  };
};

const sessionOrder = {
  Morning: 0,
  'Mid Morning': 1,
  Lunch: 2,
  'Mid Afternoon': 3,
  Evening: 4,
  Dinner: 5,
  'Late Dinner': 6,
};

function compare(a, b) {
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
}

const trendFormatter = (payload, granularity) => {
  if (payload?.length) {
    const formattedData = payload.map(obj => ({
      label:
        granularity === 'h'
          ? moment(obj[granularity], 'hh').format('h A')
          : obj[granularity],
      value: obj.order_no,
    }));
    if (granularity === 's') {
      formattedData.sort((a, b) =>
        compare(sessionOrder[a.label], sessionOrder[b.label]),
      );
    }
    return formattedData;
  }
  return [];
};

const tableFormatter = payload => {
  if (payload?.length) {
    const formattedData = payload.map((obj, i) => {
      const {
        app_name: product,
        cancel_reason: reason,
        date,
        is_cancelled: isCancelled = 0,
        order_amount: orderAmount = 0,
        order_no: orderNo,
        order_type: orderType,
        partner_type: partner,
        payment_type: payment,
        total_amount: subTotal = 0,
        items_breakup: itemBreakup = [],
      } = obj;

      const items = itemBreakup.map(childObj => {
        const { dish_name: item, quantity, base_price: price } = childObj;
        return {
          item,
          quantity,
          price,
        };
      });

      return {
        id: `${orderNo}-${date}-${i}`,
        key: `${orderNo}-${date}-${i}`,
        product,
        reason,
        date,
        isCancelled,
        subTotal,
        orderNo,
        orderType,
        partner,
        payment,
        orderAmount,
        items,
      };
    });

    return formattedData;
  }
  return [];
};

export { scoresFormatter, trendFormatter, tableFormatter };
