import * as cn from './constants';

const initObject = (type, payload, isChain = false) => ({
  type,
  payload,
  isChain,
});

const successObject = (type, payload) => ({
  type,
  payload,
});

const errorObject = (type, error) => ({
  type,
  error,
});

// export const getCancelledOrderScore = (payload, isChain) =>
//   initObject(cn.CANCELLED_ORDER_SCORES_INIT, payload, isChain);

export const getCancelledOrderScoreSuccess = payload =>
  successObject(cn.CANCELLED_ORDER_SCORES_SUCCESS, payload);

// export const getCancelledOrderScoreError = error =>
//   errorObject(cn.CANCELLED_ORDER_SCORES_ERROR, error);

// export const getCancelledOrderBreakup = (payload, isChain) =>
//   initObject(cn.CANCELLED_ORDER_BREAKUP_INIT, payload, isChain);

export const getCancelledOrderBreakupSuccess = payload =>
  successObject(cn.CANCELLED_ORDER_BREAKUP_SUCCESS, payload);

// export const getCancelledOrderBreakupError = error =>
//   errorObject(cn.CANCELLED_ORDER_BREAKUP_ERROR, error);

// export const getCancelledOrderReasons = (payload, isChain) =>
//   initObject(cn.CANCELLED_ORDER_REASONS_INIT, payload, isChain);

export const getCancelledOrderReasonsSuccess = payload =>
  successObject(cn.CANCELLED_ORDER_REASONS_SUCCESS, payload);

// export const getCancelledOrderReasonsError = error =>
//   errorObject(cn.CANCELLED_ORDER_REASONS_ERROR, error);

// export const getCancelledOrderTable = (payload, isChain) =>
//   initObject(cn.CANCELLED_ORDER_TABLE_INIT, payload, isChain);

export const getCancelledOrderTableSuccess = payload =>
  successObject(cn.CANCELLED_ORDER_TABLE_SUCCESS, payload);

// export const getCancelledOrderTableError = error =>
//   errorObject(cn.CANCELLED_ORDER_TABLE_ERROR, error);

 
export const getCancelledOrderMergeData = (payload, isChain) =>
  initObject(cn.CANCELLED_ORDER_MERGE_DATA_INIT, payload, isChain);

// export const getCancelledOrderMergeDataSuccess = payload =>
//   successObject(cn.CANCELLED_ORDER_MERGE_DATA_SUCCESS, payload);

export const getCancelledOrderMergeDataError = error =>
  errorObject(cn.CANCELLED_ORDER_MERGE_DATA_ERROR, error);
  