import checkStateLoading from 'helpers/checkStateLoading';

// Setting preloaded or initial state
const preloadedState = {
  scorecardList: {
    error: false,
    loading: false,
    payload: {
      groupTotal: {
        delta: 0,
        value: 0,
      },
      groupSales: {
        delta: 0,
        value: 0,
      },
      groupAbv: {
        delta: 0,
        value: 0,
      },
      costPerPerson: {
        delta: 0,
        value: 0,
      },
    },
  },
  topOrderedTable: {
    loading: false,
    error: false,
    payload: {
      one: {
        topOrderedCat: '-',
        topOrderedItem: '-',
      },
      two: {
        topOrderedCat: '-',
        topOrderedItem: '-',
      },
      threeFour: {
        topOrderedCat: '-',
        topOrderedItem: '-',
      },
      fiveSix: {
        topOrderedCat: '-',
        topOrderedItem: '-',
      },
      sevenPlus: {
        topOrderedCat: '-',
        topOrderedItem: '-',
      },
      kids: {
        topOrderedCat: '-',
        topOrderedItem: '-',
      },
    },
  },
  groupBreakup: {
    error: false,
    loading: false,
    payload: {},
  },
  groupContribution: {
    error: false,
    loading: false,
    payload: {},
  },
  isReady: true,
};

const csGroupAnalysisReducer = (state = preloadedState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case 'FETCH_customerSegmentation/ga/scorecardGroup1_INIT':
      return {
        ...state,
        isReady: false,
        scorecardList: {
          error: false,
          loading: true,
          payload: {},
        },
      };

    case 'FETCH_customerSegmentation/ga/scorecardGroup1_SUCCESS':
      var newState = {
        ...state,
        scorecardList: {
          error: false,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_customerSegmentation/ga/scorecardGroup1_FAIL':
      var newState = {
        ...state,
        scorecardList: {
          error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_customerSegmentation/ga/topOrderedTable_INIT':
      return {
        ...state,
        isReady: false,
        topOrderedTable: {
          error: false,
          loading: true,
          payload: {},
        },
      };

    case 'FETCH_customerSegmentation/ga/topOrderedTable_SUCCESS':
      var newState = {
        ...state,
        topOrderedTable: {
          error: false,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_customerSegmentation/ga/topOrderedTable_FAIL':
      var newState = {
        ...state,
        topOrderedTable: {
          error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_customerSegmentation/ga/groupBreakup_INIT':
      return {
        ...state,
        isReady: false,
        groupBreakup: {
          error: false,
          loading: true,
          payload: {},
        },
      };

    case 'FETCH_customerSegmentation/ga/groupBreakup_SUCCESS':
      var { attribute } = action;
      var newState = {
        ...state,
        groupBreakup: {
          error: false,
          loading: false,
          payload: {
            customers: attribute === 'customers' ? payload : {},
            sales: attribute === 'sales' ? payload : {},
            orders: attribute === 'orders' ? payload : {},
            abv: attribute === 'abv' ? payload : {},
            gcpp: attribute === 'gcpp' ? payload : {},
          },
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_customerSegmentation/ga/groupBreakup_FAIL':
      var { attribute } = action;
      var newState = {
        ...state,
        groupBreakup: {
          error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_customerSegmentation/ga/groupContribution_INIT':
      return {
        ...state,
        groupContribution: {
          error: false,
          loading: true,
          payload: {},
        },
      };
    case 'FETCH_customerSegmentation/ga/groupContribution_SUCCESS':
      var newState = {
        ...state,
        groupContribution: {
          error: false,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_customerSegmentation/ga/groupContribution_FAIL':
      var newState = {
        ...state,
        groupContribution: {
          error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    default:
      return state;
  }
};
export default csGroupAnalysisReducer;
