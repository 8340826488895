import checkStateLoading from 'helpers/checkStateLoading';

// Setting preloaded or initial state
const preloadedState = {
  scorecardList1: {
    error: null,
    loading: false,
    payload: {
      netSales: {
        delta: 0,
        value: 0,
      },
      abvLogin: {
        delta: 0,
        value: 0,
      },
      ordersLogin: {
        delta: 0,
        value: 0,
      },
      ltvLogin: {
        delta: 0,
        value: 0,
      },
      avgOverdueDays: {
        delta: 0,
        value: 0,
      },
      avgTimeVisit: {
        delta: 0,
        value: 0,
      },
      retentionRate: {
        delta: 0,
        value: 0,
      },
      dropout: {
        delta: 0,
        value: 0,
      },
    },
  },
  scorecardList2: {
    error: null,
    loading: false,
    payload: {
      avgOverdueDays: {
        delta: 0,
        value: 0,
      },
      avgTimeVisit: {
        delta: 0,
        value: 0,
      },
      retentionRate: {
        delta: 0,
        value: 0,
      },
      dropout: {
        delta: 0,
        value: 0,
      },
    },
  },
  aiScorecardTable: {
    error: null,
    loading: false,
    payload: {},
  },
  customerVisitData: {
    error: null,
    loading: false,
    payload: [],
  },
  performanceMatrix: {
    error: null,
    loading: false,
    payload: {
      customers: {},
      sales: {},
      orders: {},
      abv: {},
    },
  },
  performanceChart: {
    error: null,
    loading: false,
    payload: {
      customers: {},
      sales: {},
      orders: {},
      abv: {},
    },
  },
  isReady: true,
};

const aiEngineNewVsRepeatReducer = (state = preloadedState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case 'FETCH_aiEngine/nvsr/scorecardGroup1_INIT':
      return {
        ...state,
        isReady: false,
        scorecardList1: {
          error: null,
          loading: true,
          payload: {},
        },
      };

    case 'FETCH_aiEngine/nvsr/scorecardGroup1_SUCCESS':
      var newState = {
        ...state,
        scorecardList1: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_aiEngine/nvsr/scorecardGroup1_FAIL':
      var newState = {
        ...state,
        scorecardList1: {
          error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_aiEngine/nvsr/scorecardGroup2_INIT':
      return {
        ...state,
        isReady: false,
        scorecardList2: {
          error: null,
          loading: true,
          payload: {},
        },
      };

    case 'FETCH_aiEngine/nvsr/scorecardGroup2_SUCCESS':
      var newState = {
        ...state,
        scorecardList2: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_aiEngine/nvsr/scorecardGroup2_FAIL':
      var newState = {
        ...state,
        scorecardList2: {
          error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_aiEngine/nvsr/aiScorecard_INIT':
      return {
        ...state,
        isReady: false,
        aiScorecardTable: {
          error: null,
          loading: true,
          payload: {},
        },
      };

    case 'FETCH_aiEngine/nvsr/aiScorecard_SUCCESS':
      var newState = {
        ...state,
        aiScorecardTable: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_aiEngine/nvsr/aiScorecard_FAIL':
      var newState = {
        ...state,
        aiScorecardTable: {
          error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_aiEngine/nvsr/customerVisits_INIT':
      return {
        ...state,
        isReady: false,
        customerVisitData: {
          error: null,
          loading: true,
          payload: [],
        },
      };

    case 'FETCH_aiEngine/nvsr/customerVisits_SUCCESS':
      var newState = {
        ...state,
        customerVisitData: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_aiEngine/nvsr/customerVisits_FAIL':
      var newState = {
        ...state,
        customerVisitData: {
          error,
          loading: false,
          payload: [],
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_aiEngine/nvsr/performanceMatrix_INIT':
      return {
        ...state,
        isReady: false,
        performanceMatrix: {
          error: null,
          loading: true,
          payload: {},
        },
      };

    case 'FETCH_aiEngine/nvsr/performanceMatrix_SUCCESS':
      var newState = {
        ...state,
        performanceMatrix: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_aiEngine/nvsr/performanceMatrix_FAIL':
      var newState = {
        ...state,
        performanceMatrix: {
          error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_aiEngine/nvsr/performanceData_INIT':
      return {
        ...state,
        isReady: false,
        performanceChart: {
          error: null,
          loading: true,
          payload: {},
        },
      };

    case 'FETCH_aiEngine/nvsr/performanceData_SUCCESS':
      const { attribute } = action;

      var newState = {
        ...state,
        performanceChart: {
          error: null,
          loading: false,
          payload: {
            customers: attribute === 'customers' ? payload : {},
            sales: attribute === 'sales' ? payload : {},
            orders: attribute === 'orders' ? payload : {},
            abv: attribute === 'abv' ? payload : {},
          },
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case 'FETCH_aiEngine/nvsr/performanceData_FAIL':
      var newState = {
        ...state,
        performanceChart: {
          error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    default:
      return state;
  }
};
export default aiEngineNewVsRepeatReducer;
