import * as act from './actions';

export default (
  userClaims,
  listOfMerchant,
  merchantIds,
  merchantCurrency,
  isChainMerchant,
  isEcms,
  enableMasterMerchant,
  enablePaymentRecon,
  isCloudKiosk,
  dispatch,
  locked_tabs,
) => {
  dispatch(act.setCurrency(merchantCurrency));
  dispatch(act.setIsChainMerchant(isChainMerchant));
  dispatch(act.setIsEcms(isEcms));
  dispatch(act.enableMasterMerchant(enableMasterMerchant));
  dispatch(act.enablePaymentRecon(enablePaymentRecon));
  dispatch(act.setIsCloudKiosk(isCloudKiosk));
  dispatch(act.setLockedTabs(locked_tabs));
  // const { user_claims: userClaims } = jwt.decode(accessToken);
  const {
    merchant_id: merchantId = null,
    merchant_name: merchantName = null,
    chain_id: chainId = null,
    menu_mode: menuMode = [],
  } = userClaims || {};
  let merchantList = [];
  merchantId ? localStorage.setItem('merchant_id', merchantId) : null;
  if (listOfMerchant?.length) {
    let countries = [];
    let menuModes = new Set([]);
    [merchantList, countries, menuModes] = listOfMerchant.reduce(
      ([ml, c, mm], chainLocation) => {
        const {
          merchant_id: id,
          restaurant_name: name,
          restaurant_currency_symbol: currency,
          country,
          menu_mode: mode,
          country_code: code,
        } = chainLocation;
        ml.push({ id, name, currency });
        if (!c.some(obj => obj.code === code) && country && code) {
          c.push({ country, code });
        }
        if (mode.includes("'")) {
          const parsedMenuModes = JSON.parse(mode.replace(/'/g, '"'));
          if (parsedMenuModes.length) {
            parsedMenuModes.forEach(val => mm.add(val));
          }
        }
        return [ml, c, mm];
      },
      [[], [], new Set([])],
    );
    if (chainId) {
      dispatch(act.setCountries(countries));

      if (countries.length) {
        dispatch(act.setSelectedCountries(countries[0].code));
      }
    }
    const menuModeArray = Array.from(menuModes);
    const mm1 = menuModeArray.filter(
      (m, _, a) => !(a.includes('Web') && m === 'Master Merchant'),
    );

    const mm = mm1.map(m => (m === 'Master Merchant' ? 'Web' : m));

    mm.sort((a, b) => {
      const strA = a ? a.toUpperCase() : '';
      const strB = b ? b.toUpperCase() : '';
      if (strA > strB) {
        return -1;
      }
      if (strA < strB) {
        return 1;
      }
      return 0;
    });

    dispatch(act.setMenuModeList(mm));
    dispatch(act.setSelectedMenuMode(mm.length ? mm[0] : null));
  } else {
    merchantList = [
      {
        id: merchantId,
        name: merchantName,
        currency: '',
      },
    ];
  }
  dispatch(
    act.setChainDetails(
      chainId ? { id: chainId, name: merchantName } : { id: null, name: null },
    ),
  );

  if (!chainId && !listOfMerchant.length) {
    // const mm1 = menuMode.filter(
    //   (m, _, a) => !(a.includes('Web') && m === 'Master Merchant'),
    // );

    // const mm = mm1.map(m => (m === 'Master Merchant' ? 'Web' : m));

    menuMode.sort((a, b) => {
      const strA = a ? a.toUpperCase() : '';
      const strB = b ? b.toUpperCase() : '';
      if (strA > strB) {
        return -1;
      }
      if (strA < strB) {
        return 1;
      }
      return 0;
    });
    dispatch(act.setMenuModeList(menuMode));
    dispatch(act.setSelectedMenuMode(menuMode.length ? menuMode[0] : null));
  }

  dispatch(act.setMerchantList(merchantList));
  if (merchantIds.length < 2) {
    dispatch(
      act.setActiveMerchantList(merchantList.map(merchant => merchant.id)),
    );
  } else {
    dispatch(act.setActiveMerchantList(merchantIds));
  }
};
