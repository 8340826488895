/*
 *
 * ItemContainer reducer
 *
 */

import checkStateLoading from 'helpers/checkStateLoading';
import * as c from './constants';

export const initialState = {
  itemsByCategory: {
    error: null,
    loading: false,
    payload: {
      data: [],
      filters: {
        categories: [],
      },
      hours: [],
    },
  },
  itemsByItems: {
    error: null,
    loading: false,
    payload: {
      data:[],    
      filters: {
        items: [],
        categories : [],
      },
      columns: [],
    },
  },
  isReady: false,
};


function menuProfilingReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState = {};
  switch (type) {
      case c.MENU_PROFILING_CATEGORY:
      return {
        ...state,
        isReady: false,
        itemsByCategory: {
          error: null,
          loading: true,
          payload: state.itemsByCategory.payload,
        },
      };
    case c.MENU_PROFILING_CATEGORY_SUCCESS:
      newState = {
        ...state,
        itemsByCategory: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.MENU_PROFILING_CATEGORY_FAILURE:
      newState = {
        ...state,
        itemsByCategory: {
          error,
          loading: false,
          payload: state.itemsByCategory.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
      case c.MENU_PROFILING_ITEMS:
      return {
        ...state,
        isReady: false,
        itemsByItems: {
          error: null,
          loading: true,
          payload: state.itemsByItems.payload,
        },
      };
    case c.MENU_PROFILING_ITEMS_SUCCESS:
      newState = {
        ...state,
        itemsByItems: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.MENU_PROFILING_ITEMS_FAILURE:
      newState = {
        ...state,
        itemsByItems: {
          error,
          loading: false,
          payload: state.itemsByItems.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    default:
      return state;
  }
}

export default menuProfilingReducer;
