/*
 *
 * outlet info page constants
 *
 */

export const FETCH_PAYMENT_SCORES_ACTION =
  'app/PaymentReconciliation/FETCH_PAYMENT_SCORES_ACTION';

export const FETCH_PAYMENT_SCORES_SUCCESS_ACTION =
  'app/PaymentReconciliation/FETCH_PAYMENT_SCORES_SUCCESS_ACTION';

export const FETCH_PAYMENT_SCORES_ERROR_ACTION =
  'app/PaymentReconciliation/FETCH_PAYMENT_SCORES_ERROR_ACTION';

export const FETCH_PAYOUT_SCORES_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUT_SCORES_ACTION';

export const FETCH_PAYOUT_SCORES_SUCCESS_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUT_SCORES_SUCCESS_ACTION';

export const FETCH_PAYOUT_SCORES_ERROR_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUT_SCORES_ERROR_ACTION';

export const FETCH_PAYOUT_CHANNELS_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUT_CHANNELS_ACTION';

export const FETCH_PAYOUT_CHANNELS_SUCCESS_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUT_CHANNELS_SUCCESS_ACTION';

export const FETCH_PAYOUT_CHANNELS_ERROR_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUT_CHANNELS_ERROR_ACTION';

export const FETCH_SETTLEMENTS_ACTION =
  'app/PaymentReconciliation/FETCH_SETTLEMENTS_ACTION';

export const FETCH_SETTLEMENTS_SUCCESS_ACTION =
  'app/PaymentReconciliation/FETCH_SETTLEMENTS_SUCCESS_ACTION';

export const FETCH_SETTLEMENTS_ERROR_ACTION =
  'app/PaymentReconciliation/FETCH_SETTLEMENTS_ERROR_ACTION';

export const FETCH_PAYOUT_ORDERS_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUT_ORDERS_ACTION';

export const FETCH_PAYOUT_ORDERS_SUCCESS_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUT_ORDERS_SUCCESS_ACTION';

export const FETCH_PAYOUT_ORDERS_ERROR_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUT_ORDERS_ERROR_ACTION';

export const FETCH_PAYOUT_CHANNEL_DETAILS_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUT_CHANNEL_DETAILS_ACTION';

export const FETCH_PAYOUT_CHANNEL_DETAILS_SUCCESS_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUT_CHANNEL_DETAILS_SUCCESS_ACTION';

export const FETCH_PAYOUT_CHANNEL_DETAILS_ERROR_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUT_CHANNEL_DETAILS_ERROR_ACTION';

export const FETCH_PAYOUTS_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUTS_ACTION';

export const FETCH_PAYOUTS_SUCCESS_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUTS_SUCCESS_ACTION';

export const FETCH_PAYOUTS_ERROR_ACTION =
  'app/PaymentReconciliation/FETCH_PAYOUTS_ERROR_ACTION';

export const FETCH_POS_UNCAPTURED_ACTION =
  'app/PaymentReconciliation/FETCH_POS_UNCAPTURED_ACTION';

export const FETCH_POS_UNCAPTURED_SUCCESS_ACTION =
  'app/PaymentReconciliation/FETCH_POS_UNCAPTURED_SUCCESS_ACTION';

export const FETCH_POS_UNCAPTURED_ERROR_ACTION =
  'app/PaymentReconciliation/FETCH_POS_UNCAPTURED_ERROR_ACTION';

export const FETCH_MANUALLY_SETTLE_ACTION =
  'app/PaymentReconciliation/FETCH_MANUALLY_SETTLE_ACTION';

export const FETCH_MANUALLY_SETTLE_SUCCESS_ACTION =
  'app/PaymentReconciliation/FETCH_MANUALLY_SETTLE_SUCCESS_ACTION';

export const FETCH_MANUALLY_SETTLE_ERROR_ACTION =
  'app/PaymentReconciliation/FETCH_MANUALLY_SETTLE_ERROR_ACTION';
export const SET_PR_RESTAURANT_TIME =
  'app/PaymentReconciliation/SET_PR_RESTAURANT_TIME';
