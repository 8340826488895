// Import parsers
import { parseListIntoString } from 'helpers/parsers';

// Format Top ordered Bottom Table
const topOrderedTableFormatter = payload => {
  if (payload && Object.keys(payload).length) {
    const topOrderedList = Object.keys(payload).reduce(
      (accu, dataKey, i) => {
        const { category_name, group_size, item_name } = payload[dataKey];
        if (group_size == 1) {
          accu.one.topOrderedCat = category_name
            ? Array.from(new Set([...accu.one.topOrderedCat, category_name]))
            : accu.one.topOrderedCat;
          accu.one.topOrderedItem = item_name
            ? Array.from(new Set([...accu.one.topOrderedItem, item_name]))
            : accu.one.topOrderedItem;
        }
        if (group_size == 2) {
          accu.two.topOrderedCat = category_name
            ? Array.from(new Set([...accu.two.topOrderedCat, category_name]))
            : accu.two.topOrderedCat;
          accu.two.topOrderedItem = item_name
            ? Array.from(new Set([...accu.two.topOrderedItem, item_name]))
            : accu.two.topOrderedItem;
        }
        if (group_size == '3-4') {
          accu.threeFour.topOrderedCat = category_name
            ? Array.from(
                new Set([...accu.threeFour.topOrderedCat, category_name]),
              )
            : accu.threeFour.topOrderedCat;
          accu.threeFour.topOrderedItem = item_name
            ? Array.from(new Set([...accu.threeFour.topOrderedItem, item_name]))
            : accu.threeFour.topOrderedItem;
        }
        if (group_size == '5-6') {
          accu.fiveSix.topOrderedCat = category_name
            ? Array.from(
                new Set([...accu.fiveSix.topOrderedCat, category_name]),
              )
            : accu.fiveSix.topOrderedCat;
          accu.fiveSix.topOrderedItem = item_name
            ? Array.from(new Set([...accu.fiveSix.topOrderedItem, item_name]))
            : accu.fiveSix.topOrderedItem;
        }
        if (group_size == '7+') {
          accu.sevenPlus.topOrderedCat = category_name
            ? Array.from(
                new Set([...accu.sevenPlus.topOrderedCat, category_name]),
              )
            : accu.sevenPlus.topOrderedCat;
          accu.sevenPlus.topOrderedItem = item_name
            ? Array.from(new Set([...accu.sevenPlus.topOrderedItem, item_name]))
            : accu.sevenPlus.topOrderedItem;
        }
        if (group_size == 'Kids') {
          accu.kids.topOrderedCat = category_name
            ? Array.from(new Set([...accu.kids.topOrderedCat, category_name]))
            : accu.kids.topOrderedCat;
          accu.kids.topOrderedItem = item_name
            ? Array.from(new Set([...accu.kids.topOrderedItem, item_name]))
            : accu.kids.topOrderedItem;
        }

        return accu;
      },
      {
        one: {
          topOrderedCat: [],
          topOrderedItem: [],
        },
        two: { topOrderedCat: [], topOrderedItem: [] },
        threeFour: { topOrderedCat: [], topOrderedItem: [] },
        fiveSix: { topOrderedCat: [], topOrderedItem: [] },
        sevenPlus: { topOrderedCat: [], topOrderedItem: [] },
        kids: { topOrderedCat: [], topOrderedItem: [] },
      },
    );

    const formatedPayload = Object.keys(topOrderedList).reduce(
      (accu, group) => {
        const { topOrderedCat = [], topOrderedItem = [] } = topOrderedList[
          group
        ];
        accu[group] = {
          topOrderedCat: parseListIntoString(topOrderedCat),
          topOrderedItem: parseListIntoString(topOrderedItem),
        };

        return accu;
      },
      {
        one: {
          topOrderedCat: '',
          topOrderedItem: '',
        },
        two: { topOrderedCat: '', topOrderedItem: '' },
        threeFour: { topOrderedCat: '', topOrderedItem: '' },
        fiveSix: { topOrderedCat: '', topOrderedItem: '' },
        sevenPlus: { topOrderedCat: '', topOrderedItem: '' },
        kids: { topOrderedCat: '', topOrderedItem: '' },
      },
    );

    return formatedPayload;
  }
};

// Format group breakup chart data
const groupBreakupChartDataFromatter = (payload, attribute) => {
  if (payload && Object.keys(payload)) {
    const attr =
      attribute === 'customers'
        ? 'no_of_cust'
        : attribute === 'sales'
        ? 'sales'
        : attribute === 'abv'
        ? 'abv'
        : attribute === 'orders'
        ? 'orders'
        : 'gcpp';

    if (attribute == 'gcpp') {
      const { customer_type, gcpp, group_size } = payload;

      const formatterData =
        group_size && Object.keys(group_size)
          ? Object.keys(group_size).reduce(
              (accu, key, i) => {
                const groupSize = group_size[key];
                const newCount = gcpp[key];
                const type = customer_type[key];

                if (groupSize == 1 && type == 'login')
                  accu.one.login = accu.one.login + newCount;
                if (groupSize == 1 && type == 'Non_login')
                  accu.one.nonLogin = accu.one.nonLogin + newCount;
                if (groupSize == 2 && type == 'login')
                  accu.two.login = accu.two.login + newCount;
                if (groupSize == 2 && type == 'Non_login')
                  accu.two.nonLogin = accu.two.nonLogin + newCount;
                if (groupSize == '3-4' && type == 'login')
                  accu.threeFour.login = accu.threeFour.login + newCount;
                if (groupSize == '3-4' && type == 'Non_login')
                  accu.threeFour.nonLogin = accu.threeFour.nonLogin + newCount;
                if (groupSize == '5-6' && type == 'login')
                  accu.fiveSix.login = accu.fiveSix.login + newCount;
                if (groupSize == '5-6' && type == 'Non_login')
                  accu.fiveSix.nonLogin = accu.fiveSix.nonLogin + newCount;
                if (groupSize == '7+' && type == 'login')
                  accu.sevenPlus.login = accu.sevenPlus.login + newCount;
                if (groupSize == '7+' && type == 'Non_login')
                  accu.sevenPlus.nonLogin = accu.sevenPlus.nonLogin + newCount;
                if (groupSize == 'kids' && type == 'login')
                  accu.kids.login = accu.kids.login + newCount;
                if (groupSize == 'kids' && type == 'Non_login')
                  accu.kids.nonLogin = accu.kids.nonLogin + newCount;

                return accu;
              },
              {
                one: {
                  login: 0,
                  nonLogin: 0,
                },
                two: {
                  login: 0,
                  nonLogin: 0,
                },
                threeFour: {
                  login: 0,
                  nonLogin: 0,
                },
                fiveSix: {
                  login: 0,
                  nonLogin: 0,
                },
                sevenPlus: {
                  login: 0,
                  nonLogin: 0,
                },
                kids: {
                  login: 0,
                  nonLogin: 0,
                },
              },
            )
          : {
              one: {
                login: 0,
                nonLogin: 0,
              },
              two: {
                login: 0,
                nonLogin: 0,
              },
              threeFour: {
                login: 0,
                nonLogin: 0,
              },
              fiveSix: {
                login: 0,
                nonLogin: 0,
              },
              sevenPlus: {
                login: 0,
                nonLogin: 0,
              },
              kids: {
                login: 0,
                nonLogin: 0,
              },
            };

      return formatterData;
    }
    const formatterData = Object.keys(payload).reduce(
      (accu, key, i) => {
        const { group_size, customer_type } = payload[key];
        const newCount = payload[key][attr] || 0;
        if (group_size == 1 && customer_type == 'login')
          accu.one.login = accu.one.login + newCount;
        if (group_size == 1 && customer_type == 'Non_login')
          accu.one.nonLogin = accu.one.nonLogin + newCount;
        if (group_size == 2 && customer_type == 'login')
          accu.two.login = accu.two.login + newCount;
        if (group_size == 2 && customer_type == 'Non_login')
          accu.two.nonLogin = accu.two.nonLogin + newCount;
        if (group_size == '3-4' && customer_type == 'login')
          accu.threeFour.login = accu.threeFour.login + newCount;
        if (group_size == '3-4' && customer_type == 'Non_login')
          accu.threeFour.nonLogin = accu.threeFour.nonLogin + newCount;
        if (group_size == '5-6' && customer_type == 'login')
          accu.fiveSix.login = accu.fiveSix.login + newCount;
        if (group_size == '5-6' && customer_type == 'Non_login')
          accu.fiveSix.nonLogin = accu.fiveSix.nonLogin + newCount;
        if (group_size == '7+' && customer_type == 'login')
          accu.sevenPlus.login = accu.sevenPlus.login + newCount;
        if (group_size == '7+' && customer_type == 'Non_login')
          accu.sevenPlus.nonLogin = accu.sevenPlus.nonLogin + newCount;
        if (group_size == 'kids' && customer_type == 'login')
          accu.kids.login = accu.kids.login + newCount;
        if (group_size == 'kids' && customer_type == 'Non_login')
          accu.kids.nonLogin = accu.kids.nonLogin + newCount;

        return accu;
      },
      {
        one: {
          login: 0,
          nonLogin: 0,
        },
        two: {
          login: 0,
          nonLogin: 0,
        },
        threeFour: {
          login: 0,
          nonLogin: 0,
        },
        fiveSix: {
          login: 0,
          nonLogin: 0,
        },
        sevenPlus: {
          login: 0,
          nonLogin: 0,
        },
        kids: {
          login: 0,
          nonLogin: 0,
        },
      },
    );

    return formatterData;
  }
  return {};
};

// Format Group Contribution Chart Data
const groupContributionDataFormatter = payload => {
  if (payload && Object.keys(payload).length) {
    const { weekday, hour, orders, sales, gcpp, abv, customers } = payload;

    const value = orders || sales || gcpp || abv || customers;

    const sorter = {
      // "sunday": 0, // << if sunday is first day of week
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };

    if (value && Object.keys(value).length) {
      const formattedData = Object.keys(value)
        .map((key, i) => {
          return {
            day: weekday[key] || null,
            hour: hour[key] || null,
            value: value[key] || null,
          };
        })
        .sort((a, b) => {
          const day1 = a.day.toLowerCase();
          const day2 = b.day.toLowerCase();

          if (sorter[day1] < sorter[day2]) {
            return -1;
          }
          if (sorter[day1] > sorter[day2]) {
            return 1;
          }
          if (sorter[day1] === sorter[day2] && a.hour < b.hour) {
            return -1;
          }
          if (sorter[day1] === sorter[day2] && a.hour > b.hour) {
            return 1;
          }
          return 0;
        });

      return formattedData;
    }
    return {};
  }

  return {};
};

export {
  topOrderedTableFormatter,
  groupBreakupChartDataFromatter,
  groupContributionDataFormatter,
};
