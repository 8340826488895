// Format Score card List
const abvComparisonDataFormatter = (payload) => {
	if (payload) {
		// Destructure the payload
		const {
			abv_nl = {},
			cb_new_users_abv = {},
			cb_returning_users_abv = {}
		} = payload

		// Format the payload
		const formatedScorecardPayload = {
			nonLogin: {
				value: abv_nl.value,
				change: abv_nl.change
			},
			newUser: {
				value: cb_new_users_abv.value,
				change: cb_new_users_abv.change
			},
			returningUsers: {
				value: cb_returning_users_abv.value,
				change: cb_returning_users_abv.change
			}
		}

		return formatedScorecardPayload
	}
}

// Format Score card List
const cashbackInfluenceDataFormatter = (payload, attribute) => {
	if (payload && attribute) {
		const formattedPayload = Object.keys(payload).map((objKey) => {
			const {
				date,
				rest_ltv,
				cust_l,
				cust_r,
				cashback_percent
			} = payload[objKey]

			if (attribute === "cust") {
				return {
					date: date,
					cbPercentage: cashback_percent,
					customerLogin: cust_l,
					customerReturn: cust_r
				}
			} else if (attribute === "ltv") {
				return {
					date: date,
					cbPercentage: cashback_percent,
					ltv: rest_ltv
				}
			} else {
				return {
					date: date,
					cbPercentage: cashback_percent
				}
			}
		})
		return formattedPayload
	} else return []
}

// Format Score card List
const promotionMatrixFormatter = (payload) => {
	if (payload) {
		// Format the payload
		const formatedPayload = Object.keys(payload).map((segmentKey, i) => {
			const {
				name,
				color,
				cust,
				promo_adop,
				promo_budget,
				promo_spend,
				promo_affinity,
				roi,
				abv,
				visit_no,
				ltv,
				meal_comp,
				churn_proba,
				period_weekend,
				period_weekday
			} = payload[segmentKey]

			return {
				type: segmentKey,
				name: name.capitalize(),
				color,
				totalCustomers: cust,
				promoAdoption: promo_adop,
				promoBudget: promo_budget,
				promoSpendPercent: promo_spend,
				affinityScore: promo_affinity,
				roi,
				abv,
				visitFreq: visit_no,
				ltv,
				mealCompletion: meal_comp,
				churnProbability: churn_proba,
				peekWeekendTime: period_weekend,
				peekWeekdayTime: period_weekday
			}
		})

		return formatedPayload
	}
}

// Format Score card List
const cashbackRedemptionTableFormatter = (payload) => {
	if (typeof payload === "object") {
		// Format the payload

		const attributeDict = {
			danger_users: "dangerUsers",
			lapsed_users: "lapsedUsers",
			loyal_users: "loyalUsers",
			new_users: "newUsers",
			non_members: "nonMembers",
			overdue_users: "overdueUsers",
			regular_users: "regularUsers"
		}

		const formatedPayload = Object.keys(payload).reduce(
			(accu, segmentKey) => {
				const {
					abv = null,
					ltv = null,
					sales_percent: salesPercent = null,
					total_visits: totalVisits = null,
					users: totalUsers = null
				} = payload[segmentKey]

				if (segmentKey in attributeDict) {
					accu[attributeDict[segmentKey]] = {
						abv,
						ltv,
						salesPercent,
						totalVisits,
						totalUsers
					}
				}

				return accu
			},
			{}
		)

		return formatedPayload
	}
}

// Format Score card List
const cashbackRedemptionChartFormatter = (payload, upperbound) => {
	if (payload && typeof payload === "object") {
		// Format the payload

		const { ADBV, total_customers } = payload

		if (
			ADBV &&
			total_customers &&
			typeof total_customers === "object" &&
			typeof ADBV === "object"
		) {
			const chartData = Object.keys(ADBV)
				.reduce((accu, index) => {
					const day = ADBV[index]
					const customers = total_customers[index]

					if (
						typeof day === "number" &&
						typeof customers === "number"
					) {
						accu[day] = customers
					}

					return accu
				}, [])
				.slice(0, upperbound)

			// return chartData
			const formattedChartData = Array.from({ length: upperbound }).map(
				(v, i) => {
					const datePoint = chartData[i + 1] || 0

					return {
						day: i + 1,
						customers: datePoint
					}
				}
			)

			return formattedChartData
		}
	}

	return []
}

const cashbackRedemptionChartBreakpointsFormatter = (payload) => {
	if (payload && typeof payload === "object") {
		const { loyal = {}, regular = {}, overdue = {}, danger = {} } = payload

		return {
			loyal: loyal[0] || null,
			regular: regular[0] || null,
			overdue: overdue[0] || null,
			danger: danger[0] || null
		}
	} else return { loyal: 0, regular: 0, overdue: 0, danger: 0 }
}

export {
	promotionMatrixFormatter,
	abvComparisonDataFormatter,
	cashbackInfluenceDataFormatter,
	cashbackRedemptionTableFormatter,
	cashbackRedemptionChartBreakpointsFormatter,
	cashbackRedemptionChartFormatter,
}
