
export const FETCH_CF_LVSNL_SCORES_ACTION =
  'app/Benchmarking/FETCH_CF_LVSNL_SCORES_ACTION';

export const FETCH_CF_LVSNL_SCORES_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_CF_LVSNL_SCORES_SUCCESS_ACTION';

export const FETCH_CF_LVSNL_SCORES_ERROR_ACTION =
  'app/Benchmarking/FETCH_CF_LVSNL_SCORES_ERROR_ACTION';

export const FETCH_CF_NVSR_SCORES_ACTION =
  'app/Benchmarking/FETCH_CF_NVSR_SCORES_ACTION';

export const FETCH_CF_NVSR_SCORES_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_CF_NVSR_SCORES_SUCCESS_ACTION';

export const FETCH_CF_NVSR_SCORES_ERROR_ACTION =
  'app/Benchmarking/FETCH_CF_NVSR_SCORES_ERROR_ACTION';

export const FETCH_ORDER_TYPES_ACTION =
  'app/Benchmarking/FETCH_ORDER_TYPES_ACTION';

export const FETCH_ORDER_TYPES_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_ORDER_TYPES_SUCCESS_ACTION';

export const FETCH_ORDER_TYPES_ERROR_ACTION =
  'app/Benchmarking/FETCH_ORDER_TYPES_ERROR_ACTION';

export const FETCH_CF_LVSNL_COMPARE_PLOT_ACTION =
  'app/Benchmarking/FETCH_CF_LVSNL_COMPARE_PLOT_ACTION';

export const FETCH_CF_LVSNL_COMPARE_PLOT_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_CF_LVSNL_COMPARE_PLOT_SUCCESS_ACTION';

export const FETCH_CF_LVSNL_COMPARE_PLOT_ERROR_ACTION =
  'app/Benchmarking/FETCH_CF_LVSNL_COMPARE_PLOT_ERROR_ACTION';

export const FETCH_CF_NVSR_COMPARE_PLOT_ACTION =
  'app/Benchmarking/FETCH_CF_NVSR_COMPARE_PLOT_ACTION';

export const FETCH_CF_NVSR_COMPARE_PLOT_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_CF_NVSR_COMPARE_PLOT_SUCCESS_ACTION';

export const FETCH_CF_NVSR_COMPARE_PLOT_ERROR_ACTION =
  'app/Benchmarking/FETCH_CF_NVSR_COMPARE_PLOT_ERROR_ACTION';

export const FETCH_CF_REPEAT_RATE_PLOT_ACTION =
  'app/Benchmarking/FETCH_CF_REPEAT_RATE_PLOT_ACTION';

export const FETCH_CF_REPEAT_RATE_PLOT_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_CF_REPEAT_RATE_PLOT_SUCCESS_ACTION';

export const FETCH_CF_REPEAT_RATE_PLOT_ERROR_ACTION =
  'app/Benchmarking/FETCH_CF_REPEAT_RATE_PLOT_ERROR_ACTION';
