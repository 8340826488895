// Import Libs
import moment from 'moment';

const formatDate = date => {
  const d = moment(date);
  return d.isValid() ? d.format('YYYY-MM-DD') : null;
};

const formatDateTime = date => {
  const d = moment(date);
  d.isValid() ? d.format('YYYY-MM-DD hh:mm A') : null;
};

export default formatDate;

export { formatDate, formatDateTime };
