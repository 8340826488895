// Fetch helper function
import fetch from 'helpers/Fetch';

// Import API path/routes constant
import {
  URL_CUSTOMER_AND_RETENTION_BARCHARTS,
  URL_SCORECARD_LIST_1,
  URL_SCORECARD_LIST_2,
  URL_AI_SCORECARD_TABLE,
  URL_PERFORMANCE_MATRIX,
  URL_BARCHART_CUSTOMER,
  URL_BARCHART_SALES,
  URL_BARCHART_ORDERS,
  URL_BARCHART_ABV,
} from './constants';

// Fetch AI Scorecard 1 data
const fetchScorecardList1Data = payload => {
  return fetch({
    url: URL_SCORECARD_LIST_1,
    data: payload,
  });
};

// Fetch AI Scorecard 2 data
const fetchScorecardList2Data = payload => {
  return fetch({
    url: URL_SCORECARD_LIST_2,
    data: payload,
  });
};

// Fetch AI Scorecard Table data
const fetchAIScorecardTableData = payload => {
  return fetch({
    url: URL_AI_SCORECARD_TABLE,
    data: payload,
  });
};

// Fetch Customer and Retention Bar graph data
const fetchCustomerVisitData = payload => {
  return fetch({
    url: URL_CUSTOMER_AND_RETENTION_BARCHARTS,
    data: payload,
  });
};

// Fetch Performance Doughnut chart list data
const fetchPerformanceMatrixData = payload => {
  return fetch({
    url: URL_PERFORMANCE_MATRIX,
    data: payload,
  });
};

// Fetch Customer Bar graph data
const fetchCustomerBarChartData = payload => {
  return fetch({
    url: URL_BARCHART_CUSTOMER,
    data: payload,
  });
};

// Fetch Sales Bar graph data
const fetchSalesBarChartData = payload => {
  return fetch({
    url: URL_BARCHART_SALES,
    data: payload,
  });
};

// Fetch Orders Bar graph data
const fetchOrdersBarChartData = payload => {
  return fetch({
    url: URL_BARCHART_ORDERS,
    data: payload,
  });
};

// Fetch ABV Bar graph data
const fetchABVBarChartData = payload => {
  return fetch({
    url: URL_BARCHART_ABV,
    data: payload,
  });
};

export {
  fetchScorecardList1Data,
  fetchScorecardList2Data,
  fetchCustomerVisitData,
  fetchAIScorecardTableData,
  fetchPerformanceMatrixData,
  fetchCustomerBarChartData,
  fetchSalesBarChartData,
  fetchOrdersBarChartData,
  fetchABVBarChartData,
};
