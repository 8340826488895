export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY';
export const SET_MENU_MODE_LIST = 'SET_MENU_MODE_LIST';
export const SET_SELECTED_MENU_MODE = 'SET_SELECTED_MENU_MODE';
export const SET_CHAIN_DETAILS = 'SET_CHAIN_DETAILS';
export const SET_MERCHANT_LIST = 'SET_MERCHANT_LIST';
export const SET_ACTIVE_MERCHANT_LIST = 'SET_ACTIVE_MERCHANT_LIST';
export const SET_IS_CHAIN_MERCHANT = 'SET_IS_CHAIN_MERCHANT';
export const SET_IS_ECMS = 'SET_IS_ECMS';
export const ENABLE_MASTER_MERCHANT = 'ENABLE_MASTER_MERCHANT';
export const ENABLE_PAYMENT_RECON = 'ENABLE_PAYMENT_RECON';
export const SET_IS_CLOUD_KIOSK = 'SET_IS_CLOUD_KIOSK';
export const SET_LOCKED_TABS = 'SET_LOCKED_TABS';
