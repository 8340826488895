import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt from 'jsonwebtoken';
import PropTypes from 'prop-types';
import initStoreDetails from 'containers/Login/initStoreDetails';
import DynamicComponent from '../utils/DynamicComponent';
import { selectActiveMerchantList } from 'redux/selectors';

/**
 *
 * @param {React.Component} privateRoutes - Private routes component
 *
 * @returns {React.Component} If authenticated, return private routes component, else return login component
 */

const AuthenticatedApp = ({ privateRoutes: PrivateRoutes }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const ACCESS_TOKEN = 'access_token';
  const CURRENCY = 'currency';
  const IS_ADMIN = 'is_admin';

  const activeList = useSelector(selectActiveMerchantList);

  const checkAuthentication = () => {
    const jwtToken = localStorage.getItem(ACCESS_TOKEN);
    const currency = localStorage.getItem(CURRENCY);

    let isAdmin = 0;
    const isAdminStr = localStorage.getItem(IS_ADMIN);
    if (isAdminStr) isAdmin = JSON.parse(isAdminStr);

    const listOfMerchant = localStorage.getItem('list_of_merchants') || [];
    const merchantIds = JSON.parse(localStorage.getItem('merchant_ids')) || [];
    const isChainMerchant = Boolean(
      Number(localStorage.getItem('is_chain_merchant')),
    );
    const enablePaymentRecon = Boolean(
      Number(localStorage.getItem('enable_payment_recon')),
    );
    let enableMasterMerchant = localStorage.getItem('enable_master_merchant');
    let isEcms = localStorage.getItem('is_ecms');
    const locked_tabs = JSON.parse(localStorage.getItem('locked_tabs'));

    if (typeof isEcms === 'string') {
      isEcms = isEcms === 'true';
    }
    let isCloudKiosk = localStorage.getItem('is_cloud_kiosk');
    if (typeof isCloudKiosk === 'string') {
      isCloudKiosk = isCloudKiosk === 'true';
    }
    enableMasterMerchant = JSON.parse(enableMasterMerchant);
    if (jwtToken && !activeList.length) {
      let userClaims = {};
      if (isAdmin) {
        let userClaimsStr = localStorage.getItem('user_claims');
        if (userClaimsStr && typeof userClaimsStr == 'string') {
          userClaims = JSON.parse(userClaimsStr);
        } else {
          return false;
        }
      } else {
        const jwtd = jwt.decode(jwtToken);
        if (jwtd) userClaims = jwt.decode(jwtToken).user_claims;
      }

      initStoreDetails(
        userClaims,
        listOfMerchant ? JSON.parse(listOfMerchant) : [],
        merchantIds,
        currency,
        isChainMerchant,
        isEcms,
        enableMasterMerchant,
        enablePaymentRecon,
        isCloudKiosk,
        dispatch,
        locked_tabs,
      );
    }

    return (
      jwtToken &&
      jwt.decode(jwtToken) &&
      jwt.decode(jwtToken).identity &&
      Date.now() <= jwt.decode(jwtToken).exp * 1000
    );
  };
  return (
    <Route
      path="/dashboard"
      render={props =>
        checkAuthentication() ? (
          <DynamicComponent fullpage>
            <PrivateRoutes {...props} />
          </DynamicComponent>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

AuthenticatedApp.propTypes = {
  privateRoutes: PropTypes.object.isRequired,
};

export default AuthenticatedApp;
