import * as cn from './constants';
import checkStateLoading from '../../helpers/checkStateLoading';

export const initialState = {
  scores: {
    error: null,
    loading: false,
    payload: {
      revenueLost: 0,
      revenueLostPerc: 0,
      stockoutItems: 0,
      orderpauseDuration: 0,
    },
  },
  liveDataScores: {
    error: null,
    loading: false,
    payload: {
      stockoutItems: 0,
      stockoutCategories: 0,
      stockoutSubCategories: 0,
      stockoutSku: 0,
    },
  },
  contribution: {
    loading: false,
    error: null,
    payload: {
      values: [0, 0],
      colors: ['rgb(255, 107, 0)', 'rgb(0, 145, 255)'],
      change: [0, 0],
      perc: [0, 0],
      total: 0,
    },
  },
  trend: {
    loading: false,
    error: null,
    payload: [],
  },
  categoryItem: {
    loading: false,
    error: null,
    payload: [],
  },
  outletWise: {
    loading: false,
    error: null,
    payload: [],
  },
  liveDataOutletWise: {
    loading: false,
    error: null,
    payload: [],
  },
  tableStockout: {
    loading: false,
    error: null,
    payload: [],
  },
  liveDataStockoutTable: {
    loading: false,
    error: null,
    payload: [],
  },
  tableOrderpause: {
    loading: false,
    error: null,
    payload: [],
  },
  liveDataTableOrderPause: {
    loading: false,
    error: null,
    payload: [],
  },
};

function reducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    case cn.SCORES_INIT:
      return {
        ...state,
        scores: {
          loading: true,
          error: null,
          payload: initialState.scores.payload,
        },
      };
    case cn.SCORES_SUCCESS:
      return {
        ...state,
        scores: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.SCORES_ERROR:
      return {
        ...state,
        scores: {
          loading: false,
          error: error,
          payload: initialState.scores.payload,
        },
      };
    case cn.LIVE_DATA_SCORES_INIT:
      return {
        ...state,
        liveDataScores: {
          loading: true,
          error: null,
          payload: initialState.liveDataScores.payload,
        },
      };
    case cn.LIVE_DATA_SCORES_SUCCESS:
      return {
        ...state,
        liveDataScores: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.LIVE_DATA_SCORES_ERROR:
      return {
        ...state,
        liveDataScores: {
          loading: false,
          error: error,
          payload: initialState.liveDataScores.payload,
        },
      };

    case cn.CONTRIBUTION_INIT:
      return {
        ...state,
        contribution: {
          loading: true,
          error: null,
          payload: initialState.contribution.payload,
        },
      };
    case cn.CONTRIBUTION_SUCCESS:
      return {
        ...state,
        contribution: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.CONTRIBUTION_ERROR:
      return {
        ...state,
        contribution: {
          loading: false,
          error: error,
          payload: initialState.contribution.payload,
        },
      };

    case cn.TREND_INIT:
      return {
        ...state,
        trend: {
          loading: true,
          error: null,
          payload: initialState.trend.payload,
        },
      };
    case cn.TREND_SUCCESS:
      return {
        ...state,
        trend: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.TREND_ERROR:
      return {
        ...state,
        trend: {
          loading: false,
          error: error,
          payload: initialState.trend.payload,
        },
      };

    case cn.CATEGORY_ITEM_INIT:
      return {
        ...state,
        categoryItem: {
          loading: true,
          error: null,
          payload: initialState.categoryItem.payload,
        },
      };

    case cn.CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        categoryItem: {
          loading: false,
          error: null,
          payload: payload,
        },
      };

    case cn.CATEGORY_ITEM_ERROR:
      return {
        ...state,
        categoryItem: {
          loading: false,
          error: error,
          payload: initialState.categoryItem.payload,
        },
      };
    case cn.OUTLETWISE_INIT:
      return {
        ...state,
        outletWise: {
          loading: true,
          error: null,
          payload: initialState.outletWise.payload,
        },
      };

    case cn.OUTLETWISE_SUCCESS:
      return {
        ...state,
        outletWise: {
          loading: false,
          error: null,
          payload: payload,
        },
      };

    case cn.OUTLETWISE_ERROR:
      return {
        ...state,
        outletWise: {
          loading: false,
          error: error,
          payload: initialState.outletWise.payload,
        },
      };
    case cn.LIVE_DATA_OUTLETWISE_INIT:
      return {
        ...state,
        liveDataOutletWise: {
          loading: true,
          error: null,
          payload: initialState.liveDataOutletWise.payload,
        },
      };

    case cn.LIVE_DATA_OUTLETWISE_SUCCESS:
      return {
        ...state,
        liveDataOutletWise: {
          loading: false,
          error: null,
          payload: payload,
        },
      };

    case cn.LIVE_DATA_OUTLETWISE_ERROR:
      return {
        ...state,
        liveDataOutletWise: {
          loading: false,
          error: error,
          payload: initialState.liveDataOutletWise.payload,
        },
      };

    case cn.TABLE_STOCKOUT_INIT:
      return {
        ...state,
        tableStockout: {
          loading: true,
          error: null,
          payload: initialState.tableStockout.payload,
        },
      };
    case cn.TABLE_STOCKOUT_SUCCESS:
      return {
        ...state,
        tableStockout: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.TABLE_STOCKOUT_ERROR:
      return {
        ...state,
        tableStockout: {
          loading: false,
          error: error,
          payload: initialState.tableStockout.payload,
        },
      };

    case cn.LIVE_DATA_TABLE_STOCKOUT_INIT:
      return {
        ...state,
        liveDataStockoutTable: {
          loading: true,
          error: null,
          payload: initialState.liveDataStockoutTable.payload,
        },
      };
    case cn.LIVE_DATA_TABLE_STOCKOUT_SUCCESS:
      return {
        ...state,
        liveDataStockoutTable: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.LIVE_DATA_TABLE_STOCKOUT_ERROR:
      return {
        ...state,
        liveDataStockoutTable: {
          loading: false,
          error: error,
          payload: initialState.liveDataStockoutTable.payload,
        },
      };
    case cn.TABLE_ORDERPAUSE_INIT:
      return {
        ...state,
        tableOrderpause: {
          loading: true,
          error: null,
          payload: initialState.tableOrderpause.payload,
        },
      };
    case cn.TABLE_ORDERPAUSE_SUCCESS:
      return {
        ...state,
        tableOrderpause: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.TABLE_ORDERPAUSE_ERROR:
      return {
        ...state,
        tableOrderpause: {
          loading: false,
          error: error,
          payload: initialState.tableOrderpause.payload,
        },
      };

    case cn.LIVE_DATA_TABLE_ORDERPAUSE_INIT:
      return {
        ...state,
        liveDataTableOrderPause: {
          loading: true,
          error: null,
          payload: initialState.liveDataTableOrderPause.payload,
        },
      };
    case cn.LIVE_DATA_TABLE_ORDERPAUSE_SUCCESS:
      return {
        ...state,
        liveDataTableOrderPause: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.LIVE_DATA_TABLE_ORDERPAUSE_ERROR:
      return {
        ...state,
        liveDataTableOrderPause: {
          loading: false,
          error: error,
          payload: initialState.liveDataTableOrderPause.payload,
        },
      };
    default:
      return state;
  }
}

export default reducer;
