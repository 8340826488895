// Setting preloaded or initial state

const preloadedState = {
  list: [
    {
      name: '',
      id: '',
    },
  ],
  activeList: [],
  menuModes: [],
  selectedMenuMode: null,
  isChainMerchant: false,
  isEcms: false,
  enableMasterMerchant: false,
  enablePaymentRecon: false,
  isCloudKiosk: false,
  lockedTabs: [],
};

const merchantReducer = (state = preloadedState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_ACTIVE_MERCHANT_LIST':
      localStorage.setItem('active_merchant_list', JSON.stringify(payload));
      return {
        ...state,
        activeList: payload,
      };

    case 'SET_MERCHANT_LIST':
      return {
        ...state,
        list: payload,
      };

    case 'SET_MENU_MODE_LIST':
      return {
        ...state,
        menuModes: payload,
      };

    case 'SET_SELECTED_MENU_MODE':
      return {
        ...state,
        selectedMenuMode: payload,
      };

    case 'SET_IS_CHAIN_MERCHANT':
      return {
        ...state,
        isChainMerchant: payload,
      };

    case 'SET_IS_ECMS':
      return {
        ...state,
        isEcms: payload,
      };

    case 'ENABLE_MASTER_MERCHANT':
      return {
        ...state,
        enableMasterMerchant: payload,
      };

    case 'ENABLE_PAYMENT_RECON':
      return {
        ...state,
        enablePaymentRecon: payload,
      };

    case 'SET_IS_CLOUD_KIOSK':
      return {
        ...state,
        isCloudKiosk: payload,
      };
    case 'SET_LOCKED_TABS':
      return {
        ...state,
        lockedTabs: payload,
      };

    default:
      return state;
  }
};
export default merchantReducer;
