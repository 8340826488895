const toCamalCase = (string) => {
	return string
		? string
				.split("_")
				.map((str, i) => {
					return i != 0
						? str
								.split("")
								.map((char, j) => {
									return j == 0 ? char.toUpperCase() : char
								})
								.join("")
						: str
				})
				.join("")
		: ""
}

export default toCamalCase
