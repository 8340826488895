import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from './ErrorBoundary';
import GridLoader from 'react-spinners/GridLoader';

const commonStyle = {
  display: 'block',
  margin: 'auto',
  borderColor: 'none',
  backgroundColor: '#fafafa',
};

const fullpageCss = {
  ...commonStyle,
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginLeft: '-36px',
  marginTop: '-36px',
};

const componentCss = {
  ...commonStyle,
  position: 'relative',
  marginTop: '25%',
};

/**
 *
 * @param {boolean} [fullpage=false] - Whether to render the component in full page
 * @param {React.Component} children - React component to be loaded dynamically
 *
 * @returns {React.Component} Dynamically loaded component or loading component or error component
 */
export default function DynamicComponent({ children, fullpage = false }) {
  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <GridLoader
            cssOverride={fullpage ? fullpageCss : componentCss}
            css={fullpage ? fullpageCss : componentCss}
            size={20}
            color="#ef6937"
          />
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  );
}

DynamicComponent.propTypes = {
  children: PropTypes.object.isRequired,
  fullpage: PropTypes.bool,
};
