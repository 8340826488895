/*
 *
 * Terminal Analytics actions
 *
 */

import * as cn from './constants';

// * Dropdown
export const getDropdownsList = payload => {
  return {
    type: cn.DROPDOWN_INIT,
    payload,
  };
};

export const getDropdownsListSuccess = payload => {
  return {
    type: cn.DROPDOWN_SUCCESS,
    payload,
  };
};

export const getDropdownsListError = error => {
  return {
    type: cn.DROPDOWN_ERROR,
    error,
  };
};

export const setDropdowns = payload => ({
  type: cn.SET_DROPDOWNS,
  payload,
});

// * Score card
export const getScores = payload => {
  return {
    type: cn.SCORE_INIT,
    payload,
  };
};

export const getScoresSuccess = payload => {
  return {
    type: cn.SCORE_SUCCESS,
    payload,
  };
};

export const getScoresError = error => {
  return {
    type: cn.SCORE_ERROR,
    error,
  };
};

//* Summary table
export const getSummaryTable = payload => {
  return {
    type: cn.SUMMARY_TABLE_INIT,
    payload,
  };
};

export const getSummaryTableSuccess = payload => {
  return {
    type: cn.SUMMARY_TABLE_SUCCESS,
    payload,
  };
};

export const getSummaryTableError = error => {
  return {
    type: cn.SUMMARY_TABLE_ERROR,
    error,
  };
};

//* Transactions Orders Table
export const getTransactionsOrdersTable = payload => {
  return {
    type: cn.TRANSACTIONS_ORDERS_TABLE_INIT,
    payload,
  };
};

export const getTransactionsOrdersSuccess = payload => {
  return {
    type: cn.TRANSACTIONS_ORDERS_TABLE_SUCCESS,
    payload,
  };
};

export const getTransactionsOrdersError = error => {
  return {
    type: cn.TRANSACTIONS_ORDERS_TABLE_ERROR,
    error,
  };
};

export const setTaRestaurantStartTime = payload => {
  return {
    type: cn.SET_TA_RESTAURANT_TIME,
    payload,
  };
};
