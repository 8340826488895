import * as c from './constants';

import { initAction, successAction, errorAction } from 'helpers/actionsFormat';

const getOperationsScoreDataAction = payload =>
  initAction(c.FETCH_OPERATIONS_SCORE_ACTION, payload);
const operationsScoreDataLoadedAction = payload =>
  successAction(c.OPERATIONS_SCORE_LOADED_ACTION, payload);
const operationsScoreFailedAction = error =>
  errorAction(c.OPERATIONS_SCORE_FAILED_ACTION, error);
const getDailySalesData = payload => initAction(c.DAILY_SALES_ACTION, payload);
const dailySalesDataLoadedAction = payload =>
  successAction(c.DAILY_SALES_LOADED_ACTION, payload);
const dailySalesDataFailedAction = error =>
  errorAction(c.DAILY_SALES_FAILED_ACTION, error);
const getTableOccupancyDataAction = payload =>
  initAction(c.TABLE_OCCUPANCY_ACTION, payload);
const tableOccupanyLoadedAction = payload =>
  successAction(c.TABLE_OCCUPANCY_LOADED_ACTION, payload);
const tableOccupancyFailedAction = error =>
  errorAction(c.TABLE_OCCUPANCY_FAILED_ACTION, error);
const getOrderTypesAction = payload =>
  initAction(c.GET_ORDERTYPES_ACTION, payload);
const orderTypesLoadedAction = payload =>
  successAction(c.GET_ORDERTYPES_LOADED_ACTION, payload);
const orderTypesFailedAction = error =>
  errorAction(c.GET_ORDERTYPES_FAILED_ACTION, error);
const setOrderTypeAction = payload =>
  successAction(c.SET_ORDERTYPE_ACTION, payload);

export {
  getOperationsScoreDataAction,
  operationsScoreDataLoadedAction,
  operationsScoreFailedAction,
  getDailySalesData,
  dailySalesDataLoadedAction,
  dailySalesDataFailedAction,
  getTableOccupancyDataAction,
  tableOccupanyLoadedAction,
  tableOccupancyFailedAction,
  getOrderTypesAction,
  orderTypesLoadedAction,
  orderTypesFailedAction,
  setOrderTypeAction,
};
