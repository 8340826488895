/*
 *
 * benchmarking enterprise stats constants
 *
 */

export const FETCH_POSITION_MAP_ACTION =
  'app/Benchmarking/FETCH_POSITION_MAP_ACTION';

export const FETCH_POSITION_MAP_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_POSITION_MAP_SUCCESS_ACTION';

export const FETCH_POSITION_MAP_ERROR_ACTION =
  'app/Benchmarking/FETCH_POSITION_MAP_ERROR_ACTION';

export const FETCH_POSITION_MAP_SEGMENTS_ACTION =
  'app/Benchmarking/FETCH_POSITION_MAP_SEGMENTS_ACTION';

export const FETCH_POSITION_MAP_SEGMENTS_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_POSITION_MAP_SEGMENTS_SUCCESS_ACTION';

export const FETCH_POSITION_MAP_SEGMENTS_ERROR_ACTION =
  'app/Benchmarking/FETCH_POSITION_MAP_SEGMENTS_ERROR_ACTION';
