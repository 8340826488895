export const DOWNLOAD_IMAGE_ACTION = 'app/download/DOWNLOAD_IMAGE_ACTION';
export const DOWNLOAD_IMAGE_COMPLETED = 'app/download/DOWNLOAD_IMAGE_COMPLETED';
export const DOWNLOAD_IMAGE_FAILED = 'app/download/DOWNLOAD_IMAGE_FAILED';
export const DOWNLOAD_EXCEL_ACTION = 'app/download/DOWNLOAD_EXCEL_ACTION';
export const DOWNLOAD_EXCEL_COMPLETED = 'app/download/DOWNLOAD_EXCEL_COMPLETED';
export const DOWNLOAD_EXCEL_FAILED = 'app/download/DOWNLOAD_EXCEL_FAILED';
export const DOWNLOAD_PDF_ACTION = 'app/download/DOWNLOAD_PDF_ACTION';
export const DOWNLOAD_PDF_COMPLETED = 'app/download/DOWNLOAD_PDF_COMPLETED';
export const DOWNLOAD_PDF_FAILED = 'app/download/DOWNLOAD_PDF_FAILED';
export const DOWNLOAD_EXCEL_TRANSACTION_ACTION =
  'app/download/DOWNLOAD_EXCEL_TRANSACTION_ACTION';
export const DOWNLOAD_EXCEL_TRANSACTION_COMPLETED =
  'app/download/DOWNLOAD_EXCEL_TRANSACTION_COMPLETED';
export const DOWNLOAD_EXCEL_TRANSACTION_FAILED =
  'app/download/DOWNLOAD_EXCEL_TRANSACTION_FAILED';

export const DOWNLOAD_MONTHLY_REPORT_ACTION = 'app/download/DOWNLOAD_MONTHLY_REPORT_ACTION';
export const DOWNLOAD_MONTHLY_REPORT_COMPLETED = 'app/download/DOWNLOAD_MONTHLY_REPORT_COMPLETED';
export const DOWNLOAD_MONTHLY_REPORT_FAILED = 'app/download/DOWNLOAD_MONTHLY_REPORT_FAILED';