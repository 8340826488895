 //Remove the eu URL from UI
// export const getBaseUrl = () => {
//   const baseURL = localStorage.getItem('baseURL');
//   if (baseURL) {
//     return baseURL;
//   } else {
//     return process.env.REACT_APP_BASE_URL;
//   }
// };

export const getBaseUrl = () => {
  return process.env.REACT_APP_BASE_URL;
}