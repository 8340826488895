/*
 *
 * outlet info page constants
 *
 */

export const FETCH_OUTLET_INFO_ACTION =
  'app/OutletInfo/FETCH_OUTLET_INFO_ACTION';

export const FETCH_OUTLET_INFO_SUCCESS_ACTION =
  'app/OutletInfo/FETCH_OUTLET_INFO_SUCCESS_ACTION';

export const FETCH_OUTLET_INFO_ERROR_ACTION =
  'app/OutletInfo/FETCH_OUTLET_INFO_ERROR_ACTION';

export const UPDATE_OUTLET_INFO_ACTION =
  'app/OutletInfo/UPDATE_OUTLET_INFO_ACTION';

export const UPDATE_OUTLET_INFO_SUCCESS_ACTION =
  'app/OutletInfo/UPDATE_OUTLET_INFO_SUCCESS_ACTION';

export const UPDATE_OUTLET_INFO_ERROR_ACTION =
  'app/OutletInfo/UPDATE_OUTLET_INFO_ERROR_ACTION';

export const FETCH_ITEM_DETAILS_ACTION =
  'app/OutletInfo/FETCH_ITEM_DETAILS_ACTION';

export const FETCH_ITEM_DETAILS_SUCCESS_ACTION =
  'app/OutletInfo/FETCH_ITEM_DETAILS_SUCCESS_ACTION';

export const FETCH_ITEM_DETAILS_ERROR_ACTION =
  'app/OutletInfo/FETCH_ITEM_DETAILS_ERROR_ACTION';

export const UPDATE_ITEM_DETAILS_ACTION =
  'app/OutletInfo/UPDATE_ITEM_DETAILS_ACTION';

export const UPDATE_ITEM_DETAILS_SUCCESS_ACTION =
  'app/OutletInfo/UPDATE_ITEM_DETAILS_SUCCESS_ACTION';

export const UPDATE_ITEM_DETAILS_ERROR_ACTION =
  'app/OutletInfo/UPDATE_ITEM_DETAILS_ERROR_ACTION';

export const FETCH_TIME_RANGES_ACTION =
  'app/OutletInfo/FETCH_TIME_RANGES_ACTION';

export const FETCH_TIME_RANGES_SUCCESS_ACTION =
  'app/OutletInfo/FETCH_TIME_RANGES_SUCCESS_ACTION';

export const FETCH_TIME_RANGES_ERROR_ACTION =
  'app/OutletInfo/FETCH_TIME_RANGES_ERROR_ACTION';

export const SAVE_TIME_RANGES_ACTION = 'app/OutletInfo/SAVE_TIME_RANGES_ACTION';

export const SAVE_TIME_RANGES_SUCCESS_ACTION =
  'app/OutletInfo/SAVE_TIME_RANGES_SUCCESS_ACTION';

export const SAVE_TIME_RANGES_ERROR_ACTION =
  'app/OutletInfo/SAVE_TIME_RANGES_ERROR_ACTION';

export const DELETE_TIME_RANGES_ACTION =
  'app/OutletInfo/DELETE_TIME_RANGES_ACTION';

export const DELETE_TIME_RANGES_SUCCESS_ACTION =
  'app/OutletInfo/DELETE_TIME_RANGES_SUCCESS_ACTION';

export const DELETE_TIME_RANGES_ERROR_ACTION =
  'app/OutletInfo/DELETE_TIME_RANGES_ERROR_ACTION';

export const FETCH_CUISINES_ACTION = 'app/OutletInfo/FETCH_CUISINES_ACTION';

export const FETCH_CUISINES_SUCCESS_ACTION =
  'app/OutletInfo/FETCH_CUISINES_SUCCESS_ACTION';

export const FETCH_CUISINES_ERROR_ACTION =
  'app/OutletInfo/FETCH_CUISINES_ERROR_ACTION';

export const DOWNLOAD_EXCEL_FORMAT_ACTION =
  'app/OutletInfo/DOWNLOAD_EXCEL_FORMAT_ACTION';

export const DOWNLOAD_EXCEL_FORMAT_SUCCESS_ACTION =
  'app/OutletInfo/DOWNLOAD_EXCEL_FORMAT_SUCCESS_ACTION';

export const DOWNLOAD_EXCEL_FORMAT_ERROR_ACTION =
  'app/OutletInfo/DOWNLOAD_EXCEL_FORMAT_ERROR_ACTION';

export const UPLOAD_EXCEL_ACTION = 'app/OutletInfo/UPLOAD_EXCEL_ACTION';

export const UPLOAD_EXCEL_SUCCESS_ACTION =
  'app/OutletInfo/UPLOAD_EXCEL_SUCCESS_ACTION';

export const UPLOAD_EXCEL_ERROR_ACTION =
  'app/OutletInfo/UPLOAD_EXCEL_ERROR_ACTION';
