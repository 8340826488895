import { createSelector, createStructuredSelector } from 'reselect';
import formatDate from 'helpers/formatDate';

import {
  selectCurrentDateRangeStart,
  selectCurrentDateRangeEnd,
  selectCompareDateRangeStart,
  selectCompareDateRangeEnd,
  selectCurrentDateRange,
  selectCompareDateRange,
} from './dateRangeSelector';
import {
  selectActiveMerchantList,
  selectMerchantList,
  selectMenuModes,
  selectIsChainMerchant,
  selectEnableMasterMerchant,
  selectEnablePaymentRecon,
  selectIsEcms,
  selectIsCloudKiosk,
} from './merchantSelector';
import currencySelector from './currencySelector';
import selectBenchmarking, {
  selectChainId,
  selectChainName,
  selectCountries,
  selectSelectedCountry,
  selectbenchMarkingToggle,
} from './chainSelector';
export {
  selectCurrentDateRange,
  selectCompareDateRange,
  selectCurrentDateRangeStart,
  selectCurrentDateRangeEnd,
  selectCompareDateRangeStart,
  selectCompareDateRangeEnd,
  selectActiveMerchantList,
  selectMerchantList,
  selectMenuModes,
  selectIsChainMerchant,
  selectIsEcms,
  selectIsCloudKiosk,
  selectEnableMasterMerchant,
  selectEnablePaymentRecon,
  currencySelector,
  selectChainId,
  selectChainName,
  selectCountries,
  selectSelectedCountry,
  selectbenchMarkingToggle,
  selectBenchmarking,
};

const payloadWithCurrentDateRange = createSelector(
  selectCurrentDateRange,
  selectActiveMerchantList,
  ({ startDate, endDate }, activeMerchantList) => ({
    merchant_id: activeMerchantList,
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
  }),
);

const payloadWithCompareDateRange = createSelector(
  payloadWithCurrentDateRange,
  selectCompareDateRange,
  (payload, { cStartDate, cEndDate }) => ({
    ...payload,
    c_start_date: formatDate(cStartDate),
    c_end_date: formatDate(cEndDate),
  }),
);

export default createStructuredSelector({
  payloadWithCurrentDateRange,
  payloadWithCompareDateRange,
  currency: currencySelector,
});
