const SCORE_INIT = 'app/DerFailedVoid/SCORE_INIT';
const SCORE_SUCCESS = 'app/DerFailedVoid/SCORE_SUCCESS';
const SCORE_ERROR = 'app/DerFailedVoid/SCORE_ERROR';
const BREAKUP_INIT = 'app/DerFailedVoid/BREAKUP_INIT';
const BREAKUP_SUCCESS = 'app/DerFailedVoid/BREAKUP_SUCCESS';
const BREAKUP_ERROR = 'app/DerFailedVoid/BREAKUP_ERROR';
const TREND_INIT = 'app/DerFailedVoid/TREND_INIT';
const TREND_SUCCESS = 'app/DerFailedVoid/TREND_SUCCESS';
const TREND_ERROR = 'app/DerFailedVoid/TREND_ERROR';
const TABLE_INIT = 'app/DerFailedVoid/TABLE_INIT';
const TABLE_SUCCESS = 'app/DerFailedVoid/TABLE_SUCCESS';
const TABLE_ERROR = 'app/DerFailedVoid/TABLE_ERROR';

export {
  SCORE_INIT,
  SCORE_SUCCESS,
  SCORE_ERROR,
  BREAKUP_INIT,
  BREAKUP_SUCCESS,
  BREAKUP_ERROR,
  TREND_INIT,
  TREND_SUCCESS,
  TREND_ERROR,
  TABLE_INIT,
  TABLE_SUCCESS,
  TABLE_ERROR,
};
