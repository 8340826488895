/*
 *
 * POS Tab Constants
 *
 */

// Dropdown lists
export const DROPDOWN_LIST_INIT = 'app/POS/DROPDOWN_LIST_INIT';
export const DROPDOWN_LIST_SUCCESS = 'app/POS/DROPDOWN_LIST_SUCCESS';
export const DROPDOWN_LIST_ERROR = 'app/POS/DROPDOWN_LIST_ERROR';

// Score cards
export const SCORE_INIT = 'app/POS/SCORE_INIT';
export const SCORE_SUCCESS = 'app/POS/SCORE_SUCCESS';
export const SCORE_ERROR = 'app/POS/SCORE_ERROR';

// Summary table
export const SUMMARY_TABLE_INIT = 'app/POS/SUMMARY_TABLE_INIT';
export const SUMMARY_TABLE_SUCCESS = 'app/POS/SUMMARY_TABLE_SUCCESS';
export const SUMMARY_TABLE_ERROR = 'app/POS/SUMMARY_TABLE_ERROR';

// Item table
export const ITEM_TABLE_INIT = 'app/POS/ITEM_TABLE_INIT';
export const ITEM_TABLE_SUCCESS = 'app/POS/ITEM_TABLE_SUCCESS';
export const ITEM_TABLE_ERROR = 'app/POS/ITEM_TABLE_ERROR';

// Category Table
export const CATEGORY_TABLE_INIT = 'app/POS/CATEGORY_TABLE_INIT';
export const CATEGORY_TABLE_SUCCESS = 'app/POS/CATEGORY_TABLE_SUCCESS';
export const CATEGORY_TABLE_ERROR = 'app/POS/CATEGORY_TABLE_ERROR';

// Transactions Items Table
export const TRANSACTIONS_ITEMS_TABLE_INIT =
  'app/POS/TRANSACTIONS_ITEMS_TABLE_INIT';
export const TRANSACTIONS_ITEMS_TABLE_SUCCESS =
  'app/POS/TRANSACTIONS_ITEMS_TABLE_SUCCESS';
export const TRANSACTIONS_ITEMS_TABLE_ERROR =
  'app/POS/TRANSACTIONS_ITEMS_TABLE_ERROR';

// Transactions Orders Table
export const TRANSACTIONS_ORDERS_TABLE_INIT =
  'app/POS/TRANSACTIONS_ORDERS_TABLE_INIT';
export const TRANSACTIONS_ORDERS_TABLE_SUCCESS =
  'app/POS/TRANSACTIONS_ORDERS_TABLE_SUCCESS';
export const TRANSACTIONS_ORDERS_TABLE_ERROR =
  'app/POS/TRANSACTIONS_ORDERS_TABLE_ERROR';

export const SET_DROPDOWNS = 'app/POS/SET_DROPDOWNS';
