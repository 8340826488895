// export const CANCELLED_ORDER_SCORES_INIT =
//   'app/SmartConnect/CANCELLED_ORDER_SCORES_INIT';
export const CANCELLED_ORDER_SCORES_SUCCESS = 'app/SmartConnect/CANCELLED_ORDER_SCORES_SUCCESS';
// export const CANCELLED_ORDER_SCORES_ERROR =
//   'app/SmartConnect/CANCELLED_ORDER_SCORES_ERROR';

// export const CANCELLED_ORDER_TABLE_INIT =
//   'app/SmartConnect/CANCELLED_ORDER_TABLE_INIT';
export const CANCELLED_ORDER_TABLE_SUCCESS = 'app/SmartConnect/CANCELLED_ORDER_TABLE_SUCCESS';
// export const CANCELLED_ORDER_TABLE_ERROR =
//   'app/SmartConnect/CANCELLED_ORDER_TABLE_ERROR';

// export const CANCELLED_ORDER_REASONS_INIT =
//   'app/SmartConnect/CANCELLED_ORDER_REASONS_INIT';
export const CANCELLED_ORDER_REASONS_SUCCESS = 'app/SmartConnect/CANCELLED_ORDER_REASONS_SUCCESS';
// export const CANCELLED_ORDER_REASONS_ERROR =
//   'app/SmartConnect/CANCELLED_ORDER_REASONS_ERROR';

// export const CANCELLED_ORDER_BREAKUP_INIT =
//   'app/SmartConnect/CANCELLED_ORDER_BREAKUP_INIT';
export const CANCELLED_ORDER_BREAKUP_SUCCESS ='app/SmartConnect/CANCELLED_ORDER_BREAKUP_SUCCESS';
// export const CANCELLED_ORDER_BREAKUP_ERROR =
//   'app/SmartConnect/CANCELLED_ORDER_BREAKUP_ERROR';


export const CANCELLED_ORDER_MERGE_DATA_INIT = 'app/SmartConnect/CANCELLED_ORDER_MERGE_DATA_INIT';
// export const CANCELLED_ORDER_MERGE_DATA_SUCCESS ='app/SmartConnect/CANCELLED_ORDER_MERGE_DATA_SUCCESS';
export const CANCELLED_ORDER_MERGE_DATA_ERROR = 'app/SmartConnect/CANCELLED_ORDER_MERGE_DATA_ERROR';