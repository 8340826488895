import fetch from 'helpers/Fetch';

/**
 *
 * @description Creates an init action for the given type and payload
 * @param {string} type
 * @param {*} payload
 * @returns {{type, payload}} Initial action
 */
const initAction = (type, payload) => ({
  type,
  payload,
});

/**
 *
 * @description Creates a success action for the given type and payload
 * @param {string} type
 * @param {*} payload
 * @returns {{type, payload}} Success action
 */
const successAction = (type, payload) => ({
  type,
  payload,
});

/**
 * @description Creates a failure action for the given type and error
 *
 * @param {string} type
 * @param {*} error
 * @returns {{type, error}} Error action
 */
const errorAction = (type, error) => ({
  type,
  error,
});

/**
 *
 * @description Creates a request action for the given URL and data
 *
 * @param {string} url - Request url
 * @param {object} data - Request data
 * @returns {Promise} API response promise
 */
const fetchRequest = (url, data) =>
  fetch({
    url,
    data,
  });

export { initAction, successAction, errorAction, fetchRequest };
