import checkStateLoading from "../../../helpers/checkStateLoading"

// Setting preloaded or initial state
const preloadedState = {
	scorecardListTop: {
		error: false,
		loading: false,
		payload: {
			cbUsers: {
				delta: 0,
				value: 0
			},
			newUsers: {
				delta: 0,
				value: 0
			},
			returningUsers: {
				delta: 0,
				value: 0
			},
			lapsedUsers: {
				delta: 0,
				value: 0
			}
		}
	},
	scorecardListBottom: {
		error: false,
		loading: false,
		payload: {
			totalMembers: {
				value: 0
			},
			activeMembers: {
				value: 0
			},
			lapsedMembers: {
				value: 0
			},
			lifetimeMembers: {
				value: 0
			}
		}
	},
	datacardList: {
		error: false,
		loading: false,
		payload: {
			cbUserABV: {
				delta: 0,
				value: 0
			},
			cbUserReturnFr: {
				delta: 0,
				value: 0
			},
			ltv: {
				delta: 0,
				value: 0
			},
			cbEarned: {
				delta: 0,
				value: 0
			},
			cbRedeemed: {
				delta: 0,
				value: 0
			},
			cbPending: {
				delta: 0,
				value: 0
			}
		}
	},
	abvComparisonData: {
		error: false,
		loading: false,
		payload: {
			nonLogin: {
				delta: 0,
				value: 0
			},
			newUser: {
				delta: 0,
				value: 0
			},
			returningUsers: {
				delta: 0,
				value: 0
			}
		}
	},
	cashbackInfluenceData: {
		error: false,
		loading: false,
		payload: []
	},
	cashbackRedemptionTable: {
		error: null,
		loading: false,
		payload: {
			nonMembers: {
				totalUsers: 0,
				salesPercent: 0,
				abv: 0,
				totalVisits: 0,
				ltv: 0
			},
			newUsers: {
				totalUsers: 0,
				salesPercent: 0,
				abv: 0,
				totalVisits: 0,
				ltv: 0
			},
			loyalUsers: {
				totalUsers: 0,
				salesPercent: 0,
				abv: 0,
				totalVisits: 0,
				ltv: 0
			},
			regularUsers: {
				totalUsers: 0,
				salesPercent: 0,
				abv: 0,
				totalVisits: 0,
				ltv: 0
			},
			overdueUsers: {
				totalUsers: 0,
				salesPercent: 0,
				abv: 0,
				totalVisits: 0,
				ltv: 0
			},
			dangerUsers: {
				totalUsers: 0,
				salesPercent: 0,
				abv: 0,
				totalVisits: 0,
				ltv: 0
			},
			lapsedUsers: {
				totalUsers: 0,
				salesPercent: 0,
				abv: 0,
				totalVisits: 0,
				ltv: 0
			}
		}
	},
	cashbackRedemptionChart: {
		error: false,
		loading: false,
		payload: {
			breakpoints: {
				loyal: 0,
				regular: 0,
				overdue: 0,
				danger: 0
			},
			chartData: []
		}
	},
	isReady: true
}

const cashbackReducer = (state = preloadedState, action) => {
	// Destructure action object
	const { type, payload, error } = action

	switch (type) {
		case "FETCH_cashback/scorecardGroup1_INIT":
			return {
				...state,
				isReady: false,
				scorecardListTop: {
					error: false,
					loading: true,
					payload: {}
				}
			}

		case "FETCH_cashback/scorecardGroup1_SUCCESS":
			var newState = {
				...state,
				scorecardListTop: {
					error: false,
					loading: false,
					payload: payload
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/scorecardGroup1_FAIL":
			var newState = {
				...state,
				scorecardListTop: {
					error: error,
					loading: false,
					payload: {}
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/datacardGroup1_INIT":
			return {
				...state,
				isReady: false,
				datacardList: {
					error: false,
					loading: true,
					payload: {}
				}
			}

		case "FETCH_cashback/datacardGroup1_SUCCESS":
			var newState = {
				...state,
				datacardList: {
					error: false,
					loading: false,
					payload: payload
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/datacardGroup1_FAIL":
			var newState = {
				...state,
				datacardList: {
					error: false,
					loading: false,
					payload: payload
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/scorecardGroup2_INIT":
			return {
				...state,
				isReady: false,
				scorecardListBottom: {
					error: false,
					loading: true,
					payload: {}
				}
			}

		case "UPDATE_cashback/scorecardGroup2_SUCCESS":
			const {
				totalMembers = {},
				activeMembers = {},
				lapsedMembers = {}
			} = state.scorecardListBottom.payload
			var newState = {
				...state,
				scorecardListBottom: {
					...state.scorecardListBottom,
					payload: {
						totalMembers: {
							value: totalMembers.value || null
						},
						activeMembers: {
							value: activeMembers.value || null
						},
						lapsedMembers: {
							value: lapsedMembers.value || null
						},
						lifetimeMembers: {
							value: payload
						}
					}
				}
			}

			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/scorecardGroup2_SUCCESS":
			var newState = {
				...state,
				scorecardListBottom: {
					error: false,
					loading: false,
					payload: {
						...payload,
						lifetimeMembers: state.scorecardListBottom.payload
							.lifetimeMembers || { value: null }
					}
				}
			}

			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/scorecardGroup2_FAIL":
			var newState = {
				...state,
				scorecardListBottom: {
					error: error,
					loading: false,
					payload: {}
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/abvComparison_INIT":
			return {
				...state,
				isReady: false,
				abvComparisonData: {
					error: false,
					loading: true,
					payload: {}
				}
			}

		case "FETCH_cashback/abvComparison_SUCCESS":
			var newState = {
				...state,
				abvComparisonData: {
					error: false,
					loading: false,
					payload: payload
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/abvComparison_FAIL":
			var newState = {
				...state,
				abvComparisonData: {
					error: error,
					loading: false,
					payload: {}
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/cashbackInfluence_INIT":
			return {
				...state,
				isReady: false,
				cashbackInfluenceData: {
					error: false,
					loading: true,
					payload: []
				}
			}

		case "FETCH_cashback/cashbackInfluence_SUCCESS":
			var newState = {
				...state,
				cashbackInfluenceData: {
					error: false,
					loading: false,
					payload: payload
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/cashbackInfluence_FAIL":
			var newState = {
				...state,
				cashbackInfluenceData: {
					error: error,
					loading: false,
					payload: []
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/cashbackRedemptionTable_INIT":
			return {
				...state,
				isReady: false,
				cashbackRedemptionTable: {
					error: false,
					loading: true,
					payload: {}
				}
			}

		case "FETCH_cashback/cashbackRedemptionTable_SUCCESS":
			var newState = {
				...state,
				cashbackRedemptionTable: {
					error: false,
					loading: false,
					payload: payload
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/cashbackRedemptionTable_FAIL":
			var newState = {
				...state,
				cashbackRedemptionTable: {
					error: error,
					loading: false,
					payload: {}
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/cashbackRedemptionChart_INIT":
			return {
				...state,
				isReady: false,
				cashbackRedemptionChart: {
					error: false,
					loading: true,
					payload: {}
				}
			}

		case "FETCH_cashback/cashbackRedemptionChart_SUCCESS":
			var newState = {
				...state,
				cashbackRedemptionChart: {
					error: false,
					loading: false,
					payload: payload
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_cashback/cashbackRedemptionChart_FAIL":
			var newState = {
				...state,
				cashbackRedemptionChart: {
					error: error,
					loading: false,
					payload: {}
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		default:
			return state
	}
}
export default cashbackReducer
