import * as c from './constants';

import { initAction, successAction, errorAction } from 'helpers/actionsFormat';

const fetchMealPenetrationAction = payload =>
  initAction(c.FETCH_MEAL_PENETRATION, payload);
const mealPenetrationLoadedAction = payload =>
  successAction(c.MEAL_PENETRATION_LOADED, payload);
const mealPenetrationFailedAction = error =>
  errorAction(c.MEAL_PENETRATION_FAILED, error);
const fetchCategoryPenetrationAction = payload =>
  initAction(c.FETCH_CATEGORY_PENETRATION, payload);
const categoryPenetrationLoadedAction = payload =>
  successAction(c.CATEGORY_PENETRATION_LOADED, payload);
const categoryPenetrationFailedAction = error =>
  errorAction(c.CATEGORY_PENETRATION_FAILED, error);
const fetchCategoryPlacementAction = payload =>
  initAction(c.FETCH_CATEGORY_PLACEMENT, payload);
const categoryPlacementLoadedAction = payload =>
  successAction(c.CATEGORY_PLACEMENT_LOADED, payload);
const categoryPlacementFailedAction = error =>
  errorAction(c.CATEGORY_PLACEMENT_ERROR, error);
const fetchCategoryContribution = payload =>
  initAction(c.FETCH_CATEGORY_CONTRIBUTION, payload);
const categoryContributionDataLoaded = payload =>
  successAction(c.CATEGORY_CONTRIBUTION_LOADED, payload);
const categoryContributionError = error =>
  errorAction(c.CATEGORY_CONTRIBUTION_ERROR, error);
const fetchCategoryByTimeDataAction = payload =>
  initAction(c.FETCH_CATEGORY_BY_TIME, payload);
const categoryByTimeDataLoaded = payload =>
  successAction(c.CATEGORY_BY_TIME_LOADED, payload);
const categoryByTimeFailed = error =>
  errorAction(c.CATEGORY_BY_TIME_ERROR, error);

export {
  fetchMealPenetrationAction,
  mealPenetrationLoadedAction,
  mealPenetrationFailedAction,
  fetchCategoryPenetrationAction,
  categoryPenetrationLoadedAction,
  categoryPenetrationFailedAction,
  fetchCategoryPlacementAction,
  categoryPlacementLoadedAction,
  categoryPlacementFailedAction,
  fetchCategoryContribution,
  categoryContributionDataLoaded,
  categoryContributionError,
  fetchCategoryByTimeDataAction,
  categoryByTimeDataLoaded,
  categoryByTimeFailed,
};
