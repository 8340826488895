/*
 *
 * CategoryContainer constants
 *
 */
export const FETCH_CATEGORY_PENETRATION =
  'app/CategoryContainer/FETCH_CATEGORY_PENETRATION';
export const CATEGORY_PENETRATION_LOADED =
  'app/CategoryContainer/CATEGORY_PENETRATION_LOADED';
export const CATEGORY_PENETRATION_FAILED =
  'app/CategoryContainer/CATEGORY_PENETRATION_FAILED';
export const FETCH_MEAL_PENETRATION =
  'app/CategoryContainer/FETCH_MEAL_PENETRATION';
export const MEAL_PENETRATION_LOADED =
  'app/CategoryContainer/MEAL_PENETRATION_LOADED';
export const MEAL_PENETRATION_FAILED =
  'app/CategoryContainer/MEAL_PENETRATION_FAILED';
export const FETCH_CATEGORY_PLACEMENT =
  'app/CategoryContainer/FETCH_CATEGORY_PLACEMENT';
export const CATEGORY_PLACEMENT_LOADED =
  'app/CategoryContainer/CATEGORY_PLACEMENT_LOADED';
export const CATEGORY_PLACEMENT_ERROR =
  'app/CategoryContainer/CATEGORY_PLACEMENT_ERROR';
export const FETCH_CATEGORY_CONTRIBUTION =
  'app/CategoryContainer/FETCH_CATEGORY_CONTRIBUTION';
export const CATEGORY_CONTRIBUTION_LOADED =
  'app/CategoryContainer/CATEGORY_CONTRIBUTION_LOADED';
export const CATEGORY_CONTRIBUTION_ERROR =
  'app/CategoryContainer/CATEGORY_CONTRIBUTION_ERROR';
export const FETCH_CATEGORY_BY_TIME =
  'app/CategoryContainer/FETCH_CATEGORY_BY_TIME';
export const CATEGORY_BY_TIME_LOADED =
  'app/CategoryContainer/CATEGORY_BY_TIME_LOADED';
export const CATEGORY_BY_TIME_ERROR =
  'app/CategoryContainer/CATEGORY_BY_TIME_ERROR';
