import moment from 'moment';

const dropdownFormatter = payload => ({
  orderTypes: payload.order_types || [],
  productTypes: payload.product_types || [],
  paymentTypes: payload.payment_types || [],
  partners: payload.partner_types || [],
  paymentNetwork: payload.network_type || [],
});

const summaryTableFormatter = (payload, type) => {
  const formattedData = {
    overall: [],
    daily: [],
    orders_count:[],
  };

  if (payload?.overall_breakups?.length) {
    payload.overall_breakups.forEach(obj => {
      formattedData.overall.push({
        id: obj[type],
        key: obj[type],
        type: 'main',
        name: obj[type],
        discount: obj.discount,
        refunds: obj.refunds,
        serviceCharge: obj.service_charge,
        subTotal: obj.sub_total,
        tax: obj.taxes,
        surcharge: obj.surcharges,
        total: obj.total,
        orders: obj.total_orders,
        rounding: obj.total_rounding,
        children: obj.breakup?.length
          ? obj.breakup.map(childObj => ({
              id: `${obj[type]}${childObj.partner_type}`,
              key: `${obj[type]}${childObj.partner_type}`,
              type: 'child',

              name: childObj.partner_type,
              discount: childObj.discount,
              refunds: childObj.refunds,
              serviceCharge: childObj.service_charge,
              subTotal: childObj.sub_total,
              tax: childObj.taxes,
              surcharge: childObj.surcharges,
              total: childObj.total,
              orders: childObj.total_orders,
              rounding: childObj.total_rounding,
            }))
          : undefined,
      });
    });
  }

  if (payload?.day_breakups?.length) {
    payload.day_breakups.forEach(obj => {
      formattedData.daily.push({
        id: obj.date,
        key: `${obj.date}-main`,
        type: 'main',
        name: obj.date,
        discount: obj.discount,
        refunds: obj.refunds,
        serviceCharge: obj.service_charge,
        subTotal: obj.sub_total,
        tax: obj.taxes,
        surcharge: obj.surcharges,
        total: obj.total,
        orders: obj.total_orders,
        rounding: obj.total_rounding,
        children: obj.break_up.map(childObj => ({
          id: `${obj.date}-${childObj[type]}`,
          key: `${obj.date}${childObj[type]}`,
          type: 'child',
          name: childObj[type],
          discount: childObj.discount,
          refunds: childObj.refunds,
          serviceCharge: childObj.service_charge,
          subTotal: childObj.sub_total,
          tax: childObj.taxes,
          surcharge: childObj.surcharges,
          total: childObj.total,
          orders: childObj.total_orders,
          rounding: childObj.total_rounding,
          children: childObj.breakup_partner_type?.length
            ? childObj.breakup_partner_type.map(child2Obj => ({
                id: `${obj.date}${childObj[type]}${child2Obj.partner_type}`,
                key: `${obj.date}${childObj[type]}${child2Obj.partner_type}`,
                name: child2Obj.partner_type,
                discount: child2Obj.discount,
                refunds: child2Obj.refunds,
                serviceCharge: child2Obj.service_charge,
                subTotal: child2Obj.sub_total,
                tax: child2Obj.taxes,
                surcharge: child2Obj.surcharges,
                total: child2Obj.total,
                orders: child2Obj.total_orders,
                rounding: child2Obj.total_rounding,
              }))
            : undefined,
        })),
      });
    });
  }
   if (payload?.total_orders){
    formattedData.orders_count.push({
      orders_count:payload.total_orders
    })
 }

  return formattedData;
};

const categoryTableFormatter = (payload, type) => {
  const formattedData = [];
  if (payload?.length) {
    payload.forEach(obj => {
      formattedData.push({
        id: obj.category_name,
        key: obj.category_name,
        type: 'main',
        name: obj.category_name,
        sales: obj.total_amount,
        qty: obj.total_count,
        children: obj.break_up.map(childObj => ({
          id: `${obj.category_name}-${childObj[type]}`,
          key: `${obj.category_name}-${childObj[type]}`,
          type: 'child',
          name: childObj[type],
          sales: childObj.total_amount,
          qty: childObj.total_count,
          children: childObj.breakup_partner_type?.length
            ? childObj.breakup_partner_type.map(child2Obj => ({
                id: `${obj.category_name}-${childObj[type]}-${child2Obj.partner_type}`,
                key: `${obj.category_name}-${childObj[type]}-${child2Obj.partner_type}`,
                type: 'grandchild',
                name: child2Obj.partner_type,
                sales: child2Obj.total_amount,
                qty: child2Obj.total_count,
              }))
            : undefined,
        })),
      });
    });
  }
  return formattedData;
};

const itemTransactionsTableFormatter = payload => {
  if (payload?.length) {
    const formattedData = payload
      .map(obj => ({
        date: obj.date || undefined,
        hour: moment(obj.date, 'YYYY-MM-DD hh:mm:ss').hour() ?? undefined,
        name: obj.item_name,
        // type: obj.item_type,
        orderId: obj.order_id,
        orderType: obj.order_type,
        partner: obj.partner_types,
        payment: obj.payment_type,
        network: obj.network_type|| '-',
        price: obj.price,
        sales: obj.amount,
        product: obj.product_types,
        qty: obj.quantity,
        sku: obj.sku,
        category: obj.category_name,
      }))
      .filter(o => o.date && o.orderId && o.name);
    return formattedData;
  }
  return [];
};

const transactionsTableFormatter = payload => {
  if (payload?.length) {
    const formattedData = payload
      .map(obj => ({
        key: obj.order_no,
        id: obj.order_no,
        time: obj.date || '-',
        orderType: obj.order_type || '-',
        appName: obj.app_name || '-',
        items: obj.items || '-',
        orderTotal: obj.order_total || 0,
        is_tax_included: obj.is_tax_included === 1 ? 'Yes' : 'No',
        totalCharges: obj.total_charge || 0,
        totalTax: obj.total_tax || 0,
        surcharge:obj.surcharge || 0,
        totalDiscount: obj.total_discount || 0,
        totalAmt: obj.total_amount || 0,
        transferAmt: obj.transfer_amount || 0,
        commission: obj.commission_fee || 0,
        paymentType: obj.payment_type || '-',
        networkType: obj.network_type || '-',
        restaurantName: obj.restaurant_name,
        refundAmt: obj.refund_amount || 0,
      }))
      .filter(o => o.id && o.time);
    return formattedData;
  }
  return [];
};

const itemTableFormatter = payload => {
  if (payload?.length) {
    const formattedData = payload
      .map((obj, i) => ({
        id: `${obj.item_name}-${obj.parent_item}-${i}`,
        key: `${obj.item_name}-${obj.parent_item}-${i}`,
        item: obj.item_name,
        type: obj.item_type,
        parent: obj.parent_item,
        category: obj.category_name,
        qty: obj.quantity,
        sales: obj.sales,
      }))
      .filter(o => o.item && o.type && o.qty);
    return formattedData;
  }
  return [];
};

export {
  dropdownFormatter,
  summaryTableFormatter,
  categoryTableFormatter,
  itemTableFormatter,
  itemTransactionsTableFormatter,
  transactionsTableFormatter,
};
