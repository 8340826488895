// Fetch helper function
import fetch from "../../../helpers/Fetch"

// Import API path/routes constant
import {
	URL_SCORECARD_LIST_TOP,
	URL_SCORECARD_LIST_BOTTOM,
	URL_ABV_COMPARISON_DATA,
	URL_CASHBACK_INFLUENCE_DATA,
	URL_CASHBACK_REDEMPTION_DATA
} from "./constants"

// Fetch Promise
const fetchScorecardListTopData = (payload) => {
	return fetch({
		url: URL_SCORECARD_LIST_TOP,
		data: payload
	})
}

// Fetch Promise
const fetchScorecardListBottomData = (payload) => {
	return fetch({
		url: URL_SCORECARD_LIST_BOTTOM,
		data: payload
	})
}

// Fetch Promise
const fetchABVComparisonData = (payload) => {
	return fetch({
		url: URL_ABV_COMPARISON_DATA,
		data: payload
	})
}

// Fetch Promise
const fetchCashbackInfluenceData = (payload) => {
	return fetch({
		url: URL_CASHBACK_INFLUENCE_DATA,
		data: payload
	})
}

// Fetch Promise
const fetchCashbackRedemptionData = (payload) => {
	return fetch({
		url: URL_CASHBACK_REDEMPTION_DATA,
		data: payload
	})
}

export {
	fetchScorecardListTopData,
	fetchScorecardListBottomData,
	fetchABVComparisonData,
	fetchCashbackInfluenceData,
	fetchCashbackRedemptionData
}
