import * as CONST from './constants';
import checkStateLoading from 'helpers/checkStateLoading';

export const initialState = {
  promoScore: {
    error: null,
    loading: false,
    payload: {
      totalOrders: {
        change: 0,
        value: 0,
      },
      promoOrders: {
        change: 0,
        value: 0,
      },
      avgPromoDiscounts: {
        change: 0,
        value: 0,
      },
      amount: {
        change: 0,
        value: 0,
      },
      discount: {
        change: 0,
        value: 0,
      },
      roi: {
        change: 0,
        value: 0,
      },
      netSales: {
        change: 0,
        value: 0,
      },
      percPromoOrders: {
        change: 0,
        value: 0,
      },
    },
  },
  promoNonpromo: {
    error: null,
    loading: false,
    payload: {
      abv: {
        promo: null,
        nonPromo: null,
      },
      orders: {
        promo: null,
        nonPromo: null,
      },
    },
  },
  codeUsage: {
    error: null,
    loading: false,
    payload: [],
  },
  byDayTime: {
    error: null,
    loading: false,
    payload: [],
  },
  table: {
    error: null,
    loading: false,
    payload: {
      byPromo: [],
      byOrder: [],
    },
  },
  dropdowns: {
    error: null,
    loading: false,
    payload: {
      orderTypes: [],
      appTypes: [],
    },
    selApptype: [],
    selOrdertype: [],
  },
  isReady: false,
};

const getSelectedApp = (selApptype, payload) => {
  if (selApptype.length !== 0) {
    if (payload?.length) {
      const applist = payload.map(o => o.name);
      return { selApptype: selApptype.filter(x => applist.includes(x)) };
    }
  }
  return {};
};

const getSelectedOrdertype = (selOrdertype, payload) => {
  if (selOrdertype.length !== 0) {
    if (payload?.length) {
      const applist = payload.map(o => o.name);
      return { selOrdertype: selOrdertype.filter(x => applist.includes(x)) };
    }
  }
  return {};
};

function promotionUsageReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case CONST.FETCH_PROMOTION_USAGE_SCORES_ACTION:
      return {
        ...state,
        promoScore: {
          error: null,
          loading: true,
          payload: state.promoScore.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PROMOTION_USAGE_SCORES_SUCCESS_ACTION:
      newState = {
        ...state,
        promoScore: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PROMOTION_USAGE_SCORES_ERROR_ACTION:
      newState = {
        ...state,
        promoScore: {
          error,
          loading: false,
          payload: initialState.promoScore.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PROMOTION_USAGE_PROMO_NON_PROMO_ACTION:
      return {
        ...state,
        promoNonpromo: {
          error: null,
          loading: true,
          payload: state.promoNonpromo.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PROMOTION_USAGE_PROMO_NON_PROMO_SUCCESS_ACTION:
      newState = {
        ...state,
        promoNonpromo: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PROMOTION_USAGE_PROMO_NON_PROMO_ERROR_ACTION:
      newState = {
        ...state,
        promoNonpromo: {
          error,
          loading: false,
          payload: initialState.promoNonpromo.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case CONST.FETCH_PROMOTION_USAGE_CODE_USAGE_ACTION:
      return {
        ...state,
        codeUsage: {
          error: null,
          loading: true,
          payload: state.codeUsage.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PROMOTION_USAGE_CODE_USAGE_SUCCESS_ACTION:
      newState = {
        ...state,
        codeUsage: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PROMOTION_USAGE_CODE_USAGE_ERROR_ACTION:
      newState = {
        ...state,
        codeUsage: {
          error,
          loading: false,
          payload: initialState.codeUsage.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case CONST.FETCH_PROMOTION_USAGE_BY_DAY_TIME_ACTION:
      return {
        ...state,
        byDayTime: {
          error: null,
          loading: true,
          payload: state.byDayTime.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PROMOTION_USAGE_BY_DAY_TIME_SUCCESS_ACTION:
      newState = {
        ...state,
        byDayTime: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PROMOTION_USAGE_BY_DAY_TIME_ERROR_ACTION:
      newState = {
        ...state,
        byDayTime: {
          error,
          loading: false,
          payload: initialState.byDayTime.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PROMOTION_USAGE_TABLE_ACTION:
      return {
        ...state,
        table: {
          error: null,
          loading: true,
          payload: state.table.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PROMOTION_USAGE_TABLE_SUCCESS_ACTION:
      newState = {
        ...state,
        table: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PROMOTION_USAGE_TABLE_ERROR_ACTION:
      newState = {
        ...state,
        table: {
          error,
          loading: false,
          payload: initialState.table.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case CONST.FETCH_PROMOTION_USAGE_DROPDOWNS_ACTION:
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          error: null,
          loading: true,
        },
        isReady: false,
      };
    case CONST.FETCH_PROMOTION_USAGE_DROPDOWNS_SUCCESS_ACTION:
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          error: null,
          loading: false,
          payload,
          ...getSelectedApp(state.dropdowns.selApptype, payload.appTypes),
          ...getSelectedOrdertype(
            state.dropdowns.selOrdertype,
            payload.orderTypes,
          ),
        },
      };
    case CONST.FETCH_PROMOTION_USAGE_DROPDOWNS_ERROR_ACTION:
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          error,
          loading: false,
          payload: initialState.dropdowns.payload,
        },
      };
    case CONST.SET_PROMOTION_USAGE_APP_SELECTED:
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          selApptype: payload,
        },
      };

    case CONST.SET_PROMOTION_USAGE_ORDERTYPE_SELECTED:
      return {
        ...state,
        dropdowns: {
          ...state.dropdowns,
          selOrdertype: payload,
        },
      };

    default:
      return state;
  }
}

export default promotionUsageReducer;
