/*
 *
 * sc page reducer
 *
 */

import * as cn from './constants';
import moment from 'moment';

export const initialState = {
  scores: {
    error: null,
    loading: false,
    payload: {
      amount: 0,
      orders: 0,
      amoutPerc: 0,
      ordersPerc: 0,
    },
  },
  breakup: {
    loading: false,
    error: null,
    payload: [],
  },
  trend: {
    loading: false,
    error: null,
    payload: [],
  },
  table: {
    loading: false,
    error: null,
    payload: [],
  },
  isReady: false,
};

function voidAndFailedReducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    case cn.SCORE_INIT:
      return {
        ...state,
        scores: {
          loading: true,
          error: null,
          payload: initialState.scores.payload,
        },
        isReady: false,
      };
    case cn.SCORE_SUCCESS:
      return {
        ...state,
        scores: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.SCORE_ERROR:
      return {
        ...state,
        scores: {
          loading: false,
          error: error,
          payload: initialState.scores.payload,
        },
      };

    case cn.BREAKUP_INIT:
      return {
        ...state,
        breakup: {
          loading: true,
          error: null,
          payload: initialState.breakup.payload,
        },
        isReady: false,
      };
    case cn.BREAKUP_SUCCESS:
      return {
        ...state,
        breakup: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.BREAKUP_ERROR:
      return {
        ...state,
        breakup: {
          loading: false,
          error: error,
          payload: initialState.breakup.payload,
        },
      };

    case cn.TREND_INIT:
      return {
        ...state,
        trend: {
          loading: true,
          error: null,
          payload: initialState.trend.payload,
        },
        isReady: false,
      };
    case cn.TREND_SUCCESS:
      return {
        ...state,
        trend: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.TREND_ERROR:
      return {
        ...state,
        trend: {
          loading: false,
          error: error,
          payload: initialState.trend.payload,
        },
      };

    case cn.TABLE_INIT:
      return {
        ...state,
        table: {
          loading: true,
          error: null,
          payload: initialState.table.payload,
        },
        isReady: false,
      };
    case cn.TABLE_SUCCESS:
      return {
        ...state,
        table: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.TABLE_ERROR:
      return {
        ...state,
        table: {
          loading: false,
          error: error,
          payload: initialState.table.payload,
        },
      };

    default:
      return state;
  }
}

export default voidAndFailedReducer;
