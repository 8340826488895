import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = 'en';
const availableLanguages = ['en', 'zh-CN', 'zh-TW'];

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // resources,
    fallbackLng,
    supportedLngs: availableLanguages,
    load: 'currentOnly',
    backend: {
      backends: [
        LocalStorageBackend, // primary backend
        HttpApi, // fallback backend
      ],
      backendOptions: [
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
    detection: {
      checkWhitelist: true,
      // order: ['localStorage', 'sessionStorage'],
    },
    defaultNS: 'uiText',

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
    // react: {
    //   useSuspense: false,
    // }
  });

export default i18next;
