// Setting preloaded or initial state

const preloadedState = {
  customerMap: {
    error: null,
    loading: false,
    payload: [],
  },
  customerMapFilters: {},
};

const csCustomerAnalysisReducer = (state = preloadedState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case 'FETCH_customerSegmentation/ca/customerMap_INIT':
      return {
        ...state,
        customerMap: {
          error: null,
          loading: true,
          payload: [],
        },
      };

    case 'FETCH_customerSegmentation/ca/customerMap_SUCCESS':
      return {
        ...state,
        customerMap: {
          error: null,
          loading: false,
          payload,
        },
      };

    case 'FETCH_customerSegmentation/ca/customerMap_FAIL':
      return {
        ...state,
        customerMap: {
          error,
          loading: false,
          payload: [],
        },
      };

    case 'FETCH_customerSegmentation/ca/customerMapFilter_SUCCESS':
      return {
        ...state,
        customerMapFilters: payload,
      };

    default:
      return state;
  }
};
export default csCustomerAnalysisReducer;
