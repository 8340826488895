import { createSelector } from 'reselect';

const merchantSelector = state => state.merchant;

const selectActiveMerchantList = createSelector(
  merchantSelector,
  merchant => merchant.activeList,
);
const selectMerchantList = createSelector(
  merchantSelector,
  merchant => merchant.list,
);
const selectMenuModes = createSelector(
  merchantSelector,
  merchant => merchant.menuModes,
);
const selectIsChainMerchant = createSelector(
  merchantSelector,
  merchant => merchant.isChainMerchant,
);

const selectIsEcms = createSelector(
  merchantSelector,
  merchant => merchant.isEcms,
);

const selectIsCloudKiosk = createSelector(
  merchantSelector,
  merchant => merchant.isCloudKiosk,
);

const selectEnableMasterMerchant = createSelector(
  merchantSelector,
  merchant => merchant.enableMasterMerchant,
);

const selectEnablePaymentRecon = createSelector(
  merchantSelector,
  merchant => merchant.enablePaymentRecon,
);

const selectLockedTabs = createSelector(
  merchantSelector,
  merchant => merchant.lockedTabs,
);

export {
  selectActiveMerchantList,
  selectMerchantList,
  selectMenuModes,
  selectIsChainMerchant,
  selectEnableMasterMerchant,
  selectEnablePaymentRecon,
  selectIsEcms,
  selectIsCloudKiosk,
  selectLockedTabs,
};
