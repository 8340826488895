import { takeLatest, call, put } from 'redux-saga/effects';
import scorecardDataTransformer from 'transformers/scorecardDataTransformer';
import {
  tabsquareValueAdditionDataFormatter,
  crossupContributionDataFormatter,
  pricingAnalysisDataFormatter,
  itemPerformanceDataFormatter,
} from './formatter';
import {
  fetchSalesComp,
  fetchCrossSellUpSellContribution,
  fetchCrossSellUpSellSalesScore,
  fetchItemPerformanceCrossUpSell,
  fetchCrossUpSellPricing,
  fetchCrossUPSalesScore2,
} from './services';
import {
  salesComparisonDataLoadedAction,
  salesComparisonFailedAction,
  crossSellUpSellContributionDataLoadedAction,
  crossSellUpSellContributionFailedAction,
  itemPerformanceLoadedAction,
  itemPerformanceFailedAction,
  crossSellUpSellSalesScoreDataLoadedAction,
  crossSellUpSellSalesScoreFailedAction,
  salesScoreDataLoadedAction,
  salesScoreDataFailedAction,
  crossSellUpSellPricingDataLoadedAction,
  crossSellUpSellPricingFailedAction,
} from './actions';
import {
  FETCH_SALES_COMPARISON,
  FETCH_CROSS_SELL_UPSELL_CONTRIBUTION,
  FETCH_ITEM_PERFORMANCE,
  FETCH_CROSS_SELL_UPSELL_SALES_SCORE,
  FETCH_SALES_SCORE,
  FETCH_CROSS_SELL_UPSELL_PRICING,
} from './constants';
import { getMerchantAndDate } from 'redux/rootSaga';

function* fetchCrossSellUpSellSalesScoreDataSaga() {
  const {
    merchantIdList,
    startDate,
    endDate,
    cStartDate,
    cEndDate,
  } = yield getMerchantAndDate();
  const payload = {
    merchant_id: merchantIdList,
    start_date: startDate,
    end_date: endDate,
    c_start_date: cStartDate,
    c_end_date: cEndDate,
    graph_type: 'UC',
  };
  try {
    const response = yield call(fetchCrossSellUpSellSalesScore, payload);
    if (response.status == 206) {
      yield put(crossSellUpSellSalesScoreDataLoadedAction([]));
    } else {
      const formattedData = scorecardDataTransformer(
        response.data,
        'crossupSellTop',
      );
      yield put(crossSellUpSellSalesScoreDataLoadedAction(formattedData));
    }
  } catch (e) {
    yield put(crossSellUpSellSalesScoreFailedAction('Something went wrong'));
  }
}

function* fetchSalesCompDataSaga() {
  const { merchantIdList, startDate, endDate } = yield getMerchantAndDate();
  try {
    const { data, status } = yield call(fetchSalesComp, {
      merchant_id: merchantIdList,
      start_date: startDate,
      end_date: endDate,
    });
    if (status === 200 || (status === 206 && data)) {
      const { error_message } = data;
      if (error_message) {
        yield put(salesComparisonFailedAction("No Data Found"));
      } else {
        const formattedData = tabsquareValueAdditionDataFormatter(data);
        yield put(salesComparisonDataLoadedAction(formattedData));
      }
    } else {
      throw new Error('Something went wrong');
    }
  } catch (e) {
    console.log('Something went wrong', e);
    yield put(salesComparisonFailedAction('Something went wrong'));
  }
}

function* fetchCrossSellUpSellContributionDataSaga() {
  const { merchantIdList, startDate, endDate } = yield getMerchantAndDate();
  try {
    const { data, status } = yield call(fetchCrossSellUpSellContribution, {
      merchant_id: merchantIdList,
      start_date: startDate,
      end_date: endDate,
    });
    if (status === 200 || (status === 206 && data)) {
      const { error_message } = data;
      if (error_message) {
        yield put(crossSellUpSellContributionFailedAction("No Data Found"));
      } else {
        const formattedData = crossupContributionDataFormatter(data);
        yield put(crossSellUpSellContributionDataLoadedAction(formattedData));
      }
    } else {
      throw new Error('Something went wrong');
    }
  } catch (e) {
    console.log('Something went wrong', e);
    yield put(crossSellUpSellContributionFailedAction('Something went wrong'));
  }
}

function* fetchSalesScoreDataSaga({payload: view}) {
  const {
    merchantIdList,
    startDate,
    endDate,
    cStartDate,
    cEndDate,
  } = yield getMerchantAndDate();
  const payload = {
    merchant_id: merchantIdList,
    start_date: startDate,
    end_date: endDate,
    c_start_date: cStartDate,
    c_end_date: cEndDate,
    graph_type: view === 'cross' ? 'C' : 'U',
  };

  try {
    const response = yield call(fetchCrossUPSalesScore2, payload);
    if (response.status == 206) {
      yield put(salesScoreDataLoadedAction([]));
    } else {
      const formattedData = scorecardDataTransformer(
        { data: response.data, view },
        'crossupSellBottom',
      );
      yield put(salesScoreDataLoadedAction(formattedData));
    }
  } catch (e) {
    yield put(salesScoreDataFailedAction('Something went wrong'));
  }
}

function* fetchCrossSellUpSellPricingDataSaga({payload: view}) {
  const { merchantIdList, startDate, endDate } = yield getMerchantAndDate();
  const payload = {
    merchant_id: merchantIdList,
    start_date: startDate,
    end_date: endDate,
    graph_type: view === 'cross' ? 'C' : 'U',
  };
  try {
    const { data, status } = yield call(fetchCrossUpSellPricing, payload);

    if (status === 200 || (status === 206 && data)) {
      const { error_message } = data;
      if (error_message) {
        yield put(crossSellUpSellPricingFailedAction("No Data Found"));
      } else {
        const formattedData = pricingAnalysisDataFormatter(data);
        yield put(crossSellUpSellPricingDataLoadedAction(formattedData));
      }
    } else {
      throw new Error('Something went wrong');
    }
  } catch (e) {
    console.log('Something went wrong', e);
    yield put(crossSellUpSellPricingFailedAction('Something went wrong'));
  }
}

function* fetchItemPerformanceDataSaga({payload: view}) {
  const { merchantIdList, startDate, endDate } = yield getMerchantAndDate();
  const payload = {
    merchant_id: merchantIdList,
    start_date: startDate,
    end_date: endDate,
    graph_type: view === 'cross' ? 'C' : 'U',
  };
  try {
    const response = yield call(fetchItemPerformanceCrossUpSell, payload);
    if (response.status == 206) {
      yield put(itemPerformanceLoadedAction([]));
    } else {
      const formattedData = itemPerformanceDataFormatter(response.data);
      yield put(itemPerformanceLoadedAction(Object.values(formattedData)));
    }
  } catch (e) {
    yield put(itemPerformanceFailedAction('Something went wrong'));
  }
}

export default function* crossSellUpSellPageSaga() {
  yield takeLatest(
    FETCH_CROSS_SELL_UPSELL_SALES_SCORE,
    fetchCrossSellUpSellSalesScoreDataSaga,
  );
  yield takeLatest(FETCH_SALES_COMPARISON, fetchSalesCompDataSaga);
  yield takeLatest(
    FETCH_CROSS_SELL_UPSELL_CONTRIBUTION,
    fetchCrossSellUpSellContributionDataSaga,
  );
  yield takeLatest(FETCH_SALES_SCORE, fetchSalesScoreDataSaga);
  yield takeLatest(
    FETCH_CROSS_SELL_UPSELL_PRICING,
    fetchCrossSellUpSellPricingDataSaga,
  );
  yield takeLatest(FETCH_ITEM_PERFORMANCE, fetchItemPerformanceDataSaga);
}
