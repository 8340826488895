/*
 *
 * benchmarking time based analysis reducer
 *
 */

import * as CONST from './constants';
import checkStateLoading from 'helpers/checkStateLoading';

export const initialState = {
  performance: {
    loading: false,
    error: null,
    payload: [],
  },
  capacityUtilization: {
    loading: false,
    error: null,
    payload: [],
  },
  isReady: false,
};

function benchmarkingOverviewReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case CONST.FETCH_TIME_BASED_PERFORMANCE_ACTION:
      return {
        ...state,
        performance: {
          loading: true,
          error: null,
          payload: [],
        },
        isReady: false,
      };
    case CONST.FETCH_TIME_BASED_PERFORMANCE_SUCCESS_ACTION:
      newState = {
        ...state,
        performance: {
          loading: false,
          error: null,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_TIME_BASED_PERFORMANCE_ERROR_ACTION:
      newState = {
        ...state,
        performance: {
          loading: false,
          error,
          payload: [],
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_CAPACITY_UTILIZATION_ACTION:
      return {
        ...state,
        capacityUtilization: {
          loading: true,
          error: null,
          payload: [],
        },
        isReady: false,
      };
    case CONST.FETCH_CAPACITY_UTILIZATION_SUCCESS_ACTION:
      newState = {
        ...state,
        capacityUtilization: {
          loading: false,
          error: null,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_CAPACITY_UTILIZATION_ERROR_ACTION:
      newState = {
        ...state,
        capacityUtilization: {
          loading: false,
          error,
          payload: [],
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    default:
      return state;
  }
}

export default benchmarkingOverviewReducer;
