import i18n from 'i18n/config';

// Format Customer Map data
const customerMapDataFormatter = payload => {
  if (payload) {
    return Object.keys(payload).map((customerId, i) => {
      const {
        top_ordered_item = null,
        top_ordered_cat = null,
        least_ordered_cat = null,
        abv = null,
        ATBV = null,
        overdue_days = null,
        avg_group_size = null,
        total_spend = null,
        total_visits = null,
        recent_date = null,
        promo_depen = null,
        cashback_issued = null,
        cashback_pending = null,
        cashback_laps_soon = null,
        cashback_laps_soon_date = null,
        cust_seg = null,
        preferred_meal_time = null,
        customer_type = null,
      } = payload[customerId];

      return {
        customerId,
        topOrderedItem: top_ordered_item,
        topOrderedCat: top_ordered_cat,
        latestOrderedCat: least_ordered_cat,
        abv,
        ATBV,
        overdueDays: overdue_days,
        avgGroupSize: avg_group_size,
        totalSpend: total_spend,
        totalVisits: total_visits,
        promoDependency: i18n.t(promo_depen),
        CBIssued: cashback_issued,
        CBPending: cashback_pending,
        CBLapsSoon: cashback_laps_soon,
        CBLapsSoonDate: cashback_laps_soon_date,
        CS: i18n.t(cust_seg),
        recentDate: recent_date,
        preferredMealTime: i18n.t(preferred_meal_time),
        customerType: i18n.t(customer_type),
      };
    });
  }
  return {};
};

// Format Customer Map data
const customerMapFilterDataFormatter = payload => {
  if (payload && Object.keys(payload).length) {
    const keyCombination = {
      abv: 'abv',
      ATBV: 'ATBV',
      avg_group_size: 'avgGroupSize',
      overdue_days: 'overdueDays',
      total_spend: 'totalSpend',
      total_visits: 'totalVisits',
      cashback_issued: 'CBIssued',
      cashback_pending: 'CBPending',
      cashback_laps_soon: 'CBLapsSoon',
      top_ordered_item: 'topOrderedItem',
      top_ordered_cat: 'topOrderedCat',
      least_ordered_cat: 'latestOrderedCat',
      promo_depen: 'promoDependency',
      cust_seg: 'CS',
      preferred_meal_time: 'preferredMealTime',
      customer_type: 'customerType',
    };

    const i18nKeys = new Set([
      'promo_depen',
      'cust_seg',
      'preferred_meal_time',
      'customer_type',
    ]);

    return Object.keys(payload).reduce((accu, filterKey) => {
      let selectable = payload[filterKey];

      if (i18nKeys.has(filterKey)) {
        selectable = selectable.map(el => i18n.t(el));
      }

      const key = Object.keys(keyCombination).reduce((accu, key) => {
        if (key == filterKey) return key;
        return accu;
      }, '');

      const selectKey = key && keyCombination[key] ? keyCombination[key] : '';

      return {
        ...accu,
        [selectKey]: selectable,
      };
    }, {});
  }
  return {};
};

export { customerMapDataFormatter, customerMapFilterDataFormatter };
