/*
 *
 * chain info page constants
 *
 */

export const FETCH_ZONE_DETAILS_ACTION =
  'app/ChainInfo/FETCH_ZONE_DETAILS_ACTION';

export const FETCH_ZONE_DETAILS_SUCCESS_ACTION =
  'app/ChainInfo/FETCH_ZONE_DETAILS_SUCCESS_ACTION';

export const FETCH_ZONE_DETAILS_ERROR_ACTION =
  'app/ChainInfo/FETCH_ZONE_DETAILS_ERROR_ACTION';

export const CREATE_ZONE_ACTION = 'app/ChainInfo/CREATE_ZONE_ACTION';

export const CREATE_ZONE_SUCCESS_ACTION =
  'app/ChainInfo/CREATE_ZONE_SUCCESS_ACTION';

export const CREATE_ZONE_ERROR_ACTION =
  'app/ChainInfo/CREATE_ZONE_ERROR_ACTION';

export const EDIT_ZONE_ACTION = 'app/ChainInfo/EDIT_ZONE_ACTION';

export const EDIT_ZONE_SUCCESS_ACTION =
  'app/ChainInfo/EDIT_ZONE_SUCCESS_ACTION';

export const EDIT_ZONE_ERROR_ACTION = 'app/ChainInfo/EDIT_ZONE_ERROR_ACTION';

export const DELETE_ZONE_ACTION = 'app/ChainInfo/DELETE_ZONE_ACTION';

export const DELETE_ZONE_SUCCESS_ACTION =
  'app/ChainInfo/DELETE_ZONE_SUCCESS_ACTION';

export const DELETE_ZONE_ERROR_ACTION =
  'app/ChainInfo/DELETE_ZONE_ERROR_ACTION';

export const FETCH_LOCATIONS_ACTION = 'app/ChainInfo/FETCH_LOCATIONS_ACTION';

export const FETCH_LOCATIONS_SUCCESS_ACTION =
  'app/ChainInfo/FETCH_LOCATIONS_SUCCESS_ACTION';

export const FETCH_LOCATIONS_ERROR_ACTION =
  'app/ChainInfo/FETCH_LOCATIONS_ERROR_ACTION';
