/*
 *
 * chain info reducer
 *
 */

import * as CONST from './constants';

export const initialState = {
  zones: {
    loading: false,
    error: null,
    payload: [],
  },
  locations: {
    loading: false,
    error: null,
    payload: [],
  },
  createZone: {
    loading: false,
    error: null,
    success: false,
  },
  editZone: {
    loading: false,
    error: null,
    success: false,
  },
  deleteZone: {
    loading: false,
    error: null,
    success: false,
    list: [],
  },
};

function chainInfoReducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    case CONST.FETCH_ZONE_DETAILS_ACTION:
      return {
        ...state,
        zones: {
          error: null,
          loading: true,
          payload: state.zones.payload,
        },
      };
    case CONST.FETCH_ZONE_DETAILS_SUCCESS_ACTION:
      return {
        ...state,
        zones: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case CONST.FETCH_ZONE_DETAILS_ERROR_ACTION:
      return {
        ...state,
        zones: {
          error: error,
          loading: false,
          payload: [],
        },
      };
    case CONST.CREATE_ZONE_ACTION:
      return {
        ...state,
        createZone: {
          error: null,
          loading: true,
          success: false,
        },
      };
    case CONST.CREATE_ZONE_SUCCESS_ACTION:
      return {
        ...state,
        createZone: {
          error: null,
          loading: false,
          success: true,
        },
        zones: {
          ...state.zones,
          payload: [...state.zones.payload, payload]
        }
      };
    case CONST.CREATE_ZONE_ERROR_ACTION:
      return {
        ...state,
        createZone: {
          error: payload,
          loading: false,
          success: false,
        },
      };
    case CONST.EDIT_ZONE_ACTION:
      return {
        ...state,
        editZone: {
          error: null,
          loading: true,
          success: false,
        },
      };
    case CONST.EDIT_ZONE_SUCCESS_ACTION:
      return {
        ...state,
        editZone: {
          error: null,
          loading: false,
          success: true,
        },
        zones: {
          ...state.zones,
          payload: state.zones.payload.map(el => {
            if (el.id === payload.id)
              return Object.assign({}, payload.data);
            return el;
          })
        }
      };
    case CONST.EDIT_ZONE_ERROR_ACTION:
      return {
        ...state,
        editZone: {
          error: payload,
          loading: false,
          success: false,
        },
      };
    case CONST.DELETE_ZONE_ACTION:
      return {
        ...state,
        deleteZone: {
          error: null,
          loading: true,
          success: false,
          list: [...state.deleteZone.list, payload],
        },
      };
    case CONST.DELETE_ZONE_SUCCESS_ACTION:
      return {
        ...state,
        deleteZone: {
          error: null,
          loading: false,
          success: true,
          list: [...state.deleteZone.list.filter(id => id !== payload)],
        },
        zones: {
          ...state.zones,
          payload: [...state.zones.payload.filter(obj => obj.id !== payload)],
        },
      };
    case CONST.DELETE_ZONE_ERROR_ACTION:
      return {
        ...state,
        deleteZone: {
          error: payload,
          loading: false,
          success: false,
          list: [...state.deleteZone.list.filter(id => id !== payload)],
        },
      };
    case CONST.FETCH_LOCATIONS_ACTION:
      return {
        ...state,
        locations: {
          error: null,
          loading: true,
          payload: state.locations.payload,
        },
      };
    case CONST.FETCH_LOCATIONS_SUCCESS_ACTION:
      return {
        ...state,
        locations: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case CONST.FETCH_LOCATIONS_ERROR_ACTION:
      return {
        ...state,
        locations: {
          error: error,
          loading: false,
          payload: [],
        },
      };
    default:
      return state;
  }
}

export default chainInfoReducer;
