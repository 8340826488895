// Import Libs
import { takeLatest, call, put } from 'redux-saga/effects';

// Import services
import * as api from './services';

// Import transformers
import * as fm from './formatter';
import * as cn from './constants';
import * as a from './actions';

import scorecardDataTransformer from 'transformers/scorecardDataTransformer';

// Async fetch
// function* getCancelledOrderScoresFn({ payload, isChain }) {
//   try {
//     // Get the response from API call
//     const { data: responseData, status } = yield call(
//       api.getCancelledOrdersScores,
//       payload,
//       isChain,
//     );
//     if (status === 206) {
//       const formattedData = scorecardDataTransformer({}, 'cancelledOrders');
//       yield put(a.getCancelledOrderScoreSuccess(formattedData));
//     } else if (status === 200) {
//       const { error, premium_lock } = responseData;
//       if (error) {
//         yield put(a.getCancelledOrderScoreError(error));
//       } else if (premium_lock === false) {
//         yield put(a.getCancelledOrderScoreError('locked'));
//       } else {
//         const formattedData = scorecardDataTransformer(
//           responseData,
//           'cancelledOrders',
//         );
//         // Feed the data to reducer
//         yield put(a.getCancelledOrderScoreSuccess(formattedData));
//       }
//     }
//   } catch (e) {
//     yield put(a.getCancelledOrderScoreError('Something went wrong'));
//   }
// }

// function* getCancelledOrderBreakupFn({ payload, isChain }) {
//   try {
//     // Get the response from API call
//     const { data: responseData, status } = yield call(
//       api.getCancelledOrdersBreakup,
//       payload,
//       isChain,
//     );

//     if (status === 200 || (status === 206 && responseData)) {
//       const { error, premium_lock } = responseData;

//       if (error) {
//         yield put(a.getCancelledOrderBreakupError(error));
//       } else if (premium_lock === false) {
//         yield put(a.getCancelledOrderBreakupError('locked'));
//       } else {
//         const formattedData = fm.formatCancelledOrdersBreakup(responseData);
//         // Feed the data to reducer
//         yield put(a.getCancelledOrderBreakupSuccess(formattedData));
//       }
//     }
//   } catch (e) {
//     yield put(a.getCancelledOrderBreakupError('Something went wrong'));
//   }
// }

// function* getCancelledOrderReasonsFn({ payload, isChain }) {
//   try {
//     // Get the response from API call
//     const { data: responseData, status } = yield call(
//       api.getCancelledOrdersReasons,
//       payload,
//       isChain,
//     );

//     if (status === 200 || (status === 206 && responseData)) {
//       const { error, premium_lock } = responseData;

//       if (error) {
//         yield put(a.getCancelledOrderReasonsError(error));
//       } else if (premium_lock === false) {
//         yield put(a.getCancelledOrderReasonsError('locked'));
//       } else {
//         const formattedData = fm.formatCancelledReasonsTable(responseData);
//         // Feed the data to reducer
//         yield put(a.getCancelledOrderReasonsSuccess(formattedData));
//       }
//     }
//   } catch (e) {
//     yield put(a.getCancelledOrderReasonsError('Something went wrong'));
//   }
// }

// function* getCancelledOrderTableFn({ payload, isChain }) {
//   try {
//     // Get the response from API call
//     const { data: responseData, status } = yield call(
//       api.getCancelledOrdersTable,
//       payload,
//       isChain,
//     );

//     if (status === 200 || (status === 206 && responseData)) {
//       const { error, premium_lock } = responseData;

//       if (error) {
//         yield put(a.getCancelledOrderTableError(error));
//       } else if (premium_lock === false) {
//         yield put(a.getCancelledOrderTableError('locked'));
//       } else {
//         const formattedData = fm.formatCancelledOrdersTable(responseData);
//         // Feed the data to reducer
//         yield put(a.getCancelledOrderTableSuccess(formattedData));
//       }
//     }
//   } catch (e) {
//     yield put(a.getCancelledOrderTableError('Something went wrong'));
//   }
// }

// // watcher Saga generator function
// export default function* watchCancelledOrderContainer() {
//   yield takeLatest(cn.CANCELLED_ORDER_SCORES_INIT, getCancelledOrderScoresFn);
//   yield takeLatest(cn.CANCELLED_ORDER_BREAKUP_INIT, getCancelledOrderBreakupFn);
//   yield takeLatest(cn.CANCELLED_ORDER_REASONS_INIT, getCancelledOrderReasonsFn);
//   yield takeLatest(cn.CANCELLED_ORDER_TABLE_INIT, getCancelledOrderTableFn);
// }

function* fetchCancelledOrderDataMerge({ payload, isChain }) {
  try {
        // Get the response from API call
        const { data: responseData, status } = yield call(
          api.getCancelledOrdersMerge,
          payload,
          isChain,
        );
        if (status === 200 || (status === 206 && responseData)) {
          const { error, premium_lock } = responseData;

          if (error) {
            yield put(a.getCancelledOrderMergeDataError(error));
          } else if (premium_lock === false) {
            yield put(a.getCancelledOrderMergeDataError('locked'));
          } 
          else {
                    const formattedData = scorecardDataTransformer(responseData.score,'cancelledOrders',);
                    yield put(a.getCancelledOrderScoreSuccess(formattedData));

                    const formattedData1 = fm.formatCancelledReasonsTable(responseData.cancellation_reason);
                    yield put(a.getCancelledOrderReasonsSuccess(formattedData1));

                    const formattedData2 = fm.formatCancelledOrdersTable(responseData.table);
                    yield put(a.getCancelledOrderTableSuccess(formattedData2));
                    
                    const formattedData3 = fm.formatCancelledOrdersBreakup(responseData.breakup);
                    yield put(a.getCancelledOrderBreakupSuccess(formattedData3));
                  }   
        }
      } catch (e) {
        yield put(a.getCancelledOrderMergeDataError('Something went wrong'));
      }
}
export default function* watchCancelledOrderContainer() {
  yield takeLatest(cn.CANCELLED_ORDER_MERGE_DATA_INIT, fetchCancelledOrderDataMerge);
}