import * as api from 'constants/api';
import fetch from 'helpers/Fetch';

const reqFormat = (url, data) =>
  fetch({
    url,
    data,
  });

const getScores = payload => reqFormat(api.URL_DER_VOID_SCORES, payload);
const getBreakup = payload => reqFormat(api.URL_DER_VOID_BREAKUP, payload);
const getTrend = payload => reqFormat(api.URL_DER_VOID_TREND, payload);
const getTable = payload => reqFormat(api.URL_DER_VOID_TABLE, payload);

export { getScores, getBreakup, getTrend, getTable };
