// Import Libs
import toCamalCase from 'helpers/toCamalCase';

// Import parsers
import { parseListIntoString, parseObjIntoList } from 'helpers/parsers';

// Format Top Ordered Table Data
const topOrderedTableDataFormatter = payload => {
  if (payload) {
    // Destructure the payload
    const {
      overall_item = {},
      overall_top_cat = {},
      overall_least_cat = {},
      price_sen_item = {},
      price_sen_top_cat = {},
      price_sen_least_cat = {},
      star_item = {},
      star_top_cat = {},
      star_least_cat = {},
      trappers_item = {},
      trappers_top_cat = {},
      trappers_least_cat = {},
      pot_freq_item = {},
      pot_freq_top_cat = {},
      pot_freq_cat = {},
    } = payload;

    // Format the payload
    const formatedPayload = {
      overallSegments: {
        topOrderedCat: parseListIntoString(parseObjIntoList(overall_top_cat)),
        leastOrderedCat: parseListIntoString(
          parseObjIntoList(overall_least_cat),
        ),
        topOrderedItem: parseListIntoString(parseObjIntoList(overall_item)),
      },
      priceSensitive: {
        topOrderedCat: parseListIntoString(parseObjIntoList(price_sen_top_cat)),
        leastOrderedCat: parseListIntoString(
          parseObjIntoList(price_sen_least_cat),
        ),
        topOrderedItem: parseListIntoString(parseObjIntoList(price_sen_item)),
      },
      cashTrappers: {
        topOrderedCat: parseListIntoString(parseObjIntoList(trappers_top_cat)),
        leastOrderedCat: parseListIntoString(
          parseObjIntoList(trappers_least_cat),
        ),
        topOrderedItem: parseListIntoString(parseObjIntoList(trappers_item)),
      },
      starCustomers: {
        topOrderedCat: parseListIntoString(parseObjIntoList(star_top_cat)),
        leastOrderedCat: parseListIntoString(parseObjIntoList(star_least_cat)),
        topOrderedItem: parseListIntoString(parseObjIntoList(star_item)),
      },
      potentialFrequenters: {
        topOrderedCat: parseListIntoString(parseObjIntoList(pot_freq_top_cat)),
        leastOrderedCat: parseListIntoString(parseObjIntoList(pot_freq_cat)),
        topOrderedItem: parseListIntoString(parseObjIntoList(pot_freq_item)),
      },
    };

    return formatedPayload;
  }
};

// Format Customer Map data
const customerMapDataFormatter = payload => {
  if (payload) {
    return Object.keys(payload).map((customerId, i) => {
      const {
        ATBV = null,
        abv = null,
        avg_group_size = null,
        ltv = null,
        overdue_days = null,
        total_spend = null,
        total_visits = null,
      } = payload[customerId];

      return {
        customerId,
        abv,
        atbv: ATBV,
        ltv,
        overdueDays: overdue_days,
        avgGroupSize: avg_group_size,
        totalSpend: total_spend,
        totalVisits: total_visits,
      };
    });
  }
  return {};
};

// Format Customer Map data
const customerMapFilterDataFormatter = payload => {
  if (payload) {
    return Object.keys(payload).reduce((accu, filterKey) => {
      const selectable = payload[filterKey];
      const key = filterKey !== 'ATBV' ? toCamalCase(filterKey) : 'atbv';

      return {
        ...accu,
        [key]: selectable,
      };
    }, {});
  }
  return {};
};
// Format Customer Map slider data
export {
  topOrderedTableDataFormatter,
  customerMapDataFormatter,
  customerMapFilterDataFormatter,
};
