// Import Libs
import { takeLatest, call, put } from 'redux-saga/effects';

// Import services
import * as api from './services';

// Import transformers
import * as fm from './formatter';
import * as cn from './constants';
import * as a from './actions';

import scorecardDataTransformer from 'transformers/scorecardDataTransformer';

// Async fetch
function* fetchScores({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(api.getScores, payload);

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getScoresError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getScoresError('locked'));
      } else {
        const formattedData = fm.scoresFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getScoresSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getScoresError('Something went wrong'));
  }
}

// function* fetchBreakup({ payload }) {
//   try {
//     // Get the response from API call
//     const { data: responseData, status } = yield call(api.getBreakup, payload);

//     if (status === 200 || (status === 206 && responseData)) {
//       const { error_message } = responseData;

//       if (error_message) {
//         yield put(a.getBreakupError(error_message));
//       } else {
//         const formattedData = fm.breakupFormatter(responseData);
//         // Feed the data to reducer
//         yield put(a.getBreakupSuccess(formattedData));
//       }
//     }
//   } catch (e) {
//     yield put(a.getBreakupError('Something went wrong'));
//   }
// }

function* fetchTrend({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(api.getTrend, payload);

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getTrendError(error_message));
      } else {
        const formattedData = fm.trendFormatter(
          responseData,
          payload.granularity,
        );
        // Feed the data to reducer
        yield put(a.getTrendSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getTrendError('Something went wrong'));
  }
}

function* fetchTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(api.getTable, payload);

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getTableError(error_message));
      } else {
        const formattedData = fm.tableFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getTableSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getTableError('Something went wrong'));
  }
}

// watcher Saga generator function
export default function* watchSmartConnectContainer() {
  yield takeLatest(cn.SCORE_INIT, fetchScores);
  // yield takeLatest(cn.BREAKUP_INIT, fetchBreakup);
  yield takeLatest(cn.TREND_INIT, fetchTrend);
  yield takeLatest(cn.TABLE_INIT, fetchTable);
}
