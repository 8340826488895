// Setting preloaded or initial state

const preloadedState = {
  visible: false,
  alreadyOpened: false,
  isTabClicked: false,
};

const feedbackFormReducer = (state = preloadedState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_FEEDBACK_FORM':
      return {
        ...state,
        isTabClicked: payload.isTabClicked,
        alreadyOpened: payload.alreadyOpened,
        visible: payload.visible,
      };

    default:
      return state;
  }
};
export default feedbackFormReducer;
