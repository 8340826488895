/*
 *
 * Item-TrackerPage_Item constants
 *
 */

export const FETCH_TRACKER_METRICS_ACTION =
  'app/TrackerPage_Item/FETCH_TRACKER_METRICS_ACTION';

export const FETCH_TRACKER_METRICS_SUCCESS_ACTION =
  'app/TrackerPage_Item/FETCH_TRACKER_METRICS_SUCCESS_ACTION';

export const FETCH_TRACKER_METRICS_ERROR_ACTION =
  'app/TrackerPage_Item/FETCH_TRACKER_METRICS_ERROR_ACTION';

export const FETCH_TRACKER_CSUS_METRICS_ACTION =
  'app/TrackerPage_Item/FETCH_TRACKER_CSUS_METRICS_ACTION';

export const FETCH_TRACKER_CSUS_METRICS_SUCCESS_ACTION =
  'app/TrackerPage_Item/FETCH_TRACKER_CSUS_METRICS_SUCCESS_ACTION';

export const FETCH_TRACKER_CSUS_METRICS_ERROR_ACTION =
  'app/TrackerPage_Item/FETCH_TRACKER_CSUS_METRICS_ERROR_ACTION';

export const FETCH_TRACKER_ITEM_BY_TIME_ACTION =
  'app/TrackerPage/FETCH_TRACKER_ITEM_BY_TIME_ACTION';

export const FETCH_TRACKER_ITEM_BY_TIME_SUCCESS_ACTION =
  'app/TrackerPage/FETCH_TRACKER_ITEM_BY_TIME_SUCCESS_ACTION';

export const FETCH_TRACKER_ITEM_BY_TIME_ERROR_ACTION =
  'app/TrackerPage/FETCH_TRACKER_ITEM_BY_TIME_ERROR_ACTION';

export const FETCH_ITEM_SALES_ORDERS_TRACKER_ACTION =
  'app/TrackerPage/FETCH_ITEM_SALES_ORDERS_TRACKER_ACTION';

export const FETCH_ITEM_SALES_ORDERS_TRACKER_SUCCESS_ACTION =
  'app/TrackerPage/FETCH_ITEM_SALES_ORDERS_TRACKER_SUCCESS_ACTION';

export const FETCH_ITEM_SALES_ORDERS_TRACKER_ERROR_ACTION =
  'app/TrackerPage/FETCH_ITEM_SALES_ORDERS_TRACKER_ERROR_ACTION';
