// Import Libs
import { combineReducers } from 'redux';

// Import reducers
import aiEngineLoginVsNonLoginReducer from 'containers/CRM/AIEngineLoginVsNonLogin/reducer';
import aiEngineNewVsRepeatReducer from 'containers/CRM/AIEngineNewVsRepeat/reducer';
import merchantReducer from './merchantReducer';
import currencyReducer from './currencyReducer';
import chainReducer from './chainReducer';
import operationsPageReducer from 'containers/Operations/reducer';
import categoryPageReducer from 'containers/CategoryPage/reducer';
import itemPageReducer from 'containers/ItemPage/reducer';
import crossupSellPageReducer from 'containers/CrossSellUpSellPage/reducer';
import aiPromotionReducer from 'containers/CRM/AIPromotions/reducer';
import cashbackReducer from 'containers/CRM/Cashback/reducer';
import csGroupAnalysisReducer from 'containers/CRM/CSegmentationGroupAnalysis/reducer';
import csSegmentAnalysisReducer from 'containers/CRM/CSegmentationSegmentAnalysis/reducer';
import csCustomerAnalysisReducer from 'containers/CRM/CSegmentationCustomerAnalysis/reducer';
import downloadReducer from 'components/Layouts/SectionNavigation/reducer';
import trackerPage from 'containers/Tracker/reducer';
import trackerCategoryPage from 'containers/Tracker-CategoryMetrics/reducer';
import trackerSubCatPage from 'containers/Tracker-SubCatMetrics/reducer';
import trackerItemPage from 'containers/Tracker-ItemMetrics/reducer';
import outletInfoPage from 'containers/InfoPages/OutletInfo/reducer';
import chainInfoPage from 'containers/InfoPages/ChainInfo/reducer';
import commissionInfo from 'containers/InfoPages/Common/CommissionDetails/reducer';
import benchmarkingCommon from 'containers/Benchmarking/Common/reducer';
import benchmarkingOverview from 'containers/Benchmarking/EnterpriseOverview/reducer';
import benchmarkingStats from 'containers/Benchmarking/EnterpriseStatistics/reducer';
import positionMap from 'containers/Benchmarking/PositionMap/reducer';
import performanceAnalysis from 'containers/Benchmarking/PerformanceAnalysis/reducer';
import orderLevelAnalysis from 'containers/Benchmarking/OrderLevelAnalysis/reducer';
import timeBasedAnalysis from 'containers/Benchmarking/TimeBasedAnalysis/reducer';
import customerProfiling from 'containers/Benchmarking/CustomerProfiling/reducer';
import paymentReconciliation from 'containers/PaymentReconciliation/reducer';
import tipRemittance from 'containers/TipRemittance/reducer';
import masterMerchant from 'containers/SmartConnect/reducer';
import cancelledOrders from 'containers/SmartConnect/CancelledOrders/reducer';
import promotionUsage from 'containers/PromotionUsage/reducer';
import dayEndReport from 'containers/DayEndReporting/reducer';
import pos from 'containers/POS/reducer';
import voidOrders from 'containers/DayEndReporting/VoidOrders/reducer';
import lostRevenue from 'containers/LostRevenue/reducer';
import menuProfiling from 'containers/Benchmarking/MenuProfiling/reducer.js';
import feedbackFormReducer from './feedbackFormReducer';
import terminalAnalyticsReducer from 'containers/TerminalAnalytics/reducer';
import {
  compareDateRangeReducer,
  currentDateRangeReducer,
} from './dateRangeReducers';
import {
  terminalAnalyticsCurrentDateRangeReducer,
  terminalAnalyticsCompareDateRangeReducer,
} from './terminalanalyticsDateRangeReducers';
// Custom Reducers

const aiEnginePageReducer = combineReducers({
  loginVsNonLoginSection: aiEngineLoginVsNonLoginReducer,
  newVsRepeatSection: aiEngineNewVsRepeatReducer,
});

const customerSegmentationPageReducer = combineReducers({
  groupAnalysisSection: csGroupAnalysisReducer,
  segmentAnalysisSection: csSegmentAnalysisReducer,
  customerAnalysisSection: csCustomerAnalysisReducer,
});

const benchmarking = combineReducers({
  common: benchmarkingCommon,
  overview: benchmarkingOverview,
  overviewStats: benchmarkingStats,
  positionMap,
  performanceAnalysis,
  orderLevelAnalysis,
  timeBasedAnalysis,
  customerProfiling,
  menuProfiling,
});

const rootReducer = combineReducers({
  operationsPage: operationsPageReducer,
  categoryPage: categoryPageReducer,
  itemPage: itemPageReducer,
  crossSellUpSellPage: crossupSellPageReducer,
  aiEnginePage: aiEnginePageReducer,
  customerSegmentationPage: customerSegmentationPageReducer,
  aiPromotionPage: aiPromotionReducer,
  cashbackPage: cashbackReducer,
  trackerPage,
  trackerCategoryPage,
  trackerSubCatPage,
  trackerItemPage,
  outletInfoPage,
  chainInfoPage,
  commissionInfo,
  compareDateRange: compareDateRangeReducer,
  currentDateRange: currentDateRangeReducer,
  terminalAnalyticsCurrentDateRange: terminalAnalyticsCurrentDateRangeReducer,
  terminalAnalyticsCompareDateRange: terminalAnalyticsCompareDateRangeReducer,
  merchant: merchantReducer,
  currency: currencyReducer,
  chain: chainReducer,
  download: downloadReducer,
  feedbackForm: feedbackFormReducer,
  benchmarking,
  paymentReconciliation,
  tipRemittance,
  masterMerchant,
  cancelledOrders,
  promotionUsage,
  dayEndReport,
  pos,
  voidOrders,
  lostRevenue,
  terminalAnalytics: terminalAnalyticsReducer,
});

/**
 *
 * @param {object} state - The state of the application
 * @param {object} action - The action to be performed
 * @returns The new state of the application
 */
const appReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export default appReducer;
