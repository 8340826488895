import {
  SALES_COMP,
  ITEM_PERFORMANCE_CROSS_UP_SELL,
  PRICING_CROSS_UP_SELL,
  CROSS_UP_SALES_SCORE,
  CONTRIBUTION_CROSS_UP_SELL,
  SCORE_CARD,
} from 'constants/api';
import { fetchRequest } from 'helpers/actionsFormat';

export const fetchCrossSellUpSellSalesScore = payload =>
  fetchRequest(SCORE_CARD, payload);
export const fetchCrossSellUpSellContribution = payload =>
  fetchRequest(CONTRIBUTION_CROSS_UP_SELL, payload);
export const fetchSalesComp = payload => fetchRequest(SALES_COMP, payload);
export const fetchCrossUPSalesScore2 = payload =>
  fetchRequest(CROSS_UP_SALES_SCORE, payload);
export const fetchCrossUpSellPricing = payload =>
  fetchRequest(PRICING_CROSS_UP_SELL, payload);
export const fetchItemPerformanceCrossUpSell = payload =>
  fetchRequest(ITEM_PERFORMANCE_CROSS_UP_SELL, payload);
