// Import Libs
import { takeLatest, call, put } from 'redux-saga/effects';

// Import services
import * as api from './services';

// Import transformers
import * as fm from './formatter';
import * as cn from './constants';
import * as a from './actions';

import scorecardDataTransformer from 'transformers/scorecardDataTransformer';

function* fetchDropdowns({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getDropdowns,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getDropdownsListError(error_message));
      } else {
        const formattedData = fm.dropdownFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getDropdownsListSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getDropdownsListError('Something went wrong'));
  }
}

function* fetchScores({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(api.getScores, payload);

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getScoresError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getScoresError('locked'));
      } else {
        const formattedData = scorecardDataTransformer(
          responseData,
          'terminalAnalytics',
        );
        // Feed the data to reducer
        yield put(a.getScoresSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getScoresError('Something went wrong'));
  }
}

function* fetchSummaryTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getSummaryTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getSummaryTableError(error_message));
      } else {
        const formattedData = fm.summaryTableFormatter(
          responseData,
          payload.view_type,
        );

        // Feed the data to reducer
        yield put(a.getSummaryTableSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getSummaryTableError('Something went wrong'));
  }
}
function* fetchTransactionsOrdersTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getTransactionsOrdersTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getTransactionsOrdersError(error_message));
      } else {
        const formattedData = fm.transactionsOrdersTableFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getTransactionsOrdersSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getTransactionsOrdersError('Something went wrong'));
  }
}

// watcher Saga generator function
export default function* watchTerminalAnalyticsContainer() {
  yield takeLatest(cn.DROPDOWN_INIT, fetchDropdowns);
  yield takeLatest(cn.SCORE_INIT, fetchScores);
  yield takeLatest(cn.SUMMARY_TABLE_INIT, fetchSummaryTable);
  yield takeLatest(
    cn.TRANSACTIONS_ORDERS_TABLE_INIT,
    fetchTransactionsOrdersTable,
  );
}
