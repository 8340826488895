/*
 *
 * benchmarking time based analysis constants
 *
 */

export const FETCH_TIME_BASED_PERFORMANCE_ACTION =
  'app/Benchmarking/FETCH_TIME_BASED_PERFORMANCE_ACTION';

export const FETCH_TIME_BASED_PERFORMANCE_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_TIME_BASED_PERFORMANCE_SUCCESS_ACTION';

export const FETCH_TIME_BASED_PERFORMANCE_ERROR_ACTION =
  'app/Benchmarking/FETCH_TIME_BASED_PERFORMANCE_ERROR_ACTION';

export const FETCH_CAPACITY_UTILIZATION_ACTION =
  'app/Benchmarking/FETCH_CAPACITY_UTILIZATION_ACTION';

export const FETCH_CAPACITY_UTILIZATION_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_CAPACITY_UTILIZATION_SUCCESS_ACTION';

export const FETCH_CAPACITY_UTILIZATION_ERROR_ACTION =
  'app/Benchmarking/FETCH_CAPACITY_UTILIZATION_ERROR_ACTION';
