/*
 *
 * CrossSellUpSellPage reducer
 *
 */

import checkStateLoading from "../../helpers/checkStateLoading";
import {
  FETCH_SALES_COMPARISON,
  SALES_COMPARISON_LOADED,
  SALES_COMPARISON_FAILED,
  CROSS_SELL_UPSELL_CONTRIBUTION_LOADED,
  FETCH_ITEM_PERFORMANCE,
  ITEM_PERFORMANCE_LOADED,
  ITEM_PERFORMANCE_FAILED,
  CROSS_SELL_UPSELL_SALES_SCORE_LOADED,
  SALES_SCORE_LOADED,
  FETCH_SALES_SCORE,
  SALES_SCORE_FAILED,
  FETCH_CROSS_SELL_UPSELL_PRICING,
  CROSS_SELL_UPSELL_PRICING_LOADED,
  CROSS_SELL_UPSELL_PRICING_FAILED,
  FETCH_CROSS_SELL_UPSELL_CONTRIBUTION,
  CROSS_SELL_UPSELL_CONTRIBUTION_FAILED,
  FETCH_CROSS_SELL_UPSELL_SALES_SCORE,
  CROSS_SELL_UPSELL_SALES_SCORE_FAILED
} from "./constants";

const initialState = {
  crossSellUpSellContribution: {
    error: null,
    loading: false,
    payload: {}
  },
  crossSellUpSellSalesScore: {
    error: null,
    loading: false,
    payload: {
      crossImpact: {
        delta: 0,
        value: 0
      },
      crossSell: {
        delta: 0,
        value: 0
      },
      upsellImpact: {
        delta: 0,
        value: 0
      },
      upSell: {
        delta: 0,
        value: 0
      }
    }
  },
  salesComp: {
    error: null,
    loading: false,
    payload: {}
  },
  salesScore: {
    error: null,
    loading: false,
    payload: {
      adoption: {
        delta: 0,
        value: 0
      },
      sales: {
        delta: 0,
        value: 0
      }
    }
  },
  itemPerformance: {
    error: null,
    loading: false,
    payload: {}
  },
  crossSellUpSellPricing: {
    error: null,
    loading: false,
    payload: {}
  },
  isReady: false
};

function crossSellUpSellPageReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState = {};
  switch (type) {
    case FETCH_CROSS_SELL_UPSELL_SALES_SCORE:
        return {
          ...state,
          isReady: false,
          crossSellUpSellSalesScore: {
            error: null,
            loading: true,
            payload: {}
          }
        }
    case CROSS_SELL_UPSELL_SALES_SCORE_LOADED:
      newState = {
        ...state,
        crossSellUpSellSalesScore: {
          error: null,
          loading: false,
          payload: payload
        }
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState)
      };
    case CROSS_SELL_UPSELL_SALES_SCORE_FAILED:
        newState = {
          ...state,
          crossSellUpSellSalesScore: {
            error: error,
            loading: false,
            payload: {}
          }
        }
        return {
          ...newState,
          isReady: !checkStateLoading(newState)
        }
    case FETCH_CROSS_SELL_UPSELL_CONTRIBUTION:
        return {
          ...state,
          isReady: false,
          crossSellUpSellContribution: {
            error: null,
            loading: true,
            payload: {}
          }
        }
    case CROSS_SELL_UPSELL_CONTRIBUTION_LOADED:
        newState = {
          ...state,
          crossSellUpSellContribution: {
            error: null,
            loading: false,
            payload: payload
          }
        };
        return {
          ...newState,
          isReady: !checkStateLoading(newState)
        };
    case CROSS_SELL_UPSELL_CONTRIBUTION_FAILED:
        newState = {
          ...state,
          crossSellUpSellContribution: {
            error: error,
            loading: false,
            payload: {}
          }
        }
        return {
          ...newState,
          isReady: !checkStateLoading(newState)
        }
    case FETCH_SALES_COMPARISON:
        return {
          ...state,
          isReady: false,
          salesComp: {
            error: null,
            loading: true,
            payload: {}
          }
        }
    case SALES_COMPARISON_LOADED:
        newState = {
          ...state,
          salesComp: {
            error: null,
            loading: false,
            payload: payload
          }
        };
        return {
          ...newState,
          isReady: !checkStateLoading(newState)
        }
    case SALES_COMPARISON_FAILED:
        newState = {
          ...state,
          salesComp: {
            error: error,
            loading: false,
            payload: {}
          }
        }
        return {
          ...newState,
          isReady: !checkStateLoading(newState)
        }
    case FETCH_SALES_SCORE:
      return {
        ...state,
        isReady: false,
        salesScore: {
          error: null,
          loading: true,
          payload: {}
        }
      }
    case SALES_SCORE_LOADED:
      newState = {
        ...state,
        salesScore: {
          error: null,
          loading: false,
          payload: payload
        }
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState)
      }
    case SALES_SCORE_FAILED:
      newState = {
        ...state,
        salesScore: {
          error: error,
          loading: false,
          payload: {}
        }
      }
      return {
        ...newState,
        isReady: !checkStateLoading(newState)
      }
    case FETCH_CROSS_SELL_UPSELL_PRICING:
        return {
          ...state,
          isReady: false,
          crossSellUpSellPricing: {
            error: null,
            loading: true,
            payload: {}
          }
        }
    case CROSS_SELL_UPSELL_PRICING_LOADED:
        newState = {
          ...state,
          crossSellUpSellPricing: {
            error: null,
            loading: false,
            payload: payload
          }
        };
        return {
          ...newState,
          isReady: !checkStateLoading(newState)
        }
    case CROSS_SELL_UPSELL_PRICING_FAILED:
        newState = {
          ...state,
          crossSellUpSellPricing: {
            error: error,
            loading: false,
            payload: {}
          }
        }
        return {
          ...newState,
          isReady: !checkStateLoading(newState)
        }
    case FETCH_ITEM_PERFORMANCE:
      return {
        ...state,
        isReady: false,
        itemPerformance: {
          error: null,
          loading: true,
          payload: {}
        }
      }
    case ITEM_PERFORMANCE_LOADED:
      newState = {
        ...state,
        itemPerformance: {
          error: null,
          loading: false,
          payload: payload
        }
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState)
      }
    case ITEM_PERFORMANCE_FAILED:
      newState = {
        ...state,
        itemPerformance: {
          error: error,
          loading: false,
          payload: {}
        }
      }
      return {
        ...newState,
        isReady: !checkStateLoading(newState)
      }
    default:
      return state;
  }
}

export default crossSellUpSellPageReducer;
