/*
 *
 * benchmarking overview constants
 *
 */

export const FETCH_OVERVIEW_MAP_ACTION =
  'app/Benchmarking/FETCH_OVERVIEW_MAP_ACTION';

export const FETCH_OVERVIEW_MAP_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_OVERVIEW_MAP_SUCCESS_ACTION';

export const FETCH_OVERVIEW_MAP_ERROR_ACTION =
  'app/Benchmarking/FETCH_OVERVIEW_MAP_ERROR_ACTION';

export const FETCH_OVERVIEW_SCORE_CARD_ACTION =
  'app/Benchmarking/FETCH_OVERVIEW_SCORE_CARD_ACTION';

export const FETCH_OVERVIEW_SCORE_CARD_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_OVERVIEW_SCORE_CARD_SUCCESS_ACTION';

export const FETCH_OVERVIEW_SCORE_CARD_ERROR_ACTION =
  'app/Benchmarking/FETCH_OVERVIEW_SCORE_CARD_ERROR_ACTION';

export const FETCH_OVERVIEW_CONTRIBUTION_ACTION =
  'app/Benchmarking/FETCH_OVERVIEW_CONTRIBUTION_ACTION';

export const FETCH_OVERVIEW_CONTRIBUTION_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_OVERVIEW_CONTRIBUTION_SUCCESS_ACTION';

export const FETCH_OVERVIEW_CONTRIBUTION_ERROR_ACTION =
  'app/Benchmarking/FETCH_OVERVIEW_CONTRIBUTION_ERROR_ACTION';

export const FETCH_OVERVIEW_GROWTH_RATE_ACTION =
  'app/Benchmarking/FETCH_OVERVIEW_GROWTH_RATE_ACTION';

export const FETCH_OVERVIEW_GROWTH_RATE_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_OVERVIEW_GROWTH_RATE_SUCCESS_ACTION';

export const FETCH_OVERVIEW_GROWTH_RATE_ERROR_ACTION =
  'app/Benchmarking/FETCH_OVERVIEW_GROWTH_RATE_ERROR_ACTION';
