// Parse List into String helper
const parseListIntoString = (list) => {
	return list && list.length
		? list.reduce((accu, item) => {
				return item ? (accu ? `${accu}, ${item}` : `${item}`) : accu
		  }, "")
		: "-"
}

// Parse Object into List

const parseObjIntoList = (obj) => {
	return Object.keys(obj).length
		? Object.keys(obj).map((itemKey) => obj[itemKey])
		: []
}

export { parseListIntoString, parseObjIntoList }
