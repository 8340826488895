import { setupCache } from 'axios-cache-adapter';

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: {
    methods: ['put', 'patch', 'delete'],
    query: false,
    debug: true,
  },
});

export default cache;
