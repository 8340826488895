import * as api from 'constants/api';
import fetch from 'helpers/Fetch';

export const getDropdowns = payload =>
  fetch({
    url: api.URL_DER_DROPDOWNS,
    data: payload,
  });

export const getScores = payload =>
  fetch({
    url: api.URL_DER_SUMMARY_SCORES,
    data: payload,
  });

export const getSummaryTable = payload =>
  fetch({
    url: api.URL_DER_SUMMARY_TABLE,
    data: payload,
  });

export const getItemTable = payload =>
  fetch({
    url: api.URL_DER_ITEM_TABLE,
    data: payload,
  });

export const getCategoryTable = payload =>
  fetch({
    url: api.URL_DER_CATEGORY_TABLE,
    data: payload,
  });

export const getItemTransactionsTable = payload =>
  fetch({
    url: api.URL_DER_ITEM_TRANSACTIONS_TABLE,
    data: payload,
  });


export const getTransactionsTable = payload =>
  fetch({
    url: api.URL_DER_TRANSACTIONS_TABLE,
    data: payload,
  });
