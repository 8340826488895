/*
 *
 * CategoryContainer reducer
 *
 */

import checkStateLoading from 'helpers/checkStateLoading';
import {
  FETCH_CATEGORY_PLACEMENT,
  CATEGORY_PLACEMENT_LOADED,
  CATEGORY_PLACEMENT_ERROR,
  FETCH_CATEGORY_CONTRIBUTION,
  CATEGORY_CONTRIBUTION_LOADED,
  CATEGORY_CONTRIBUTION_ERROR,
  FETCH_CATEGORY_BY_TIME,
  CATEGORY_BY_TIME_LOADED,
  CATEGORY_BY_TIME_ERROR,
  FETCH_CATEGORY_PENETRATION,
  CATEGORY_PENETRATION_LOADED,
  CATEGORY_PENETRATION_FAILED,
  FETCH_MEAL_PENETRATION,
  MEAL_PENETRATION_LOADED,
  MEAL_PENETRATION_FAILED,
} from './constants';

export const initialState = {
  categoryPenetration: {
    error: null,
    loading: false,
    payload: {},
  },
  mealPenetration: {
    error: null,
    loading: false,
    payload: [],
  },
  categoryPlacement: {
    error: null,
    loading: false,
    payload: {},
  },
  categoryContribution: {
    error: null,
    loading: false,
    payload: [],
  },
  categoryByTime: {
    error: null,
    loading: false,
    payload: [],
  },
  isReady: false,
};

function categoryContainerReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case FETCH_CATEGORY_PENETRATION:
      return {
        ...state,
        isReady: false,
        categoryPenetration: {
          error: null,
          loading: true,
          payload: {},
        },
      };
    case CATEGORY_PENETRATION_LOADED:
      newState = {
        ...state,
        categoryPenetration: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CATEGORY_PENETRATION_FAILED:
      newState = {
        ...state,
        categoryPenetration: {
          error: error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case FETCH_MEAL_PENETRATION:
      return {
        ...state,
        isReady: false,
        mealPenetration: {
          error: null,
          loading: true,
          payload: {},
        },
      };
    case MEAL_PENETRATION_LOADED:
      newState = {
        ...state,
        mealPenetration: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case MEAL_PENETRATION_FAILED:
      newState = {
        ...state,
        mealPenetration: {
          error: error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case FETCH_CATEGORY_PLACEMENT:
      return {
        ...state,
        isReady: false,
        categoryPlacement: {
          error: null,
          loading: true,
          payload: {},
        },
      };
    case CATEGORY_PLACEMENT_LOADED:
      newState = {
        ...state,
        categoryPlacement: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CATEGORY_PLACEMENT_ERROR:
      newState = {
        ...state,
        categoryPlacement: {
          error: error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case FETCH_CATEGORY_CONTRIBUTION:
      return {
        ...state,
        isReady: false,
        categoryContribution: {
          error: null,
          loading: true,
          payload: initialState.categoryContribution.payload,
        },
      };
    case CATEGORY_CONTRIBUTION_LOADED:
      newState = {
        ...state,
        categoryContribution: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CATEGORY_CONTRIBUTION_ERROR:
      newState = {
        ...state,
        categoryContribution: {
          error: error,
          loading: false,
          payload: initialState.categoryContribution.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case FETCH_CATEGORY_BY_TIME:
      return {
        ...state,
        isReady: false,
        categoryByTime: {
          error: null,
          loading: true,
          payload: initialState.categoryByTime.payload,
        },
      };
    case CATEGORY_BY_TIME_LOADED:
      newState = {
        ...state,
        categoryByTime: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CATEGORY_BY_TIME_ERROR:
      newState = {
        ...state,
        categoryByTime: {
          error: error,
          loading: false,
          payload: initialState.categoryByTime.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    default:
      return state;
  }
}

export default categoryContainerReducer;
