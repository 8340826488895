// Import Libs
import { takeLatest, call, put } from "redux-saga/effects"

// Import services
import { fetchScorecardListData, fetchPromotionMatrixData } from "./services"

// Import transformers
import scorecardDataTransformer from "../../../transformers/scorecardDataTransformer"

import { promotionMatrixFormatter } from "./formatters"

export function* fetchScorecardListSaga(action) {
	const { payload } = action
	try {
		// Get the response from API call
		const response = yield call(fetchScorecardListData, payload)

		// Get the data/payload and status code from response
		const { data, status } = response

		// Get the formatted data to feed reducer
		const formattedData = scorecardDataTransformer(data, "aiPromotion")

		// Feed the data to reducer
		yield put({
			type: "FETCH_aiPromotion/scorecardGroup1_SUCCESS",
			payload: formattedData
		})
	} catch (error) {
		const { response } = error

		const { status, data } = response || {}

		console.log("Failed!", {
			status,
			data
		})
		// yield false
	}
}

export function* fetchPromotionMatrixSaga(action) {
	const { payload } = action
	try {
		// Get the response from API call
		const { data, status } = yield call(fetchPromotionMatrixData, payload)

		if (status === 200 && data) {
			const { error_message, premium_lock } = data

			if (error_message) {
				yield put({
					type: "FETCH_aiPromotion/promotionMatrix_FAIL",
					error: error_message
				})
			} else if (premium_lock === false) {
				yield put({
					type: "FETCH_aiPromotion/promotionMatrix_FAIL",
					error: "locked"
				})
			} else {
				const formattedData = promotionMatrixFormatter(data)

				// Feed the data to reducer
				yield put({
					type: "FETCH_aiPromotion/promotionMatrix_SUCCESS",
					payload: formattedData
				})
			}
		} else throw new Error("Unexpected behaviour")
	} catch (error) {
		console.log("Something went wrong", error)
		yield put({
			type: "FETCH_aiPromotion/promotionMatrix_FAIL",
			error: "Something went wrong"
		})
	}
}

// watcher Saga generator function
export default function* watchAIPromotionContainer() {
	yield takeLatest(
		"FETCH_aiPromotion/scorecardGroup1_INIT",
		fetchScorecardListSaga
	)

	yield takeLatest(
		"FETCH_aiPromotion/promotionMatrix_INIT",
		fetchPromotionMatrixSaga
	)
}
