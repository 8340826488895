import * as api from 'constants/api';
import fetch from 'helpers/Fetch';

export const getScores = payload =>
  fetch({
    url: api.URL_POS_SUMMARY_SCORES,
    data: payload,
  });

export const getSummaryTable = payload =>
  fetch({
    url: api.URL_POS_SUMMARY_TABLE,
    data: payload,
  });

export const getItemTable = payload =>
  fetch({
    url: api.URL_POS_ITEM_TABLE,
    data: payload,
  });

export const getCategoryTable = payload =>
  fetch({
    url: api.URL_POS_CATEGORY_TABLE,
    data: payload,
  });

export const getTransactionsItemsTable = payload =>
  fetch({
    url: api.URL_POS_TRANSACTIONS_ITEMS_TABLE,
    data: payload,
  });

export const getTransactionsOrdersTable = payload =>
  fetch({
    url: api.URL_POS_TRANSACTIONS_ORDERS_TABLE,
    data: payload,
  });
export const getDropdownLists = payload =>
  fetch({
    url: api.URL_POS_DROPDOWN_LIST,
    data: payload,
  });
