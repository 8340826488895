// Import Libs
import { takeLatest, call, put } from 'redux-saga/effects';

// Import services
import { fetchCustomerAnalysisTableData } from './services';

// Import transformers
import {
  customerMapDataFormatter,
  customerMapFilterDataFormatter,
} from './formatters';

// Async fetch Customer Map Saga
export function* fetchCustomerMapSaga(action) {
  const { payload } = action;
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      fetchCustomerAnalysisTableData,
      payload,
    );

    if (status === 200 || status === 206) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put({
          type: 'FETCH_customerSegmentation/ca/customerMap_FAIL',
          error: error_message,
        });
      } else if (premium_lock === false) {
        yield put({
          type: 'FETCH_customerSegmentation/ca/customerMap_FAIL',
          error: 'locked',
        });
      } else {
        const { slider_num, slider_name, data } = responseData;
        let filters = {};

        if (typeof slider_num === 'object') {
          filters = { ...filters, ...slider_num };
        }
        if (typeof slider_name === 'object') {
          filters = { ...filters, ...slider_name };
        }
        const formattedCustomerMap = customerMapDataFormatter(data);
        const formattedFilterData = customerMapFilterDataFormatter(filters);

        // Feed the data to reducer
        yield put({
          type: 'FETCH_customerSegmentation/ca/customerMap_SUCCESS',
          payload: formattedCustomerMap,
        });

        // Feed the data to reducer
        yield put({
          type: 'FETCH_customerSegmentation/ca/customerMapFilter_SUCCESS',
          payload: formattedFilterData,
        });
      }
    } else throw new Error('Something went wrong');
  } catch (error) {
    console.log('Something went wrong', error);
    yield put({
      type: 'FETCH_customerSegmentation/ca/customerMap_FAIL',
      error: 'Something went wrong',
    });
  }
}

// watcher Saga generator function
export default function* watchCSCustomerAnalysisContainer() {
  yield takeLatest(
    'FETCH_customerSegmentation/ca/customerMap_INIT',
    fetchCustomerMapSaga,
  );
}
