/*
 *
 * Category-TrackerPage constants
 *
 */

export const FETCH_TRACKER_METRICS_ACTION =
  'app/TrackerPage_SubCat/FETCH_TRACKER_METRICS_ACTION';

export const FETCH_TRACKER_METRICS_SUCCESS_ACTION =
  'app/TrackerPage_SubCat/FETCH_TRACKER_METRICS_SUCCESS_ACTION';

export const FETCH_TRACKER_METRICS_ERROR_ACTION =
  'app/TrackerPage_SubCat/FETCH_TRACKER_METRICS_ERROR_ACTION';

export const FETCH_TRACKER_SUB_CAT_BY_TIME_ACTION =
  'app/TrackerPage_SubCat/FETCH_TRACKER_SUB_CAT_BY_TIME_ACTION';

export const FETCH_TRACKER_SUB_CAT_BY_TIME_SUCCESS_ACTION =
  'app/TrackerPage_SubCat/FETCH_TRACKER_SUB_CAT_BY_TIME_SUCCESS_ACTION';

export const FETCH_TRACKER_SUB_CAT_BY_TIME_ERROR_ACTION =
  'app/TrackerPage_SubCat/FETCH_TRACKER_SUB_CAT_BY_TIME_ERROR_ACTION';

export const FETCH_SUB_CAT_SALES_ORDERS_TRACKER_ACTION =
  'app/TrackerPage_SubCat/FETCH_SUB_CAT_SALES_ORDERS_TRACKER_ACTION';

export const FETCH_SUB_CAT_SALES_ORDERS_TRACKER_SUCCESS_ACTION =
  'app/TrackerPage_SubCat/FETCH_SUB_CAT_SALES_ORDERS_TRACKER_SUCCESS_ACTION';

export const FETCH_SUB_CAT_SALES_ORDERS_TRACKER_ERROR_ACTION =
  'app/TrackerPage_SubCat/FETCH_SUB_CAT_SALES_ORDERS_TRACKER_ERROR_ACTION';
