import * as C from './constants';
import { successAction } from 'helpers/actionsFormat';

export const setCurrency = payload => successAction(C.SET_CURRENCY, payload);
export const setCountries = payload => successAction(C.SET_COUNTRIES, payload);
export const setSelectedCountries = payload =>
  successAction(C.SET_SELECTED_COUNTRY, payload);
export const setMenuModeList = payload =>
  successAction(C.SET_MENU_MODE_LIST, payload);
export const setSelectedMenuMode = payload =>
  successAction(C.SET_SELECTED_MENU_MODE, payload);
export const setChainDetails = payload =>
  successAction(C.SET_CHAIN_DETAILS, payload);
export const setMerchantList = payload =>
  successAction(C.SET_MERCHANT_LIST, payload);
export const setActiveMerchantList = payload =>
  successAction(C.SET_ACTIVE_MERCHANT_LIST, payload);
export const setIsChainMerchant = payload =>
  successAction(C.SET_IS_CHAIN_MERCHANT, payload);
export const enablePaymentRecon = payload =>
  successAction(C.ENABLE_PAYMENT_RECON, payload);
export const setIsEcms = payload => successAction(C.SET_IS_ECMS, payload);
export const enableMasterMerchant = payload =>
  successAction(C.ENABLE_MASTER_MERCHANT, payload);
export const setIsCloudKiosk = payload =>
  successAction(C.SET_IS_CLOUD_KIOSK, payload);
export const setLockedTabs = payload =>
  successAction(C.SET_LOCKED_TABS, payload);
