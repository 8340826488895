export const FETCH_OPERATIONS_SCORE_ACTION =
  'app/OperationsContainer/FETCH_OPERATIONS_SCORE_ACTION';
export const OPERATIONS_SCORE_LOADED_ACTION =
  'app/OperationsContainer/OPERATIONS_SCORE_LOADED_ACTION';
export const OPERATIONS_SCORE_FAILED_ACTION =
  'app/OperationsContainer/OPERATIONS_SCORE_FAILED_ACTION';
export const DAILY_SALES_ACTION = 'app/OperationsContainer/DAILY_SALES_ACTION';
export const DAILY_SALES_LOADED_ACTION =
  'app/OperationsContainer/DAILY_SALES_LOADED_ACTION';
export const DAILY_SALES_FAILED_ACTION =
  'app/OperationsContainer/DAILY_SALES_FAILED_ACTION';
export const TABLE_OCCUPANCY_ACTION =
  'app/OperationsContainer/TABLE_OCCUPANCY_ACTION';
export const TABLE_OCCUPANCY_LOADED_ACTION =
  'app/OperationsContainer/TABLE_OCCUPANCY_LOADED_ACTION';
export const TABLE_OCCUPANCY_FAILED_ACTION =
  'app/OperationsContainer/TABLE_OCCUPANCY_FAILED_ACTION';
export const GET_ORDERTYPES_ACTION =
  'app/OperationsContainer/GET_ORDERTYPES_ACTION';
export const GET_ORDERTYPES_LOADED_ACTION =
  'app/OperationsContainer/GET_ORDERTYPES_LOADED_ACTION';
export const GET_ORDERTYPES_FAILED_ACTION =
  'app/OperationsContainer/GET_ORDERTYPES_FAILED_ACTION';
export const SET_ORDERTYPE_ACTION =
  'app/OperationsContainer/SET_ORDERTYPE_ACTION';
