// ****************************************
// **** AI Engine Section Routes/Paths ****
// ****************************************

// ** New vs Repeat **
export const URL_SCORECARD_LIST_1 = '/nvsr_score1';
export const URL_SCORECARD_LIST_2 = '/lvsnl_score2';
export const URL_CUSTOMER_AND_RETENTION_BARCHARTS = '/lvsnl_visit';
export const URL_AI_SCORECARD_TABLE = '/nvsr_ai';
export const URL_PERFORMANCE_MATRIX = '/nvsr_per';
export const URL_BARCHART_CUSTOMER = '/nvsr_cust';
export const URL_BARCHART_SALES = '/nvsr_sales';
export const URL_BARCHART_ORDERS = '/nvsr_orders';
export const URL_BARCHART_ABV = '/nvsr_abv';
