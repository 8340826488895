/*
 *
 * benchmarking reducer
 *
 */

import * as CONST from './constants';

export const initialState = {
  zones: {
    loading: false,
    error: null,
    payload: [],
    selectedZones: [],
    selectedLocations: [],
    allZones: [],
    allLocations: [],
    isZoneMapped: true,
  },
};

const getSelectedZones = (prevSelected = [], allZones = []) => {
  const currentSelected = [];
  prevSelected.forEach(z => {
    if (allZones.includes(z)) {
      currentSelected.push(z);
    }
  });
  if (currentSelected.length && currentSelected.length !== allZones.length) {
    return currentSelected;
  }
  return [];
};

function benchmarkingCommonReducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    case CONST.FETCH_ZONE_LIST_ACTION:
      return {
        ...state,
        zones: {
          ...state.zones,
          error: null,
          loading: true,
        },
      };
    case CONST.FETCH_ZONE_LIST_SUCCESS_ACTION:
      return {
        ...state,
        zones: {
          error: null,
          loading: false,
          payload: payload.data,
          selectedZones: [
            ...getSelectedZones(
              state.zones.selectedZones,
              payload.selectedZones,
            ),
          ],
          selectedLocations: [
            ...getSelectedZones(
              state.zones.selectedLocations,
              payload.selectedLocations,
            ),
          ],
          allZones: payload.selectedZones,
          allLocations: payload.selectedLocations,
          isZoneMapped: payload.isZoneMapped,
        },
      };
    case CONST.FETCH_ZONE_LIST_ERROR_ACTION:
      return {
        ...state,
        zones: {
          error: error,
          loading: false,
          payload: [],
          selectedZones: [],
          selectedLocations: [],
          allZones: [],
          allLocations: [],
          isZoneMapped: state.isZoneMapped,
        },
      };
    case CONST.SET_SELECTED_LOCATIONS:
      return {
        ...state,
        zones: {
          ...state.zones,
          selectedLocations: payload,
        },
      };
    case CONST.SET_SELECTED_ZONES:
      return {
        ...state,
        zones: {
          ...state.zones,
          selectedZones: payload,
        },
      };
    default:
      return state;
  }
}

export default benchmarkingCommonReducer;
