import * as cn from './constants';

export const initialState = {
  imageLoading: false,
  pdfLoading: false,
  excelLoading: false,
  excelTransactionLoading: false,
  monthlyreportLoading:false,
};

function reducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case cn.DOWNLOAD_IMAGE_ACTION:
      return {
        ...state,
        imageLoading: true,
      };
    case cn.DOWNLOAD_IMAGE_COMPLETED:
      return {
        ...state,
        imageLoading: false,
      };
    case cn.DOWNLOAD_IMAGE_FAILED:
      return {
        ...state,
        imageLoading: false,
      };
    case cn.DOWNLOAD_EXCEL_ACTION:
      return {
        ...state,
        excelLoading: true,
      };
    case cn.DOWNLOAD_EXCEL_COMPLETED:
      return {
        ...state,
        excelLoading: false,
      };
    case cn.DOWNLOAD_EXCEL_FAILED:
      return {
        ...state,
        excelLoading: false,
      };
    case cn.DOWNLOAD_EXCEL_TRANSACTION_ACTION:
      return {
        ...state,
        excelTransactionLoading: true,
      };
    case cn.DOWNLOAD_EXCEL_TRANSACTION_COMPLETED:
      return {
        ...state,
        excelTransactionLoading: false,
      };
    case cn.DOWNLOAD_EXCEL_TRANSACTION_FAILED:
      return {
        ...state,
        excelTransactionLoading: false,
      };
    case cn.DOWNLOAD_PDF_ACTION:
      return {
        ...state,
        pdfLoading: true,
      };
    case cn.DOWNLOAD_PDF_COMPLETED:
      return {
        ...state,
        pdfLoading: false,
      };
    case cn.DOWNLOAD_PDF_FAILED:
      return {
        ...state,
        pdfLoading: false,
      };

      case cn.DOWNLOAD_MONTHLY_REPORT_ACTION:
        return {
          ...state,
          monthlyreportLoading: true,
        };
      case cn.DOWNLOAD_MONTHLY_REPORT_COMPLETED:
        return {
          ...state,
          monthlyreportLoading: false,
        };
      case cn.DOWNLOAD_MONTHLY_REPORT_FAILED:
        return {
          ...state,
          monthlyreportLoading: false,
        };
    default:
      return state;
  }
}

export default reducer;