import * as cn from './constants';

const initObject = (type, payload) => ({
  type,
  payload,
});

const successObject = (type, payload) => ({
  type,
  payload,
});

const errorObject = (type, error) => ({
  type,
  error,
});

const getContribution = payload => initObject(cn.CONTRIBUTION_INIT, payload);

const getContributionSuccess = payload =>
  successObject(cn.CONTRIBUTION_SUCCESS, payload);

const getContributionError = error => errorObject(cn.CONTRIBUTION_ERROR, error);

const getScores = payload => initObject(cn.SCORES_INIT, payload);

const getScoresSuccess = payload => successObject(cn.SCORES_SUCCESS, payload);

const getScoresError = error => errorObject(cn.SCORES_ERROR, error);

const getLiveDataScores = payload =>
  initObject(cn.LIVE_DATA_SCORES_INIT, payload);

const getLiveDataScoresSuccess = payload =>
  successObject(cn.LIVE_DATA_SCORES_SUCCESS, payload);

const getLiveDataScoresError = error =>
  errorObject(cn.LIVE_DATA_SCORES_ERROR, error);

const getTrend = payload => initObject(cn.TREND_INIT, payload);

const getTrendSuccess = payload => successObject(cn.TREND_SUCCESS, payload);

const getTrendError = error => errorObject(cn.TREND_ERROR, error);

const getCategoryItem = payload => initObject(cn.CATEGORY_ITEM_INIT, payload);

const getCategoryItemSuccess = payload =>
  successObject(cn.CATEGORY_ITEM_SUCCESS, payload);

const getCategoryItemError = payload =>
  errorObject(cn.CATEGORY_ITEM_ERROR, payload);

const getOutlewise = payload => initObject(cn.OUTLETWISE_INIT, payload);

const getOutlewiseSuccess = payload =>
  successObject(cn.OUTLETWISE_SUCCESS, payload);

const getOutlewiseError = payload => errorObject(cn.OUTLETWISE_ERROR, payload);

const getLiveDataOutletWise = payload =>
  initObject(cn.LIVE_DATA_OUTLETWISE_INIT, payload);

const getLiveDataOutletWiseSuccess = payload =>
  successObject(cn.LIVE_DATA_OUTLETWISE_SUCCESS, payload);

const getLiveDataOutletWiseError = payload =>
  errorObject(cn.LIVE_DATA_OUTLETWISE_ERROR, payload);

const getTableStockout = payload => initObject(cn.TABLE_STOCKOUT_INIT, payload);

const getTableStockoutSuccess = payload =>
  successObject(cn.TABLE_STOCKOUT_SUCCESS, payload);

const getTableStockoutError = error =>
  errorObject(cn.TABLE_STOCKOUT_ERROR, error);

const getLiveDataTableStockout = payload =>
  initObject(cn.LIVE_DATA_TABLE_STOCKOUT_INIT, payload);

const getLiveDataTableStockoutSuccess = payload =>
  successObject(cn.LIVE_DATA_TABLE_STOCKOUT_SUCCESS, payload);

const getLiveDataTableStockoutError = error =>
  errorObject(cn.LIVE_DATA_TABLE_STOCKOUT_ERROR, error);

const getTableOrderpause = payload =>
  initObject(cn.TABLE_ORDERPAUSE_INIT, payload);

const getTableOrderpauseSuccess = payload =>
  successObject(cn.TABLE_ORDERPAUSE_SUCCESS, payload);

const getTableOrderpauseError = error =>
  errorObject(cn.TABLE_ORDERPAUSE_ERROR, error);

const getLiveDataTableOrderpause = payload =>
  initObject(cn.LIVE_DATA_TABLE_ORDERPAUSE_INIT, payload);

const getLiveDataTableOrderpauseSuccess = payload =>
  successObject(cn.LIVE_DATA_TABLE_ORDERPAUSE_SUCCESS, payload);

const getLiveDataTableOrderpauseError = error =>
  errorObject(cn.LIVE_DATA_TABLE_ORDERPAUSE_ERROR, error);

export {
  getContribution,
  getContributionSuccess,
  getContributionError,
  getScores,
  getScoresSuccess,
  getScoresError,
  getLiveDataScores,
  getLiveDataScoresSuccess,
  getLiveDataScoresError,
  getTrend,
  getTrendSuccess,
  getTrendError,
  getTableStockout,
  getTableStockoutSuccess,
  getTableStockoutError,
  getLiveDataTableStockoutError,
  getLiveDataTableStockout,
  getLiveDataTableStockoutSuccess,
  getTableOrderpause,
  getTableOrderpauseSuccess,
  getTableOrderpauseError,
  getLiveDataTableOrderpause,
  getLiveDataTableOrderpauseSuccess,
  getLiveDataTableOrderpauseError,
  getCategoryItem,
  getCategoryItemSuccess,
  getCategoryItemError,
  getOutlewise,
  getOutlewiseSuccess,
  getOutlewiseError,
  getLiveDataOutletWise,
  getLiveDataOutletWiseSuccess,
  getLiveDataOutletWiseError,
};
