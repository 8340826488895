/*
 *
 * benchmarking enterprise stats reducer
 *
 */

import * as CONST from './constants';

export const initialState = {
  stats: {
    loading: false,
    error: null,
    payload: [],
  },
};

function benchmarkingEnterpriseStatsReducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    case CONST.FETCH_ENTERPRISE_STATS_ACTION:
      return {
        ...state,
        stats: {
          loading: true,
          error: null,
          payload: state.stats.payload,
        },
      };
    case CONST.FETCH_ENTERPRISE_STATS_SUCCESS_ACTION:
      return {
        ...state,
        stats: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case CONST.FETCH_ENTERPRISE_STATS_ERROR_ACTION:
      return {
        ...state,
        stats: {
          loading: false,
          error: error,
          payload: [],
        },
      };
    default:
      return state;
  }
}

export default benchmarkingEnterpriseStatsReducer;
