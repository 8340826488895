import * as api from 'constants/api';
import fetch from 'helpers/Fetch';

export const getScores = payload =>
  fetch({
    url: api.URL_MM_SCORES,
    data: payload,
    isMasterMerchant: true,
  });

export const getChainScores = payload =>
  fetch({
    url: api.URL_SC_CHAIN_SCORES,
    data: payload,
    isMasterMerchant: true,
  });

export const getDropdowns = payload =>
  fetch({
    url: api.URL_MM_DROPDOWN_LIST,
    data: payload,
    isMasterMerchant: true,
  });

export const getChainDropdowns = payload =>
  fetch({
    url: api.URL_SC_CHAIN_DROPDOWN_LIST,
    data: payload,
    isMasterMerchant: true,
  });

export const getPerformance = payload =>
  fetch({
    url: api.URL_MM_PERFORMANCE,
    data: payload,
    isMasterMerchant: true,
  });

export const getChainPerformance = payload =>
  fetch({
    url: api.URL_SC_CHAIN_PERFORMANCE,
    data: payload,
    isMasterMerchant: true,
  });

export const getZoneLocationPerformance = payload =>
  fetch({
    url: api.URL_SC_CHAIN_ZL_PERFORMANCE,
    data: payload,
    isMasterMerchant: true,
  });

export const getTrend = payload =>
  fetch({
    url: api.URL_MM_TREND,
    data: payload,
    isMasterMerchant: true,
  });

export const getChainTrend = payload =>
  fetch({
    url: api.URL_SC_CHAIN_TREND,
    data: payload,
    isMasterMerchant: true,
  });

export const getProfitPercentage = payload =>
  fetch({
    url: api.URL_MM_PROFIT_PERCENTAGE,
    data: payload,
    isMasterMerchant: true,
  });

export const getProfitTrend = payload =>
  fetch({
    url: api.URL_MM_PROFIT_TREND,
    data: payload,
    isMasterMerchant: true,
  });
