import { takeLatest, call, put } from 'redux-saga/effects';
import { getMerchantAndDate } from 'redux/rootSaga';
import scorecardDataTransformer from 'transformers/scorecardDataTransformer';
import {
  dailySalesDataFormatter,
  tableOccupancyDataFormatter,
} from './formatter';
import {
  DAILY_SALES_ACTION,
  TABLE_OCCUPANCY_ACTION,
  FETCH_OPERATIONS_SCORE_ACTION,
  GET_ORDERTYPES_ACTION,
} from './constants';
import {
  dailySalesDataLoadedAction,
  dailySalesDataFailedAction,
  tableOccupanyLoadedAction,
  tableOccupancyFailedAction,
  operationsScoreDataLoadedAction,
  operationsScoreFailedAction,
  orderTypesLoadedAction,
  orderTypesFailedAction,
} from './actions';
import {
  getTableOccupancy,
  getOperationsScore,
  getDailyOperations,
  getOrderTypes,
} from './services';

function* operationsScoreDataSaga() {
  const { merchantIdList, startDate, endDate, cStartDate, cEndDate } =
    yield getMerchantAndDate();
  try {
    const response = yield call(getOperationsScore, {
      merchant_id: merchantIdList,
      start_date: startDate,
      end_date: endDate,
      c_start_date: cStartDate,
      c_end_date: cEndDate,
      graph_type: 'O',
    });
    if (response.status == 206) yield put(operationsScoreDataLoadedAction([]));
    else {
      const formattedData = scorecardDataTransformer(
        response.data,
        'operations',
      );
      yield put(operationsScoreDataLoadedAction(formattedData));
    }
  } catch (e) {
    yield put(operationsScoreFailedAction('Something went wrong'));
  }
}

function* dailySalesDataSaga(action) {
  const { merchantIdList, startDate, endDate } = yield getMerchantAndDate();
  const payload = {
    merchant_id: merchantIdList,
    start_date: startDate,
    end_date: endDate,
    graph_type: action.payload,
  };

  try {
    const { data, status } = yield call(getDailyOperations, payload);

    if (status === 200 || (status === 206 && data)) {
      const { error_message } = data;
      if (error_message) {
        yield put(dailySalesDataFailedAction("No Data Found"));
      } else {
        const formattedData = dailySalesDataFormatter(data, action.payload);
        yield put(dailySalesDataLoadedAction(formattedData));
      }
    } else throw new Error('Something went wrong');
  } catch (error) {
    console.log('Something went wrong', error);
    yield put(dailySalesDataFailedAction('Something went wrong'));
  }
}

function* tableOccupancyDataSaga({ payload }) {
  try {
    const response = yield call(getTableOccupancy, payload);
    if (response.status == 206) yield put(tableOccupanyLoadedAction([]));
    else {
      const formattedData = tableOccupancyDataFormatter(response.data);
      yield put(tableOccupanyLoadedAction(formattedData));
    }
  } catch (e) {
    yield put(tableOccupancyFailedAction('Something went wrong'));
  }
}

function* orderTypesSaga() {
  const { merchantIdList, startDate, endDate } = yield getMerchantAndDate();
  try {
    const response = yield call(getOrderTypes, {
      merchant_id: merchantIdList,
      start_date: startDate,
      end_date: endDate,
    });
    if (response.status == 206) yield put(orderTypesLoadedAction(['All']));
    else {
      yield put(orderTypesLoadedAction(['All', ...response.data] || ['All']));
    }
  } catch (e) {
    yield put(orderTypesFailedAction('Something went wrong'));
  }
}

export default function* operationsContainerSaga() {
  yield takeLatest(FETCH_OPERATIONS_SCORE_ACTION, operationsScoreDataSaga);
  yield takeLatest(DAILY_SALES_ACTION, dailySalesDataSaga);
  yield takeLatest(TABLE_OCCUPANCY_ACTION, tableOccupancyDataSaga);
  yield takeLatest(GET_ORDERTYPES_ACTION, orderTypesSaga);
}
