// Format AI Score card Table
const aiScorecardTableDataFormatter = payload => {
  if (payload) {
    // Destructure the payload
    const {
      ai_revenue_l = {},
      ai_revenue_nl = {},
      l = {},
      abv_l = {},
      abv_nl = {},
      personalization_eff_l = {},
      avg_item_price_l = {},
      avg_item_price_nl = {},
      cust_adop_l = {},
      cust_adop_nl = {},
      recom_adop_l = {},
      recom_adop_nl = {},
      items_per_order_l = {},
      items_per_order_nl = {},
      avg_meal_comp_l = {},
      avg_meal_comp_nl = {},
    } = payload;

    // Formatted Payload
    const formatedPayload = {
      aiRevenue: {
        login: {
          value: ai_revenue_l.value,
          delta: ai_revenue_l.change,
        },
        nonLogin: {
          value: '-',
          delta: undefined,
        },
      },
      abv: {
        login: {
          value: abv_l.value,
          delta: abv_l.change,
        },
        nonLogin: {
          value: abv_nl.value,
          delta: abv_nl.change,
        },
      },
      personalEfficiency: {
        login: {
          value: personalization_eff_l.value,
          delta: personalization_eff_l.change,
        },
        nonLogin: {
          value: '-',
          delta: undefined,
        },
      },
      aip: {
        login: {
          value: avg_item_price_l.value,
          delta: avg_item_price_l.change,
        },
        nonLogin: {
          value: avg_item_price_nl.value,
          delta: avg_item_price_nl.change,
        },
      },
      customAdoption: {
        login: {
          value: cust_adop_l.value,
          delta: cust_adop_l.change,
        },
        nonLogin: {
          value: cust_adop_nl.value,
          delta: cust_adop_nl.change,
        },
      },
      avgItemPerOrder: {
        login: {
          value: items_per_order_l.value,
          delta: items_per_order_l.change,
        },
        nonLogin: {
          value: items_per_order_nl.value,
          delta: items_per_order_nl.change,
        },
      },
       // Disable Avg. Meal Completion
      // amc: {
      //   login: {
      //     value: avg_meal_comp_l.value,
      //     delta: avg_meal_comp_l.change,
      //   },
      //   nonLogin: {
      //     value: avg_meal_comp_nl.value,
      //     delta: avg_meal_comp_nl.change,
      //   },
      // },
      // DISABLED RECOM ADOPTION
      // recomendationAdoption: {
      //   login: {
      //     value: recom_adop_l.value,
      //     delta: recom_adop_l.change,
      //   },
      //   nonLogin: {
      //     value: recom_adop_nl.value,
      //     delta: recom_adop_nl.change,
      //   },
      // },
    };

    return formatedPayload;
  }
};

// Format customer visit bar graph data
const customerVisitDataFormatter = payload => {
  let formatedPayload = [];

  if (payload) {
    // Destructure the payload
    const { num_visits, no_of_cust, retention_percentage } = payload;

    formatedPayload = Object.keys(num_visits).map((visitKey, i) => {
      const visitDataIndex = Object.keys(num_visits).findIndex(
        (visitIndex, i) => {
          return num_visits[visitIndex] == num_visits[visitKey];
        },
      );

      if (typeof visitDataIndex === 'number') {
        return {
          customers:
            no_of_cust && no_of_cust[visitDataIndex]
              ? no_of_cust[visitDataIndex]
              : 0,
          visits: num_visits[visitKey],
          retention:
            retention_percentage && retention_percentage[visitDataIndex]
              ? retention_percentage[visitDataIndex]
              : undefined,
        };
      }
      return {
        customers: 0,
        visits: num_visits[visitKey],
        retention: 0,
      };
    });
  }
  return formatedPayload;
};

// Format performance matrix
const performanceMatrixFormatter = payload => {
  if (payload) {
    payload = Object.keys(payload).reduce((accu, item) => {
      return {
        ...accu,
        [item]: payload[item] && payload[item][0] ? payload[item][0] : 0,
      };
    }, {});

    // Destructure payload
    const {
      cust_l,
      cust_nl,
      total_cust,
      sales_l,
      sales_nl,
      total_sales,
      orders_l,
      orders_nl,
      total_orders,
      abv_l,
      abv_nl,
      abv_per_diff,
    } = payload;

    const formatedPayload = {
      customers: {
        login: cust_l,
        nonLogin: cust_nl,
        total: total_cust,
      },
      sales: {
        login: sales_l,
        nonLogin: sales_nl,
        total: total_sales,
      },
      orders: {
        login: orders_l,
        nonLogin: orders_nl,
        total: total_orders,
      },
      abv: {
        login: abv_l,
        nonLogin: abv_nl,
        difference: abv_per_diff,
      },
    };

    return formatedPayload;
  }
};

const performanceChartFormatter = (payload, attribute) => {
  if (payload) {
    return Object.keys(payload).map((customer, i) => {
      const {
        date,
        sales_l=0,
        sales_nl=0,
        perc_login=0,
        orders_l=0,
        orders_nl=0,
        abv_l=0,
        abv_nl=0,
        cust_l=0,
        cust_nl=0,
        abv_per_diff=0,
      } = payload[customer];

      return {
        date,
        login:
          attribute === 'customers'
            ? cust_l
            : attribute === 'sales'
            ? sales_l
            : attribute === 'abv'
            ? abv_l
            : attribute === 'orders'
            ? orders_l
            : null,
        nonLogin:
          attribute === 'customers'
            ? cust_nl
            : attribute === 'sales'
            ? sales_nl
            : attribute === 'abv'
            ? abv_nl
            : attribute === 'orders'
            ? orders_nl
            : null,
        loginPercent: attribute === 'abv' ? abv_per_diff : perc_login,
      };
    });
  }
  return [];
};

export {
  aiScorecardTableDataFormatter,
  customerVisitDataFormatter,
  performanceMatrixFormatter,
  performanceChartFormatter,
};
