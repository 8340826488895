export const trackerDetailsFormatter = payload => {
  let index = 0;
  if (payload) {
    const formattedPayload = Object.values(payload).map(obj => {
      const {
        id,
        restaurant_name,
        section_type,
        section_name,
        section_id,
        description,
        date_modified,
        end_date,
        impact,
        merchant_id,
      } = obj;
      index++;
      return {
        id,
        SrNo: index,
        slNo: id,
        restaurantName: restaurant_name,
        sectionType: section_type,
        sectionName: section_name,
        sectionId: section_id,
        description,
        start: date_modified,
        end: end_date,
        impact,
        merchantId1: merchant_id,
      };
    });
    return formattedPayload;
  } else return [];
};

export const trackerSectionListFormatter = payload => {
  if (payload) {
    const formattedPayload = Object.entries(payload).map(([value, label]) => {
      return {
        value,
        label,
      };
    });
    return formattedPayload;
  } else return [];
};

export const trackerSectionDetailsFormatter = payload => {
  const { name, id, sequence, view } = payload;
  return [
    { label: 'Name', value: name || '-' },
    { label: 'ID', value: id || '-' },
    { label: 'Sequence', value: sequence || '-' },
    // { label: 'View Type', value: view || '-' },
  ];
};
