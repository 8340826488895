export const dailySalesDataFormatter = (payload, viewBy) => {
  if (payload) {
    const formattedPayload = Object.keys(payload).map(objKey => {
      const obj = payload[objKey];
      return {
        date: obj.date,
        [viewBy]: obj[viewBy],
      };
    });
    return formattedPayload;
  } else return [];
};

export const tableOccupancyDataFormatter = payload => {
  if (payload) {
    const formattedPayload = Object.keys(payload).map(objKey => {
      const obj = payload[objKey];
      const {
        day_of_week,
        hour,
        Percentage_Occupancy,
        Percentage_Occepancy_change,
      } = obj;
        return {
          day: day_of_week,
          hour: hour,
          percentageOccupancy: Percentage_Occupancy || null,
          percentageChange: Percentage_Occepancy_change || null,
        };
    }).filter(obj => obj.percentageOccupancy && typeof obj.hour == "number");

        // remove duplicates
        const result = formattedPayload.filter(function(obj) {
          var key = obj.hour + '|' + obj.day;
          if (!this[key]) {
            this[key] = true;
            return true;
          }
        }, Object.create(null));
    return result;
  } else return [];
};
