// *************************************
// **** Group Analysis Routes/Paths ****
// *************************************

export const URL_SCORECARD_LIST = '/ga_score';
export const URL_BARCHART_CUSTOMER = '/ga_cust';
export const URL_BARCHART_SALES = '/ga_sales';
export const URL_BARCHART_ORDERS = '/ga_orders';
export const URL_BARCHART_ABV = '/ga_abv';
export const URL_BARCHART_GCPP = '/ga_gcpp';
export const URL_TOB_TABLE = '/ga_top_ordered';
export const URL_PGC_HEATMAP = '/ga_percent_group_contri';
