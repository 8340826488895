/*
 *
 * outlet info reducer
 *
 */

import * as CONST from './constants';

export const initialState = {
  outletInfo: {
    loading: true,
    error: null,
    payload: {
      area: null,
      tables: null,
      seats: null,
      cuisines: [],
      address: null,
      pincode: null,
    },
  },
  updateOutletInfo: {
    loading: false,
    error: null,
    success: false,
  },
  itemDetails: {
    loading: true,
    error: null,
    payload: {
      margin: null,
      list: [],
    },
  },
  updateItemDetails: {
    loading: false,
    error: null,
    success: false,
  },
  timeRanges: {
    loading: true,
    error: null,
    payload: [],
  },
  saveTimeRange: {
    loading: false,
    error: null,
    success: false,
  },
  deleteTimeRange: {
    loading: false,
    error: null,
    success: false,
  },
  cuisines: {
    error: null,
    loading: true,
    payload: [],
  },
  downloadExcel: {
    error: null,
    loading: true,
    payload: null,
  },
  uploadExcel: {
    error: null,
    loading: true,
    success: false,
  },
};

function operationsContainerReducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    case CONST.FETCH_OUTLET_INFO_ACTION:
      return {
        ...state,
        outletInfo: {
          error: null,
          loading: true,
          payload: state.outletInfo.payload,
        },
      };
    case CONST.FETCH_OUTLET_INFO_SUCCESS_ACTION:
      return {
        ...state,
        outletInfo: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case CONST.FETCH_OUTLET_INFO_ERROR_ACTION:
      return {
        ...state,
        outletInfo: {
          error: error,
          loading: false,
          payload: state.outletInfo.payload,
        },
      };
    case CONST.UPDATE_OUTLET_INFO_ACTION:
      return {
        ...state,
        updateOutletInfo: {
          error: null,
          loading: true,
          success: false,
        },
      };
    case CONST.UPDATE_OUTLET_INFO_SUCCESS_ACTION:
      return {
        ...state,
        updateOutletInfo: {
          error: null,
          loading: false,
          success: true,
        },
      };
    case CONST.UPDATE_OUTLET_INFO_ERROR_ACTION:
      return {
        ...state,
        updateOutletInfo: {
          error: error,
          loading: false,
          success: false,
        },
      };
    case CONST.FETCH_ITEM_DETAILS_ACTION:
      return {
        ...state,
        itemDetails: {
          error: null,
          loading: true,
          payload: state.itemDetails.payload,
        },
      };
    case CONST.FETCH_ITEM_DETAILS_SUCCESS_ACTION:
      return {
        ...state,
        itemDetails: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case CONST.FETCH_ITEM_DETAILS_ERROR_ACTION:
      return {
        ...state,
        itemDetails: {
          error: error,
          loading: false,
          payload: {
            margin: null,
            list: [],
          },
        },
      };
    case CONST.UPDATE_ITEM_DETAILS_ACTION:
      return {
        ...state,
        updateItemDetails: {
          error: null,
          loading: true,
          success: false,
        },
      };
    case CONST.UPDATE_ITEM_DETAILS_SUCCESS_ACTION:
      return {
        ...state,
        updateItemDetails: {
          error: null,
          loading: false,
          success: true,
        },
      };
    case CONST.UPDATE_ITEM_DETAILS_ERROR_ACTION:
      return {
        ...state,
        updateItemDetails: {
          error: error,
          loading: false,
          success: false,
        },
      };
    case CONST.FETCH_TIME_RANGES_ACTION:
      return {
        ...state,
        timeRanges: {
          error: null,
          loading: true,
          payload: state.timeRanges.payload,
        },
      };
    case CONST.FETCH_TIME_RANGES_SUCCESS_ACTION:
      return {
        ...state,
        timeRanges: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case CONST.FETCH_TIME_RANGES_ERROR_ACTION:
      return {
        ...state,
        timeRanges: {
          error: error,
          loading: false,
          payload: state.timeRanges.payload,
        },
      };
    case CONST.SAVE_TIME_RANGES_ACTION:
      return {
        ...state,
        saveTimeRange: {
          error: null,
          loading: true,
          success: false,
        },
      };
    case CONST.SAVE_TIME_RANGES_SUCCESS_ACTION:
      return {
        ...state,
        saveTimeRange: {
          error: null,
          loading: false,
          success: true,
        },
      };
    case CONST.SAVE_TIME_RANGES_ERROR_ACTION:
      return {
        ...state,
        saveTimeRange: {
          error: error,
          loading: false,
          success: false,
        },
      };
    case CONST.DELETE_TIME_RANGES_ACTION:
      return {
        ...state,
        deleteTimeRange: {
          error: null,
          loading: true,
          success: false,
        },
      };
    case CONST.DELETE_TIME_RANGES_SUCCESS_ACTION:
      return {
        ...state,
        deleteTimeRange: {
          error: null,
          loading: false,
          success: true,
        },
        timeRanges: {
          ...state.timeRanges,
          payload: state.timeRanges.payload.length
            ? state.timeRanges.payload.filter(tr => tr.name !== payload)
            : [],
        },
      };
    case CONST.DELETE_TIME_RANGES_ERROR_ACTION:
      return {
        ...state,
        deleteTimeRange: {
          error: error,
          loading: false,
          success: false,
        },
      };
    case CONST.FETCH_CUISINES_ACTION:
      return {
        ...state,
        cuisines: {
          error: null,
          loading: true,
          payload: state.cuisines.payload,
        },
      };
    case CONST.FETCH_CUISINES_SUCCESS_ACTION:
      return {
        ...state,
        cuisines: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case CONST.FETCH_CUISINES_ERROR_ACTION:
      return {
        ...state,
        cuisines: {
          error: error,
          loading: false,
          payload: state.cuisines.payload,
        },
      };
    case CONST.DOWNLOAD_EXCEL_FORMAT_ACTION:
      return {
        ...state,
        downloadExcel: {
          error: null,
          loading: true,
          payload: null,
        },
      };
    case CONST.DOWNLOAD_EXCEL_FORMAT_SUCCESS_ACTION:
      return {
        ...state,
        downloadExcel: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case CONST.DOWNLOAD_EXCEL_FORMAT_ERROR_ACTION:
      return {
        ...state,
        downloadExcel: {
          error: error,
          loading: false,
          payload: null,
        },
      };
    case CONST.UPLOAD_EXCEL_ACTION:
      return {
        ...state,
        uploadExcel: {
          error: null,
          loading: true,
          success: false,
        },
      };
    case CONST.UPLOAD_EXCEL_SUCCESS_ACTION:
      return {
        ...state,
        uploadExcel: {
          error: null,
          loading: false,
          success: true,
        },
      };
    case CONST.UPLOAD_EXCEL_ERROR_ACTION:
      return {
        ...state,
        uploadExcel: {
          error: error,
          loading: false,
          success: false,
        },
      };

    default:
      return state;
  }
}

export default operationsContainerReducer;
