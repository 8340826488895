/*
 *
 * sc page reducer
 *
 */

import checkStateLoading from 'helpers/checkStateLoading';
import * as cn from './constants';

export const initialState = {
  scores: {
    error: null,
    loading: false,
    payload: {
      salesInclPromo: {
        delta: 0,
        value: 0,
      },
      salesExclPromo: {
        delta: 0,
        value: 0,
      },
      abvInclPromo: {
        delta: 0,
        value: 0,
      },
      abvExclPromo: {
        delta: 0,
        value: 0,
      },
      merchantPromo: {
        delta: 0,
        value: 0,
      },
      orders: {
        delta: 0,
        value: 0,
      },
    },
  },
  dropdownList: {
    loading: false,
    error: null,
    payload: {
      orderTypes: [],
      partners: [],
    },
    orderTypes: [],
    partners: [],
  },
  performance: {
    loading: false,
    error: null,
    payload: {
      data: [],
      advData: [],
    },
  },
  trend: {
    loading: false,
    error: null,
    payload: [],
  },
  chainTrend: {
    loading: false,
    error: null,
    payload: {
      zl: [],
      op: []
    },
  },
  zlPerf: {
    loading: false,
    error: null,
    payload: [],
  },
  profitPercentage: {
    loading: false,
    error: null,
    payload: [],
  },
  profitTrend: {
    loading: false,
    error: null,
    payload: [],
  },
  salesType: 'excl_promo',
  isReady: false,
};

const compareAry = (a, b) => {
  if (!a) return false;
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e === v).length;
    const bCount = b.filter(e => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

const setSelectedDropdowns = (o, p, payload) => {
  let returnObj = {};
  if (o.length !== 0) {
    if (payload?.orderTypes?.length) {
      let intersection = o.filter(x => payload.orderTypes.includes(x));
      // const isSame = compareAry(o, intersection);
      returnObj.orderTypes = intersection;
    }
  }
  if (p.length !== 0) {
    if (payload?.partners?.length) {
      let intersection = p.filter(x => payload.partners.includes(x));
      // const isSame = compareAry(o, intersection);
      // console.log(isSame);
      returnObj.partners = intersection;
    }
  }
  return returnObj;
};

function masterMerchantReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case cn.PARTNER_ORDERTYPE_LIST_INIT:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          loading: true,
          error: null,
        },
        isReady: false,
      };
    case cn.PARTNER_ORDERTYPE_LIST_SUCCESS:
      newState = {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          loading: false,
          error: null,
          ...(payload.orderTypes.length !=
            state.dropdownList.payload.orderTypes.length ||
          payload.partners.length !=
            state.dropdownList.payload.partners.length ||
          !compareAry(
            state.dropdownList.payload.orderTypes,
            payload.orderTypes,
          ) ||
          !compareAry(state.dropdownList.payload.partners, payload.partners)
            ? { payload: payload }
            : {}),
          ...setSelectedDropdowns(
            state.dropdownList.orderTypes,
            state.dropdownList.partners,
            payload,
          ),
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case cn.PARTNER_ORDERTYPE_LIST_ERROR:
      newState = {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          loading: false,
          error: error,
          payload: initialState.dropdownList.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case cn.SMART_CONNECT_PERFORMANCE_INIT:
      return {
        ...state,
        performance: {
          loading: true,
          error: null,
          payload: initialState.performance.payload,
        },
        isReady: false,
      };
    case cn.SMART_CONNECT_PERFORMANCE_SUCCESS:
      newState = {
        ...state,
        performance: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case cn.SMART_CONNECT_PERFORMANCE_ERROR:
      newState = {
        ...state,
        performance: {
          loading: false,
          error: error,
          payload: initialState.performance.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case cn.SMART_CONNECT_TREND_INIT:
      return {
        ...state,
        trend: {
          loading: true,
          error: null,
          payload: initialState.trend.payload,
        },
        isReady: false,
      };
    case cn.SMART_CONNECT_TREND_SUCCESS:
      newState = {
        ...state,
        trend: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case cn.SMART_CONNECT_TREND_ERROR:
      newState = {
        ...state,
        trend: {
          loading: false,
          error: error,
          payload: initialState.trend.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case cn.SMART_CONNECT_CHAIN_TREND_INIT:
      return {
        ...state,
        chainTrend: {
          loading: true,
          error: null,
          payload: initialState.chainTrend.payload,
        },
        isReady: false,
      };
    case cn.SMART_CONNECT_CHAIN_TREND_SUCCESS:
      newState = {
        ...state,
        chainTrend: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case cn.SMART_CONNECT_CHAIN_TREND_ERROR:
      newState = {
        ...state,
        chainTrend: {
          loading: false,
          error: error,
          payload: initialState.chainTrend.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case cn.SMART_CONNECT_ZL_PERF_INIT:
      return {
        ...state,
        zlPerf: {
          loading: true,
          error: null,
          payload: initialState.zlPerf.payload,
        },
        isReady: false,
      };
    case cn.SMART_CONNECT_ZL_PERF_SUCCESS:
      newState = {
        ...state,
        zlPerf: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case cn.SMART_CONNECT_ZL_PERF_ERROR:
      newState = {
        ...state,
        zlPerf: {
          loading: false,
          error: error,
          payload: initialState.zlPerf.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case cn.PROFIT_TREND_INIT:
      return {
        ...state,
        profitTrend: {
          loading: true,
          error: null,
          payload: initialState.profitTrend.payload,
        },
        isReady: false,
      };
    case cn.PROFIT_TREND_SUCCESS:
      newState = {
        ...state,
        profitTrend: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case cn.PROFIT_TREND_ERROR:
      newState = {
        ...state,
        profitTrend: {
          loading: false,
          error: error,
          payload: initialState.profitTrend.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case cn.PROFIT_PERCENTAGE_INIT:
      return {
        ...state,
        profitPercentage: {
          loading: true,
          error: null,
          payload: initialState.profitPercentage.payload,
        },
        isReady: false,
      };
    case cn.PROFIT_PERCENTAGE_SUCCUESS:
      newState = {
        ...state,
        profitPercentage: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case cn.PROFIT_PERCENTAGE_ERROR:
      newState = {
        ...state,
        profitPercentage: {
          loading: false,
          error: error,
          payload: initialState.profitPercentage.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case cn.SMART_CONNECT_SCORE_INIT:
      return {
        ...state,
        scores: {
          loading: true,
          error: null,
          payload: initialState.scores.payload,
        },
        isReady: false,
      };
    case cn.SMART_CONNECT_SCORE_SUCCESS:
      newState = {
        ...state,
        scores: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case cn.SMART_CONNECT_SCORE_ERROR:
      newState = {
        ...state,
        scores: {
          loading: false,
          error: error,
          payload: initialState.scores.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case cn.SET_ORDERTYPES_ACTION:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          orderTypes: payload,
        },
      };
    case cn.SET_PARTNERS_ACTION:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          partners: payload,
        },
      };
    case cn.SET_SALES_TYPE:
      return {
        ...state,
        salesType: payload,
      };

    default:
      return state;
  }
}

export default masterMerchantReducer;
