export const FETCH_TIP_SCORES_ACTION =
  'app/TipRemittance/FETCH_TIP_SCORES_ACTION';

export const FETCH_TIP_SCORES_SUCCESS_ACTION =
  'app/TipRemittance/FETCH_TIP_SCORES_SUCCESS_ACTION';

export const FETCH_TIP_SCORES_ERROR_ACTION =
  'app/TipRemittance/FETCH_TIP_SCORES_ERROR_ACTION';

export const FETCH_TIP_PAYMENT_DETAILS_ACTION =
  'app/TipRemittance/FETCH_TIP_PAYMENT_DETAILS_ACTION';
export const FETCH_TIP_PAYMENT_DETAILS_SUCCESS_ACTION =
  'app/TipRemittance/FETCH_TIP_PAYMENT_DETAILS_SUCCESS_ACTION';
export const FETCH_TIP_PAYMENT_DETAILS_ERROR_ACTION =
  'app/TipRemittance/FETCH_TIP_PAYMENT_DETAILS_SUCCESS_ERROR_ACTION';

export const FETCH_TIP_DETAILS_BREAKUP_ACTION =
  'app/TipRemittance/FETCH_TIP_DETAILS_BREAKUP_ACTION';
export const FETCH_TIP_DETAILS_BREAKUP_SUCCESS_ACTION =
  'app/TipRemittance/FETCH_TIP_DETAILS_BREAKUP_SUCCESS_ACTION';
export const FETCH_TIP_DETAILS_BREAKUP_ERROR_ACTION =
  'app/TipRemittance/FETCH_TIP_DETAILS_BREAKUP_ERROR_ACTION';
