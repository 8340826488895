String.prototype.capitalize = function() {
	return this.charAt(0).toUpperCase() + this.slice(1)
}

// Format Score card List
const promotionMatrixFormatter = (payload) => {
	if (payload) {
		// Format the payload
		const formatedPayload = Object.keys(payload).map((segmentKey, i) => {
			const {
				name = segmentKey,
				color = "",
				cust = 0,
				promo_adop = 0,
				promo_budget = 0,
				promo_spend = 0,
				promo_affinity = 0,
				roi = 0,
				abv = 0,
				visit_no = 0,
				ltv = 0,
				meal_comp = 0,
				churn_proba = 0,
				period_weekend = "",
				period_weekday = ""
			} = payload[segmentKey]

			return {
				type: segmentKey,
				name: name.capitalize(),
				color,
				totalCustomers: cust,
				promoAdoption: promo_adop,
				promoBudget: promo_budget,
				promoSpendPercent: promo_spend,
				affinityScore: promo_affinity,
				roi,
				abv,
				visitFreq: visit_no,
				ltv,
				mealCompletion: meal_comp,
				churnProbability: churn_proba,
				peekWeekendTime: period_weekend,
				peekWeekdayTime: period_weekday
			}
		})

		return formatedPayload
	}
}

export { promotionMatrixFormatter }
