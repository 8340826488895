/*
 *
 * benchmarking overview reducer
 *
 */

import * as CONST from './constants';
import checkStateLoading from 'helpers/checkStateLoading';

export const initialState = {
  overviewMap: {
    loading: false,
    error: null,
    payload: {
      legends: [0, 0],
      zones: [],
      locations: [],
    },
  },
  overviewScore: {
    error: null,
    loading: false,
    payload: {
      abv: {
        delta: 0,
        value: 0,
      },
      sales: {
        delta: 0,
        value: 0,
      },
      orders: {
        delta: 0,
        value: 0,
      },
      customers: {
        delta: 0,
        value: 0,
      },
    },
  },
  contribution: {
    error: null,
    loading: false,
    payload: {
      zone: [],
      location: [],
    },
  },
  growthRate: {
    error: null,
    loading: false,
    payload: {
      labels: {
        dates: [],
        months: [],
        weeks: [],
      },
      data: [],
    },
  },
  isReady: false,
};

function benchmarkingOverviewReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case CONST.FETCH_OVERVIEW_MAP_ACTION:
      return {
        ...state,
        overviewMap: {
          loading: true,
          error: null,
          payload: state.overviewMap.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_OVERVIEW_MAP_SUCCESS_ACTION:
      newState = {
        ...state,
        overviewMap: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_OVERVIEW_MAP_ERROR_ACTION:
      newState = {
        ...state,
        overviewMap: {
          loading: false,
          error: error,
          payload: {
            legends: [0, 0],
            zones: [],
            locations: [],
          },
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_OVERVIEW_SCORE_CARD_ACTION:
      return {
        ...state,
        overviewScore: {
          loading: true,
          error: null,
          payload: state.overviewScore.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_OVERVIEW_SCORE_CARD_SUCCESS_ACTION:
      newState = {
        ...state,
        overviewScore: {
          loading: false,
          error: null,
          payload: payload ? payload : initialState.overviewScore.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_OVERVIEW_SCORE_CARD_ERROR_ACTION:
      newState = {
        ...state,
        overviewScore: {
          loading: false,
          error: error,
          payload: initialState.overviewScore.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_OVERVIEW_CONTRIBUTION_ACTION:
      return {
        ...state,
        contribution: {
          loading: true,
          error: null,
          payload: initialState.contribution.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_OVERVIEW_CONTRIBUTION_SUCCESS_ACTION:
      newState = {
        ...state,
        contribution: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_OVERVIEW_CONTRIBUTION_ERROR_ACTION:
      newState = {
        ...state,
        contribution: {
          loading: false,
          error: error,
          payload: initialState.contribution.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_OVERVIEW_GROWTH_RATE_ACTION:
      return {
        ...state,
        growthRate: {
          loading: true,
          error: null,
          payload: state.growthRate.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_OVERVIEW_GROWTH_RATE_SUCCESS_ACTION:
      newState = {
        ...state,
        growthRate: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_OVERVIEW_GROWTH_RATE_ERROR_ACTION:
      newState = {
        ...state,
        growthRate: {
          loading: false,
          error: error,
          payload: initialState.growthRate.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    default:
      return state;
  }
}

export default benchmarkingOverviewReducer;
