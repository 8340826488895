// ****************************************
// **** AI Engine Section Routes/Paths ****
// ****************************************

// ** Login vs Non Login **
export const URL_SCORECARD_LIST_1 = '/lvsnl_score1';
export const URL_SCORECARD_LIST_2 = '/lvsnl_score2';
export const URL_CUSTOMER_AND_RETENTION_BARCHARTS = '/lvsnl_visit';
export const URL_AI_SCORECARD_TABLE = '/lvsnl_ai';
export const URL_PERFORMANCE_MATRIX = '/lvsnl_per';
export const URL_BARCHART_CUSTOMER = '/lvsnl_cust';
export const URL_BARCHART_SALES = '/lvsnl_sales';
export const URL_BARCHART_ORDERS = '/lvsnl_orders';
export const URL_BARCHART_ABV = '/lvsnl_abv';
