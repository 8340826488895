// Import Libs
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

// Import Reducers
import rootReducer from './reducers/rootReducer';

// Import saga
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

/**
 *
 * @param {object} preloadedState - Redux initial state
 * @returns {object} Redux store
 */
const configureStore = preloadedState => {
  // Create the store with middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  const middlewares = [sagaMiddleware, thunkMiddleware];

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const storeEnhancers = [middlewareEnhancer];

  // Composed Inhancer with Redux devtools (Only in prod.)
  const composeEnhancers =
    process?.env?.NODE_ENV &&
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 25,
        })
      : compose;

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(...storeEnhancers),
  );

  // Initiate redux saga
  sagaMiddleware.run(rootSaga);

  if (process?.env?.NODE_ENV && process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept('./reducers/rootReducer', () => {
        const newRootReducer = require('./reducers/rootReducer').default;
        store.replaceReducer(newRootReducer);
      });
    }
  }

  return store;
};

export default configureStore;
