import axios from 'axios';
import { REFRESH_ACCESS_TOKEN } from 'constants/api';
import { getRefreshToken, setAuthToken } from '../authHelper';
import { getBaseUrl } from '../../config';

// const baseURL = process.env.REACT_APP_BASE_URL;
const baseURL = getBaseUrl();

const refreshAuthLogic = failedRequest =>
  axios({
    baseURL: baseURL,
    url: REFRESH_ACCESS_TOKEN,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getRefreshToken(),
    },
  })
    .then(response => {
      if (response.status === 200) {
        if (response && response.data && response.data.access_token) {
          setAuthToken(response.data.access_token);
          failedRequest.response.config.headers['Authorization'] =
            'Bearer ' + response.data.access_token;
          return Promise.resolve();
        }
      }

      return Promise.reject("Couldn't find access token");
    })
    .catch(error => {
      window.location.reload(true);
      return Promise.reject(error);
    });

export default refreshAuthLogic;
