import { ACCESS_TOKEN, REFRESH_TOKEN, MENU_MODE } from '../constants/api';
import clearLocalStorage from './clearLocalStorage';

export const setAuthToken = token => localStorage.setItem(ACCESS_TOKEN, token);

/**
 * Service which gets the access token from the localStorage(Browser).
 */
export const getAuthToken = () => localStorage.getItem(ACCESS_TOKEN);

/**
 * Service which stores the refresh token in localStorage(Browser).
 * @param token the refresh token
 */
export const setRefreshToken = token =>
  localStorage.setItem(REFRESH_TOKEN, token);

/**
 * Service which gets the refresh token from the localStorage(Browser).
 */
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);

export const clearAuthTokens = () => clearLocalStorage();

export const getMenuMode = () => localStorage.getItem(MENU_MODE);
