/*
 *
 * Terminal Analytics page reducer
 *
 */

import * as cn from './constants';
import moment from 'moment';
import getArrayIntersection from 'helpers/getArrayIntersection';
// TA -> Terminal Analytics
const TA_RESTAURANT_TIME = 'ta_restaurant_start_time';
const defaultRestaurantTime = '00:00';
const persistedTaRestaurantTime = localStorage.getItem(TA_RESTAURANT_TIME);
const currentRestaurantTime = persistedTaRestaurantTime
  ? persistedTaRestaurantTime
  : defaultRestaurantTime;
export const initialState = {
  scores: {
    error: null,
    loading: false,
    payload: {
      totalAmountCollected: {
        delta: 0,
        value: 0,
      },
      refund: {
        delta: 0,
        value: 0,
      },
      totalCount: {
        delta: 0,
        value: 0,
      },
      abv: {
        delta: 0,
        value: 0,
      },
      avgBillValue: {
        delta: 0,
        value: 0,
      },
      refundCount: {
        delta: 0,
        value: 0,
      },
    },
  },
  summaryTable: {
    loading: false,
    error: null,
    payload: {
      overall: [],
      daily: [],
    },
  },
  transactionsOrdersTable: {
    loading: false,
    error: null,
    payload: [],
  },
  dropdownList: {
    loading: false,
    error: null,
    payload: {
      terminalType: [],
      paymentTypes: [],
      networkTypes: [],
      cardTypes: [],

    },
    selected: {
      terminalType: [],
      paymentTypes: [],
      networkTypes: [],
      cardTypes: [],
    },
  },
  taRestaurantStartTime: currentRestaurantTime,
};

function terminalAnalyticsReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case cn.DROPDOWN_INIT:
      return {
        ...state,
        loading: true,
        error: null,
        payload: initialState.dropdownList.payload,
      };
    case cn.DROPDOWN_SUCCESS:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          loading: false,
          error: null,
          payload: payload,
          ...(state.dropdownList.selected.terminalType.length ||
          state.dropdownList.selected.paymentTypes.length ||
          state.dropdownList.selected.networkTypes.length ||
          state.dropdownList.selected.cardTypes.length
            ? {
                selected: {
                  terminalType: state.dropdownList.selected.terminalType.length
                    ? getArrayIntersection(
                        state.dropdownList.selected.terminalType,
                        payload.terminalType,
                      )
                    : [],
                  paymentTypes: state.dropdownList.selected.paymentTypes.length
                    ? getArrayIntersection(
                        state.dropdownList.selected.paymentTypes,
                        payload.paymentTypes,
                      )
                    : [],
                  networkTypes: state.dropdownList.selected.networkTypes.length
                    ? getArrayIntersection(
                        state.dropdownList.selected.networkTypes,
                        payload.networkTypes,
                      )
                    : [],
                    cardTypes: state.dropdownList.selected.cardTypes.length
                    ? getArrayIntersection(
                        state.dropdownList.selected.cardTypes,
                        payload.cardTypes,
                      )
                    : [],
                },
              }
            : {}),
        },
      };

    case cn.DROPDOWN_ERROR:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          loading: false,
          error: error,
          payload: initialState.dropdownList.payload,
        },
      };

    case cn.SET_DROPDOWNS:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          selected: {
            ...state.dropdownList.selected,
            ...payload,
          },
        },
      };

    case cn.SCORE_INIT:
      return {
        ...state,
        scores: {
          loading: true,
          error: null,
          payload: initialState.scores.payload,
        },
      };

    case cn.SCORE_SUCCESS:
      return {
        ...state,
        scores: {
          loading: false,
          error: null,
          payload,
        },
      };

    case cn.SCORE_ERROR:
      return {
        ...state,
        scores: {
          loading: false,
          error,
          payload: initialState.scores.payload,
        },
      };

    case cn.SUMMARY_TABLE_INIT:
      return {
        ...state,
        summaryTable: {
          loading: true,
          error: null,
          payload: initialState.summaryTable.payload,
        },
      };

    case cn.SUMMARY_TABLE_SUCCESS:
      return {
        ...state,
        summaryTable: {
          loading: false,
          error: null,
          payload: payload,
        },
      };

    case cn.SUMMARY_TABLE_ERROR:
      return {
        ...state,
        summaryTable: {
          loading: false,
          error: error,
          payload: initialState.summaryTable.payload,
        },
      };

    case cn.TRANSACTIONS_ORDERS_TABLE_INIT:
      return {
        ...state,
        transactionsOrdersTable: {
          loading: true,
          error: false,
          payload: initialState.transactionsOrdersTable.payload,
        },
      };

    case cn.TRANSACTIONS_ORDERS_TABLE_SUCCESS:
      return {
        ...state,
        transactionsOrdersTable: {
          loading: false,
          error: false,
          payload,
        },
      };

    case cn.TRANSACTIONS_ORDERS_TABLE_ERROR:
      return {
        ...state,
        transactionsOrdersTable: {
          loading: false,
          error,
          payload: initialState.transactionsOrdersTable.payload,
        },
      };
    case cn.SET_TA_RESTAURANT_TIME:
      if (payload !== state.taRestaurantStartTime) {
        localStorage.setItem(TA_RESTAURANT_TIME, payload);
      }
      return {
        ...state,
        taRestaurantStartTime: payload,
      };
    default:
      return { ...state };
  }
}

export default terminalAnalyticsReducer;
