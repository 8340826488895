// Format AI Score card Table
const aiScorecardTableDataFormatter = payload => {
  if (payload) {
    // Destructure the payload
    const {
      ai_revenue_r = {},
      ai_revenue_n = {},
      abv_r = {},
      abv_n = {},
      personalization_eff_r = {},
      personalization_eff_l = {},
      avg_item_price_r = {},
      avg_item_price_n = {},
      cust_adop_r = {},
      cust_adop_n = {},
      recom_adop_r = {},
      recom_adop_n = {},
      items_per_order_r = {},
      items_per_order_n = {},
      avg_meal_comp_r = {},
      avg_meal_comp_n = {},
    } = payload;

    // Formatted Payload
    const formatedPayload = {
      aiRevenue: {
        latest: {
          value: '-',
          delta: undefined,
        },
        repeat: {
          value: '-',
          delta: undefined,
        },
      },
      abv: {
        latest: {
          value: abv_n.value,
          delta: abv_n.change,
        },
        repeat: {
          value: abv_r.value,
          delta: abv_r.change,
        },
      },
      personalEfficiency: {
        latest: {
          value: personalization_eff_l.value,
          delta: personalization_eff_l.change,
        },
        repeat: {
          value: personalization_eff_r.value,
          delta: personalization_eff_r.change,
        },
      },
      aip: {
        latest: {
          value: avg_item_price_n.value,
          delta: avg_item_price_n.change,
        },
        repeat: {
          value: avg_item_price_r.value,
          delta: avg_item_price_r.change,
        },
      },
      customAdoption: {
        latest: {
          value: cust_adop_n.value,
          delta: cust_adop_n.change,
        },
        repeat: {
          value: cust_adop_r.value,
          delta: cust_adop_r.change,
        },
      },
      avgItemPerOrder: {
        latest: {
          value: items_per_order_n.value,
          delta: items_per_order_n.change,
        },
        repeat: {
          value: items_per_order_r.value,
          delta: items_per_order_r.change,
        },
      },
      // Disable Avg. Meal Completion
      // amc: {
      //   latest: {
      //     value: avg_meal_comp_n.value,
      //     delta: avg_meal_comp_n.change,
      //   },
      //   repeat: {
      //     value: avg_meal_comp_r.value,
      //     delta: avg_meal_comp_r.change,
      //   },
      // },
      // recomendationAdoption: {
      //   latest: {
      //     value: recom_adop_n.value,
      //     delta: recom_adop_n.change,
      //   },
      //   repeat: {
      //     value: recom_adop_r.value,
      //     delta: recom_adop_r.change,
      //   },
      // },
    };

    return formatedPayload;
  }
};

// Format customer visit bar graph data
const customerVisitDataFormatter = payload => {
  let formatedPayload = [];

  if (payload) {
    // Destructure the payload
    const { num_visits, no_of_cust, retention_percentage } = payload;

    formatedPayload = Object.keys(num_visits).map((visitKey, i) => {
      const visitDataIndex = Object.keys(num_visits).findIndex(
        (visitIndex, i) => {
          return num_visits[visitIndex] == num_visits[visitKey];
        },
      );

      if (typeof visitDataIndex === 'number') {
        return {
          customers:
            no_of_cust && no_of_cust[visitDataIndex]
              ? no_of_cust[visitDataIndex]
              : 0,
          visits: num_visits[visitKey],
          retention:
            retention_percentage && retention_percentage[visitDataIndex]
              ? retention_percentage[visitDataIndex]
              : undefined,
        };
      }
      return {
        customers: 0,
        visits: num_visits[visitKey],
        retention: 0,
      };
    });
  }
  return formatedPayload;
};

// Format performance matrix
const performanceMatrixFormatter = payload => {
  if (payload) {
    payload = Object.keys(payload).reduce((accu, item) => {
      return {
        ...accu,
        [item]: payload[item] && payload[item][0] ? payload[item][0] : 0,
      };
    }, {});

    // Destructure payload
    const {
      cust_r,
      cust_n,
      total_cust,
      sales_r,
      sales_n,
      total_sales,
      orders_r,
      orders_n,
      total_orders,
      abv_r,
      abv_n,
      abv_per_diff,
    } = payload;

    const formatedPayload = {
      customers: {
        latest: cust_n,
        repeat: cust_r,
        total: total_cust,
      },
      sales: {
        latest: sales_n,
        repeat: sales_r,
        total: total_sales,
      },
      orders: {
        latest: orders_n,
        repeat: orders_r,
        total: total_orders,
      },
      abv: {
        latest: abv_n,
        repeat: abv_r,
        difference: abv_per_diff,
      },
    };

    return formatedPayload;
  }
};

// Performance chart formatter
const performanceChartFormatter = (payload, attribute) => {
  if (payload) {
    return Object.keys(payload).map((customer, i) => {
      const {
        date,
        sales_r,
        sales_n,
        perc_login,
        orders_r,
        orders_n,
        abv_r,
        abv_n,
        cust_r,
        cust_n,
        abv_per_diff,
      } = payload[customer];

      return {
        date,
        latest:
          attribute == 'customers'
            ? cust_n
            : attribute == 'sales'
            ? sales_n
            : attribute == 'abv'
            ? abv_n
            : attribute == 'orders'
            ? orders_n
            : null,
        repeat:
          attribute == 'customers'
            ? cust_r
            : attribute == 'sales'
            ? sales_r
            : attribute == 'abv'
            ? abv_r
            : attribute == 'orders'
            ? orders_r
            : null,
        repeatPercent: attribute == 'abv' ? abv_per_diff : perc_login,
      };
    });
  }
  return [];
};

export {
  aiScorecardTableDataFormatter,
  customerVisitDataFormatter,
  performanceMatrixFormatter,
  performanceChartFormatter,
};
