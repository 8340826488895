import {
  FETCH_SEGMENT_ANALYSIS_INIT,
  FETCH_SEGMENT_ANALYSIS_SUCCESS,
  FETCH_SEGMENT_ANALYSIS_ERROR,
} from './constants';

import checkStateLoading from 'helpers/checkStateLoading';

// Setting preloaded or initial state
const preloadedState = {
  segmentsData: {
    error: null,
    loading: false,
    payload: {
      customerMap: [],
      compareCustomerMap: [],
      topOrderedTable: {
        overallSegments: {
          topOrderedCat: '-',
          leastOrderedCat: '-',
          topOrderedItem: '-',
        },
        priceSensitive: {
          topOrderedCat: '-',
          leastOrderedCat: '-',
          topOrderedItem: '-',
        },
        cashTrappers: {
          topOrderedCat: '-',
          leastOrderedCat: '-',
          topOrderedItem: '-',
        },
        starCustomers: {
          topOrderedCat: '-',
          leastOrderedCat: '-',
          topOrderedItem: '-',
        },
        potentialFrequenters: {
          topOrderedCat: '-',
          leastOrderedCat: '-',
          topOrderedItem: '-',
        },
      },
      customerMapFilters: {},
    },
  },
  isReady: true,
};

const csSegmentAnalysisReducer = (state = preloadedState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case FETCH_SEGMENT_ANALYSIS_INIT:
      return {
        ...state,
        isReady: false,
        segmentsData: {
          error: null,
          loading: true,
          payload: preloadedState.segmentsData.payload,
        },
      };

    case FETCH_SEGMENT_ANALYSIS_SUCCESS:
      var newState = {
        ...state,
        segmentsData: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case FETCH_SEGMENT_ANALYSIS_ERROR:
      var newState = {
        ...state,
        segmentsData: {
          error,
          loading: false,
          payload: preloadedState.segmentsData.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    default:
      return state;
  }
};
export default csSegmentAnalysisReducer;
