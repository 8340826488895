// Setting preloaded or initial state

const preloadedState = {
  id: null,
  name: null,
  countries: [],
  selectedCountry: undefined,
  benchMarkingToggle: 'locations',
};

const chainReducer = (state = preloadedState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_CHAIN_DETAILS':
      localStorage.setItem('chain_id', payload.id);
      return {
        ...state,
        id: payload.id,
        name: payload.name,
      };

    case 'SET_COUNTRIES':
      return {
        ...state,
        countries: payload,
      };

    case 'SET_SELECTED_COUNTRY':
      return {
        ...state,
        selectedCountry: payload,
      };

    case 'SET_BENCHMARKING_TOGGLE':
      return {
        ...state,
        benchMarkingToggle: payload,
      };

    case 'SET_SELECTED_ZONES':
      return {
        ...state,
        selectedZones: payload,
      };
    case 'SET_SELECTED_LOCATIONS':
      return {
        ...state,
        selectedLocations: payload,
      };

    default:
      return state;
  }
};
export default chainReducer;
