import React from 'react';
import { Result, Button } from 'antd';

import styles from './ErrorBoundary.module.scss';

/**
 *
 * @param {React.Component} children - React component to be loaded dynamically wrapped in Suspense
 * @returns {React.Component} children component if loaded successfully, otherwise error component
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          className={styles.errorBoundaryCard}
          status="warning"
          subTitle="Can't load this page. Please try again"
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => window.location.reload()}
            >
              Retry
            </Button>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
