/*
 *
 * payment reconciliation reducer
 *
 */

import * as CONST from './constants';
import checkStateLoading from 'helpers/checkStateLoading';

// PR -> Payment Reconciliation
const PR_RESTAURANT_TIME = 'pr_restaurant_start_time';
const defaultRestaurantTime = '00:00';
const persistedPrRestaurantTime = localStorage.getItem(PR_RESTAURANT_TIME);
const currentRestaurantTime = persistedPrRestaurantTime
  ? persistedPrRestaurantTime
  : defaultRestaurantTime;

export const initialState = {
  paymentScore: {
    error: null,
    loading: false,
    payload: {
      amountCollected: {
        delta: 0,
        value: 0,
      },
      refunds: {
        delta: 0,
        value: 0,
      },
      pendingAmount: {
        delta: 0,
        value: 0,
      },
      netSettlementAmount: {
        delta: 0,
        value: 0,
      },
      amountPaid: {
        delta: 0,
        value: 0,
      },
      commission: {
        delta: 0,
        value: 0,
      },
    },
  },
  payoutScore: {
    error: null,
    loading: false,
    payload: {
      orderAmount: {
        delta: 0,
        value: 0,
      },
      refunds: {
        delta: 0,
        value: 0,
      },
      commission: {
        delta: 0,
        value: 0,
      },
      totalAmount: {
        delta: 0,
        value: 0,
      },
    },
  },
  payoutChannels: {
    error: null,
    loading: false,
    payload: [],
  },
  settlements: {
    error: null,
    loading: false,
    payload: [],
  },
  payouts: {
    error: null,
    loading: false,
    payload: [],
  },
  posUncaptured: {
    error: null,
    loading: false,
    payload: [],
  },
  manuallySettle: {
    error: null,
    loading: false,
    payload: [],
  },
  payoutOrders: {
    error: null,
    loading: false,
    payload: [],
  },
  paymentChannelsAndStatus: {
    error: null,  
    loading: false,
    payload: [],
  },
  isReady: false,
  prRestaurantStartTime: currentRestaurantTime,

};

function paymentReconciliationReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case CONST.FETCH_PAYMENT_SCORES_ACTION:
      return {
        ...state,
        paymentScore: {
          error: null,
          loading: true,
          payload: state.paymentScore.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PAYMENT_SCORES_SUCCESS_ACTION:
      newState = {
        ...state,
        paymentScore: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PAYMENT_SCORES_ERROR_ACTION:
      newState = {
        ...state,
        paymentScore: {
          error,
          loading: false,
          payload: initialState.paymentScore.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PAYOUT_SCORES_ACTION:
      return {
        ...state,
        payoutScore: {
          error: null,
          loading: true,
          payload: state.payoutScore.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PAYOUT_SCORES_SUCCESS_ACTION:
      newState = {
        ...state,
        payoutScore: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PAYOUT_SCORES_ERROR_ACTION:
      newState = {
        ...state,
        payoutScore: {
          error,
          loading: false,
          payload: initialState.payoutScore.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
      case CONST.FETCH_PAYOUT_CHANNELS_ACTION:
        return {
          ...state,
          payoutChannels: {
            error: null,
            loading: true,
            payload: state.payoutChannels.payload,
          },
          isReady: false,
        };
      case CONST.FETCH_PAYOUT_CHANNELS_SUCCESS_ACTION:
        newState = {
          ...state,
          payoutChannels: {
            error: null,
            loading: false,
            payload,
          },
        };
        return {
          ...newState,
          isReady: !checkStateLoading(newState),
        };
      case CONST.FETCH_PAYOUT_CHANNELS_ERROR_ACTION:
        newState = {
          ...state,
          payoutChannels: {
            error,
            loading: false,
            payload: initialState.payoutChannels.payload,
          },
        };
        return {
          ...newState,
          isReady: !checkStateLoading(newState),
        };
    case CONST.FETCH_SETTLEMENTS_ACTION:
      return {
        ...state,
        settlements: {
          error: null,
          loading: true,
          payload: state.settlements.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_SETTLEMENTS_SUCCESS_ACTION:
      newState = {
        ...state,
        settlements: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_SETTLEMENTS_ERROR_ACTION:
      newState = {
        ...state,
        settlements: {
          error,
          loading: false,
          payload: initialState.settlements.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case CONST.FETCH_PAYOUT_ORDERS_ACTION:
      return {
        ...state,
        payoutOrders: {
          error: null,
          loading: true,
          payload: state.payoutOrders.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PAYOUT_ORDERS_SUCCESS_ACTION:
      newState = {
        ...state,
        payoutOrders: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PAYOUT_ORDERS_ERROR_ACTION:
      newState = {
        ...state,
        payoutOrders: {
          error,
          loading: false,
          payload: initialState.payoutOrders.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PAYOUT_CHANNEL_DETAILS_ACTION:
      return {
        ...state,
        paymentChannelsAndStatus: {
          error: null,
          loading: true,
          payload: state.paymentChannelsAndStatus.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PAYOUT_CHANNEL_DETAILS_SUCCESS_ACTION:
      newState = {
        ...state,
        paymentChannelsAndStatus: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PAYOUT_CHANNEL_DETAILS_ERROR_ACTION:
      newState = {
        ...state,
        paymentChannelsAndStatus: {
          error,
          loading: false,
          payload: initialState.paymentChannelsAndStatus.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PAYOUTS_ACTION:
      return {
        ...state,
        payouts: {
          error: null,
          loading: true,
          payload: initialState.payouts.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PAYOUTS_SUCCESS_ACTION:
      newState = {
        ...state,
        payouts: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PAYOUTS_ERROR_ACTION:
      newState = {
        ...state,
        payouts: {
          error,
          loading: false,
          payload: initialState.payouts.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_POS_UNCAPTURED_ACTION:
      return {
        ...state,
        posUncaptured: {
          error: null,
          loading: true,
          payload: initialState.posUncaptured.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_POS_UNCAPTURED_SUCCESS_ACTION:
      newState = {
        ...state,
        posUncaptured: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_POS_UNCAPTURED_ERROR_ACTION:
      newState = {
        ...state,
        posUncaptured: {
          error,
          loading: false,
          payload: initialState.posUncaptured.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_MANUALLY_SETTLE_ACTION:
      return {
        ...state,
        manuallySettle: {
          error: null,
          loading: true,
          payload: initialState.manuallySettle.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_MANUALLY_SETTLE_SUCCESS_ACTION:
      newState = {
        ...state,
        manuallySettle: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_MANUALLY_SETTLE_ERROR_ACTION:
      newState = {
        ...state,
        manuallySettle: {
          error,
          loading: false,
          payload: initialState.manuallySettle.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
      case CONST.SET_PR_RESTAURANT_TIME:
        if (payload !== state.prRestaurantStartTime) {
          localStorage.setItem(PR_RESTAURANT_TIME, payload);
        }
        return {
          ...state,
          prRestaurantStartTime: payload,
        };

    default:
      return state;
  }
}

export default paymentReconciliationReducer;
