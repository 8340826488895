/*
 *
 * smart connect tab constants
 *
 */

export const DROPDOWN_INIT = 'app/DayEndReport/DROPDOWN_INIT';
export const DROPDOWN_SUCCESS = 'app/DayEndReport/DROPDOWN_SUCCESS';
export const DROPDOWN_ERROR = 'app/DayEndReport/DROPDOWN_ERROR';

export const SCORE_INIT = 'app/DayEndReport/SCORE_INIT';
export const SCORE_SUCCESS = 'app/DayEndReport/SCORE_SUCCESS';
export const SCORE_ERROR = 'app/DayEndReport/SCORE_ERROR';

export const SUMMARY_TABLE_INIT = 'app/DayEndReport/SUMMARY_TABLE_INIT';
export const SUMMARY_TABLE_SUCCESS = 'app/DayEndReport/SUMMARY_TABLE_SUCCESS';
export const SUMMARY_TABLE_ERROR = 'app/DayEndReport/SUMMARY_TABLE_ERROR';

export const ITEM_TABLE_INIT = 'app/DayEndReport/ITEM_TABLE_INIT';
export const ITEM_TABLE_SUCCESS = 'app/DayEndReport/ITEM_TABLE_SUCCESS';
export const ITEM_TABLE_ERROR = 'app/DayEndReport/ITEM_TABLE_ERROR';

export const CATEGORY_TABLE_INIT = 'app/DayEndReport/CATEGORY_TABLE_INIT';
export const CATEGORY_TABLE_SUCCESS = 'app/DayEndReport/CATEGORY_TABLE_SUCCESS';
export const CATEGORY_TABLE_ERROR = 'app/DayEndReport/CATEGORY_TABLE_ERROR';

export const ITEM_TRANSACTIONS_TABLE_INIT =
  'app/DayEndReport/ITEM_TRANSACTIONS_TABLE_INIT';
export const ITEM_TRANSACTIONS_TABLE_SUCCESS =
  'app/DayEndReport/ITEM_TRANSACTIONS_TABLE_SUCCESS';
export const ITEM_TRANSACTIONS_TABLE_ERROR =
  'app/DayEndReport/ITEM_TRANSACTIONS_TABLE_ERROR';

export const TRANSACTIONS_TABLE_INIT =
  'app/DayEndReport/TRANSACTIONS_TABLE_INIT';
export const TRANSACTIONS_TABLE_SUCCESS =
  'app/DayEndReport/TRANSACTIONS_TABLE_SUCCESS';
export const TRANSACTIONS_TABLE_ERROR =
  'app/DayEndReport/TRANSACTIONS_TABLE_ERROR';

export const SET_TIME = 'app/DayEndReport/SET_TIME';
export const SET_DATE = 'app/DayEndReport/SET_DATE';
export const SET_ADV_TOGGLE = 'app/DayEndReport/SET_ADV_TOGGLE';
export const SET_DROPDOWNS = 'app/DayEndReport/SET_DROPDOWNS';
