import {
  TABLE_OCCUPANCY,
  SCORE_CARD,
  OPERATIONS_DAILY,
  ALL_ORDER_TYPES,
} from 'constants/api';
import { fetchRequest } from 'helpers/actionsFormat';

const getOperationsScore = payload => fetchRequest(SCORE_CARD, payload);
const getDailyOperations = payload => fetchRequest(OPERATIONS_DAILY, payload);
const getTableOccupancy = payload => fetchRequest(TABLE_OCCUPANCY, payload);
const getOrderTypes = payload => fetchRequest(ALL_ORDER_TYPES, payload);

export {
  getOperationsScore,
  getDailyOperations,
  getTableOccupancy,
  getOrderTypes,
};
