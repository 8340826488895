import moment from 'moment';

const dropdownListFormatter = payload => {
  return [...payload.order_types] || [];
};

const itemTableFormatter = payload => {
  if (payload?.length) {
    const formattedData = payload
      .map((obj, i) => ({
        id: obj.item_id,
        key: obj.item_id,
        item: obj.item_name,
        type: obj.item_type,
        category: obj.category_name,
        qty: obj.quantity,
        sales: obj.sales,
        tabsquarePer: obj.tabsquare_contribution,
        tabsquareSales: obj.tabsquare_value,
      }))
      .filter(o => o.item && o.type && o.qty);
    return formattedData;
  }
  return [];
};

const summaryTableFormatter = (payload, type) => {
  const formattedData = {
    overall: [],
    daily: [],
  };

  if (payload?.overall_breakups?.length) {
    payload.overall_breakups.forEach(obj => {
      formattedData.overall.push({
        id: obj[type],
        key: obj[type],
        type: 'main',
        name: obj[type],
        orderNo: obj.order_no,
        orderTotal: obj.order_total,
        refundAmount: obj.refund_amount,
        tabsquareSales: obj.tabsquare_sales_sum,
        tabsquarePer: obj.tabsquare_per,
        totalAmount: obj.total_amount,
        totalCharge: obj.total_charge,
        totalDiscount: obj.total_discount,
        totalTax: obj.total_tax,
        children: obj.breakup?.length
          ? obj.breakup.map(childObj => ({
              id: `${obj[type]}${childObj.order_no}`,
              key: `${obj[type]}${childObj.order_no}`,
              type: 'child',
              name: childObj.breakup_partner_type,
              orderNo: childObj.order_no,
              orderTotal: childObj.order_total,
              refundAmount: childObj.refund_amount,
              tabsquareSales: childObj.tabsquare_sales_sum,
              tabsquarePer: childObj.tabsquare_per,
              totalAmount: childObj.total_amount,
              totalCharge: childObj.total_charge,
              totalDiscount: childObj.total_discount,
              totalTax: childObj.total_tax,
            }))
          : undefined,
      });
    });
  }

  if (payload?.day_breakups?.length) {
    payload.day_breakups.forEach(obj => {
      formattedData.daily.push({
        id: obj.date,
        key: `${obj.date}-main`,
        type: 'main',
        name: obj.date,
        orderNo: obj.order_no,
        orderTotal: obj.order_total,
        refundAmount: obj.refund_amount,
        tabsquareSales: obj.tabsquare_sales,
        tabsquarePer: obj.tabsquare_per,
        totalAmount: obj.total_amount,
        totalCharge: obj.total_charge,
        totalDiscount: obj.total_discount,
        totalTax: obj.total_tax,
        children: obj.break_up.map(childObj => ({
          id: `${obj.date}-${childObj[type]}`,
          key: `${obj.date}${childObj[type]}`,
          type: 'child',
          name: childObj[type],
          orderNo: childObj.order_no,
          orderTotal: childObj.order_total,
          refundAmount: childObj.refund_amount,
          tabsquareSales: childObj.tabsquare_sales,
          tabsquarePer: childObj.tabsquare_per,
          totalAmount: childObj.total_amount,
          totalCharge: childObj.total_charge,
          totalDiscount: childObj.total_discount,
          totalTax: childObj.total_tax,
          children: childObj.breakup_partner_type?.length
            ? childObj.breakup_partner_type.map(child2Obj => ({
                id: `${obj.date}${childObj[type]}${child2Obj.breakup_partner_type}`,
                key: `${obj.date}${childObj[type]}${child2Obj.breakup_partner_type}`,
                name: child2Obj.breakup_partner_type,
                orderNo: child2Obj.order_no,
                orderTotal: child2Obj.order_total,
                refundAmount: child2Obj.refund_amount,
                tabsquareSales: child2Obj.tabsquare_sales,
                tabsquarePer: child2Obj.tabsquare_per,
                totalAmount: child2Obj.total_amount,
                totalCharge: child2Obj.total_charge,
                totalDiscount: child2Obj.total_discount,
                totalTax: child2Obj.total_tax,
              }))
            : undefined,
        })),
      });
    });
  }
  return formattedData;
};

const categoryTableFormatter = (payload, type) => {
  const formattedData = [];
  if (payload?.length) {
    payload.forEach(obj => {
      formattedData.push({
        id: obj.category_name,
        key: obj.category_name,
        type: 'main',
        name: obj.category_name,
        sales: obj.total_amount,
        qty: obj.total_count,
        tabsquarePer: obj.tabsquare_contribution,
        tabsquareSales: obj.tabsquare_value,
        children: obj.break_up.map(childObj => ({
          id: `${obj.category_name}-${childObj[type]}`,
          key: `${obj.category_name}-${childObj[type]}`,
          type: 'child',
          name: childObj[type],
          sales: childObj.total_amount,
          qty: childObj.total_count,
          children: childObj?.breakup_partner_type?.length
            ? childObj.breakup_partner_type.map(child2Obj => ({
                id: `${obj.category_name}-${childObj[type]}-${child2Obj.partner_type}`,
                key: `${obj.category_name}-${childObj[type]}-${child2Obj.partner_type}`,
                type: 'grandchild',
                name: child2Obj.partner_type,
                sales: child2Obj.total_amount,
                qty: child2Obj.total_count,
              }))
            : undefined,
        })),
      });
    });
  }
  return formattedData;
};

const transactionsItemsTableFormatter = payload => {
  console.log(payload, 'testingpayloadddd');
  if (payload?.length) {
    const formattedData = payload
      .map(obj => ({
        date: obj.date || undefined,
        hour: moment(obj.date, 'YYYY-MM-DD hh:mm:ss').hour() ?? undefined,
        name: obj.item_name,
        // type: obj.item_type,
        orderId: obj.order_no,
        orderType: obj.order_type,
        // payment: obj.payment_type,
        price: obj.price,
        sales: obj.amount,
        // product: obj.product_types,
        qty: obj.quantity,
        sku: obj.sku,
        category: obj.category_name,
      }))
      .filter(o => o.date && o.orderId && o.name);
    return formattedData;
  }
  return [];
};

const transactionsOrdersTableFormatter = payload => {
  if (payload?.length) {
    const formattedData = payload
      .map(obj => ({
        key: obj.order_no,
        id: obj.order_no,
        time: obj.date || '-',
        orderType: obj.order_type || '-',
        appName: obj.app_name || '-',
        items: obj.items || '-',
        orderTotal: obj.order_total || 0,
        is_tax_included: obj.is_tax_included === 1 ? 'Yes' : 'No',
        totalCharges: obj.total_charge || 0,
        totalTax: obj.total_tax || 0,
        totalDiscount: obj.total_discount || 0,
        totalAmt: obj.total_amount || 0,
        transferAmt: obj.transfer_amount || 0,
        commission: obj.commission_fee || 0,
        paymentType: obj.payment_type || '-',
        restaurantName: obj.restaurant_name,
        refundAmt: obj.refund_amount || 0,
      }))
      .filter(o => o.id && o.time);
    return formattedData;
  }
  return [];
};

export {
  itemTableFormatter,
  summaryTableFormatter,
  categoryTableFormatter,
  transactionsOrdersTableFormatter,
  transactionsItemsTableFormatter,
  dropdownListFormatter,
};
