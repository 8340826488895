import {
  CATEGORY_PENETRATION_CONTRIBUTION,
  CATEGORY_PLACEMENT,
  CATEGORY_BY_TIME,
  MEAL_PENETRATION,
} from 'constants/api';
import { fetchRequest } from 'helpers/actionsFormat';

const fetchCategoryPenetrationContribution = payload =>  fetchRequest(CATEGORY_PENETRATION_CONTRIBUTION, payload);
const fetchCategoryPlacement = payload => fetchRequest(CATEGORY_PLACEMENT, payload);
const fetchCategoryByTime = payload => fetchRequest(CATEGORY_BY_TIME, payload);
const fetchMealPenetration = payload => fetchRequest(MEAL_PENETRATION, payload);

export {
  fetchCategoryPenetrationContribution,
  fetchCategoryPlacement,
  fetchCategoryByTime,
  fetchMealPenetration,
}