// Setting preloaded or initial state

const currencyReducer = (state = "$", action) => {
	const { payload, type } = action

	switch (type) {
		case "SET_CURRENCY":
			return payload ? payload.trim() : state

		default:
			return state
	}
}

export default currencyReducer
