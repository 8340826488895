import * as api from 'constants/api';
import fetch from 'helpers/Fetch';

const reqFormat = (url, data) => ({
  url,
  data,
  isMasterMerchant: true,
});

// export const getCancelledOrdersScores = (payload, isChain) =>
//   fetch(
//     reqFormat(
//       isChain
//         ? api.URL_SC_CANCELLED_ORDERS_SCORES_CHAIN
//         : api.URL_SC_CANCELLED_ORDERS_SCORES_OUTLET,
//       payload,
//     ),
//   );

// export const getCancelledOrdersBreakup = (payload, isChain) =>
//   fetch(
//     reqFormat(
//       isChain
//         ? api.URL_SC_CANCELLED_ORDERS_BREAKUP_CHAIN
//         : api.URL_SC_CANCELLED_ORDERS_BREAKUP_OUTLET,
//       payload,
//     ),
//   );

// export const getCancelledOrdersTable = (payload, isChain) =>
//   fetch(
//     reqFormat(
//       isChain
//         ? api.URL_SC_CANCELLED_ORDERS_TABLE_CHAIN
//         : api.URL_SC_CANCELLED_ORDERS_TABLE_OUTLET,
//       payload,
//     ),
//   );

// export const getCancelledOrdersReasons = (payload, isChain) =>
//   fetch(
//     reqFormat(
//       isChain
//         ? api.URL_SC_CANCELLED_ORDERS_REASONS_CHAIN
//         : api.URL_SC_CANCELLED_ORDERS_REASONS_OUTLET,
//       payload,
//     ),
//   );
 
  export const getCancelledOrdersMerge = (payload, isChain) =>
  fetch(
    reqFormat(
      isChain
        ? api.URL_SC_CANCELLED_ORDERS_CHAIN_MERGE
        : api.URL_SC_CANCELLED_ORDERS_OUTLET_MERGE,
      payload,
    ),
  );
