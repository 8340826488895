export const tabsquareValueAdditionDataFormatter = payload => {
  if (payload) {
    const formattedPayload = Object.keys(payload).map(objKey => {
      const obj = payload[objKey];
      return {
        date: obj.date,
        tva: obj['Tabsquare value addition'],
        sales: obj.Sales,
      };
    });
    return formattedPayload;
  } else return [];
};

export const crossupContributionDataFormatter = payload => {
  if (payload) {
    return {
      sales: payload[0].Sales,
      percentContribution: payload[0].Percent_contribution,
      percentCrossSell: payload[0].Percent_Cross_sell_contribution,
      percentUpSell: payload[0].Percent_upsell_contribution,
    };
  } else return [];
};

export const itemPerformanceDataFormatter = payload => {
  const formattedPayload = [];
  if (payload) {
    Object.values(payload).map(obj => {
      if (obj['Item name'] && obj['Customization options']) {
        formattedPayload.push({
          id: `${obj['Item name']}-${obj['Customization options']}`,
          // key: `${obj['Item name']}-${obj['Customization options']}`,
          item: obj['Item name'],
          customization: obj['Customization options'],
          adoption: obj['Adoption rate'],
          customizationSales: obj['Customization sales'],
        });
      }
    });
  }
  return formattedPayload;
};

export const pricingAnalysisDataFormatter = payload => {
  if (payload) {
    const formattedPayload = Object.keys(payload).map(objKey => {
      const obj = payload[objKey];
      return {
        itemName: obj.item_name,
        sales: obj.sales,
      };
    });
    return formattedPayload;
  }
  return [];
};
