// Fetch helper function
import fetch from 'helpers/Fetch';

// Import API path/routes constant
import {
  URL_SCORECARD_LIST,
  URL_TOB_TABLE,
  URL_BARCHART_CUSTOMER,
  URL_BARCHART_SALES,
  URL_BARCHART_ORDERS,
  URL_BARCHART_ABV,
  URL_BARCHART_GCPP,
  URL_PGC_HEATMAP,
} from './constants';

// Fetch AI Scorecard data
const fetchScorecardListData = payload => {
  return fetch({
    url: URL_SCORECARD_LIST,
    data: payload,
  });
};

// Fetch Top order table data
const fetchTopOrderedTableData = payload => {
  return fetch({
    url: URL_TOB_TABLE,
    data: payload,
  });
};

// Fetch Group breakup Customers data
const fetchGroupBreakupCustomersData = payload => {
  return fetch({
    url: URL_BARCHART_CUSTOMER,
    data: payload,
  });
};

// Fetch Group breakup Sales data
const fetchGroupBreakupSalesData = payload => {
  return fetch({
    url: URL_BARCHART_SALES,
    data: payload,
  });
};

// Fetch Group breakup Orders data
const fetchGroupBreakupOrdersData = payload => {
  return fetch({
    url: URL_BARCHART_ORDERS,
    data: payload,
  });
};

// Fetch Group breakup ABV data
const fetchGroupBreakupABVData = payload => {
  return fetch({
    url: URL_BARCHART_ABV,
    data: payload,
  });
};

// Fetch Group breakup GCPP data
const fetchGroupBreakupGCPPData = payload => {
  return fetch({
    url: URL_BARCHART_GCPP,
    data: payload,
  });
};

// Fetch Group breakup GCPP data
const fetchGroupContributionData = payload => {
  return fetch({
    url: URL_PGC_HEATMAP,
    data: payload,
  });
};

export {
  fetchScorecardListData,
  fetchTopOrderedTableData,
  fetchGroupBreakupCustomersData,
  fetchGroupBreakupSalesData,
  fetchGroupBreakupOrdersData,
  fetchGroupBreakupABVData,
  fetchGroupBreakupGCPPData,
  fetchGroupContributionData,
};
