import * as api from 'constants/api';
import fetch from 'helpers/Fetch';

const reqFormat = (url, data) =>
  fetch({
    url,
    data,
  });

const getScores = payload => reqFormat(api.URL_LR_SCORE, payload);
const getLiveDataScores = payload =>
  reqFormat(api.URL_LR_LIVE_DATA_SCORES, payload);
const getContribution = payload => reqFormat(api.URL_LR_CONTRIBUTION, payload);
const getTrend = payload => reqFormat(api.URL_LR_TREND, payload);
const getTableStockout = payload => reqFormat(api.URL_LR_TABLE_SO, payload);
const getTableOrderPause = payload => reqFormat(api.URL_LR_TABLE_OP, payload);
const getLiveDataTableOrderPause = payload =>
  reqFormat(api.URL_LR_LIVE_DATA_ORDER_PAUSE_TABLE, payload);
const getCategoryItem = payload => reqFormat(api.URL_LR_CI, payload);
const getOutletWise = payload => reqFormat(api.URL_LR_OUTLETWISE, payload);
const getLiveDataOutletWise = payload =>
  reqFormat(api.URL_LR_LIVE_DATA_OUTLETWISE, payload);
const getLiveDataStockoutTable = payload =>
  reqFormat(api.URL_LR_LIVE_DATA_STOCKOUT_TABLE, payload);

export {
  getScores,
  getLiveDataScores,
  getContribution,
  getTrend,
  getCategoryItem,
  getOutletWise,
  getLiveDataOutletWise,
  getTableStockout,
  getTableOrderPause,
  getLiveDataTableOrderPause,
  getLiveDataStockoutTable,
};
