// Fetch helper function
import fetch from 'helpers/Fetch';

// Import API path/routes constant
import { URL_CUSTOMER_ANALYSIS_TABLE } from './constants';

// Fetch Customer Analysis table data
const fetchCustomerAnalysisTableData = payload => {
  return fetch({
    url: URL_CUSTOMER_ANALYSIS_TABLE,
    data: payload,
  });
};

export { fetchCustomerAnalysisTableData };
