/*
 *
 * sc actions
 *
 */

import * as cn from './constants';

export const getDropdownsList = payload => {
  return {
    type: cn.DROPDOWN_INIT,
    payload,
  };
};

export const getDropdownsListSuccess = payload => {
  return {
    type: cn.DROPDOWN_SUCCESS,
    payload,
  };
};

export const getDropdownsListError = error => {
  return {
    type: cn.DROPDOWN_ERROR,
    error,
  };
};

export const getScores = payload => {
  return {
    type: cn.SCORE_INIT,
    payload,
  };
};

export const getScoresSuccess = payload => {
  return {
    type: cn.SCORE_SUCCESS,
    payload,
  };
};

export const getScoresError = error => {
  return {
    type: cn.SCORE_ERROR,
    error,
  };
};

export const getSummaryTable = payload => {
  return {
    type: cn.SUMMARY_TABLE_INIT,
    payload,
  };
};

export const getSummaryTableSuccess = payload => {
  return {
    type: cn.SUMMARY_TABLE_SUCCESS,
    payload,
  };
};

export const getSummaryTableError = error => {
  return {
    type: cn.SUMMARY_TABLE_ERROR,
    error,
  };
};

export const getItemTable = payload => {
  return {
    type: cn.ITEM_TABLE_INIT,
    payload,
  };
};

export const getItemTableSuccess = payload => {
  return {
    type: cn.ITEM_TABLE_SUCCESS,
    payload,
  };
};

export const getItemTableError = error => {
  return {
    type: cn.ITEM_TABLE_ERROR,
    error,
  };
};

export const getCategoryTable = payload => {
  return {
    type: cn.CATEGORY_TABLE_INIT,
    payload,
  };
};

export const getCategoryTableSuccess = payload => {
  return {
    type: cn.CATEGORY_TABLE_SUCCESS,
    payload,
  };
};

export const getCategoryTableError = error => {
  return {
    type: cn.CATEGORY_TABLE_ERROR,
    error,
  };
};

export const getItemTransactionsTable = payload => {
  return {
    type: cn.ITEM_TRANSACTIONS_TABLE_INIT,
    payload,
  };
};

export const getItemTransactionsTableSuccess = payload => {
  return {
    type: cn.ITEM_TRANSACTIONS_TABLE_SUCCESS,
    payload,
  };
};

export const getItemTransactionsTableError = error => {
  return {
    type: cn.ITEM_TRANSACTIONS_TABLE_ERROR,
    error,
  };
};

export const getTransactionsTable = payload => {
  return {
    type: cn.TRANSACTIONS_TABLE_INIT,
    payload,
  };
};

export const getTransactionsTableSuccess = payload => {
  return {
    type: cn.TRANSACTIONS_TABLE_SUCCESS,
    payload,
  };
};

export const getTransactionsTableError = error => {
  return {
    type: cn.TRANSACTIONS_TABLE_ERROR,
    error,
  };
};

export const setDropdowns = payload => ({
  type: cn.SET_DROPDOWNS,
  payload,
});

export const setDate = payload => ({
  type: cn.SET_DATE,
  payload,
});

export const setTime = payload => ({
  type: cn.SET_TIME,
  payload,
});

export const setAdvToggle = payload => ({
  type: cn.SET_ADV_TOGGLE,
  payload,
});
