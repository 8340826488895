import {
  URL_TRACKER_DETAILS,
  URL_TRACKER_SECTION_LIST,
  URL_TRACKER_SECTION_DETAILS,
  URL_ADD_TRACKER,
  URL_TRACKER_SCORE_CARDS,
  URL_TRACKER_DETAILS_MERGE,
} from 'constants/api';
import fetch from 'helpers/Fetch';

export const fetchTrackerMergeData = payload =>
fetch({ url: URL_TRACKER_DETAILS_MERGE, data: payload, ignoreCache: true });

export const fetchTrackerScoreCards = (payload) => {
  return fetch({ url: URL_TRACKER_SCORE_CARDS, data: payload, ignoreCache: true });
};

export const fetchTrackerDetails = payload =>
  fetch({
    url: URL_TRACKER_DETAILS,
    data: payload,
    ignoreCache: true,
  });

export const fetchTrackerSectionDetails = payload =>
  fetch({
    url: URL_TRACKER_SECTION_DETAILS,
    data: payload,
    ignoreCache: true,
  });

export const fetchTrackerSectionList = payload =>
  fetch({
    url: URL_TRACKER_SECTION_LIST,
    data: payload,
    ignoreCache: true,
  });

export const addTracker = payload =>
  fetch({
    url: URL_ADD_TRACKER,
    data: payload,
    ignoreCache: true,
  });
