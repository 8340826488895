// ********************************************************
// **** Segment analysis Section - Action constants ****
// ********************************************************

export const FETCH_SEGMENT_ANALYSIS_INIT =
  'CRM/CustomerSegmentation/segmentAnalysis_INIT';

export const FETCH_SEGMENT_ANALYSIS_SUCCESS =
  'CRM/CustomerSegmentation/segmentAnalysis_SUCCESS';

export const FETCH_SEGMENT_ANALYSIS_ERROR =
  'CRM/CustomerSegmentation/segmentAnalysis_ERROR';