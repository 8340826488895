/*
 *
 * OperationsContainer reducer
 *
 */

import checkStateLoading from 'helpers/checkStateLoading';

import {
  DAILY_SALES_ACTION,
  DAILY_SALES_LOADED_ACTION,
  DAILY_SALES_FAILED_ACTION,
  TABLE_OCCUPANCY_ACTION,
  TABLE_OCCUPANCY_LOADED_ACTION,
  TABLE_OCCUPANCY_FAILED_ACTION,
  FETCH_OPERATIONS_SCORE_ACTION,
  OPERATIONS_SCORE_LOADED_ACTION,
  OPERATIONS_SCORE_FAILED_ACTION,
  GET_ORDERTYPES_ACTION,
  GET_ORDERTYPES_LOADED_ACTION,
  GET_ORDERTYPES_FAILED_ACTION,
  SET_ORDERTYPE_ACTION,
} from './constants';

export const initialState = {
  operationsScore: {
    error: null,
    loading: false,
    payload: {
      netSales: {
        delta: 0,
        value: 0,
      },
      grossSales: {
        delta: 0,
        value: 0,
      },
      refunds: {
        delta: 0,
        value: 0,
      },
      abv: {
        delta: 0,
        value: 0,
      },
      orders: {
        delta: 0,
        value: 0,
      },
      tableOccupancy: {
        delta: 0,
        value: 0,
      },
    },
  },
  dailySales: {
    error: null,
    loading: false,
    payload: {},
  },
  tableOccupancy: {
    error: null,
    loading: false,
    payload: [],
  },
  orderTypes: {
    error: null,
    loading: false,
    payload: [],
    selected: 'All',
  },
  isReady: false,
};

function operationsContainerReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case FETCH_OPERATIONS_SCORE_ACTION:
      return {
        ...state,
        isReady: false,
        operationsScore: {
          error: null,
          loading: true,
          payload: {},
        },
      };
    case OPERATIONS_SCORE_LOADED_ACTION:
      newState = {
        ...state,
        operationsScore: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case OPERATIONS_SCORE_FAILED_ACTION:
      newState = {
        ...state,
        operationsScore: {
          error: error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case DAILY_SALES_ACTION:
      return {
        ...state,
        isReady: false,
        dailySales: {
          error: null,
          loading: true,
          payload: {},
        },
      };
    case DAILY_SALES_LOADED_ACTION:
      newState = {
        ...state,
        dailySales: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case DAILY_SALES_FAILED_ACTION:
      newState = {
        ...state,
        dailySales: {
          error: error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case TABLE_OCCUPANCY_ACTION:
      return {
        ...state,
        isReady: false,
        tableOccupancy: {
          error: null,
          loading: true,
          payload: initialState.tableOccupancy.payload,
        },
      };
    case TABLE_OCCUPANCY_LOADED_ACTION:
      newState = {
        ...state,
        tableOccupancy: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case TABLE_OCCUPANCY_FAILED_ACTION:
      newState = {
        ...state,
        tableOccupancy: {
          error: error,
          loading: false,
          payload: initialState.tableOccupancy.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case GET_ORDERTYPES_ACTION:
      return {
        ...state,
        isReady: false,
        orderTypes: {
          ...state.orderTypes,
          error: null,
          loading: true,
        },
      };
    case GET_ORDERTYPES_LOADED_ACTION:
      newState = {
        ...state,
        orderTypes: {
          error: null,
          loading: false,
          payload: payload,
          selected: payload.includes(state.orderTypes.selected)
            ? state.orderTypes.selected
            : 'All',
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case GET_ORDERTYPES_FAILED_ACTION:
      newState = {
        ...state,
        orderTypes: {
          error: error,
          loading: false,
          payload: [],
          selected: 'All',
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case SET_ORDERTYPE_ACTION:
      return {
        ...state,
        isReady: false,
        orderTypes: {
          ...state.orderTypes,
          selected: payload,
        },
      };
    default:
      return state;
  }
}

export default operationsContainerReducer;
