/*
 *
 * Tracker Item reducer
 *
 */

import {
  FETCH_TRACKER_METRICS_ACTION,
  FETCH_TRACKER_METRICS_SUCCESS_ACTION,
  FETCH_TRACKER_METRICS_ERROR_ACTION,
  FETCH_TRACKER_CSUS_METRICS_ACTION,
  FETCH_TRACKER_CSUS_METRICS_SUCCESS_ACTION,
  FETCH_TRACKER_CSUS_METRICS_ERROR_ACTION,
  FETCH_TRACKER_ITEM_BY_TIME_ACTION,
  FETCH_TRACKER_ITEM_BY_TIME_SUCCESS_ACTION,
  FETCH_TRACKER_ITEM_BY_TIME_ERROR_ACTION,
  FETCH_ITEM_SALES_ORDERS_TRACKER_ACTION,
  FETCH_ITEM_SALES_ORDERS_TRACKER_SUCCESS_ACTION,
  FETCH_ITEM_SALES_ORDERS_TRACKER_ERROR_ACTION,
} from './constants';

export const initialState = {
  metrics: {
    loading: false,
    error: null,
    payload: [],
    timeRanges: [],
  },
  csusMetrics: {
    loading: false,
    error: null,
    payload: [],
    itemPerformance: [],
    timeRanges: [],
  },
  itemByTime: {
    loading: false,
    error: null,
    payload: [],
  },
  salesOrders: {
    loading: false,
    error: null,
    paylad: [],
  },
  isReady: false,
};

function trackerReducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    case FETCH_TRACKER_METRICS_ACTION:
      return {
        ...state,
        metrics: {
          error: null,
          loading: true,
          payload: state.metrics.payload,
          timeRanges: state.metrics.timeRanges,
        },
      };
    case FETCH_TRACKER_METRICS_SUCCESS_ACTION:
      return {
        ...state,
        metrics: {
          error: null,
          loading: false,
          payload: payload.allMetrics,
          timeRanges: payload.timeRanges,
        },
      };
    case FETCH_TRACKER_METRICS_ERROR_ACTION:
      return {
        ...state,
        metrics: {
          error: error,
          loading: false,
          payload: state.metrics.payload,
          timeRanges: state.metrics.timeRanges,
        },
      };
    case FETCH_TRACKER_CSUS_METRICS_ACTION:
      return {
        ...state,
        csusMetrics: {
          error: null,
          loading: true,
          payload: state.csusMetrics.payload,
          itemPerformance: state.csusMetrics.itemPerformance,
          timeRanges: state.csusMetrics.timeRanges,
        },
      };
    case FETCH_TRACKER_CSUS_METRICS_SUCCESS_ACTION:
      return {
        ...state,
        csusMetrics: {
          error: null,
          loading: false,
          payload: payload.allMetrics,
          itemPerformance: payload.itemPerformance,
          timeRanges: payload.timeRanges,
        },
      };
    case FETCH_TRACKER_CSUS_METRICS_ERROR_ACTION:
      return {
        ...state,
        csusMetrics: {
          error: error,
          loading: false,
          payload: state.csusMetrics.payload,
          itemPerformance: state.csusMetrics.itemPerformance,
          timeRanges: state.csusMetrics.timeRanges,
        },
      };
    case FETCH_TRACKER_ITEM_BY_TIME_ACTION:
      return {
        ...state,
        itemByTime: {
          error: null,
          loading: true,
          payload: state.itemByTime.payload,
        },
      };
    case FETCH_TRACKER_ITEM_BY_TIME_SUCCESS_ACTION:
      return {
        ...state,
        itemByTime: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case FETCH_TRACKER_ITEM_BY_TIME_ERROR_ACTION:
      return {
        ...state,
        itemByTime: {
          error: error,
          loading: false,
          payload: [],
        },
      };
    case FETCH_ITEM_SALES_ORDERS_TRACKER_ACTION:
      return {
        ...state,
        salesOrders: {
          error: null,
          loading: true,
          payload: state.salesOrders.payload,
        },
      };
    case FETCH_ITEM_SALES_ORDERS_TRACKER_SUCCESS_ACTION:
      return {
        ...state,
        salesOrders: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case FETCH_ITEM_SALES_ORDERS_TRACKER_ERROR_ACTION:
      return {
        ...state,
        salesOrders: {
          error: error,
          loading: false,
          payload: [],
        },
      };
    default:
      return state;
  }
}

export default trackerReducer;
