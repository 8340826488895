/*
 *
 * sc page reducer
 *
 */

import * as cn from './constants';
import moment from 'moment';
import getArrayIntersection from 'helpers/getArrayIntersection';
const RESTAURANT_TIME = 'restaurant_time';
const START_DATE = 'start_date';
const END_DATE = 'end_date';
const defaultRestaurantTime = '00:00';
const defaultStartDate = moment().format('YYYY-MM-DD');
const defaultEndDate = moment().format('YYYY-MM-DD');
const persistedRestaurantTime = localStorage.getItem(RESTAURANT_TIME);
const persistedStartDate = localStorage.getItem(START_DATE);
const persistedEndDate = localStorage.getItem(END_DATE);
const currentRestaurantTime = persistedRestaurantTime
  ? persistedRestaurantTime
  : defaultRestaurantTime;
const currentStartDate = persistedStartDate
  ? persistedStartDate
  : defaultStartDate;
const currentEndDate = persistedEndDate ? persistedEndDate : defaultEndDate;
export const initialState = {
  scores: {
    error: null,
    loading: false,
    payload: {
      subTotal: null,
      serviceCharge: null,
      discount: null,
      rounding: null,
      tax: null,
      refunds: null,
      total: null,
      orders: null,
      includeGst: null,
    },
  },
  dropdownList: {
    loading: false,
    error: null,
    payload: {
      orderTypes: [],
      productTypes: [],
      paymentTypes: [],
      partners: [],
      paymentNetwork: [],
    },
    selected: {
      orderTypes: [],
      productTypes: [],
      paymentTypes: [],
      partners: [],
      paymentNetwork: [],
    },
  },
  summaryTable: {
    loading: false,
    error: null,
    payload: {
      overall: [],
      daily: [],
      orders: [], 
    },
  },
  itemTable: {
    loading: false,
    error: null,
    payload: [],
  },
  categoryTable: {
    loading: false,
    error: null,
    payload: [],
  },
  itemTransactionsTable: {
    loading: false,
    error: null,
    payload: [],
  },
  transactionsTable: {
    loading: false,
    error: null,
    payload: [],
  },
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  restaurantTime: '00:00',
  startDate: currentStartDate,
  endDate: currentEndDate,
  restaurantTime: currentRestaurantTime,
  advDateToggle: 'payment',
  isReady: false,
};

function dayEndReportingReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case cn.DROPDOWN_INIT:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          loading: true,
          error: null,
        },
        isReady: false,
      };
    case cn.DROPDOWN_SUCCESS:
      newState = {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          loading: false,
          error: null,
          payload: payload,
          ...(state.dropdownList.selected.orderTypes.length ||
          state.dropdownList.selected.productTypes.length ||
          state.dropdownList.selected.paymentTypes.length ||
          state.dropdownList.selected.partners.length ||
          state.dropdownList.selected.paymentNetwork.length
            ? {
                selected: {
                  orderTypes: state.dropdownList.selected.orderTypes.length
                    ? getArrayIntersection(
                        state.dropdownList.selected.orderTypes,
                        payload.orderTypes,
                      )
                    : [],
                  productTypes: state.dropdownList.selected.productTypes.length
                    ? getArrayIntersection(
                        state.dropdownList.selected.productTypes,
                        payload.productTypes,
                      )
                    : [],
                  paymentTypes: state.dropdownList.selected.paymentTypes.length
                    ? getArrayIntersection(
                        state.dropdownList.selected.paymentTypes,
                        payload.paymentTypes,
                      )
                    : [],
                  partners: state.dropdownList.selected.partners.length
                    ? getArrayIntersection(
                        state.dropdownList.selected.partners,
                        payload.partners,
                      )
                    : [],
                    paymentNetwork: state.dropdownList.selected.paymentNetwork.length
                    ? getArrayIntersection(
                        state.dropdownList.selected.paymentNetwork,
                        payload.paymentNetwork,
                      )
                    : [],
                },
              }
            : {}),
        },
      };
      return {
        ...newState,
        isReady: true,
      };
    case cn.DROPDOWN_ERROR:
      newState = {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          loading: false,
          error: error,
          payload: initialState.dropdownList.payload,
        },
      };
      return {
        ...newState,
        isReady: true,
      };

    case cn.SCORE_INIT:
      return {
        ...state,
        scores: {
          loading: true,
          error: null,
          payload: initialState.scores.payload,
        },
        isReady: false,
      };
    case cn.SCORE_SUCCESS:
      newState = {
        ...state,
        scores: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
      };
    case cn.SCORE_ERROR:
      newState = {
        ...state,
        scores: {
          loading: false,
          error: error,
          payload: initialState.scores.payload,
        },
      };
      return {
        ...newState,
      };
    case cn.SUMMARY_TABLE_INIT:
      return {
        ...state,
        summaryTable: {
          loading: true,
          error: null,
          payload: initialState.summaryTable.payload,
        },
        isReady: false,
      };
    case cn.SUMMARY_TABLE_SUCCESS:
      newState = {
        ...state,
        summaryTable: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
      };
    case cn.SUMMARY_TABLE_ERROR:
      newState = {
        ...state,
        summaryTable: {
          loading: false,
          error: error,
          payload: initialState.summaryTable.payload,
        },
      };
      return {
        ...newState,
      };

    case cn.CATEGORY_TABLE_INIT:
      return {
        ...state,
        categoryTable: {
          loading: true,
          error: null,
          payload: initialState.categoryTable.payload,
        },
        isReady: false,
      };
    case cn.CATEGORY_TABLE_SUCCESS:
      newState = {
        ...state,
        categoryTable: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
      };
    case cn.CATEGORY_TABLE_ERROR:
      newState = {
        ...state,
        categoryTable: {
          loading: false,
          error: error,
          payload: initialState.categoryTable.payload,
        },
      };
      return {
        ...newState,
      };

    case cn.ITEM_TABLE_INIT:
      return {
        ...state,
        itemTable: {
          loading: true,
          error: null,
          payload: initialState.itemTable.payload,
        },
        isReady: false,
      };
    case cn.ITEM_TABLE_SUCCESS:
      newState = {
        ...state,
        itemTable: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
      };
    case cn.ITEM_TABLE_ERROR:
      newState = {
        ...state,
        itemTable: {
          loading: false,
          error: error,
          payload: initialState.itemTable.payload,
        },
      };
      return {
        ...newState,
      };

    case cn.ITEM_TRANSACTIONS_TABLE_INIT:
      return {
        ...state,
        itemTransactionsTable: {
          loading: true,
          error: null,
          payload: initialState.itemTransactionsTable.payload,
        },
        isReady: false,
      };
    case cn.ITEM_TRANSACTIONS_TABLE_SUCCESS:
      newState = {
        ...state,
        itemTransactionsTable: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
      };
    case cn.ITEM_TRANSACTIONS_TABLE_ERROR:
      newState = {
        ...state,
        itemTransactionsTable: {
          loading: false,
          error: error,
          payload: initialState.itemTransactionsTable.payload,
        },
      };
      return {
        ...newState,
      };

    case cn.TRANSACTIONS_TABLE_INIT:
      return {
        ...state,
        transactionsTable: {
          loading: true,
          error: null,
          payload: initialState.transactionsTable.payload,
        },
        isReady: false,
      };
    case cn.TRANSACTIONS_TABLE_SUCCESS:
      newState = {
        ...state,
        transactionsTable: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
      };
    case cn.TRANSACTIONS_TABLE_ERROR:
      newState = {
        ...state,
        transactionsTable: {
          loading: false,
          error: error,
        },
      };
      return {
        ...newState,
      };

    case cn.SET_DATE:
      if (
        new Date(payload[0]).getTime() !== new Date(state.startDate).getTime()
      ) {
        localStorage.setItem(START_DATE, payload[0]);
      }
      if (
        new Date(payload[1]).getTime() !== new Date(state.endDate).getTime()
      ) {
        localStorage.setItem(END_DATE, payload[1]);
      }
      return {
        ...state,
        startDate: payload[0],
        endDate: payload[1],
      };
    case cn.SET_TIME:
      if (payload !== state.restaurantTime) {
        localStorage.setItem(RESTAURANT_TIME, payload);
      }
      return {
        ...state,
        restaurantTime: payload,
      };
    case cn.SET_ADV_TOGGLE:
      return {
        ...state,
        advDateToggle: payload,
      };
    case cn.SET_DROPDOWNS:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          selected: {
            ...state.dropdownList.selected,
            ...payload,
          },
        },
      };

    default:
      return state;
  }
}

export default dayEndReportingReducer;
