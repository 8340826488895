/*
 *
 *
 *  POS Tab Reducers
 *
 */

import * as cn from './constants';
import moment from 'moment';
import getArrayIntersection from 'helpers/getArrayIntersection';

export const initialState = {
  scores: {
    error: null,
    loading: false,
    payload: {
      subTotal: null,
      serviceCharge: null,
      discount: null,
      tax: null,
      refunds: null,
      total: null,
      orders: null,
      includeGst: null,
      tabsquareContribution: null,
    },
  },
  itemTable: {
    loading: false,
    error: null,
    payload: [],
  },
  summaryTable: {
    loading: false,
    error: null,
    payload: {
      overall: [],
      daily: [],
    },
  },
  categoryTable: {
    loading: false,
    error: null,
    payload: [],
  },
  transactionsItemsTable: {
    loading: false,
    error: null,
    payload: [],
  },
  transactionsOrdersTable: {
    loading: false,
    error: null,
    payload: [],
  },
  dropdownList: {
    loading: false,
    error: null,
    payload: [],
    selected: [],
  },
};

function posReducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    case cn.DROPDOWN_LIST_INIT:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          loading: true,
          error: null,
        },
      };
    case cn.DROPDOWN_LIST_SUCCESS:
      const newState = {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          loading: false,
          error: null,
          payload: payload,
          ...(state.dropdownList.selected.length
            ? {
                selected: [
                  state.dropdownList.selected.length
                    ? getArrayIntersection(state.dropdownList.selected, payload)
                    : [],
                ],
              }
            : {}),
        },
      };
      return { ...newState };
    case cn.DROPDOWN_LIST_ERROR:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          loading: true,
          error: error,
          payload: initialState.dropdownList.payload,
        },
      };
    case cn.SET_DROPDOWNS:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          selected: [...state.dropdownList.selected, ...payload],
        },
      };
    case cn.SCORE_INIT:
      return {
        ...state,
        scores: {
          loading: true,
          error: null,
          payload: initialState.scores.payload,
        },
      };
    case cn.SCORE_SUCCESS:
      return {
        ...state,
        scores: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.SCORE_ERROR:
      return {
        ...state,
        scores: {
          loading: false,
          error: error,
          payload: initialState.scores.payload,
        },
      };

    case cn.SUMMARY_TABLE_INIT:
      return {
        ...state,
        summaryTable: {
          loading: true,
          error: null,
          payload: initialState.summaryTable.payload,
        },
      };
    case cn.SUMMARY_TABLE_SUCCESS:
      return {
        ...state,
        summaryTable: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.SUMMARY_TABLE_ERROR:
      return {
        ...state,
        summaryTable: {
          loading: false,
          error: error,
          payload: initialState.summaryTable.payload,
        },
      };

    case cn.ITEM_TABLE_INIT:
      return {
        ...state,
        itemTable: {
          loading: true,
          error: null,
          payload: initialState.itemTable.payload,
        },
      };
    case cn.ITEM_TABLE_SUCCESS:
      return {
        ...state,
        itemTable: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.ITEM_TABLE_ERROR:
      return {
        ...state,
        itemTable: {
          loading: false,
          error: error,
          payload: initialState.itemTable.payload,
        },
      };
    case cn.CATEGORY_TABLE_INIT:
      return {
        ...state,
        categoryTable: {
          loading: true,
          error: null,
          payload: initialState.categoryTable.payload,
        },
      };
    case cn.CATEGORY_TABLE_SUCCESS:
      return {
        ...state,
        categoryTable: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.CATEGORY_TABLE_ERROR:
      return {
        ...state,
        categoryTable: {
          loading: false,
          error: error,
          payload: initialState.categoryTable.payload,
        },
      };
    case cn.TRANSACTIONS_ITEMS_TABLE_INIT:
      return {
        ...state,
        transactionsItemsTable: {
          loading: true,
          error: null,
          payload: initialState.transactionsItemsTable.payload,
        },
      };
    case cn.TRANSACTIONS_ITEMS_TABLE_SUCCESS:
      return {
        ...state,
        transactionsItemsTable: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.TRANSACTIONS_ITEMS_TABLE_ERROR:
      return {
        ...state,
        transactionsItemsTable: {
          loading: false,
          error: error,
          payload: initialState.transactionsItemsTable.payload,
        },
      };

    case cn.TRANSACTIONS_ORDERS_TABLE_INIT:
      return {
        ...state,
        transactionsOrdersTable: {
          loading: true,
          error: null,
          payload: initialState.transactionsOrdersTable.payload,
        },
      };
    case cn.TRANSACTIONS_ORDERS_TABLE_SUCCESS:
      return {
        ...state,
        transactionsOrdersTable: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
    case cn.TRANSACTIONS_ORDERS_TABLE_ERROR:
      return {
        ...state,
        transactionsOrdersTable: {
          loading: false,
          error: error,
        },
      };
    default:
      return state;
  }
}

export default posReducer;
