import moment from 'moment';

const scoresFormatter = payload => {
  const {
    total_failed_orders: orders = 0,
    failed_order_amount: amount = 0,
    percentage_failed_orders: ordersPerc = 0,
    percentage_sales_failed_orders: amoutPerc = 0,
  } = payload;

  return {
    amount,
    orders,
    amoutPerc,
    ordersPerc,
  };
};

function compare(a, b) {
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
}

const contributionFormatter = payload => {
  const {
    lost_revenue_stockout = {},
    lost_revenue_order_pause = {},
    total_lost_revenue: total = 0,
  } = payload;

  const { change: soChange = 0, value: soValue = 0 } = lost_revenue_stockout;
  const { change: opChange = 0, value: opValue = 0 } = lost_revenue_order_pause;

  const soPerc = total ? (soValue / total) * 100 : 0;
  const opPerc = total ? (opValue / total) * 100 : 0;

  return {
    values: [Number(soValue), Number(opValue)],
    colors: ['rgb(255, 107, 0)', 'rgb(0, 145, 255)'],
  
    change: [Number(soChange), Number(opChange)],
    perc: [Number(soPerc), Number(opPerc)],
    total: Number(total),
  };
};

const trendFormatter = (payload, granularity) => {
  if (payload?.length) {
    const formattedData = payload.map(obj => ({
      label:
        granularity === 'h'
          ? moment(obj[granularity], 'hh').format('h A')
          : obj[granularity],
      value: obj.order_no,
    }));
    if (granularity === 's') {
      formattedData.sort((a, b) =>
        compare(sessionOrder[a.label], sessionOrder[b.label]),
      );
    }
    return formattedData;
  }
  return [];
};

const tableOrderpauseFormatter = payload => {
  if (payload?.length) {
    const formattedData = payload.map((obj, i) => {
      const {
        partner_id: id,
        partner_name: partner,
        restaurant_name: restaurant,
        revenue_lost: revenueLost,
        total_pause_duration: duration,
        breakup = [],
      } = obj;

      const childItems = breakup.map(childObj => {
        const {
          pause_duration: duration,
          pause_time: pauseTime,
          order_start_time: startTime,
          revenue_lost: revenueLost,
          stop_reason: stopReason = '-',
        } = childObj;
        return {
          id: `${partner}-${pauseTime}-${startTime}`,
          duration,
          pauseTime,
          startTime,
          revenueLost,
          stopReason,
        };
      });

      return {
        id: `${partner}-${restaurant}-${duration}`,
        key: `${partner}-${restaurant}-${duration}`,
        partner,
        restaurant,
        revenueLost,
        duration,
        childItems,
      };
    });

    return formattedData;
  }
  return [];
};

const tableStockoutFormatter = payload => {
  if (payload?.length) {
    //const uniqueIndices = {};
    const formattedData = payload.map((obj, index) => {
      const {
        category_name: category,
        subcategory_name: subCategory,
        dish_name: item,
        sku_name: sku,
        restaurant_name: restaurant,
        revenue_lost: revenueLost,
        total_stockout_duration: duration,
        breakup = [],
      } = obj;

      const childItems = breakup.map(childObj => {
        const {
          total_stockout_duration: duration,
          stockin_time: stockinTime,
          stockout_time: stockoutTime,
          revenue_lost: revenueLost,
          remarks,
        } = childObj;
        return {
          id: `${restaurant}-${stockinTime}`,
          duration,
          stockoutTime,
          revenueLost,
          stockinTime,
          remarks,
        };
      });
        // let uniqueIndex = i + 1;
        // while (uniqueIndices[uniqueIndex]) {
        //   uniqueIndex++;
        // }
        // uniqueIndices[uniqueIndex] = true;

      return {
        // uniqueIndex, 
        id: `${restaurant}-${subCategory}-${category}-${item}-${sku}`,
        key: `${restaurant}-${subCategory}-${category}-${item}-${sku}-${index}`,
        // key: `${restaurant}-${subCategory}-${category}-${item}-${sku}-${uniqueIndex}`,
        category,
        subCategory,
        item,
        sku,
        restaurant,
        revenueLost,
        duration,
        childItems,
      };
    });

    return formattedData;
  }
  return [];
};

const orderPauseDataFormatter = arr => {
  let orderPauseData = {};

  arr.forEach(obj => {
    const {
      restaurant_name,
      source,
      total_revenue_lost,
      total_pause_duration,
      color,
    } = obj;

    if (!orderPauseData[restaurant_name]) {
      orderPauseData[restaurant_name] = {};
    }

    if (!orderPauseData[restaurant_name]['total_revenue_lost']) {
      orderPauseData[restaurant_name]['total_revenue_lost'] = {};
    }

    if (!orderPauseData[restaurant_name]['total_pause_duration']) {
      orderPauseData[restaurant_name]['total_pause_duration'] = [];
    }
    if (!orderPauseData[restaurant_name]['colors']) {
      orderPauseData[restaurant_name]['colors'] = [];
    }

    orderPauseData[restaurant_name][source] = total_pause_duration;
    orderPauseData[restaurant_name]['total_revenue_lost'][source]
      ? ''
      : (orderPauseData[restaurant_name]['total_revenue_lost'][source] = []);
    orderPauseData[restaurant_name]['total_revenue_lost'][source] =
      total_revenue_lost;

    orderPauseData[restaurant_name]['total_pause_duration'].push(
      total_pause_duration,
    );

    orderPauseData[restaurant_name]['colors'].push(color);
  });

  const labels = Object.keys(orderPauseData);

  const finalOrderPauseData = {};
  const totalRevenueLost = {};
  labels.forEach(label => {
    const data = Object.entries(orderPauseData[label]);

    data.forEach(data => {
      const [valuelabel, value] = data;

      if (valuelabel !== 'total_revenue_lost') {
        if (!finalOrderPauseData[valuelabel]) {
          finalOrderPauseData[valuelabel] = [];
        }

        if (valuelabel === 'colors') {
          finalOrderPauseData[valuelabel] =
            finalOrderPauseData[valuelabel].concat(value);
        } else {
          finalOrderPauseData[valuelabel].push(value);
        }
      } else {
        const keys = Object.keys(value);
        keys.forEach(key => {
          if (!totalRevenueLost[key]) {
            totalRevenueLost[key] = [];
          }
          totalRevenueLost[key].push(value[key]);
        });
      }
    });
  });

  finalOrderPauseData.total_revenue_lost = totalRevenueLost;

  return {
    labels: labels,
    orderPauseData: finalOrderPauseData,
  };
};

const liveDataOutletWiseFormatter = (payload, selectedGranularity) => {
  if (payload && selectedGranularity) {
    return payload.map(item => {
      if (selectedGranularity === 'item') {
        return item.dish_count;
      } else if (selectedGranularity === 'category') {
        return item.category_count;
      } else if (selectedGranularity === 'subcategory') {
        return item.sub_category_count;
      } else if (selectedGranularity === 'sku') {
        return item.sku_count;
      }
    });
  } else {
    return [];
  }
};

export {
  scoresFormatter,
  trendFormatter,
  tableOrderpauseFormatter,
  tableStockoutFormatter,
  contributionFormatter,
  orderPauseDataFormatter,
  liveDataOutletWiseFormatter,
};
