// Fetch helper function
import fetch from "../../../helpers/Fetch"

// Import API path/routes constant
import { URL_SCORECARD_LIST, URL_PROMOTION_MATRIX } from "./constants"

// Fetch Promise to
const fetchScorecardListData = (payload) => {
	return fetch({
		url: URL_SCORECARD_LIST,
		data: payload
	})
}

const fetchPromotionMatrixData = (payload) => {
	return fetch({
		url: URL_PROMOTION_MATRIX,
		data: payload
	})
}

export { fetchScorecardListData, fetchPromotionMatrixData }
