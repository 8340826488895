// Import Libs
import { takeLatest, call, put } from 'redux-saga/effects';

// Import services
import {
  fetchScorecardListData,
  fetchTopOrderedTableData,
  fetchGroupBreakupCustomersData,
  fetchGroupBreakupSalesData,
  fetchGroupBreakupOrdersData,
  fetchGroupBreakupABVData,
  fetchGroupBreakupGCPPData,
  fetchGroupContributionData,
} from './services';

// Import transformers

import scorecardDataTransformer from 'transformers/scorecardDataTransformer';

import {
  topOrderedTableFormatter,
  groupBreakupChartDataFromatter,
  groupContributionDataFormatter,
} from './formatters';

export function* fetchScorecardListSaga(action) {
  const { payload } = action;
  try {
    // Get the response from API call
    const response = yield call(fetchScorecardListData, payload);

    // Get the data/payload and status code from response
    const { data, status } = response;

    // Get the formatted data to feed reducer
    const formattedData = scorecardDataTransformer(data, 'csGroupAnalysis');

    // Feed the data to reducer
    yield put({
      type: 'FETCH_customerSegmentation/ga/scorecardGroup1_SUCCESS',
      payload: formattedData,
    });
  } catch (error) {
    const { response } = error;

    const { status, data } = response || {};

    console.log('Failed!', {
      status,
      data,
    });
    // yield false
  }
}

export function* fetchTopOrderedTableSaga(action) {
  const { payload } = action;
  try {
    // Get the response from API call
    const { data, status } = yield call(fetchTopOrderedTableData, payload);
    if (status === 200 || (status === 206 && data)) {
      const { error_message, premium_lock } = data;

      if (error_message) {
        yield put({
          type: 'FETCH_customerSegmentation/ga/topOrderedTable_FAIL',
          error: error_message,
        });
      } else if (premium_lock === false) {
        yield put({
          type: 'FETCH_customerSegmentation/ga/topOrderedTable_FAIL',
          error: 'locked',
        });
      } else {
        const formattedData = topOrderedTableFormatter(data);

        // Feed the data to reducer
        yield put({
          type: 'FETCH_customerSegmentation/ga/topOrderedTable_SUCCESS',
          payload: formattedData,
        });
      }
    } else throw new Error('Something went wrong');
  } catch (error) {
    console.log('Something went wrong', error);
    yield put({
      type: 'FETCH_customerSegmentation/ga/topOrderedTable_FAIL',
      error: 'Something went wrong',
    });
  }
}

// Performance MatriX Saga
export function* fetchGroupBreakupChartSaga(action) {
  const { attribute, payload } = action;

  const services = {
    customers: fetchGroupBreakupCustomersData,
    sales: fetchGroupBreakupSalesData,
    orders: fetchGroupBreakupOrdersData,
    abv: fetchGroupBreakupABVData,
    gcpp: fetchGroupBreakupGCPPData,
  };

  try {
    if (!(attribute in services))
      throw new Error('No appropriate way find to handle such case!');

    const { data, status } = yield call(services[attribute], payload);

    if (status === 200 || (status === 206 && data)) {
      const { error_message, premium_lock } = data;

      if (error_message) {
        yield put({
          type: 'FETCH_customerSegmentation/ga/groupBreakup_FAIL',
          error: error_message,
        });
      } else if (premium_lock === false) {
        yield put({
          type: 'FETCH_customerSegmentation/ga/groupBreakup_FAIL',
          error: 'locked',
        });
      } else {
        const formattedData = groupBreakupChartDataFromatter(data, attribute);

        // Feed the data to reducer
        yield put({
          type: 'FETCH_customerSegmentation/ga/groupBreakup_SUCCESS',
          payload: formattedData,
          attribute,
        });
      }
    } else throw new Error('Something went wrong');
  } catch (error) {
    console.log('Something went wrong', error);
    yield put({
      type: 'FETCH_customerSegmentation/ga/groupBreakup_FAIL',
      error: 'Something went wrong',
    });
  }
}

// Fetch Percent Group Contribution data
export function* fetchGroupContributionDataSaga(action) {
  const { payload } = action;
  try {
    // Get the response from API call
    const { data, status } = yield call(fetchGroupContributionData, payload);

    if (status === 200 || (status === 206 && data)) {
      const { error_message, premium_lock } = data;

      if (error_message) {
        yield put({
          type: 'FETCH_customerSegmentation/ga/groupContribution_FAIL',
          error: error_message,
        });
      } else if (premium_lock === false) {
        yield put({
          type: 'FETCH_customerSegmentation/ga/groupContribution_FAIL',
          error: 'locked',
        });
      } else {
        const formattedData = groupContributionDataFormatter(data);

        // Feed the data to reducer
        yield put({
          type: 'FETCH_customerSegmentation/ga/groupContribution_SUCCESS',
          payload: formattedData,
        });
      }
    } else throw new Error('Something went wrong');
  } catch (error) {
    console.log('Something went wrong', error);
    yield put({
      type: 'FETCH_customerSegmentation/ga/groupContribution_FAIL',
      error: 'Something went wrong',
    });
  }
}

// watcher Saga generator function
export default function* watchCSGroupAnalysisContainer() {
  yield takeLatest(
    'FETCH_customerSegmentation/ga/scorecardGroup1_INIT',
    fetchScorecardListSaga,
  );
  yield takeLatest(
    'FETCH_customerSegmentation/ga/topOrderedTable_INIT',
    fetchTopOrderedTableSaga,
  );
  yield takeLatest(
    'FETCH_customerSegmentation/ga/groupBreakup_INIT',
    fetchGroupBreakupChartSaga,
  );
  yield takeLatest(
    'FETCH_customerSegmentation/ga/groupContribution_INIT',
    fetchGroupContributionDataSaga,
  );
}
