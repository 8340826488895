import * as C from './constants';
import { initAction, successAction, errorAction } from 'helpers/actionsFormat';

export const fetchCrossSellUpSellSalesScoresDataAction = payload =>
  initAction(C.FETCH_CROSS_SELL_UPSELL_SALES_SCORE, payload);
export const crossSellUpSellSalesScoreDataLoadedAction = payload =>
  successAction(C.CROSS_SELL_UPSELL_SALES_SCORE_LOADED, payload);
export const crossSellUpSellSalesScoreFailedAction = error =>
  errorAction(C.CROSS_SELL_UPSELL_SALES_SCORE_FAILED, error);
export const fetchCrossSellUpSellContributionDataAction = payload =>
  initAction(C.FETCH_CROSS_SELL_UPSELL_CONTRIBUTION, payload);
export const crossSellUpSellContributionDataLoadedAction = payload =>
  successAction(C.CROSS_SELL_UPSELL_CONTRIBUTION_LOADED, payload);
export const crossSellUpSellContributionFailedAction = error =>
  errorAction(C.CROSS_SELL_UPSELL_CONTRIBUTION_FAILED, error);
export const fetchSalesComparisonAction = payload =>
  initAction(C.FETCH_SALES_COMPARISON, payload);
export const salesComparisonDataLoadedAction = payload =>
  successAction(C.SALES_COMPARISON_LOADED, payload);
export const salesComparisonFailedAction = error =>
  errorAction(C.SALES_COMPARISON_FAILED, error);
export const fetchSalesScoreDataAction = payload =>
  initAction(C.FETCH_SALES_SCORE, payload);
export const salesScoreDataLoadedAction = payload =>
  successAction(C.SALES_SCORE_LOADED, payload);
export const salesScoreDataFailedAction = error =>
  errorAction(C.SALES_SCORE_FAILED, error);
export const fetchCrossSellUpSellPricingDataAction = payload =>
  initAction(C.FETCH_CROSS_SELL_UPSELL_PRICING, payload);
export const crossSellUpSellPricingDataLoadedAction = payload =>
  successAction(C.CROSS_SELL_UPSELL_PRICING_LOADED, payload);
export const crossSellUpSellPricingFailedAction = error =>
  errorAction(C.CROSS_SELL_UPSELL_PRICING_FAILED, error);
export const fetchItemPerformanceDataAction = payload =>
  initAction(C.FETCH_ITEM_PERFORMANCE, payload);
export const itemPerformanceLoadedAction = payload =>
  successAction(C.ITEM_PERFORMANCE_LOADED, payload);
export const itemPerformanceFailedAction = error =>
  errorAction(C.ITEM_PERFORMANCE_FAILED, error);
