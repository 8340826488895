import { isValid, addDays } from 'date-fns';

const defaultCurrentDateRange = {
  start: addDays(new Date(), 0),
  end: addDays(new Date(), 0),
};

const defaultCompareDateRange = {
  start: addDays(new Date(), -1),
  end: addDays(new Date(), -1),
};

const CURRENT_DATE_RANGE = 'current_terminal_analytics';
const COMPARE_DATE_RANGE = 'compare_terminal_analytics';

const persistedCurrentDateRange = localStorage.getItem(CURRENT_DATE_RANGE);
const persistedCompareDateRange = localStorage.getItem(COMPARE_DATE_RANGE);

const convetObjToArr = dateRange => {
  if (dateRange.start && dateRange.end) {
    return [dateRange.start, dateRange.end];
  } else return [];
};

const convetArrToObj = dateRange => {
  if (dateRange[0] && dateRange[1]) {
    return {
      start: dateRange[0],
      end: dateRange[1],
    };
  } else return {};
};

const currentDateRangeTerminalAnalytics = persistedCurrentDateRange
  ? convetArrToObj(JSON.parse(persistedCurrentDateRange))
  : defaultCurrentDateRange;
const compareDateRangeTerminalAnalytics = persistedCompareDateRange
  ? convetArrToObj(JSON.parse(persistedCompareDateRange))
  : defaultCompareDateRange;

const terminalAnalyticsCurrentDateRangeReducer = (
  state = currentDateRangeTerminalAnalytics,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_CURRENT_DATE_RANGE_TERMINAL_ANALYTICS':
      // eslint-disable-next-line no-case-declarations
      const { start, end } = payload;
      if (isValid(new Date(start)) && isValid(new Date(end))) {
        if (
          new Date(start).getTime() !== new Date(state.start).getTime() ||
          new Date(end).getTime() !== new Date(state.end).getTime()
        ) {
          localStorage.setItem(
            CURRENT_DATE_RANGE,
            JSON.stringify(convetObjToArr(payload)),
          );
          return payload;
        } else return state;
      } else return state;
    default:
      return state;
  }
};

const terminalAnalyticsCompareDateRangeReducer = (
  state = compareDateRangeTerminalAnalytics,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_COMPARE_DATE_RANGE_TERMINAL_ANALYTICS':
      // eslint-disable-next-line no-case-declarations
      const { start, end } = payload;
      if (isValid(new Date(start)) && isValid(new Date(end))) {
        if (
          new Date(start).getTime() !== new Date(state.start).getTime() ||
          new Date(end).getTime() !== new Date(state.end).getTime()
        ) {
          localStorage.setItem(
            COMPARE_DATE_RANGE,
            JSON.stringify(convetObjToArr(payload)),
          );
          return payload;
        } else return state;
      } else return state;
    default:
      return state;
  }
};

export {
  terminalAnalyticsCompareDateRangeReducer,
  terminalAnalyticsCurrentDateRangeReducer,
};
