import { createSelector } from 'reselect';

const currentDateRangeSelector = state => state.currentDateRange;

const compareDateRangeSelector = state => state.compareDateRange;

const selectCurrentDateRangeStart = createSelector(
  currentDateRangeSelector,
  dateRange => dateRange.start,
);

const selectCurrentDateRangeEnd = createSelector(
  currentDateRangeSelector,
  dateRange => dateRange.end,
);

const selectCompareDateRangeStart = createSelector(
  compareDateRangeSelector,
  dateRange => dateRange.start,
);

const selectCompareDateRangeEnd = createSelector(
  compareDateRangeSelector,
  dateRange => dateRange.end,
);

const selectCurrentDateRange = createSelector(
  currentDateRangeSelector,
  ({ start, end }) => ({ startDate: start, endDate: end }),
);
const selectCompareDateRange = createSelector(
  compareDateRangeSelector,
  ({ start, end }) => ({ cStartDate: start, cEndDate: end }),
);

export {
  selectCurrentDateRange,
  selectCompareDateRange,
  selectCurrentDateRangeStart,
  selectCurrentDateRangeEnd,
  selectCompareDateRangeStart,
  selectCompareDateRangeEnd,
};
