/*
 *
 * benchmarking customer profiling reducer
 *
 */

import * as CONST from './constants';
import checkStateLoading from 'helpers/checkStateLoading';

export const initialState = {
  lvsnlScores: {
    loading: false,
    error: null,
    payload: {
      lCustomers: {
        delta: 0,
        value: 0,
      },
      nlCustomers: {
        delta: 0,
        value: 0,
      },
      lAbv: {
        delta: 0,
        value: 0,
      },
      nlAbv: {
        delta: 0,
        value: 0,
      },
    },
  },
  nvsrScores: {
    loading: false,
    error: null,
    payload: {
      nCustomers: {
        delta: 0,
        value: 0,
      },
      rCustomers: {
        delta: 0,
        value: 0,
      },
      nAbv: {
        delta: 0,
        value: 0,
      },
      rAbv: {
        delta: 0,
        value: 0,
      },
    },
  },
  lvsnl: {
    loading: false,
    error: null,
    payload: [],
  },
  nvsr: {
    loading: false,
    error: null,
    payload: [],
  },
  repeatRate: {
    loading: false,
    error: null,
    payload: [],
  },
  orderTypes: {
    loading: false,
    error: null,
    payload: [],
    selected: [],
  },
  isReady: false,
};

function benchmarkingOverviewReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case CONST.FETCH_CF_LVSNL_SCORES_ACTION:
      return {
        ...state,
        lvsnlScores: {
          error: null,
          loading: true,
          payload: state.lvsnlScores.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_CF_LVSNL_SCORES_SUCCESS_ACTION:
      newState = {
        ...state,
        lvsnlScores: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_CF_LVSNL_SCORES_ERROR_ACTION:
      newState = {
        ...state,
        lvsnlScores: {
          error,
          loading: false,
          payload: initialState.lvsnlScores.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_CF_NVSR_SCORES_ACTION:
      return {
        ...state,
        nvsrScores: {
          error: null,
          loading: true,
          payload: state.nvsrScores.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_CF_NVSR_SCORES_SUCCESS_ACTION:
      newState = {
        ...state,
        nvsrScores: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_CF_NVSR_SCORES_ERROR_ACTION:
      newState = {
        ...state,
        nvsrScores: {
          error,
          loading: false,
          payload: initialState.nvsrScores.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_CF_LVSNL_COMPARE_PLOT_ACTION:
      return {
        ...state,
        lvsnl: {
          error: null,
          loading: true,
          payload: state.lvsnl.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_CF_LVSNL_COMPARE_PLOT_SUCCESS_ACTION:
      newState = {
        ...state,
        lvsnl: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_CF_LVSNL_COMPARE_PLOT_ERROR_ACTION:
      newState = {
        ...state,
        lvsnl: {
          error,
          loading: false,
          payload: initialState.lvsnl.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_CF_NVSR_COMPARE_PLOT_ACTION:
      return {
        ...state,
        nvsr: {
          error: null,
          loading: true,
          payload: state.nvsr.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_CF_NVSR_COMPARE_PLOT_SUCCESS_ACTION:
      newState = {
        ...state,
        nvsr: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_CF_NVSR_COMPARE_PLOT_ERROR_ACTION:
      newState = {
        ...state,
        nvsr: {
          error,
          loading: false,
          payload: initialState.nvsr.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_CF_REPEAT_RATE_PLOT_ACTION:
      return {
        ...state,
        repeatRate: {
          error: null,
          loading: true,
          payload: state.repeatRate.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_CF_REPEAT_RATE_PLOT_SUCCESS_ACTION:
      newState = {
        ...state,
        repeatRate: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_CF_REPEAT_RATE_PLOT_ERROR_ACTION:
      newState = {
        ...state,
        repeatRate: {
          error,
          loading: false,
          payload: initialState.repeatRate.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_ORDER_TYPES_ACTION:
      return {
        ...state,
        orderTypes: {
          error: null,
          loading: true,
          payload: state.orderTypes.payload,
          selected: state.orderTypes.selected,
        },
        isReady: false,
      };
    case CONST.FETCH_ORDER_TYPES_SUCCESS_ACTION:
      newState = {
        ...state,
        orderTypes: {
          error: null,
          loading: false,
          payload,
          selected: [],
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_ORDER_TYPES_ERROR_ACTION:
      newState = {
        ...state,
        orderTypes: {
          error,
          loading: false,
          payload: initialState.orderTypes.payload,
          selected: [],
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    default:
      return state;
  }
}

export default benchmarkingOverviewReducer;
