import checkStateLoading from "../../../helpers/checkStateLoading"

// Setting preloaded or initial state
const preloadedState = {
	scorecardList: {
		error: null,
		loading: false,
		payload: {
			totalPromoSpend: {
				delta: 0,
				value: 0
			},
			promoSpendPerCust: {
				delta: 0,
				value: 0
			},
			totalItemPromoted: {
				delta: 0,
				value: 0
			},
			avgPromoValuePerOrder: {
				delta: 0,
				value: 0
			}
		}
	},
	promotionMatrix: {
		error: null,
		loading: false,
		payload: []
	},
	isReady: true
}

const aiPromotionReducer = (state = preloadedState, action) => {
	// Destructure action object
	const { type, payload, error } = action

	switch (type) {
		case "FETCH_aiPromotion/scorecardGroup1_INIT":
			return {
				...state,
				isReady: false,
				scorecardList: {
					error: null,
					loading: true,
					payload: {}
				}
			}

		case "FETCH_aiPromotion/scorecardGroup1_SUCCESS":
			var newState = {
				...state,
				scorecardList: {
					error: false,
					loading: false,
					payload: payload
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_aiPromotion/scorecardGroup1_FAIL":
			var newState = {
				...state,
				scorecardList: {
					error: error,
					loading: false,
					payload: {}
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_aiPromotion/promotionMatrix_INIT":
			return {
				...state,
				isReady: false,
				promotionMatrix: {
					error: null,
					loading: true,
					payload: []
				}
			}

		case "FETCH_aiPromotion/promotionMatrix_SUCCESS":
			var newState = {
				...state,
				promotionMatrix: {
					error: null,
					loading: false,
					payload: payload
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		case "FETCH_aiPromotion/promotionMatrix_FAIL":
			var newState = {
				...state,
				promotionMatrix: {
					error: error,
					loading: false,
					payload: []
				}
			}
			return {
				...newState,
				isReady: !checkStateLoading(newState)
			}

		default:
			return state
	}
}
export default aiPromotionReducer
