/*
 *
 * benchmarking order level analysis reducer
 *
 */

import * as CONST from './constants';
import checkStateLoading from 'helpers/checkStateLoading';

export const initialState = {
  performance: {
    loading: false,
    error: null,
    payload: [],
  },
  itemPlot: {
    loading: false,
    error: null,
    payload: [],
  },
  list: {
    loading: false,
    error: null,
    payload: {
      buckets: [],
      sessions: [],
    },
  },
  isReady: false,
};

function benchmarkingOrdelevelAnalysisReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case CONST.FETCH_ORDER_TYPE_PERFORMANCE_ACTION:
      return {
        ...state,
        performance: {
          loading: true,
          error: null,
          payload: [],
        },
        isReady: false,
      };
    case CONST.FETCH_ORDER_TYPE_PERFORMANCE_SUCCESS_ACTION:
      newState = {
        ...state,
        performance: {
          loading: false,
          error: null,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_ORDER_TYPE_PERFORMANCE_ERROR_ACTION:
      newState = {
        ...state,
        performance: {
          loading: false,
          error,
          payload: [],
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_ORDER_ITEM_PLOT_ACTION:
      return {
        ...state,
        itemPlot: {
          loading: true,
          error: null,
          payload: [],
        },
        isReady: false,
      };
    case CONST.FETCH_ORDER_ITEM_PLOT_SUCCESS_ACTION:
      newState = {
        ...state,
        itemPlot: {
          loading: false,
          error: null,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_ORDER_ITEM_PLOT_ERROR_ACTION:
      newState = {
        ...state,
        itemPlot: {
          loading: false,
          error,
          payload: [],
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_BUCKET_LIST_ACTION:
      return {
        ...state,
        list: {
          loading: true,
          error: null,
          payload: state.list.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_BUCKET_LIST_SUCCESS_ACTION:
      newState = {
        ...state,
        list: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_BUCKET_LIST_ERROR_ACTION:
      newState = {
        ...state,
        list: {
          loading: false,
          error: error,
          payload: initialState.list.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    default:
      return state;
  }
}

export default benchmarkingOrdelevelAnalysisReducer;
