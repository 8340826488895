/*
 *
 * benchmarking position map reducer
 *
 */

import * as CONST from './constants';
import checkStateLoading from 'helpers/checkStateLoading';

export const initialState = {
  position: {
    loading: false,
    error: null,
    payload: [],
  },
  isReady: false,
};

function benchmarkingPositionMapReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case CONST.FETCH_POSITION_MAP_ACTION:
      return {
        ...state,
        position: {
          loading: true,
          error: null,
          payload: state.position.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_POSITION_MAP_SUCCESS_ACTION:
      newState = {
        ...state,
        position: {
          loading: false,
          error: null,
          payload: payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_POSITION_MAP_ERROR_ACTION:
      newState = {
        ...state,
        position: {
          loading: false,
          error: error,
          payload: [],
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    default:
      return state;
  }
}

export default benchmarkingPositionMapReducer;
