import * as cn from './constants';

const initObject = (type, payload) => ({
  type,
  payload,
});

const successObject = (type, payload) => ({
  type,
  payload,
});

const errorObject = (type, error) => ({
  type,
  error,
});

const getScores = payload => initObject(cn.SCORE_INIT, payload);

const getScoresSuccess = payload => successObject(cn.SCORE_SUCCESS, payload);

const getScoresError = error => errorObject(cn.SCORE_ERROR, error);

const getBreakup = payload => initObject(cn.BREAKUP_INIT, payload);

const getBreakupSuccess = payload => successObject(cn.BREAKUP_SUCCESS, payload);

const getBreakupError = error => errorObject(cn.BREAKUP_ERROR, error);

const getTrend = payload => initObject(cn.TREND_INIT, payload);

const getTrendSuccess = payload => successObject(cn.TREND_SUCCESS, payload);

const getTrendError = error => errorObject(cn.TREND_ERROR, error);

const getTable = payload => initObject(cn.TABLE_INIT, payload);

const getTableSuccess = payload => successObject(cn.TABLE_SUCCESS, payload);

const getTableError = error => errorObject(cn.TABLE_ERROR, error);

export {
  getScores,
  getScoresSuccess,
  getScoresError,
  getBreakup,
  getBreakupSuccess,
  getBreakupError,
  getTrend,
  getTrendSuccess,
  getTrendError,
  getTable,
  getTableSuccess,
  getTableError,
};
