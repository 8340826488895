export const steps = [
  {
    selector: '.sidebar__nav',
    content: 'Move to different tabs.',
  },
  {
    selector: '#currentDateRange',
    content: 'To analyse the data select the date range of interest.',
  },
  {
    selector: '#compareDateRange',
    content:
      'Select the compare date range to view the change in percentage with respect to current date range.',
  },
  {
    selector: '.scoreCardGroup',
    content: 'Score cards to indicate the top line numbers for the business.',
  },
  {
    selector: '#downloadDropdown',
    content:
      'Download the reports in PDF or JPEG format shown on the current screen.  Raw data in excel can be sent over the email.',
  },
  {
    selector: '#help',
    content: 'For detailed videos on each tab refer to this section.',
  },
  {
    selector: '#outletInfo',
    content: 'Add additional details in settings tab.',
  },
];
