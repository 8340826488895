import { createSelector, createStructuredSelector } from 'reselect';

const chainSelector = state => state.chain;

const selectChainId = createSelector(chainSelector, chain => chain.id);
const selectChainName = createSelector(chainSelector, chain => chain.name);
const selectCountries = createSelector(chainSelector, chain => chain.countries);
const selectSelectedCountry = createSelector(
  chainSelector,
  chain => chain.selectedCountry,
);
const selectbenchMarkingToggle = createSelector(
  chainSelector,
  chain => chain.benchMarkingToggle,
);

export default createStructuredSelector({
  chainId: selectChainId,
  selectedCountry: selectSelectedCountry,
  benchMarkingToggle: selectbenchMarkingToggle,
});

export {
  selectChainId,
  selectChainName,
  selectCountries,
  selectSelectedCountry,
  selectbenchMarkingToggle,
};
