// Import Libs
import { takeLatest, call, put } from 'redux-saga/effects';

// Import services
import * as api from './services';

// Import transformers
import * as fm from './formatter';
import * as cn from './constants';
import * as a from './actions';

import scorecardDataTransformer from 'transformers/scorecardDataTransformer';

// Async fetch
function* fetchScores({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(api.getScores, payload);

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getScoresError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getScoresError('locked'));
      } else {
        const formattedData = scorecardDataTransformer(
          responseData[0],
          'dayEndReport',
        );
        // Feed the data to reducer
        yield put(a.getScoresSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getScoresError('Something went wrong'));
  }
}

function* fetchDropdowns({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getDropdowns,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getDropdownsListError(error_message));
      } else {
        // const formattedData = fm.dropdownFormatter(responseData1);
        const formattedData = fm.dropdownFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getDropdownsListSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getDropdownsListError('Something went wrong'));
  }
}

function* fetchTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getSummaryTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getSummaryTableError(error_message));
      } else {
        const formattedData = fm.summaryTableFormatter(
          responseData,
          payload.view_type,
        );
        // Feed the data to reducer
        yield put(a.getSummaryTableSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getSummaryTableError('Something went wrong'));
  }
}

function* fetchItemTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getItemTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;
      if (error_message) {
        yield put(a.getItemTableError(error_message));
      } else {
        const formattedData = fm.itemTableFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getItemTableSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getItemTableError('Something went wrong'));
  }
}

function* fetchCategoryTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getCategoryTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getCategoryTableError(error_message));
      } else {
        const formattedData = fm.categoryTableFormatter(
          responseData,
          payload.view_type,
        );
        // Feed the data to reducer
        yield put(a.getCategoryTableSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getCategoryTableError('Something went wrong'));
  }
}

function* fetchItemTransactionsTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getItemTransactionsTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getItemTransactionsTableError(error_message));
      } else {
        const formattedData = fm.itemTransactionsTableFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getItemTransactionsTableSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getItemTransactionsTableError('Something went wrong'));
  }
}

function* fetchTransactionsTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getTransactionsTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getTransactionsTableError(error_message));
      } else {
        const formattedData = fm.transactionsTableFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getTransactionsTableSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getTransactionsTableError('Something went wrong'));
  }
}

// watcher Saga generator function
export default function* watchSmartConnectContainer() {
  yield takeLatest(cn.DROPDOWN_INIT, fetchDropdowns);
  yield takeLatest(cn.SCORE_INIT, fetchScores);
  yield takeLatest(cn.SUMMARY_TABLE_INIT, fetchTable);
  yield takeLatest(cn.ITEM_TABLE_INIT, fetchItemTable);
  yield takeLatest(cn.CATEGORY_TABLE_INIT, fetchCategoryTable);
  yield takeLatest(cn.ITEM_TRANSACTIONS_TABLE_INIT, fetchItemTransactionsTable);
  yield takeLatest(cn.TRANSACTIONS_TABLE_INIT, fetchTransactionsTable);
}
