// Import Libs
import { takeLatest, call, put } from 'redux-saga/effects';

// Import services
import { fetchCustomerMapData } from './services';

// Import transformers
import {
  topOrderedTableDataFormatter,
  customerMapDataFormatter,
  customerMapFilterDataFormatter,
} from './formatters';

import {
  FETCH_SEGMENT_ANALYSIS_INIT,
  FETCH_SEGMENT_ANALYSIS_SUCCESS,
  FETCH_SEGMENT_ANALYSIS_ERROR,
} from './constants';

// Async fetch Customer Map Saga
export function* fetchCustomerMapSaga({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      fetchCustomerMapData,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put({
          type: FETCH_SEGMENT_ANALYSIS_ERROR,
          error: error_message,
        });
      } else if (premium_lock === false) {
        yield put({
          type: FETCH_SEGMENT_ANALYSIS_ERROR,
          error: 'locked',
        });
      } else {
        const { data, slider, top_ordered, compare_data } = responseData;

        const customerMap = customerMapDataFormatter(data);
        const compareCustomerMap = customerMapDataFormatter(compare_data);
        const customerMapFilters = customerMapFilterDataFormatter(slider);
        const topOrderedTable = topOrderedTableDataFormatter(top_ordered);

        // Feed the data to reducer
        yield put({
          type: FETCH_SEGMENT_ANALYSIS_SUCCESS,
          payload: {
            customerMap,
            compareCustomerMap,
            customerMapFilters,
            topOrderedTable,
          },
        });
      }
    }
  } catch (error) {
    console.log('Something went wrong', error);
    yield put({
      type: FETCH_SEGMENT_ANALYSIS_ERROR,
      error: 'Something went wrong',
    });
  }
}

// watcher Saga generator function
export default function* watchCSSegmentAnalysisContainer() {
  yield takeLatest(FETCH_SEGMENT_ANALYSIS_INIT, fetchCustomerMapSaga);
}
