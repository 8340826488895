import * as CONST from './constants';
import checkStateLoading from 'helpers/checkStateLoading';

export const initialState = {
  tipScore: {
    error: null,
    loading: false,
    payload: {
      totalTipCollected: {
        delta: 0,
        value: 0,
      },
      fees: {
        delta: 0,
        value: 0,
      },
      tipAmount: {
        delta: 0,
        value: 0,
      },
      tipToBeSettled: {
        delta: 0,
        value: 0,
      },
    },
  },
  tipPaymentDetails: {
    error: null,
    loading: false,
    payload: [],
  },
  tipDetailBreakup: {
    payload: [],
    loading: false,
    error: null,
  },
  isReady: false,
};

function tipRemittanceReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case CONST.FETCH_TIP_SCORES_ACTION:
      return {
        ...state,
        tipScore: {
          error: null,
          loading: true,
          payload: state.tipScore.payload,
        },
        isReady: false,
      };

    case CONST.FETCH_TIP_SCORES_SUCCESS_ACTION:
      newState = {
        ...state,
        tipScore: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case CONST.FETCH_TIP_SCORES_ERROR_ACTION:
      newState = {
        ...state,
        tipScore: {
          error,
          loading: false,
          payload: state.tipScore.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case CONST.FETCH_TIP_PAYMENT_DETAILS_ACTION:
      return {
        ...state,
        tipPaymentDetails: {
          error: null,
          loading: true,
          payload: state.tipPaymentDetails.payload,
        },
        isReady: false,
      };

    case CONST.FETCH_TIP_PAYMENT_DETAILS_SUCCESS_ACTION:
      newState = {
        ...state,
        tipPaymentDetails: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case CONST.FETCH_TIP_PAYMENT_DETAILS_ERROR_ACTION:
      newState = {
        ...state,
        tipPaymentDetails: {
          error,
          loading: false,
          payload: state.tipPaymentDetails.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case CONST.FETCH_TIP_DETAILS_BREAKUP_ACTION:
      return {
        ...state,
        tipDetailBreakup: {
          error: null,
          loading: true,
          payload: state.tipDetailBreakup.payload,
        },
        isReady: false,
      };

    case CONST.FETCH_TIP_DETAILS_BREAKUP_SUCCESS_ACTION:
      newState = {
        ...state,
        tipDetailBreakup: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case CONST.FETCH_TIP_DETAILS_BREAKUP_ERROR_ACTION:
      newState = {
        ...state,
        tipDetailBreakup: {
          error,
          loading: false,
          payload: state.tipDetailBreakup.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    default:
      return state;
  }
}

export default tipRemittanceReducer;
