/*
 *
 * outlet info reducer
 *
 */

import * as CONST from './constants';

export const initialState = {
  commissionDetails: {
    loading: false,
    error: null,
    payload: [],
  },
  saveCommissionDetails: {
    loading: false,
    error: null,
    success: false,
  },
  deleteCommissionDetails: {
    loading: false,
    error: null,
    success: false,
  },
};

function operationsContainerReducer(state = initialState, action) {
  const { type, payload, error } = action;
  switch (type) {
    case CONST.FETCH_COMMISSION_DETAILS_ACTION:
      return {
        ...state,
        commissionDetails: {
          error: null,
          loading: true,
          payload: state.commissionDetails.payload,
        },
      };
    case CONST.FETCH_COMMISSION_DETAILS_SUCCESS_ACTION:
      return {
        ...state,
        commissionDetails: {
          error: null,
          loading: false,
          payload: payload,
        },
      };
    case CONST.FETCH_COMMISSION_DETAILS_ERROR_ACTION:
      return {
        ...state,
        commissionDetails: {
          error: error,
          loading: false,
          payload: initialState.commissionDetails.payload,
        },
      };
    case CONST.SAVE_COMMISSION_DETAILS_ACTION:
      return {
        ...state,
        saveCommissionDetails: {
          error: null,
          loading: true,
          success: false,
        },
      };
    case CONST.SAVE_COMMISSION_DETAILS_SUCCESS_ACTION:
      return {
        ...state,
        saveCommissionDetails: {
          error: null,
          loading: false,
          success: true,
        },
      };
    case CONST.SAVE_COMMISSION_DETAILS_ERROR_ACTION:
      return {
        ...state,
        saveCommissionDetails: {
          error: error,
          loading: false,
          success: false,
        },
      };
    case CONST.DELETE_COMMISSION_DETAILS_ACTION:
      return {
        ...state,
        deleteCommissionDetails: {
          error: null,
          loading: true,
          success: false,
        },
      };
    case CONST.DELETE_COMMISSION_DETAILS_SUCCESS_ACTION:
      return {
        ...state,
        deleteCommissionDetails: {
          error: null,
          loading: false,
          success: true,
        },
        commissionDetails: {
          ...state.commissionDetails,
          payload: state.commissionDetails.payload.length
            ? state.commissionDetails.payload.filter(tr => tr.id !== payload)
            : [],
        },
      };
    case CONST.DELETE_COMMISSION_DETAILS_ERROR_ACTION:
      return {
        ...state,
        deleteCommissionDetails: {
          error: error,
          loading: false,
          success: false,
        },
      };

    default:
      return state;
  }
}

export default operationsContainerReducer;
