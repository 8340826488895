/*
 *
 * benchmarking performance analysis reducer
 *
 */

import * as CONST from './constants';
import checkStateLoading from 'helpers/checkStateLoading';

export const initialState = {
  performance: {
    loading: false,
    error: null,
    payload: [],
  },
  performanceScore: {
    error: null,
    loading: false,
    payload: {
      sales: {
        delta: 0,
        value: 0,
      },
      orders: {
        delta: 0,
        value: 0,
      },
      abv: {
        delta: 0,
        value: 0,
      },
      pricePerItem: {
        delta: 0,
        value: 0,
      },
    },
  },
  priceBand: {
    error: null,
    loading: false,
    payload: [],
  },
  priceBandStats: {
    error: null,
    loading: false,
    payload: {
      data: [],
      bands: [],
      chain: [],
    },
  },
  mealPenetration: {
    error: null,
    loading: false,
    payload: [],
  },
  isReady: false,
};

function benchmarkingOverviewReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState;
  switch (type) {
    case CONST.FETCH_PERFORMANCE_ANALYSIS_ACTION:
      return {
        ...state,
        performance: {
          loading: true,
          error: null,
          payload: initialState.performance.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PERFORMANCE_ANALYSIS_SUCCESS_ACTION:
      newState = {
        ...state,
        performance: {
          loading: false,
          error: null,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PERFORMANCE_ANALYSIS_ERROR_ACTION:
      newState = {
        ...state,
        performance: {
          loading: false,
          error,
          payload: [],
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PERFORMANCE_ANALYSIS_SCORE_CARD_ACTION:
      return {
        ...state,
        performanceScore: {
          loading: true,
          error: null,
          payload: initialState.performanceScore.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PERFORMANCE_ANALYSIS_SCORE_CARD_SUCCESS_ACTION:
      newState = {
        ...state,
        performanceScore: {
          loading: false,
          error: null,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PERFORMANCE_ANALYSIS_SCORE_CARD_ERROR_ACTION:
      newState = {
        ...state,
        performanceScore: {
          loading: false,
          error,
          payload: initialState.performanceScore.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PRICE_BAND_ANALYSIS_ACTION:
      return {
        ...state,
        priceBand: {
          loading: true,
          error: null,
          payload: initialState.priceBand.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PRICE_BAND_ANALYSIS_SUCCESS_ACTION:
      newState = {
        ...state,
        priceBand: {
          loading: false,
          error: null,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PRICE_BAND_ANALYSIS_ERROR_ACTION:
      newState = {
        ...state,
        priceBand: {
          loading: false,
          error,
          payload: [],
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PRICE_BAND_STATS_ACTION:
      return {
        ...state,
        priceBandStats: {
          loading: true,
          error: null,
          payload: initialState.priceBandStats.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_PRICE_BAND_STATS_SUCCESS_ACTION:
      newState = {
        ...state,
        priceBandStats: {
          loading: false,
          error: null,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_PRICE_BAND_STATS_ERROR_ACTION:
      newState = {
        ...state,
        priceBandStats: {
          loading: false,
          error,
          payload: initialState.priceBandStats.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_MEAL_PENETRATION_ACTION:
      return {
        ...state,
        mealPenetration: {
          loading: true,
          error: null,
          payload: initialState.mealPenetration.payload,
        },
        isReady: false,
      };
    case CONST.FETCH_MEAL_PENETRATION_SUCCESS_ACTION:
      newState = {
        ...state,
        mealPenetration: {
          loading: false,
          error: null,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case CONST.FETCH_MEAL_PENETRATION_ERROR_ACTION:
      newState = {
        ...state,
        mealPenetration: {
          loading: false,
          error,
          payload: [],
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    default:
      return state;
  }
}

export default benchmarkingOverviewReducer;
