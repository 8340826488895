// Import Libs
import { takeLatest, call, put } from 'redux-saga/effects';

// Import services
import * as api from './services';

// Import transformers
import * as fm from './formatter';
import * as cn from './constants';
import * as a from './actions';

import scorecardDataTransformer from 'transformers/scorecardDataTransformer';

// Async fetch
function* getMasterMerchantScoresFn({ payload, isChain }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      isChain ? api.getChainScores : api.getScores,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getSmartConnectScoreError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getSmartConnectScoreError('locked'));
      } else {
        const formattedData = scorecardDataTransformer(
          responseData,
          'masterMerchant',
        );
        // Feed the data to reducer
        yield put(a.getSmartConnectScoreSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getSmartConnectScoreError('Something went wrong'));
  }
}

function* getOrdertypePartnerListFn({ payload, isChain }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      isChain ? api.getChainDropdowns : api.getDropdowns,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getOrderTypePartnerListError(error_message));
      } else {
        const formattedData = fm.orderTypePartnerListFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getOrderTypePartnerListSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getOrderTypePartnerListError('Something went wrong'));
  }
}

function* getMasterMerchantPerformanceFn({ payload, isChain }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      isChain ? api.getChainPerformance : api.getPerformance,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getSmartConnectPerformanceError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getSmartConnectPerformanceError('locked'));
      } else {
        const formattedData = fm.performanceDataFormatter(
          responseData,
          payload.graph !== 'order_type',
        );
        // Feed the data to reducer
        yield put(a.getSmartConnectPerformanceSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getSmartConnectPerformanceError('Something went wrong'));
  }
}

function* getSmartConnectTrendFn({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(api.getTrend, payload);

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getSmartConnectTrendError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getSmartConnectTrendError('locked'));
      } else if (responseData.data?.length) {
        const { graph, metric, granularity } = payload;

        const formattedData = fm.trendDataFormatter(
          responseData.data,
          graph !== 'order_type',
          metric,
          granularity,
        );
        // Feed the data to reducer
        yield put(a.getSmartConnectTrendSuccess(formattedData));
      } else {
        yield put(a.getSmartConnectTrendError('No Data Found'));
      }
    }
  } catch (e) {
    yield put(a.getSmartConnectTrendError('Something went wrong'));
  }
}

function* getSmartConnectChainTrendFn({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getChainTrend,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getSmartConnectChainTrendError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getSmartConnectChainTrendError('locked'));
      } else if (responseData.overall?.length) {
        const { graph, metric, granularity, switch:sw } = payload;

        const formattedData = fm.chainTrendDataFormatter(
          responseData,
          graph,
          metric,
          granularity,
          sw,
        );
        // Feed the data to reducer
        yield put(a.getSmartConnectChainTrendSuccess(formattedData));
      } else {
        yield put(a.getSmartConnectChainTrendError('No Data Found'));
      }
    }
  } catch (e) {
    console.log(e)
    yield put(a.getSmartConnectChainTrendError('Something went wrong'));
  }
}

function* getSmartConnectZlPerf({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getZoneLocationPerformance,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getSmartConnectZoneLocPerfError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getSmartConnectZoneLocPerfError('locked'));
      } else if (responseData.length) {
        const { graph, metric, switch: sw } = payload;

        const formattedData = fm.zlPerfFormatter(
          responseData,
          graph !== 'order_type',
          metric,
          sw,
        );
        // Feed the data to reducer
        yield put(a.getSmartConnectZoneLocPerfSuccess(formattedData));
      } else {
        yield put(a.getSmartConnectZoneLocPerfError('No Data Found'));
      }
    }
  } catch (e) {
    yield put(a.getSmartConnectZoneLocPerfError('Something went wrong'));
  }
}

function* getProfitPercentageFn({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getProfitPercentage,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getProfitPercentageError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getProfitPercentageError('locked'));
      } else if (responseData.data?.length) {
        const { graph } = payload;

        const formattedData = fm.profitPercentageDataFormatter(
          responseData.data,
          graph !== 'order_type',
        );
        // Feed the data to reducer
        yield put(a.getProfitPercentageSuccess(formattedData));
      } else {
        yield put(a.getProfitPercentageError('No Data Found'));
      }
    }
  } catch (e) {
    yield put(a.getProfitPercentageError('Something went wrong'));
  }
}

function* getProfitTrendFn({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getProfitTrend,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getProfitTrendError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getProfitTrendError('locked'));
      } else if (responseData.data?.length) {
        const { graph, granularity } = payload;

        const formattedData = fm.profitTrendDataFormatter(
          responseData.data,
          graph !== 'order_type',
          granularity,
        );
        // Feed the data to reducer
        yield put(a.getProfitTrendSuccess(formattedData));
      } else {
        yield put(a.getProfitTrendError('No Data Found'));
      }
    }
  } catch (e) {
    yield put(a.getProfitTrendError('Something went wrong'));
  }
}

// watcher Saga generator function
export default function* watchSmartConnectContainer() {
  yield takeLatest(cn.SMART_CONNECT_SCORE_INIT, getMasterMerchantScoresFn);
  yield takeLatest(cn.PARTNER_ORDERTYPE_LIST_INIT, getOrdertypePartnerListFn);
  yield takeLatest(
    cn.SMART_CONNECT_PERFORMANCE_INIT,
    getMasterMerchantPerformanceFn,
  );
  yield takeLatest(cn.SMART_CONNECT_TREND_INIT, getSmartConnectTrendFn);
  yield takeLatest(
    cn.SMART_CONNECT_CHAIN_TREND_INIT,
    getSmartConnectChainTrendFn,
  );
  yield takeLatest(cn.SMART_CONNECT_ZL_PERF_INIT, getSmartConnectZlPerf);
  yield takeLatest(cn.PROFIT_PERCENTAGE_INIT, getProfitPercentageFn);
  yield takeLatest(cn.PROFIT_TREND_INIT, getProfitTrendFn);
}
