import checkStateLoading from 'helpers/checkStateLoading';
import * as c from './constants';

export const initialState = {
  itemsScore: {
    error: null,
    loading: false,
    payload: {
      itemsSold: {
        delta: 0,
        value: 0,
      },
      percentageSold: {
        delta: 0,
        value: 0,
      },
      itemsPerOrder: {
        delta: 0,
        value: 0,
      },
      avgPrice: {
        delta: 0,
        value: 0,
      },
    },
  },
  itemMapsCategories: {
    error: null,
    loading: false,
    payload: {},
  },
  itemMaps: {
    error: null,
    loading: false,
    payload: [],
    margin: null,
    scaleFlag:0,
  },
  itemMapsQuadrant: {
    error: null,
    loading: false,
    payload: {},
  },
  searchedItems: {
    error: null,
    loading: false,
    payload: {},
  },
  itemStats: {
    error: null,
    loading: false,
    payload: {
      data: [],
      filters: {
        categories: [],
        items: [],
        quadrants:[],
        repeatCust:[],
      },
    },
  },
  itemTransactions: {
    error: null,
    loading: false,
    payload: {
      tableData: [],
      totalCount: 0,
      prodType:[],
      payType:[],
      payMethod:[],
      outletFilter:[],
    },
  },
  itemsByTime: {
    error: null,
    loading: false,
    payload: {
      data: [],
      filters: {
        categories: [],
        items: [],
      },
      hours: [],
    },
  },
  isReady: false,
};

function itemContainerReducer(state = initialState, action) {
  const { type, payload, error } = action;
  let newState = {};
  switch (type) {
    case c.FETCH_ITEMS_SCORE:
      return {
        ...state,
        isReady: false,
        itemsScore: {
          error: null,
          loading: true,
          payload: {},
        },
      };
    case c.ITEMS_SCORE_LOADED:
      newState = {
        ...state,
        itemsScore: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.ITEMS_SCORE_FAILED:
      newState = {
        ...state,
        itemsScore: {
          error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.FETCH_ITEM_MAPS:
      return {
        ...state,
        isReady: false,
        itemMaps: {
          error: null,
          loading: true,
          payload: initialState.itemMaps.payload,
          margin: null,
          scaleFlag:0,
        },
      };
    case c.ITEM_MAPS_LOADED:
      newState = {
        ...state,
        itemMaps: {
          error: null,
          loading: false,
          payload: payload.data,
          margin: payload.margin,
          scaleFlag:payload.scaleFlag,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.ITEM_MAPS_FAILED:
      newState = {
        ...state,
        itemMaps: {
          error,
          loading: false,
          payload: initialState.itemMaps.payload,
          margin: null,
          scaleFlag:0,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.FETCH_ITEM_MAPS_QUADRANT:
      return {
        ...state,
        isReady: false,
        itemMapsQuadrant: {
          error: null,
          loading: true,
          payload: {},
        },
      };
    case c.ITEM_MAPS_QUADRANT_LOADED:
      newState = {
        ...state,
        itemMapsQuadrant: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.ITEM_MAPS_QUADRANT_FAILED:
      newState = {
        ...state,
        itemMapsQuadrant: {
          error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.FETCH_SEARCHED_ITEMS:
      return {
        ...state,
        isReady: false,
        searchedItems: {
          error: null,
          loading: true,
          payload: {},
        },
      };
    case c.SEARCHED_ITEMS_LOADED:
      newState = {
        ...state,
        searchedItems: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.SEARCHED_ITEMS_FAILED:
      newState = {
        ...state,
        searchedItems: {
          error,
          loading: false,
          payload: {},
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.FETCH_ITEM_STATS:
      return {
        ...state,
        isReady: false,
        itemStats: {
          error: null,
          loading: true,
          payload: initialState.itemStats.payload,
        },
      };
    case c.ITEM_STATS_LOADED:
      newState = {
        ...state,
        itemStats: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.ITEM_STATS_FAILED:
      newState = {
        ...state,
        itemStats: {
          error,
          loading: false,
          payload: initialState.itemStats.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case c.FETCH_ITEM_TRANSACTIONS:
      return {
        ...state,
        isReady: false,
        itemTransactions: {
          error: null,
          loading: true,
          payload: state.itemTransactions.payload,
        },
      };
    case c.ITEM_TRANSACTIONS_LOADED:
      newState = {
        ...state,
        itemTransactions: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.ITEM_TRANSACTIONS_FAILED:
      newState = {
        ...state,
        itemTransactions: {
          error,
          loading: false,
          payload: state.itemTransactions.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };

    case c.FETCH_ITEMS_BY_TIME:
      return {
        ...state,
        isReady: false,
        itemsByTime: {
          error: null,
          loading: true,
          payload: state.itemsByTime.payload,
        },
      };
    case c.ITEMS_BY_TIME_LOADED:
      newState = {
        ...state,
        itemsByTime: {
          error: null,
          loading: false,
          payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    case c.ITEMS_BY_TIME_FAILED:
      newState = {
        ...state,
        itemsByTime: {
          error,
          loading: false,
          payload: state.itemsByTime.payload,
        },
      };
      return {
        ...newState,
        isReady: !checkStateLoading(newState),
      };
    default:
      return state;
  }
}

export default itemContainerReducer;
