import React from 'react';
import { render } from 'react-dom';
import configCharts from './utils/configCharts';

// Import styles
import './assets/scss/app.scss';
import 'antd/dist/antd.less';

// Import Components
import App from 'containers/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './i18n/config';

// ? configuring the charts
configCharts();

const rootNode = document.getElementById('root');

render(<App />, rootNode);

if (module.hot) {
  module.hot.accept('containers/App', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('containers/App').default;
    render(<NextApp />, rootNode);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
