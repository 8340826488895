import * as api from 'constants/api';
import fetch from 'helpers/Fetch';

export const getScores = payload =>
  fetch({
    url: api.URL_TERMINAL_ANALYTICS_SCORE,
    data: payload,
  });

export const getSummaryTable = payload =>
  fetch({
    url: api.URL_TERMINAL_ANALYTICS_DAILY_SUMMARY,
    data: payload,
  });

export const getTransactionsOrdersTable = payload =>
  fetch({
    url: api.URL_TERMINAL_ANALYTICS_TRANSACTIONS_ORDERS,
    data: payload,
  });
export const getDropdowns = payload =>
  fetch({
    url: api.URL_TERMINAL_ANALYTICS_DROPDOWNS,
    data: payload,
  });
