/*
 *
 * Terminal Analytics Constants
 *
 */

// * Dropdowns
export const DROPDOWN_INIT = 'app/TerminalAnalytics/DROPDOWN_INIT';
export const DROPDOWN_SUCCESS = 'app/TerminalAnalytics/DROPDOWN_SUCCESS';
export const DROPDOWN_ERROR = 'app/TerminalAnalytics/DROPDOWN_ERROR';
export const SET_DROPDOWNS = 'app/TerminalAnalytics/SET_DROPDOWNS';

//* Score cards
export const SCORE_INIT = 'app/TerminalAnalytics/SCORE_INIT';
export const SCORE_SUCCESS = 'app/TerminalAnalytics/SCORE_SUCCESS';
export const SCORE_ERROR = 'app/TerminalAnalytics/SCORE_ERROR';

//* Summary table
export const SUMMARY_TABLE_INIT = 'app/TerminalAnalytics/SUMMARY_TABLE_INIT';
export const SUMMARY_TABLE_SUCCESS =
  'app/TerminalAnalytics/SUMMARY_TABLE_SUCCESS';
export const SUMMARY_TABLE_ERROR = 'app/TerminalAnalytics/SUMMARY_TABLE_ERROR';

//* Transactions Order Table
export const TRANSACTIONS_ORDERS_TABLE_INIT =
  'app/TerminalAnalytics/TRANSACTIONS_ORDERS_TABLE_INIT';
export const TRANSACTIONS_ORDERS_TABLE_SUCCESS =
  'app/TerminalAnalytics/TRANSACTIONS_ORDERS_TABLE_SUCCESS';
export const TRANSACTIONS_ORDERS_TABLE_ERROR =
  'app/TerminalAnalytics/TRANSACTIONS_ORDERS_TABLE_ERROR';
export const SET_TA_RESTAURANT_TIME =
  'app/TerminalAnalytics/SET_TA_RESTAURANT_TIME';
