/*
 *
 * TrackerTable actions
 *
 */

import {
  // FETCH_TRACKER_SCORE_CARDS_ACTION,
  FETCH_TRACKER_SCORE_CARDS_SUCCESS_ACTION,
  FETCH_TRACKER_SCORE_CARDS_ERROR_ACTION,
  // FETCH_TRACKER_DETAILS_ACTION,
  FETCH_TRACKER_DETAILS_SUCCESS_ACTION,
  FETCH_TRACKER_DETAILS_ERROR_ACTION,
  FETCH_TRACKER_SECTION_LIST_ACTION,
  FETCH_TRACKER_SECTION_LIST_SUCCESS_ACTION,
  FETCH_TRACKER_SECTION_LIST_ERROR_ACTION,
  FETCH_TRACKER_SECTION_DETAILS_ACTION,
  FETCH_TRACKER_SECTION_DETAILS_SUCCESS_ACTION,
  FETCH_TRACKER_SECTION_DETAILS_ERROR_ACTION,
  ADD_TRACKER_ACTION,
  ADD_TRACKER_SUCCESS_ACTION,
  ADD_TRACKER_ERROR_ACTION,
  FETCH_TRACKER_MERGE_ACTION,
} from './constants';

import * as API from './services';
import * as formatter from './formatter';
import scorecardDataTransformer from 'transformers/scorecardDataTransformer';

//Merge API

export function fetchTrackerMergeAction(payload) {
  return {
    type: FETCH_TRACKER_MERGE_ACTION,
    payload,
  };
}

// * Score Cards

// export function fetchTrackerScoreCardsAction(payload) {
//   return {
//     type: FETCH_TRACKER_SCORE_CARDS_ACTION,
//     payload,
//   };
// }

export function fetchTrackerScoreCardsSuccessAction(payload) {
  return {
    type: FETCH_TRACKER_SCORE_CARDS_SUCCESS_ACTION,
    payload,
  };
}

export function fetchTrackerScoreCardsErrorAction(error) {
  return {
    type: FETCH_TRACKER_SCORE_CARDS_ERROR_ACTION,
    error,
  };
}
// * Tracker Details 

// export function fetchTrackerDetailsAction(payload) {
//   return {
//     type: FETCH_TRACKER_DETAILS_ACTION,
//     payload,
//   };
// }

export function fetchTrackerDetailsSuccessAction(payload) {
  return {
    type: FETCH_TRACKER_DETAILS_SUCCESS_ACTION,
    payload,
  };
}

export function fetchTrackerDetailsErrorAction(error) {
  return {
    type: FETCH_TRACKER_DETAILS_ERROR_ACTION,
    error,
  };
}

export function fetchTrackerSectionListAction(payload) {
  return {
    type: FETCH_TRACKER_SECTION_LIST_ACTION,
    payload,
  };
}

export function fetchTrackerSectionListSuccessAction(payload) {
  return {
    type: FETCH_TRACKER_SECTION_LIST_SUCCESS_ACTION,
    payload,
  };
}

export function fetchTrackerSectionListErrorAction(error) {
  return {
    type: FETCH_TRACKER_SECTION_LIST_ERROR_ACTION,
    error,
  };
}

export function fetchTrackerSectionDetailsAction(payload) {
  return {
    type: FETCH_TRACKER_SECTION_DETAILS_ACTION,
    payload,
  };
}

export function fetchTrackerSectionDetailsSuccessAction(payload) {
  return {
    type: FETCH_TRACKER_SECTION_DETAILS_SUCCESS_ACTION,
    payload,
  };
}

export function fetchTrackerSectionDetailsErrorAction(error) {
  return {
    type: FETCH_TRACKER_SECTION_DETAILS_ERROR_ACTION,
    error,
  };
}

export function addTrackerAction(payload) {
  return {
    type: ADD_TRACKER_ACTION,
    payload,
  };
}

export function addTrackerSuccessAction(payload) {
  return {
    type: ADD_TRACKER_SUCCESS_ACTION,
    payload,
  };
}

export function addTrackerErrorAction(error) {
  return {
    type: ADD_TRACKER_ERROR_ACTION,
    error,
  };
}

// export function fetchTrackerSectionDetailsFn(data) {
//   return async dispatch => {
//     dispatch(fetchTrackerSectionDetailsAction());
//     try {
//       if (data.section_id) {
//         const response = await API.fetchTrackerSectionDetails(data);
//         if (response.status === 200) {
//           const formattedData = formatter.trackerSectionDetailsFormatter(
//             response.data,
//           );
//           dispatch(fetchTrackerSectionDetailsSuccessAction(formattedData));
//         } else {
//           dispatch(
//             fetchTrackerSectionDetailsErrorAction('Something went wrong'),
//           );
//         }
//       } else {
//         dispatch(
//           fetchTrackerSectionDetailsSuccessAction([
//             { label: 'Name', value: '-' },
//             { label: 'ID', value: '-' },
//             { label: 'Sequence', value: '-' },
//             // { label: 'View Type', value: '-' },
//           ]),
//         );
//       }
//     } catch (e) {
//       dispatch(fetchTrackerSectionListErrorAction('Something went wrong'));
//     }
//   };
// }

export function addTrackerFn(data) {
  return async dispatch => {
    dispatch(addTrackerAction());
    try {
      const response = await API.addTracker(data);
      if (response.status === 200) {
        dispatch(addTrackerSuccessAction(response.data));
      } else {
        dispatch(addTrackerErrorAction('Something went wrong'));
      }
    } catch (e) {
      dispatch(addTrackerErrorAction('Something went wrong'));
    }
  };
}
