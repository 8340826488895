/*
 *
 * TrackerPage constants
 *
 */

export const FETCH_TRACKER_MERGE_ACTION =
  'app/TrackerPage/FETCH_TRACKER_MERGE_ACTION';

export const FETCH_TRACKER_SCORE_CARDS_ACTION =
  'app/TrackerPage/FETCH_TRACKER_SCORE_CARDS_ACTION';
export const FETCH_TRACKER_SCORE_CARDS_SUCCESS_ACTION =
  'app/TrackerPage/FETCH_TRACKER_SCORE_CARDS_SUCCESS_ACTION';
export const FETCH_TRACKER_SCORE_CARDS_ERROR_ACTION =
  'app/TrackerPage/FETCH_TRACKER_SCORE_CARDS_ERROR_ACTION';

export const FETCH_TRACKER_DETAILS_ACTION =
  'app/TrackerPage/FETCH_TRACKER_DETAILS_ACTION';
export const FETCH_TRACKER_DETAILS_SUCCESS_ACTION =
  'app/TrackerPage/FETCH_TRACKER_DETAILS_SUCCESS_ACTION';
export const FETCH_TRACKER_DETAILS_ERROR_ACTION =
  'app/TrackerPage/FETCH_TRACKER_DETAILS_ERROR_ACTION';

export const FETCH_TRACKER_SECTION_LIST_ACTION =
  'app/TrackerPage/FETCH_TRACKER_SECTION_LIST_ACTION';
export const FETCH_TRACKER_SECTION_LIST_SUCCESS_ACTION =
  'app/TrackerPage/FETCH_TRACKER_SECTION_LIST_SUCCESS_ACTION';
export const FETCH_TRACKER_SECTION_LIST_ERROR_ACTION =
  'app/TrackerPage/FETCH_TRACKER_SECTION_LIST_ERROR_ACTION';

export const FETCH_TRACKER_SECTION_DETAILS_ACTION =
  'app/TrackerPage/FETCH_TRACKER_SECTION_DETAILS_ACTION';
export const FETCH_TRACKER_SECTION_DETAILS_SUCCESS_ACTION =
  'app/TrackerPage/FETCH_TRACKER_SECTION_DETAILS_SUCCESS_ACTION';
export const FETCH_TRACKER_SECTION_DETAILS_ERROR_ACTION =
  'app/TrackerPage/FETCH_TRACKER_SECTION_DETAILS_ERROR_ACTION';

export const ADD_TRACKER_ACTION = 'app/TrackerPage/ADD_TRACKER_ACTION';
export const ADD_TRACKER_SUCCESS_ACTION =
  'app/TrackerPage/ADD_TRACKER_SUCCESS_ACTION';
export const ADD_TRACKER_ERROR_ACTION =
  'app/TrackerPage/ADD_TRACKER_ERROR_ACTION';
