import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { TourProvider } from '@reactour/tour';
import { steps } from '../../utils/steps';

// Import Components
import MainRoute from './MainRoute';

// Import redux store
import configureStore from 'redux/configureStore';

// Cache checking
import withClearCache from 'ClearCache';

const store = configureStore({});

/**
 *
 * @returns {React.Component} Main app component
 */
const MainApp = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <TourProvider
          steps={steps}
          // padding={{
          //   mask: 14,
          // }}
          styles={{
            popover: base => ({
              ...base,
              '--reactour-accent': '#ef6937',
              borderRadius: '5px',
            }),
            maskArea: base => ({ ...base, rx: '5px' }),
            maskWrapper: base => ({ ...base, color: '#333' }),
            // badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
            // controls: (base) => ({ ...base, marginTop: 100 }),
            close: base => ({ ...base, left: 'auto', right: 8, top: 8 }),
          }}
        >
          <MainRoute />
        </TourProvider>
      </BrowserRouter>
    </Provider>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

/**
 *
 * @returns {React.Component} Main app component wrapped with clear cache functionality
 */
const App = () => <ClearCacheComponent />;

export default App;
