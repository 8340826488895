function liveDataScoreCardTransformer(payload) {
  // Destructure the payload
  const { abv, item_per_order, item_price, item_sold, orders, sales } = payload;

  // Format the payload

  const formatedScorecardPayload = {
    sales: {
      value: sales || null,
      change: undefined,
    },
    orders: {
      value: orders || null,
      change: undefined,
    },
    abv: {
      value: abv || null,
      change: undefined,
    },
    itemsPerOrder: {
      value: item_per_order || null,
      change: undefined,
    },
    itemSold: {
      value: item_sold || null,
      change: undefined,
    },
    avgItemPrice: {
      value: item_price || null,
      change: undefined,
    },
  };

  return formatedScorecardPayload;
}

function itemScoreCardTransformer(payload) {
  // Destructure the payload
  const { Items_sold = {}, item_per_order = {}, Avg_item_price = {} } = payload;

  // Format the payload
  let formatedPayload = {};
  formatedPayload = {
    itemsSold: {
      delta: Items_sold.change,
      value: Items_sold.value,
    },
    percentageSold: {
      delta: payload['Percentage Item sold'].change,
      value: payload['Percentage Item sold'].value,
    },
    itemsPerOrder: {
      delta: item_per_order.change,
      value: item_per_order.value,
    },
    avgPrice: {
      delta: Avg_item_price.change,
      value: Avg_item_price.value,
    },
  };

  return formatedPayload;
}

function OperationsScoreCardTransformer(payload) {
  // Destructure the payload
  const {
    total_sales = {},
    gross_sales = {},
    refunds = {},
    total_abv = {},
    total_orders = {},
    table_occupancy = {},
  } = payload;

  // Format the payload
  const formatedPayload = {
    netSales: {
      delta: total_sales.change,
      value: total_sales.value,
    },
    grossSales: {
      delta: gross_sales?.change,
      value: gross_sales?.value,
    },
    refunds: {
      delta: refunds?.change,
      value: refunds?.value,
    },
    abv: {
      delta: total_abv.change,
      value: total_abv.value,
    },
    orders: {
      delta: total_orders.change,
      value: total_orders.value,
    },
    tableOccupancy: {
      delta: table_occupancy.change,
      value: table_occupancy.value,
    },
  };

  return formatedPayload;
}

function crossupSellScorecardTransformer(payload) {
  // Destructure the payload
  const {
    cross_impact = {},
    cross_sell = {},
    upsell_impact = {},
    upsell = {},
  } = payload;

  // Format the payload
  const formatedPayload = {
    crossImpact: {
      delta: cross_impact.change,
      value: cross_impact.value,
    },
    crossSell: {
      delta: cross_sell.change,
      value: cross_sell.value,
    },
    upsellImpact: {
      delta: upsell_impact.change,
      value: upsell_impact.value,
    },
    upSell: {
      delta: upsell.change,
      value: upsell.value,
    },
  };

  return formatedPayload;
}

function crossupSellScorecard2Transformer(payload) {
  // Destructure the payload
  const { data, view } = payload;
  const adoption =
    view === 'up' ? data['Adoption Upsell'] : data['Adoption Cross_sell'];
  const sales = view === 'up' ? data['Sales Upsell'] : data['Sales Cross_sell'];
  // Format the payload
  const formatedPayload = {
    adoption: {
      delta: adoption.change,
      value: adoption.value,
    },
    sales: {
      delta: sales.change,
      value: sales.value,
    },
  };

  return formatedPayload;
}

function aiPromotionScorecardTransformer(payload) {
  // Destructure the payload
  const {
    total_promotions_spend = {},
    item_promoted = {},
    promotion_per_cust = {},
    avg_promo_value_percent = {},
  } = payload;

  // Format the payload
  const formatedPayload = {
    totalPromoSpend: {
      delta: total_promotions_spend.change,
      value: total_promotions_spend.value,
    },
    promoSpendPerCust: {
      delta: promotion_per_cust.change,
      value: promotion_per_cust.value,
    },
    totalItemPromoted: {
      delta: item_promoted.change,
      value: item_promoted.value,
    },
    avgPromoValuePerOrder: {
      delta: avg_promo_value_percent.change,
      value: avg_promo_value_percent.value,
    },
  };

  return formatedPayload;
}

function csGroupAnalysisScorecardTransformer(payload) {
  // Destructure the payload
  const {
    total_groups = {},
    sales = {},
    group_abv = {},
    cost_per_person = {},
  } = payload;

  // Format the payload
  const formatedPayload = {
    groupTotal: {
      delta: total_groups.change,
      value: total_groups.value,
    },
    groupSales: {
      delta: sales.change,
      value: sales.value,
    },
    groupAbv: {
      delta: group_abv.change,
      value: group_abv.value,
    },
    costPerPerson: {
      delta: cost_per_person.change,
      value: cost_per_person.value,
    },
  };

  return formatedPayload;
}

function aiEngineLvsnlTopScorecardTransformer(payload) {
  const {
    sales_l = {},
    abv_l = {},
    orders_l = {},
    ltv = {},
    avg_overdue_days = {},
    avg_time_visit = {},
    retention_rate = {},
    dropout = {},
  } = payload;

  // Format the payload
  const formatedPayload = {
    netSales: {
      delta: sales_l?.change,
      value: sales_l?.value,
    },
    abvLogin: {
      delta: abv_l.change,
      value: abv_l.value,
    },
    ordersLogin: {
      delta: orders_l.change,
      value: orders_l.value,
    },
    ltvLogin: {
      delta: ltv.change,
      value: ltv.value,
    },
    avgOverdueDays: {
      delta: avg_overdue_days.change,
      value: avg_overdue_days.value,
    },
    avgTimeVisit: {
      delta: avg_time_visit.change,
      value: avg_time_visit.value,
    },
    retentionRate: {
      delta: retention_rate.change,
      value: retention_rate.value,
    },
    dropout: {
      delta: dropout.change,
      value: dropout.value,
    },
  };

  return formatedPayload;
}

function aiEngineLvsnlBottomScorecardTransformer(payload) {
  // Destructure the payload
  const {
    avg_overdue_days = {},
    avg_time_visit = {},
    retention_rate = {},
    dropout = {},
  } = payload;

  const formatedPayload = {
    avgOverdueDays: {
      delta: avg_overdue_days.change,
      value: avg_overdue_days.value,
    },
    avgTimeVisit: {
      delta: avg_time_visit.change,
      value: avg_time_visit.value,
    },
    retentionRate: {
      delta: retention_rate.change,
      value: retention_rate.value,
    },
    dropout: {
      delta: dropout.change,
      value: dropout.value,
    },
  };

  return formatedPayload;
}

function aiEngineNvsrTopScorecardTransformer(payload) {
  // Destructure the payload
  const {
    sales = {},
    abv = {},
    orders = {},
    ltv = {},
    avg_overdue_days = {},
    avg_time_visit = {},
    retention_rate = {},
    dropout = {},
  } = payload;

  // Format the payload
  const formatedPayload = {
    netSales: {
      delta: sales?.change,
      value: sales?.value,
    },
    abvLogin: {
      delta: abv.change,
      value: abv.value,
    },
    ordersLogin: {
      delta: orders.change,
      value: orders.value,
    },
    ltvLogin: {
      delta: ltv.change,
      value: ltv.value,
    },
    avgOverdueDays: {
      delta: avg_overdue_days.change,
      value: avg_overdue_days.value,
    },
    avgTimeVisit: {
      delta: avg_time_visit.change,
      value: avg_time_visit.value,
    },
    retentionRate: {
      delta: retention_rate.change,
      value: retention_rate.value,
    },
    dropout: {
      delta: dropout.change,
      value: dropout.value,
    },
  };

  return formatedPayload;
}

function aiEngineNvsrBottomScorecardTransformer(payload) {
  // Destructure the payload
  const {
    avg_overdue_days = {},
    avg_time_visit = {},
    retention_rate = {},
    dropout = {},
  } = payload;

  const formatedPayload = {
    avgOverdueDays: {
      delta: avg_overdue_days.change,
      value: avg_overdue_days.value,
    },
    avgTimeVisit: {
      delta: avg_time_visit.change,
      value: avg_time_visit.value,
    },
    retentionRate: {
      delta: retention_rate.change,
      value: retention_rate.value,
    },
    dropout: {
      delta: dropout.change,
      value: dropout.value,
    },
  };

  return formatedPayload;
}

function cashbackBottomScorecardTransformer(payload) {
  // Destructure the payload
  const {
    active_members = {},
    total_members = {},
    cb_lapsed_user_6_months = {},
  } = payload;

  // Format the payload

  const formatedScorecardPayload = {
    totalMembers: {
      value: total_members[0] || null,
      change: undefined,
    },
    activeMembers: {
      value: active_members[0] || null,
      change: undefined,
    },
    lapsedMembers: {
      value: cb_lapsed_user_6_months[0] || null,
      change: undefined,
    },
    lifetimeMembers: {
      value: null,
      change: undefined,
    },
  };

  return formatedScorecardPayload;
}

function cashbackTopScorecardTransformer(payload) {
  // Destructure the payload
  const {
    cb_earned = {},
    cb_lapsed_user = {},
    cb_new_users = {},
    cb_pending = {},
    cb_redeemed = {},
    cb_return_freq = {},
    cb_total_users = {},
    cb_user_abv = {},
  } = payload;

  // Format the payload
  const formatedScorecardPayload = {
    cbUsers: {
      delta: cb_total_users.change,
      value: cb_total_users.value,
    },
    newUsers: {
      delta: cb_new_users.change,
      value: cb_new_users.value,
    },
    returningUsers: {
      delta: null,
      value: null,
    },
    lapsedUsers: {
      delta: cb_lapsed_user.change,
      value: cb_lapsed_user.value,
    },
  };

  // Format the payload
  const formatedDatacardPayload = {
    cbUserABV: {
      delta: cb_user_abv.change,
      value: cb_user_abv.value,
    },
    cbUserReturnFr: {
      delta: cb_return_freq.change,
      value: cb_return_freq.value,
    },
    ltv: {
      delta: null,
      value: null,
    },
    cbEarned: {
      delta: cb_earned.change,
      value: cb_earned.value,
    },
    cbRedeemed: {
      delta: cb_redeemed.change,
      value: cb_redeemed.value,
    },
    cbPending: {
      delta: cb_pending.change,
      value: cb_pending.value,
    },
  };

  return {
    scorecardGroupData: formatedScorecardPayload,
    datacardGroupData: formatedDatacardPayload,
  };
}

function PaymentReconciliationTransformer(payload) {
  // Destructure the payload
  const {
    amount_collected = {},
    refund = {},
    pending_amount = {},
    net_settlement_amount = {},
    amount_paid = {},
    commission_fee = {},
  } = payload;

  // Format the payload
  const formatedPayload = {
    amountCollected: {
      delta: amount_collected.change || 0,
      value: amount_collected.value || 0,
    },
    refunds: {
      delta: refund.change || 0,
      value: refund.value || 0,
    },
    pendingAmount: {
      delta: pending_amount.change || 0,
      value: pending_amount.value || 0,
    },
    netSettlementAmount: {
      delta: net_settlement_amount.change || 0,
      value: net_settlement_amount.value || 0,
    },
    amountPaid: {
      delta: amount_paid.change || 0,
      value: amount_paid.value || 0,
    },
    commission: {
      delta: commission_fee.change || 0,
      value: commission_fee.value || 0,
    },
  };

  return formatedPayload;
}
function TipRemittanceTransformer(payload) {
  // Destructure the payload
  const {
    total_tip_amount = {},
    total_tip_fee = {},
    tip_to_settle = {},
    amount_after_fees = {},
  } = payload;

  // Format the payload
  const formatedPayload = {
    tipAmount: {
      delta: total_tip_amount.change || 0,
      value: total_tip_amount.value || 0,
    },
    fees: {
      delta: total_tip_fee.change || 0,
      value: total_tip_fee.value || 0,
    },
    tipToBeSettled: {
      delta: tip_to_settle.change || 0,
      value: tip_to_settle.value || 0,
    },
    totalTipCollected: {
      delta: amount_after_fees.change || 0,
      value: amount_after_fees.value || 0,
    },
  };

  return formatedPayload;
}

function PayoutTransformer(payload) {
  // Destructure the payload
  const {
    total_amount = {},
    refunds = {},
    transferred_amount = {},
    commission_fee = {},
  } = payload;

  // Format the payload
  const formatedPayload = {
    orderAmount: {
      delta: total_amount.change || 0,
      value: total_amount.value || 0,
    },
    refunds: {
      delta: refunds.change || 0,
      value: refunds.value || 0,
    },
    totalAmount: {
      delta: transferred_amount.change || 0,
      value: transferred_amount.value || 0,
    },
    commission: {
      delta: commission_fee.change || 0,
      value: commission_fee.value || 0,
    },
  };

  return formatedPayload;
}

function BenchmarkingOverviewTransformer(payload) {
  // Destructure the payload
  const {
    Total_sales = {},
    ABV = {},
    Total_orders = {},
    Total_customers = {},
  } = payload;

  // Format the payload
  //PS: here change is prev value
  const formatedPayload = {
    sales: {
      delta: Total_sales.change
        ? ((Total_sales.value - Total_sales.change) / Total_sales.change) * 100
        : null,
      value: Total_sales.value,
    },
    abv: {
      delta: ABV.change ? ((ABV.value - ABV.change) / ABV.change) * 100 : null,
      value: ABV.value,
    },
    orders: {
      delta: Total_orders.change
        ? ((Total_orders.value - Total_orders.change) / Total_orders.change) *
          100
        : null,
      value: Total_orders.value,
    },
    customers: {
      delta: Total_customers.change
        ? ((Total_customers.value - Total_customers.change) /
            Total_customers.change) *
          100
        : null,
      value: Total_customers.value,
    },
  };

  return formatedPayload;
}

function BenchmarkingPerformanceTransformer(payload) {
  // Destructure the payload
  const {
    Total_sales = {},
    ABV = {},
    Total_orders = {},
    price_per_item = {},
  } = payload;

  // Format the payload
  //PS: here change is prev value
  const formatedPayload = {
    sales: {
      delta: Total_sales.change
        ? ((Total_sales.value - Total_sales.change) / Total_sales.change) * 100
        : 0,
      value: Total_sales.value ?? 0,
    },
    abv: {
      delta: ABV.change ? ((ABV.value - ABV.change) / ABV.change) * 100 : 0,
      value: ABV.value ?? 0,
    },
    orders: {
      delta: Total_orders.change
        ? ((Total_orders.value - Total_orders.change) / Total_orders.change) *
          100
        : 0,
      value: Total_orders.value ?? 0,
    },
    pricePerItem: {
      delta: price_per_item.change
        ? ((price_per_item.value - price_per_item.change) /
            price_per_item.change) *
          100
        : 0,
      value: price_per_item.value ?? 0,
    },
  };

  return formatedPayload;
}

function BenchmarkingCustomerProfiling(payload) {
  // Destructure the payload
  const {
    Total_Login_Customers = {},
    Total_NonLogin_Customers = {},
    ABV_Login = {},
    ABV_Nonlogin = {},
  } = payload;

  // Format the payload
  const formatedPayload = {
    lCustomers: {
      delta: Total_Login_Customers.change ?? 0,
      value: Total_Login_Customers.value ?? 0,
    },
    nlCustomers: {
      delta: Total_NonLogin_Customers.change ?? 0,
      value: Total_NonLogin_Customers.value ?? 0,
    },
    lAbv: {
      delta: ABV_Login.change ?? 0,
      value: ABV_Login.value ?? 0,
    },
    nlAbv: {
      delta: ABV_Nonlogin.change ?? 0,
      value: ABV_Nonlogin.value ?? 0,
    },
  };

  return formatedPayload;
}

function BenchmarkingCfNvsr(payload) {
  // Destructure the payload
  const {
    customers_new = {},
    customers_repeat = {},
    abv_new = {},
    abv_repeat = {},
  } = payload;

  // Format the payload
  const formatedPayload = {
    nCustomers: {
      delta: customers_new.change ?? 0,
      value: customers_new.value ?? 0,
    },
    rCustomers: {
      delta: customers_repeat.change ?? 0,
      value: customers_repeat.value ?? 0,
    },
    nAbv: {
      delta: abv_new.change ?? 0,
      value: abv_new.value ?? 0,
    },
    rAbv: {
      delta: abv_repeat.change ?? 0,
      value: abv_repeat.value ?? 0,
    },
  };

  return formatedPayload;
}

function MasterMerchantTransformer(payload) {
  // Destructure the payload
  const {
    sales_incl_promo = {},
    sales_excl_promo = {},
    abv_incl_promo = {},
    abv_excl_promo = {},
    merchant_promo = {},
    orders = {},
  } = payload;

  // Format the payload
  const formatedPayload = {
    salesInclPromo: {
      delta: sales_incl_promo.change,
      value: sales_incl_promo.value,
    },
    salesExclPromo: {
      delta: sales_excl_promo.change,
      value: sales_excl_promo.value,
    },
    abvInclPromo: {
      delta: abv_incl_promo.change,
      value: abv_incl_promo.value,
    },
    abvExclPromo: {
      delta: abv_excl_promo.change,
      value: abv_excl_promo.value,
    },
    merchantPromo: {
      delta: merchant_promo.change,
      value: merchant_promo.value,
    },
    orders: {
      delta: orders.change,
      value: orders.value,
    },
  };

  return formatedPayload;
}

function PromotionUsageTransformer(payload) {
  // Destructure the payload
  const {
    total_orders,
    promo_orders,
    promo_discount,
    promo_order_amount,
    avg_promo_discount,
    roi,
    perc_promo_orders,
    total_sales,
  } = payload;

  // Format the payload

  const formatedScorecardPayload = {
    totalOrders: {
      value: total_orders.value,
      change: total_orders.change,
    },
    promoOrders: {
      value: promo_orders.value,
      change: promo_orders.change,
    },
    avgPromoDiscounts: {
      value: avg_promo_discount.value,
      change: avg_promo_discount.change,
    },
    discount: {
      value: promo_discount.value,
      change: promo_discount.change,
    },
    amount: {
      value: promo_order_amount.value,
      change: promo_order_amount.change,
    },
    netSales: {
      value: total_sales.value,
      change: total_sales.change,
    },
    roi: {
      value: roi.value,
      change: roi.change,
    },
    percPromoOrders: {
      value: perc_promo_orders.value,
      change: perc_promo_orders.change,
    },
  };

  return formatedScorecardPayload;
}

const DayEndReportTransformer = payload => ({
  discount: payload.discount,
  refunds: payload.refunds,
  serviceCharge: payload.service_charge,
  subTotal: payload.sub_total,
  tax: payload.taxes,
  total: payload.total,
  orders: payload.total_orders,
  rounding: payload.total_rounding,
  includeGst: payload.restIncludeGst,
});

const posDataTransformer = payload => {
  const {
    order_no,
    total_discount,
    order_total,
    tabsquare_per,
    tabsquare_sales,
    total_amount,
    total_charge,
    refund_amount,
    total_tax,
  } = payload;

  const formattedScorecardPayload = {
    orderNo: {
      value: order_no,
      change: 0,
    },
    totalDiscount: {
      value: total_discount,
      change: 0,
    },
    orderTotal: {
      value: order_total,
      change: 0,
    },
    tabsquareContribution: {
      value: tabsquare_sales,
      change: tabsquare_per,
    },
    totalAmount: {
      value: total_amount,
      change: 0,
    },
    totalCharge: {
      value: total_charge,
      change: 0,
    },
    totalRefund: {
      value: refund_amount,
      change: 0,
    },
    totalTax: {
      value: total_tax,
      change: 0,
    },
  };
  return formattedScorecardPayload;
};

const CancelledOrdersTransformer = payload => {
  const {
    total_cancelled_orders = {},
    cancelled_orders_percentage = {},
    total_cancelled_amount = {},
    cancelled_sales_percentage = {},
  } = payload;
  return {
    cancelledOrders: {
      value: total_cancelled_orders.value ?? 0,
      delta: total_cancelled_orders.change ?? 0,
    },
    cancelledOrdersPercentage: {
      value: cancelled_orders_percentage.value ?? 0,
      delta: cancelled_orders_percentage.change ?? 0,
    },
    cancelledSales: {
      value: total_cancelled_amount.value ?? 0,
      delta: total_cancelled_amount.change ?? 0,
    },
    cancelledSalesPercentage: {
      value: cancelled_sales_percentage.value ?? 0,
      delta: cancelled_sales_percentage.change ?? 0,
    },
  };
};

const LostRevenueTransformer = payload => {
  const {
    total_revenue_lost = {},
    percent_total_sales = {},
    total_stock_out_items = {},
    total_pause_time = {},
  } = payload;
  return {
    revenueLost: {
      value: total_revenue_lost.value ?? 0,
      delta: total_revenue_lost.change ?? 0,
    },
    revenueLostPerc: {
      value: percent_total_sales.value ?? 0,
      delta: percent_total_sales.change ?? 0,
    },
    stockoutItems: {
      value: total_stock_out_items.value ?? 0,
      delta: total_stock_out_items.change ?? 0,
    },
    orderpauseDuration: {
      value: total_pause_time.value ?? 0,
      delta: total_pause_time.change ?? 0,
    },
  };
};
const LostRevenueLiveDataTransformer = payload => {
  const { category_count, dish_count, sku_count, sub_category_count } = payload;
  return {
    stockoutCategories: {
      value: category_count ?? 0,
    },
    stockoutItems: {
      value: dish_count ?? 0,
    },
    stockoutSku: {
      value: sku_count ?? 0,
    },
    stockoutSubCategories: {
      value: sub_category_count ?? 0,
    },
  };
};

const TerminalAnalyticsScoreCardTransformer = payload => {
  const { amount, orders, refund, transfer_amount,abv,refund_count } = payload;
  return {
    totalAmountCollected: {
      value: amount.value,
      delta: amount.change,
    },
    refund: {
      value: refund.value,
      delta: refund.change,
    },
    abv: {
      value: transfer_amount.value,
      delta: transfer_amount.change,
    },
    totalCount: {
      value: orders.value,
      delta: orders.change,
    },
    avgBillValue: {
      value: abv.value,
      delta: abv.change,
    },
    refundCount: {
      value: refund_count.value,
      delta: refund_count.change,
    },
  };
};

const TrackerScoreCardTransformer = payload => {
  const { average_impact, category_count, item_count, sc_count } = payload;
  return {
    netImpact: {
      delta: average_impact ?? 0,
    },
    ongoingCategoryTracker: {
      value: category_count ?? 0,
    },
    ongoingItemTracker: {
      value: item_count ?? 0,
    },
    ongoingSubCategoryTracker: {
      value: sc_count ?? 0,
    },
  };
};

// All transformer functions
const transformers = {
  operations: OperationsScoreCardTransformer,
  item: itemScoreCardTransformer,
  crossupSellTop: crossupSellScorecardTransformer,
  crossupSellBottom: crossupSellScorecard2Transformer,
  aiPromotion: aiPromotionScorecardTransformer,
  csGroupAnalysis: csGroupAnalysisScorecardTransformer,
  aiEngineLvsnlTop: aiEngineLvsnlTopScorecardTransformer,
  aiEngineLvsnlBottom: aiEngineLvsnlBottomScorecardTransformer,
  aiEngineNvsrTop: aiEngineNvsrTopScorecardTransformer,
  aiEngineNvsrBottom: aiEngineNvsrBottomScorecardTransformer,
  cashbackTop: cashbackTopScorecardTransformer,
  cashbackBottom: cashbackBottomScorecardTransformer,
  liveData: liveDataScoreCardTransformer,
  paymentReconciliation: PaymentReconciliationTransformer,
  tipRemittance: TipRemittanceTransformer,
  payouts: PayoutTransformer,
  bmOverview: BenchmarkingOverviewTransformer,
  bmPerformance: BenchmarkingPerformanceTransformer,
  bmCustomerProfiling: BenchmarkingCustomerProfiling,
  bmCfNvsr: BenchmarkingCfNvsr,
  masterMerchant: MasterMerchantTransformer,
  promotionUsage: PromotionUsageTransformer,
  dayEndReport: DayEndReportTransformer,
  cancelledOrders: CancelledOrdersTransformer,
  lostRevenue: LostRevenueTransformer,
  lostRevenueLiveData: LostRevenueLiveDataTransformer,
  POS: posDataTransformer,
  terminalAnalytics: TerminalAnalyticsScoreCardTransformer,
  tracker: TrackerScoreCardTransformer,
};

/**
 * Format Scorecard data for the scorecard component
 *
 * @param {Object} payload - The payload to be formatted
 * @param {String} type - The type of the payload
 * @returns {Object} formatted payload
 */
const scorecardDataTransformer = (payload, type) => {
  if (
    typeof payload !== 'object' ||
    typeof type !== 'string' ||
    type.length <= 0
  ) {
    console.error('Unsupported format');
  } else {
    return transformers[type](payload);
  }
};

export default scorecardDataTransformer;
