/*
 *
 * benchmarking common constants
 *
 */

export const FETCH_ZONE_LIST_ACTION = 'app/Benchmarking/FETCH_ZONE_LIST_ACTION';

export const FETCH_ZONE_LIST_SUCCESS_ACTION =
  'app/Benchmarking/FETCH_ZONE_LIST_SUCCESS_ACTION';

export const FETCH_ZONE_LIST_ERROR_ACTION =
  'app/Benchmarking/FETCH_ZONE_LIST_ERROR_ACTION';

export const SET_SELECTED_ZONES = 'app/Benchmarking/SET_SELECTED_ZONES';

export const SET_SELECTED_LOCATIONS = 'app/Benchmarking/SET_SELECTED_LOCATIONS';
