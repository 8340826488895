// Import Libs
import { takeLatest, call, put } from 'redux-saga/effects';

// Import services
import * as api from './services';

// Import transformers
import * as fm from './formatter';
import * as cn from './constants';
import * as a from './actions';

import scorecardDataTransformer from 'transformers/scorecardDataTransformer';

// Async fetch
function* fetchScores({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(api.getScores, payload);

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getScoresError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getScoresError('locked'));
      } else {
        const formattedData = scorecardDataTransformer(
          responseData[0] ?? {},
          'lostRevenue',
        );
        // Feed the data to reducer
        yield put(a.getScoresSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getScoresError('Something went wrong!'));
  }
}
function* fetchLiveDataScores({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getLiveDataScores,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getLiveDataScoresError(error_message));
      } else {
        const formattedData = scorecardDataTransformer(
          responseData[0] ?? {},
          'lostRevenueLiveData',
        );
        // Feed the data to reducer
        yield put(a.getLiveDataScoresSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getLiveDataScoresError('Something went wrong!'));
  }
}

function* fetchContribution({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getContribution,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message, premium_lock } = responseData;

      if (error_message) {
        yield put(a.getContributionError(error_message));
      } else if (premium_lock === false) {
        yield put(a.getContributionError('locked'));
      } else {
        const formattedData = fm.contributionFormatter(responseData[0] ?? {});
        // Feed the data to reducer
        yield put(a.getContributionSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getContributionError('Something went wrong!'));
  }
}

function* fetchTrend({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(api.getTrend, payload);

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getTrendError(error_message));
      } else {
        yield put(
          a.getTrendSuccess(
            responseData?.data?.length ? responseData.data : [],
          ),
        );
      }
    }
  } catch (e) {
    yield put(a.getTrendError('Something went wrong!'));
  }
}
function* fetchCategoryItem({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getCategoryItem,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error } = responseData;
      if (error) {
        yield put(a.getCategoryItemError(error));
      } else {
        yield put(
          a.getCategoryItemSuccess(responseData?.length ? responseData : []),
        );
      }
    }
  } catch (e) {
    yield put(a.getCategoryItemError('Something went wrong!'));
  }
}
function* fetchOutletWise({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getOutletWise,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getOutlewiseError(error_message));
      } else {
        yield put(
          a.getOutlewiseSuccess(responseData?.length ? responseData : []),
        );
      }
    }
  } catch (e) {
    yield put(a.getOutlewiseError('Something went wrong!'));
  }
}

function* fetchLiveDataOutletWise({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getLiveDataOutletWise,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getLiveDataOutletWiseError(error_message));
      } else {
        yield put(
          a.getLiveDataOutletWiseSuccess(
            responseData?.length ? responseData : [],
          ),
        );
      }
    }
  } catch (e) {
    yield put(a.getLiveDataOutletWiseError('Something went wrong!'));
  }
}

function* fetchTableStockout({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getTableStockout,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getTableStockoutError(error_message));
      } else {
        const formattedData = fm.tableStockoutFormatter(responseData);
        // Feed the data to reducer
        yield put(a.getTableStockoutSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getTableStockoutError('Something went wrong!'));
  }
}

function* fetchLiveDataStockoutTable({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getLiveDataStockoutTable,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error } = responseData;

      if (error) {
        yield put(a.getLiveDataTableStockoutError(error));
      } else {
        // Feed the data to reducer
        yield put(
          a.getLiveDataTableStockoutSuccess(
            responseData.length ? responseData : [],
          ),
        );
      }
    }
  } catch (e) {
    yield put(a.getLiveDataTableStockoutError('Something went wrong!'));
  }
}

function* fetchTableOrderpause({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getTableOrderPause,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getTableOrderpauseError(error_message));
      } else {
        const formattedData = fm.tableOrderpauseFormatter(
          responseData.length ? responseData : [],
        );
        // Feed the data to reducer
        yield put(a.getTableOrderpauseSuccess(formattedData));
      }
    }
  } catch (e) {
    yield put(a.getTableOrderpauseError('Something went wrong!'));
  }
}

function* fetchliveDataTableOrderpause({ payload }) {
  try {
    // Get the response from API call
    const { data: responseData, status } = yield call(
      api.getLiveDataTableOrderPause,
      payload,
    );

    if (status === 200 || (status === 206 && responseData)) {
      const { error_message } = responseData;

      if (error_message) {
        yield put(a.getLiveDataTableOrderpauseError(error_message));
      } else {
        // Feed the data to reducer
        yield put(
          a.getLiveDataTableOrderpauseSuccess(
            responseData.length ? responseData : [],
          ),
        );
      }
    }
  } catch (e) {
    yield put(a.getLiveDataTableOrderpauseError('Something went wrong!'));
  }
}

// watcher Saga generator function
export default function* watchActions() {
  yield takeLatest(cn.SCORES_INIT, fetchScores);
  yield takeLatest(cn.CONTRIBUTION_INIT, fetchContribution);
  yield takeLatest(cn.TREND_INIT, fetchTrend);
  yield takeLatest(cn.TABLE_STOCKOUT_INIT, fetchTableStockout);
  yield takeLatest(
    cn.LIVE_DATA_TABLE_STOCKOUT_INIT,
    fetchLiveDataStockoutTable,
  );
  yield takeLatest(cn.TABLE_ORDERPAUSE_INIT, fetchTableOrderpause);
  yield takeLatest(
    cn.LIVE_DATA_TABLE_ORDERPAUSE_INIT,
    fetchliveDataTableOrderpause,
  );
  yield takeLatest(cn.CATEGORY_ITEM_INIT, fetchCategoryItem);
  yield takeLatest(cn.OUTLETWISE_INIT, fetchOutletWise);
  yield takeLatest(cn.LIVE_DATA_OUTLETWISE_INIT, fetchLiveDataOutletWise);
  yield takeLatest(cn.LIVE_DATA_SCORES_INIT, fetchLiveDataScores);
}
